import { Modal, Card, CardContent, IconButton } from '@material-ui/core';
import { CloseIcon } from '@/components/Icons';
import { TaxEventForm } from '@/components/Forms';
import { useMemo } from 'react';
import { parseISO } from 'date-fns';
import { getNextTaxEventDate } from '@/constants/tax-event.constants';
import useStyles from '../styles';
import { IEditTaxEventModalProps } from './types';

const EditTaxEventModal = ({
  open,
  onClose,
  onSubmit,
  initialValues,
}: IEditTaxEventModalProps): JSX.Element => {
  const classes = useStyles();

  const formInitialValues = useMemo(
    () => ({
      id: initialValues?.id,
      targetDate: initialValues?.targetDate
        ? parseISO(initialValues.targetDate)
        : getNextTaxEventDate(),
      amountUah: initialValues?.amountUah ?? 0,
    }),
    [initialValues?.amountUah, initialValues?.id, initialValues?.targetDate],
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modalWrap}
      disableEnforceFocus
      disableAutoFocus
    >
      <Card className={classes.smallModalCard}>
        <CardContent className={classes.modalCardContent}>
          <IconButton
            aria-label="delete"
            className={classes.closeIconWrap}
            size="small"
            onClick={onClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
          <TaxEventForm
            onSubmit={onSubmit ?? (() => Promise.resolve())}
            initialValues={formInitialValues}
          />
        </CardContent>
      </Card>
    </Modal>
  );
};

export default EditTaxEventModal;
