import FullScreenDialog from '@/components/FullScreenDialog';
import { ReportForm } from '@/components/Forms';
import useStyles from './styles';
import { IProjectDialogProps } from './types';

const ReportDialog = ({
  openDialog,
  handleDialogClose,
  report,
  setReport,
  onSubmitHandler,
  user,
  onSuccessDelete,
  ...props
}: IProjectDialogProps): JSX.Element => {
  const classes = useStyles();

  return (
    <FullScreenDialog open={openDialog} handleDialogClose={handleDialogClose}>
      <div {...props}>
        <div className={classes.attachUserBlock}>
          <ReportForm
            user={user}
            report={report}
            setReport={setReport}
            handleClose={handleDialogClose}
            onSubmitHandler={onSubmitHandler}
            onSuccessDelete={onSuccessDelete}
          />
        </div>
      </div>
    </FullScreenDialog>
  );
};

export default ReportDialog;
