import CustomCheckBox from '../CustomCheckBox';
import { ICheckboxGroupProps } from './types';
import useCheckboxGroup from './useCheckboxGroup';

export const CheckboxGroup = ({
  options,
  onChange,
  name,
  values,
  onBlur,
  ...rest
}: ICheckboxGroupProps) => {
  const { handleChange } = useCheckboxGroup({ onChange, values, name });

  return (
    <>
      {options.map((el, i) => (
        <CustomCheckBox
          key={i}
          name={name}
          label={el.label}
          checked={values === el.value}
          onChange={handleChange(el.value)}
          onBlur={onBlur}
          {...rest}
        />
      ))}
    </>
  );
};

export default CheckboxGroup;
