import { Button, Card, CardContent } from '@material-ui/core';
import { useEffect, useState } from 'react';
import DesktopRangeDatePicker from '@/components/DesktopRangeDatePicker';
import { DateRange } from '@mui/lab';
import { getYtdAndNextYearDateRangePickerState } from '@/constants';
import { EditTaxEventModal } from '@/components/Modals';
import { ITaxDataFormValues } from '@/components/Forms/TaxEventForm/types';
import taxEventsService from '@/services/tax-events.service';
import { toast } from 'react-toastify';
import { ITaxesTableProps } from './types';
import useStyles from './styles';
import { ITaxData } from '../TaxesTable/types';
import TaxesTable from '../TaxesTable';

export default function UserTaxesTable({ user, ...props }: ITaxesTableProps): JSX.Element {
  const classes = useStyles();

  const [date, setDate] = useState<DateRange<Date | null>>(getYtdAndNextYearDateRangePickerState());
  const [taxData, setTaxData] = useState<ITaxData[]>([]);
  const [editModalTaxData, setEditModalTaxData] = useState<ITaxData | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const onSubmitTaxData = async ({ id, ...data }: ITaxDataFormValues) => {
    try {
      if (id) {
        const updatedTaxEvent = await taxEventsService.editTaxEvent(id, {
          ...data,
          user: user.id,
        });
        setTaxData((old) => old.map((o) => (o.id === id ? updatedTaxEvent : o)));
      } else {
        const createdTaxEvent = await taxEventsService.createTaxEvent({
          ...data,
          user: user.id,
        });
        setTaxData((old) => [createdTaxEvent, ...old]);
      }
    } catch (e) {
      if (typeof e === 'object' && e && 'message' in e) {
        toast.error(e.message);
      }
    }
    setModalOpen(false);
    setEditModalTaxData(null);
  };

  const onDeleteTaxData = async (taxId: string) => {
    try {
      await taxEventsService.deleteTaxEvent(taxId);
      setTaxData((old) => old.filter((o) => o.id !== taxId));
    } catch (e) {
      if (typeof e === 'object' && e && 'message' in e) {
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    const filter = {
      from: date[0] ?? undefined,
      to: date[1] ?? undefined,
      userId: user.id,
    };
    let isMounted = true;

    taxEventsService.getTaxEvents(filter).then((data) => {
      if (isMounted) {
        setTaxData(data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [date, user.id]);

  return (
    <>
      <div className={classes.datePicker}>
        <div className={classes.datePickerInput}>
          <DesktopRangeDatePicker date={date} setDate={setDate} />
        </div>
        <Button
          color="primary"
          onClick={() => setModalOpen(true)}
          variant="outlined"
          className={classes.button}
        >
          Add Tax payment event
        </Button>
      </div>
      <Card className={classes.card} {...props}>
        <CardContent>
          <TaxesTable
            user={user}
            taxData={taxData}
            onEdit={(data) => {
              setEditModalTaxData(data);
              setModalOpen(true);
            }}
            onDelete={(data) => onDeleteTaxData(data.id)}
          />
        </CardContent>
      </Card>
      <EditTaxEventModal
        open={isModalOpen}
        initialValues={editModalTaxData ?? undefined}
        onClose={() => {
          setEditModalTaxData(null);
          setModalOpen(false);
        }}
        onSubmit={onSubmitTaxData}
      />
    </>
  );
}
