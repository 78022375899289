import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 28,
    lineHeight: '35px',
    letterSpacing: '0.16px',
    margin: theme.spacing(3.75, 2.5, 5.875),
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      fontWeight: 400,
      margin: theme.spacing(1),
    },
  },
  card: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      borderRadius: 0,
      border: theme.palette.type === 'dark' ? 'none' : '1px solid #E8E9E8',
      padding: '0 115px',
    },
  },
  accordion: {
    boxShadow: 'none',
    '&.Mui-expanded': {
      margin: 0,
    },
    '&:before': {
      opacity: 0,
    },
    '&:not(:last-of-type)': {
      borderBottom:
        theme.palette.type === 'dark'
          ? '1px solid rgba(255, 255, 255, 0.12)'
          : '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  summary: {
    minHeight: 89,
    '&.Mui-expanded': {
      minHeight: 89,
    },
  },
  details: {
    flexDirection: 'column',
    paddingBottom: theme.spacing(2.5),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(7.5),
    },
  },
  img: {
    maxWidth: 786,
    maxHeight: 335,
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(7.5),
    },
  },
}));

export default useStyles;
