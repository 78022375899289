import { useEffect } from 'react';
import {
  earningCardSelector,
  loadUserOverallStatistic,
  loadUserProfile,
  overallMinutesSelector,
  overallSalarySelector,
  userSelector,
} from '@/redux';
import { Theme, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

const useDashboard = (month: number) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserProfile());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadUserOverallStatistic({ month, type: 'month' }));
  }, [dispatch, month]);

  const user = useSelector(userSelector);

  const overallMinutes = useSelector(overallMinutesSelector);
  const overallSalary = useSelector(overallSalarySelector);
  const earningCardProps = useSelector(earningCardSelector);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return {
    user,
    overallMinutes,
    overallSalary,
    earningCardProps,
    isDesktop,
  };
};

export default useDashboard;
