import React from 'react';
import { ButtonGroup, Button, Select, MenuItem } from '@material-ui/core';
import { Periods } from '@/types/periods';
import clsx from 'clsx';
import useStyles from './styles';
import { INavButtonGroupProps } from './types';

const NavButtonsGroup: React.FC<INavButtonGroupProps> = ({
  state,
  setChanges,
  userCard,
  isDesktop,
  ...props
}: INavButtonGroupProps) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setChanges(event.target.value as Periods);
  };

  return (
    <div className={classes.navBlock} {...props}>
      {isDesktop ? (
        <ButtonGroup
          size="large"
          className={clsx(userCard ? classes.userBtnGroup : classes.btnGroup)}
          color="primary"
        >
          {Object.values(Periods).map((key) => (
            <Button
              key={key}
              className={
                state === key
                  ? clsx(userCard ? classes.userActiveBtn : classes.activeBtn)
                  : classes.btn
              }
              onClick={() => setChanges(key)}
            >
              {key === Periods.Week ? 'Current week' : key}
            </Button>
          ))}
        </ButtonGroup>
      ) : (
        <div>
          <Select
            value={state}
            onChange={handleChange}
            displayEmpty
            className={classes.select}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {Object.values(Periods).map((key) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </div>
  );
};

export default NavButtonsGroup;
