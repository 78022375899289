import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import auth from '@DevimaSolutions/o-auth';
import AppPopper from '@/components/AppPopper';
import { Button, Typography } from '@material-ui/core';
import { userRoles } from '@/constants';
import { IUserPopperProps } from './types';
import useStyles from './styles';

const UserPopper: React.FC<IUserPopperProps> = ({ className, user, ...props }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const history = useHistory();

  const onClickHandler = useCallback(
    (path: string) => {
      setOpen(false);
      history.push(path);
    },
    [setOpen, history],
  );

  const logOutHandler = useCallback(() => {
    auth().signOut();
  }, []);

  const openPopperHandler = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);
  return (
    <AppPopper
      placement="bottom-end"
      className={className}
      open={open}
      onClose={() => setOpen(false)}
      content={
        <MenuList className={classes.list}>
          {user?.role !== userRoles.admin && (
            <>
              <MenuItem onClick={() => onClickHandler('/profile')} className={classes.listItem}>
                <ListItemIcon className={classes.listIcon}>
                  <PersonOutlineIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText disableTypography className={classes.listText} primary="Profile" />
              </MenuItem>
              <Divider />
            </>
          )}
          <MenuItem onClick={logOutHandler} className={classes.listItem}>
            <ListItemIcon className={classes.listIcon}>
              <PowerSettingsNewIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText disableTypography className={classes.listText} primary="Log Out" />
          </MenuItem>
        </MenuList>
      }
      {...props}
    >
      <div className={classes.userBlock}>
        <Button disableRipple onClick={openPopperHandler} className={classes.btn}>
          <Typography className={classes.userName}>{user?.fullName}</Typography>
          <Avatar className={classes.avatar} alt="Remy Sharp" src={user?.photo} />
        </Button>
      </div>
    </AppPopper>
  );
};

export default UserPopper;
