import { Modal, Card, CardContent, IconButton } from '@material-ui/core';
import { CloseIcon } from '@/components/Icons';
import { EventForm } from '@/components/Forms';
import useStyles from '../styles';
import { ICreateEventModalProps } from '../types';

const EventModal = ({
  open,
  closeModalHandler,
  onSubmitHandler,
  event,
}: ICreateEventModalProps): JSX.Element => {
  const classes = useStyles();

  const closeModal = () => {
    closeModalHandler(false);
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      className={classes.modalWrap}
      disableEnforceFocus
      disableAutoFocus
    >
      <Card className={classes.modalCard}>
        <CardContent className={classes.modalCardContent}>
          <IconButton
            aria-label="delete"
            className={classes.closeIconWrap}
            size="small"
            onClick={closeModal}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
          <EventForm event={event} onSubmitHandler={onSubmitHandler} />
        </CardContent>
      </Card>
    </Modal>
  );
};

export default EventModal;
