import { FormGroup, Grid, Button } from '@material-ui/core';
import { PasswordInput } from '@/components/FormItems';
import useStyles from './styles';
import useChangePasswordForm from './useChangePasswordForm';

const ChangePasswordForm = (): JSX.Element => {
  const classes = useStyles();

  const { handleSubmit, handleChange, handleBlur, values, touched, errors, changePasswordLoading } =
    useChangePasswordForm();
  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid container item spacing={3} xs={12} md={11}>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <PasswordInput
                  id="oldPassword"
                  label="Old password"
                  name="oldPassword"
                  placeholder="••••••••"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.oldPassword || ''}
                  error={touched?.oldPassword && !!errors?.oldPassword}
                  helperText={touched?.oldPassword && errors?.oldPassword}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <PasswordInput
                  id="password"
                  label="New password"
                  name="password"
                  placeholder="••••••••"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.password || ''}
                  error={touched?.password && !!errors?.password}
                  helperText={touched?.password && errors?.password}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <PasswordInput
                  id="passwordConfirmation"
                  label="Confirm password*"
                  name="passwordConfirmation"
                  placeholder="••••••••"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.passwordConfirmation || ''}
                  error={touched?.passwordConfirmation && !!errors?.passwordConfirmation}
                  helperText={touched?.passwordConfirmation && errors?.passwordConfirmation}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              classes={{
                root: classes.button,
                label: classes.buttonLabel,
              }}
              disabled={changePasswordLoading}
            >
              Change password
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
