import { useSelector } from 'react-redux';
import { blurSelector } from '@/redux';

const useStatisticCard = () => {
  const isDashboardVisible = useSelector(blurSelector);

  return { isVisible: !isDashboardVisible };
};

export default useStatisticCard;
