import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function AddCircleIcon({ viewBox = '0, 0, 80, 80', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <g filter="url(#filter0_d_2754:1750)">
        <path
          d="M40.0005 61.0001C53.8076 61.0001 65.0005 49.8072 65.0005 36.0001C65.0005 22.193 53.8076 11.0001 40.0005 11.0001C26.1934 11.0001 15.0005 22.193 15.0005 36.0001C15.0005 49.8072 26.1934 61.0001 40.0005 61.0001Z"
          fill="white"
        />
        <path
          d="M40 26.0001V46.0001"
          stroke="#5535B8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.0005 36H50.0005"
          stroke="#5535B8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2754:1750"
          x="-5"
          y="-5"
          width="90"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2754:1750" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2754:1750"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  );
}
