import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      minWidth: '8.75rem',
      padding: 0,
      alignItems: 'center',
      marginTop: '57px',
      marginBottom: '35px',
      overflow: 'auto',
      maxHeight: '485px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column-reverse',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    listIcon: {
      minWidth: '14px',
      marginRight: '7px',
      '& > svg': {
        width: '14px',
        height: '14px',
      },
    },
    listHeader: {
      position: 'fixed',
      top: 0,
      zIndex: 10,
      backgroundColor: theme.palette.primary.main,
      width: '100%',
      borderTopLeftRadius: '7px',
      borderTopRightRadius: '7px',
      display: 'flex',
      justifyContent: 'center',
    },
    contentStyle: {
      padding: 0,
    },
    userBlock: {
      display: 'flex',
    },
    listText: {
      fontSize: '13px',
    },
    listItem: {
      background: theme.palette.background.paper,
      width: '100%',
      borderRadius: 0,
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      minHeight: '50px',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    duration: {
      color: theme.palette.text.primary,
    },
    container: {
      borderRadius: '9px',
      overflow: 'hidden',
    },
    button: {
      textTransform: 'none',
      borderRadius: 0,
      width: '100%',
      color: theme.palette.common.white,
    },
    buttonBlock: {
      width: '100%',
      background: theme.palette.primary.main,
      borderBottomLeftRadius: '7px',
      borderBottomRightRadius: '7px',
      position: 'fixed',
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
    },
    btn: {
      height: '40px',
    },
    type: {
      whiteSpace: 'break-spaces',
      margin: '4px 7px',
    },
    birthday: {
      color: theme.palette.basic.blue,
    },
    warning: {
      color: theme.palette.error.main,
    },
    meeting: {
      color: theme.palette.basic.blue,
    },
    approval: {
      color: theme.palette.success.main,
    },
    requested: {
      color: theme.palette.basic.blue,
    },
    event: {
      color: theme.palette.basic.purple,
    },
    popperBackground: {
      '& .MuiBox-root': {
        background: theme.palette.primary.main,
        borderRadius: '6px',
      },
    },
    arrowStyle: {
      color: theme.palette.primary.main,
      width: '105px',
      height: '100px',
      marginTop: '-45px !important',
      marginLeft: '-36px !important',
      marginRight: '0px !important',
      zIndex: -10,
    },
    paper: {
      borderTop: 'none',
      maxWidth: '362px',
      width: '362px',
    },
    title: {
      fontSize: '18px',
      color: theme.palette.common.white,
      lineHeight: '25px',
      fontWeight: 600,
      margin: '16px 0px',
    },
  }),
);

export default useStyles;
