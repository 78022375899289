import { useCallback, ChangeEvent, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  IconButton,
  Typography,
  Divider,
} from '@material-ui/core';
import { BackArrow, EditIcon, ProjectIcon } from '@/components/Icons';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { userRoles } from '@/constants';
import useStyles from './styles';
import { IUserAccordionProps } from './types';

const UserAccordion = ({
  user,
  editUser,
  toggleModal,
  toggleUserProjectModal,
  ...props
}: IUserAccordionProps): JSX.Element => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | null>(null);

  const attachProject = useCallback(() => {
    toggleUserProjectModal?.(true);
  }, [toggleUserProjectModal]);

  const handleChange = useCallback(
    (panel: string) => (event: ChangeEvent<unknown>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : null);
    },
    [],
  );

  const edit = () => {
    editUser(user);
    toggleModal(true);
  };
  return (
    <div {...props}>
      <Accordion
        className={classes.accordionStyle}
        square
        expanded={expanded === user.id}
        onChange={handleChange(user.id)}
      >
        <AccordionSummary
          expandIcon={<BackArrow className={classes.arrow} />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <div className={classes.userBlock}>
            <Avatar
              className={classes.avatar}
              alt={user?.firstName}
              src={user?.photo || '/src/assets/svg/logo.svg'}
            />
            <div>
              <Typography className={classes.name} component="span">
                {user.firstName} {user.lastName}
              </Typography>
              <Typography
                className={clsx(classes.status, {
                  [classes.disactiveStatus]: user.status !== 'Active',
                })}
              >
                {user.status}
              </Typography>
            </div>
          </div>
          <div className={classes.actionButtons}>
            <IconButton className={classes.editIcon} color="inherit" onClick={edit}>
              <EditIcon />
            </IconButton>
            {user.role !== userRoles.admin && user.status !== 'Inactive' && (
              <IconButton color="inherit" onClick={attachProject}>
                <ProjectIcon />
              </IconButton>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div className={classes.userInfoRow}>
              <Typography className={classes.infoTitle}>Email:</Typography>
              <Typography className={classes.infoText}>{user.email}</Typography>
            </div>
            <div className={classes.userInfoRow}>
              <Typography className={classes.infoTitle}>Phone:</Typography>
              <Typography className={classes.infoText}>{user.phoneNumber}</Typography>
            </div>
            <div className={classes.userInfoRow}>
              <Typography className={classes.infoTitle}>Position:</Typography>
              <Typography className={classes.infoText}>{user.position}</Typography>
            </div>
            <div className={classes.userInfoRow}>
              <Typography className={classes.infoTitle}>Time type:</Typography>
              <Typography className={classes.infoText}>{user.timeType}</Typography>
            </div>
            <div className={classes.userInfoRow}>
              <Typography className={classes.infoTitle}>Hire date:</Typography>
              <Typography className={classes.infoText}>
                {!!user.hireDate && format(parseISO(user.hireDate), 'dd.MM.yyyy')}
              </Typography>
            </div>
            <div className={classes.userInfoRow}>
              <Typography className={classes.infoTitle}>Role:</Typography>
              <Typography className={classes.infoText}>{user.role}</Typography>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <div className={classes.dividerBlock}>
        <Divider className={classes.divider} />
      </div>
    </div>
  );
};

export default UserAccordion;
