import { Grid, Button, Typography } from '@material-ui/core';
import { CustomInput, DatePicker } from '@/components/FormItems';
import { Form, Formik } from 'formik';
import { format, getQuarter, isValid, subMonths } from 'date-fns';
import { taxEventSchema } from '@/validations/taxEventSchema';
import useStyles from './styles';
import { ITaxEventFormProps } from './types';

const TaxEventForm = ({ initialValues, onSubmit, ...props }: ITaxEventFormProps): JSX.Element => {
  const classes = useStyles();

  const getDatePickerInputValue = (date: Date) =>
    isValid(date)
      ? `${format(date, 'dd.MM.yyyy')} (Q${getQuarter(subMonths(date, 2))})`
      : 'Select date';

  return (
    <div {...props}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={taxEventSchema}
      >
        {({ values, touched, errors, handleBlur, handleChange, setFieldValue, isSubmitting }) => (
          <>
            <Form>
              <Grid container spacing={2}>
                <Typography
                  component="h2"
                  variant="h1"
                  className={values.id ? classes.editTitle : classes.title}
                >
                  {values.id ? 'Edit tax event' : 'Create tax event'}
                </Typography>
                <Grid item xs={12}>
                  <DatePicker
                    id="targetDate"
                    name="targetDate"
                    label="Tax payment date"
                    variant="inline"
                    margin="normal"
                    onChange={(date) => setFieldValue('targetDate', date)}
                    onBlur={handleBlur}
                    value={values.targetDate}
                    inputValue={getDatePickerInputValue(values.targetDate)}
                    error={touched.targetDate && !!errors?.targetDate}
                    helperText={touched.targetDate && errors?.targetDate}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    id="amountUah"
                    name="amountUah"
                    placeholder="Tax amount UAH"
                    label="Tax amount UAH"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.amountUah ?? ''}
                    error={touched.amountUah && !!errors?.amountUah}
                    helperText={touched.amountUah && errors?.amountUah}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    classes={{
                      root: classes.button,
                      label: classes.buttonLabel,
                    }}
                    disabled={isSubmitting}
                  >
                    {values.id ? 'Edit' : 'Create'}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default TaxEventForm;
