import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function ThreeDotsIcon({ viewBox = '-10.5, -5.5, 24, 24', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <circle cx="1.5" cy="1.5" r="1.5" fill="currentColor" />
      <circle cx="1.5" cy="6.5" r="1.5" fill="currentColor" />
      <circle cx="1.5" cy="11.5" r="1.5" fill="currentColor" />
    </SvgIcon>
  );
}
