import { makeStyles } from '@material-ui/core/styles';
import bg from '@/assets/svg/login/bg.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  bg: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.primary.main,
    },
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  input: {
    marginTop: '25px',
  },
  paper: {
    margin: 'auto',
    padding: '0px 12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '446px',
    [theme.breakpoints.up('md')]: {
      margin: '0px 64px',
      padding: '0px',
      alignItems: 'unset',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),

    '& .MuiOutlinedInput-root': {
      height: '45px',
    },
    '& .MuiOutlinedInput-input': {
      maxWidth: '446px',
      fontSize: '21px',
      lineHeight: '21px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    },
  },
  wrapper: {
    width: '100%',
  },
  label: {
    fontSize: '16px',
    lineHeight: '30px',
    marginTop: '-45px !important',
    [theme.breakpoints.up('sm')]: {
      fontSize: '21px',
    },
  },
  formTitle: {
    fontSize: '30px',
    lineHeight: '45px',
    fontWeight: 500,
    marginBottom: '30px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '48px',
      lineHeight: '67px',
      marginBottom: '73px',
    },
  },
  submit: {
    padding: theme.spacing(1.4375, 0),
    margin: theme.spacing(6, 0, 0),
    width: '100%',
    textTransform: 'none',
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      padding: theme.spacing(1.4375, 6.325),
      margin: theme.spacing(9.875, 0, 0),
      maxWidth: '150px',
    },
  },
  link: {
    fontSize: '14px',
    marginTop: '12px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
  },
  icon: {
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.54)' : 'white',
  },
  loginWrapper: {
    display: 'flex',
    width: '100%',
  },
}));

export default useStyles;
