import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function SuccessIcon({ viewBox = '0, 0, 20, 21', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <g clipPath="url(#clip0_553:599)">
        <path
          d="M18.3332 9.30166V10.0683C18.3321 11.8653 17.7503 13.6139 16.6743 15.0532C15.5983 16.4925 14.0859 17.5454 12.3626 18.0549C10.6394 18.5644 8.79755 18.5032 7.1119 17.8805C5.42624 17.2577 3.98705 16.1067 3.00897 14.5992C2.03089 13.0917 1.56633 11.3084 1.68457 9.51526C1.80281 7.72213 2.49751 6.01527 3.66507 4.64922C4.83263 3.28318 6.41049 2.33115 8.16333 1.93512C9.91617 1.53909 11.7501 1.72028 13.3915 2.45166"
          stroke="#61D8A9"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3333 3.40173L10 11.7434L7.5 9.2434"
          stroke="#61D8A9"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_553:599">
          <rect width="20" height="20" fill="white" transform="translate(0 0.0683594)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
