import axios from 'axios';
import { toast } from 'react-toastify';

// eslint-disable-next-line
export default function logError(e: any) {
  if (e?.response?.data?.message) {
    // eslint-disable-next-line
    console.error(e?.response?.data?.error);
    if (axios.isAxiosError(e)) {
      toast.error(e.response?.data.message, { toastId: e.response?.data.message });
    }
  } else {
    // eslint-disable-next-line
    console.error(e?.message);
  }
}
