import { ProjectAccordion } from '@/components/Accordions';
import { ProjectDialog } from '@/components/MobileDialogs';
import useFetchUsers from '@/hooks/useFetchUsers';
import { LoadUsersActionProps } from '@/redux';
import { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { userRoles } from '@/constants';
import useStyles from './styles';
import { IProjectsLayoutProps } from './types';

const ProjectsLayout = ({
  openDialog,
  projects,
  hasNext,
  editProjectId,
  setEditProjectId,
  fetchProjects,
  toggleProjectDialog,
  onSuccessProject,
  ...props
}: IProjectsLayoutProps): JSX.Element => {
  const classes = useStyles();

  const [searchName, setSearchName] = useState('');

  const fetchUsersOptions = useMemo<LoadUsersActionProps>(
    () => ({
      limit: 100,
      status: 'Active',
      orderBy: 'firstName',
      order: 'asc',
      roles: [userRoles.user, userRoles.trainee],
    }),
    [],
  );

  const handleEditModalClose = () => {
    toggleProjectDialog(false);
    setEditProjectId(null);
  };

  const onSuccess = () => {
    handleEditModalClose();
    onSuccessProject();
  };

  useFetchUsers(searchName, fetchUsersOptions);

  return (
    <>
      <div className={classes.projectsBlock}>
        <InfiniteScroll
          dataLength={projects.length}
          loader={null}
          hasMore={hasNext}
          next={fetchProjects}
        >
          {projects.map((project) => (
            <ProjectAccordion
              searchName={searchName}
              setSearchName={setSearchName}
              openDialog={openDialog}
              setEditProjectId={setEditProjectId}
              toggleProjectDialog={toggleProjectDialog}
              key={project.id}
              project={project}
            />
          ))}
        </InfiniteScroll>
      </div>
      <ProjectDialog
        {...props}
        searchName={searchName}
        setSearchName={setSearchName}
        openDialog={openDialog}
        handleEditModalClose={handleEditModalClose}
        projectId={editProjectId}
        setEditProjectId={setEditProjectId}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default ProjectsLayout;
