import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import resultsService from '@/services/results.service';
import logError from '@/utils/errors';
import { resultsPaginationSelector } from '@/redux/results/selectors';
import {
  IPagination,
  IOverallMinutes,
  IOverallSalary,
  IPaginatedReports,
  IPaginatedResults,
  IOverallStatistic,
} from '@/interfaces';
import {
  setLoadingResults,
  storeResultReports,
  storeResults,
  storeOverallMinutes,
  storeOverallSalary,
  storeEarningCardData,
  storeStatistic,
} from './actions';
import {
  LoadResultReportsAction,
  LoadResultsAction,
  LoadOverallSalaryAction,
  LoadOverallMinutesAction,
  LoadUserOverallSalaryAction,
  LoadEarningCardAction,
  LoadUserOverallStatisticAction,
} from './types';
import {
  LOAD_RESULT_REPORTS,
  LOAD_RESULTS,
  LOAD_OVERALL_SALARY,
  LOAD_OVERALL_MINUTES,
  LOAD_USER_OVERALL_SALARY,
  LOAD_USER_OVERALL_MINUTES,
  LOAD_EARNING_CARD,
  LOAD_USER_OVERALL_STATISTIC,
} from './actionTypes';

function* loadReports({ payload }: LoadResultReportsAction): SagaIterator {
  try {
    yield put(setLoadingResults(true));

    const resultsPagination: IPagination = yield select(resultsPaginationSelector);

    const result: IPaginatedReports = yield call(resultsService.loadReports, {
      ...payload,
      page:
        typeof resultsPagination.currentPage === 'number' ? resultsPagination.currentPage + 1 : 0,
    });

    yield put(storeResultReports(result));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingResults(false));
  }
}

function* loadResults({ payload }: LoadResultsAction): SagaIterator {
  try {
    yield put(setLoadingResults(true));

    const resultsPagination: IPagination = yield select(resultsPaginationSelector);

    const result: IPaginatedResults = yield call(resultsService.loadResults, {
      ...payload,
      page:
        typeof resultsPagination.currentPage === 'number' ? resultsPagination.currentPage + 1 : 0,
    });

    yield put(storeResults(result));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingResults(false));
  }
}

function* loadOverallSalary({ payload }: LoadOverallSalaryAction): SagaIterator {
  try {
    const overallResult: IOverallSalary = yield call(resultsService.loadOverallSalary, payload);
    yield put(storeOverallSalary(overallResult));
  } catch (e) {
    logError(e);
  }
}

function* loadOverallStatistic({ payload }: LoadUserOverallStatisticAction): SagaIterator {
  try {
    const result: IOverallStatistic = yield call(resultsService.loadOverallStatistic, payload);
    yield put(storeStatistic(result));
  } catch (e) {
    logError(e);
  }
}

function* loadUserOverallSalary({ payload }: LoadUserOverallSalaryAction): SagaIterator {
  try {
    const overallResult: IOverallSalary = yield call(resultsService.loadUserOverallSalary, payload);
    yield put(storeOverallSalary(overallResult));
  } catch (e) {
    logError(e);
  }
}

function* loadOverallMinutes({ payload }: LoadOverallMinutesAction): SagaIterator {
  try {
    const overallResult: IOverallMinutes = yield call(resultsService.loadOverallHours, payload);
    yield put(storeOverallMinutes(overallResult));
  } catch (e) {
    logError(e);
  }
}

function* loadUserOverallMinutes({ payload }: LoadOverallMinutesAction): SagaIterator {
  try {
    const overallResult: IOverallMinutes = yield call(resultsService.loadUserOverallHours, payload);
    yield put(storeOverallMinutes(overallResult));
  } catch (e) {
    logError(e);
  }
}

function* loadEarningCard({ payload }: LoadEarningCardAction): SagaIterator {
  try {
    const [overallSalary, overallMinutes]: [IOverallSalary, IOverallMinutes] = yield all(
      payload.userId
        ? [
            call(resultsService.loadOverallSalary, {
              type: 'month',
              month: payload.month,
              id: payload.userId,
            }),
            call(resultsService.loadOverallHours, {
              type: 'month',
              month: payload.month,
              id: payload.userId,
            }),
          ]
        : [
            call(resultsService.loadUserOverallSalary, { type: 'month', month: payload.month }),
            call(resultsService.loadUserOverallHours, { type: 'month', month: payload.month }),
          ],
    );
    yield put(storeEarningCardData({ overallSalary, overallMinutes }));
  } catch (e) {
    logError(e);
  }
}

function* resultsRootSaga() {
  yield all([
    takeLatest(LOAD_RESULTS, loadResults),
    takeLatest(LOAD_RESULT_REPORTS, loadReports),
    takeLatest(LOAD_OVERALL_SALARY, loadOverallSalary),
    takeLatest(LOAD_USER_OVERALL_SALARY, loadUserOverallSalary),
    takeLatest(LOAD_USER_OVERALL_STATISTIC, loadOverallStatistic),
    takeLatest(LOAD_OVERALL_MINUTES, loadOverallMinutes),
    takeLatest(LOAD_USER_OVERALL_MINUTES, loadUserOverallMinutes),
    takeLatest(LOAD_EARNING_CARD, loadEarningCard),
  ]);
}

export default resultsRootSaga;
