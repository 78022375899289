import {
  IReportsState,
  ReportsAction,
  STORE_REPORTS,
  SET_LOADING_REPORTS,
  SET_REPORT_STORE,
} from '@/redux/reports';

const initialState: IReportsState = {
  isLoading: false,
  reportsData: {
    reports: {},
    totalRemoteHours: '',
    totalHours: '',
    totalSalary: '',
    hasNext: false,
    currentPage: null,
    total: 0,
  },
};

export function reportsReducer(
  state: IReportsState = initialState,
  action: ReportsAction,
): IReportsState {
  switch (action.type) {
    case STORE_REPORTS:
      return { ...state, reportsData: action.payload };
    case SET_REPORT_STORE: {
      return {
        ...state,
        reportsData: {
          ...state.reportsData,
          reports: {
            ...state.reportsData.reports,
            [action.payload.reportDay]: action.payload.reports,
          },
        },
      };
    }
    case SET_LOADING_REPORTS:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}

export default reportsReducer;
