import { string, object, boolean, date } from 'yup';

const vacationSchema = object().shape({
  startDate: date().required('The field Date is required'),
  endDate: date().required('The field Date is required'),
  type: string().required('The field Type is required'),
  isPayment: boolean(),
  comment: string().max(500).required('The field Comment is required'),
  paymentType: string(),
});

export default vacationSchema;
