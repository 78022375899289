import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '20px 20px 0 0',
      boxShadow: 'none',
      [theme.breakpoints.up('md')]: {
        marginBottom: 10,
        boxShadow: '3px 0px 15px rgba(78, 81, 97, 0.14)',
        borderRadius: 7,
      },
      '& .MuiCardContent-root': {
        padding: 10,
        paddingBottom: 0,
      },
    },
    content: {
      width: '100%',
      transition: 'filter 0.5s',
    },
    headerHeight: {
      marginBottom: '15.5px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: '40px',
    },
    title: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '25px',
    },
    titleBlock: {
      display: 'flex',
      flexDirection: 'column',
    },
    revenueBlock: {
      display: 'flex',
      fontSize: 11,
      lineHeight: '15px',
    },
    current: {
      marginRight: 12,
      '& $target': {
        color: theme.palette.primary.main,
      },
    },
    before: {
      borderLeft: '1px solid',
      borderColor: theme.palette.text.primary,
      paddingLeft: 12,
      '& $target': {
        color: theme.palette.info.main,
      },
    },
    target: {
      marginRight: 4,
    },
    blured: { filter: `blur(10px)` },
    unBlured: { filter: `blur(0px)` },
  }),
);

export default useStyles;
