import { Avatar, Typography, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ClockIcon, DollarIcon } from '@/components/Icons';
import { useSelector } from 'react-redux';
import { blurSelector } from '@/redux';
import clsx from 'clsx';
import { IUserMobileResultCardProps } from './types';
import useStyles from './styles';

export default function UserMobileResultCard({
  result,
  ...props
}: IUserMobileResultCardProps): JSX.Element {
  const classes = useStyles();
  const showBlur = useSelector(blurSelector);
  return (
    <div {...props}>
      <Link className={classes.cardLink} to={`/results/${result.id}`}>
        <div className={classes.userBlock}>
          <Avatar
            className={classes.avatar}
            alt={result.firstName}
            src={result.photo || '/src/assets/svg/logo.svg'}
          />
          <Typography className={classes.blodText} variant="body1" component="span">
            {result.firstName} {result.lastName}
          </Typography>
        </div>
      </Link>
      <div className={classes.infoBlock}>
        <div className={classes.infoField}>
          <ClockIcon className={classes.icon} />
          <Typography className={classes.infoBlodText} variant="body1" component="h5">
            {result.hours ?? '0:0'}
          </Typography>
        </div>
        <div className={classes.middleInfoField}>
          <DollarIcon className={classes.icon} />
          <Typography
            className={clsx(classes.infoBlodText, { [classes.blurred]: showBlur })}
            variant="body1"
            component="h5"
          >
            {result.salary ?? '0.0'}
          </Typography>
        </div>
      </div>
      <Divider className={classes.divider} />
    </div>
  );
}
