import React from 'react';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import AppPopper from '@/components/AppPopper';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { notificationTypeIcon } from '@/constants/notification.constants';
import { durationFormat } from '@/utils';
import clsx from 'clsx';
import { INotificationPopperProps } from './types';
import useStyles from './styles';
import useNotificationPopper from './useNotificationPopper';

const NotificationPopper: React.FC<INotificationPopperProps> = ({
  className,
  notificationsCount,
  ...props
}) => {
  const classes = useStyles();
  const {
    openPopperHandler,
    onClickHandler,
    open,
    setOpen,
    handleNotificationsShow,
    notifications,
    isShowAll,
    shortNewNotificationArray,
    scrollRef,
  } = useNotificationPopper(notificationsCount);
  return (
    <AppPopper
      placement="bottom-end"
      className={className}
      open={open}
      arrow
      arrowClass={classes.arrowStyle}
      popperClass={classes.popperBackground}
      paperClass={classes.paper}
      contentClass={classes.contentStyle}
      onClose={() => setOpen(false)}
      content={
        <div className={classes.container}>
          <div className={classes.listHeader}>
            <Typography className={classes.title}>
              {shortNewNotificationArray.length} New Notifications
            </Typography>
          </div>
          <MenuList className={classes.list}>
            {isShowAll
              ? notifications.map((notification, idx) => (
                  <MenuItem
                    onClick={() =>
                      onClickHandler(notification.type === 'Warning' ? '/reports' : '/time-off')
                    }
                    className={classes.listItem}
                    key={idx}
                    {...(idx === notifications.length - 1 && { ref: scrollRef })}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      {notificationTypeIcon.find((el) => el.type === notification.type)?.content}
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      className={clsx(
                        classes.type,
                        classes[
                          notificationTypeIcon.find((el) => el.type === notification.type)?.class ??
                            'listItem'
                        ],
                      )}
                      primary={notification.message}
                    />
                    <Typography className={classes.duration}>
                      {durationFormat(notification)}
                    </Typography>
                  </MenuItem>
                ))
              : shortNewNotificationArray.map((notification, idx) => (
                  <MenuItem
                    onClick={() =>
                      onClickHandler(notification.type === 'Warning' ? '/reports' : '/time-off')
                    }
                    className={classes.listItem}
                    key={idx}
                    {...(idx === notifications.length - 1 && { ref: scrollRef })}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      {notificationTypeIcon.find((el) => el.type === notification.type)?.content}
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      className={clsx(
                        classes.type,
                        classes[
                          notificationTypeIcon.find((el) => el.type === notification.type)?.class ??
                            'listItem'
                        ],
                      )}
                      primary={notification.message}
                    />
                    <Typography className={classes.duration}>
                      {durationFormat(notification)}
                    </Typography>
                  </MenuItem>
                ))}
          </MenuList>
          <div className={classes.buttonBlock}>
            <Button className={classes.button} onClick={handleNotificationsShow}>
              {isShowAll ? 'Hide' : 'Read all'}
            </Button>
          </div>
        </div>
      }
      {...props}
    >
      <Tooltip title="Notification">
        <IconButton onClick={openPopperHandler} aria-label="show notifications" color="inherit">
          <Badge badgeContent={notificationsCount} color="primary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    </AppPopper>
  );
};

export default NotificationPopper;
