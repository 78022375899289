import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function EventIcon({ viewBox = '0, 0, 20, 20', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M6.89163 6.84125C6.85203 6.96385 6.88423 7.09645 6.97683 7.18665L8.22983 8.40746L7.93403 10.1321C7.91263 10.2579 7.96503 10.3873 8.06823 10.4617C8.12603 10.5039 8.19383 10.5257 8.26544 10.5257C8.31964 10.5257 8.37384 10.5125 8.42244 10.4877L9.97104 9.67287L11.5205 10.4881C11.6353 10.5461 11.7715 10.5357 11.8743 10.4617C11.9769 10.3869 12.0297 10.2577 12.0087 10.1325L11.7129 8.40726L12.9659 7.18645C13.0579 7.09565 13.0903 6.96345 13.0503 6.84065C13.0103 6.71845 12.9059 6.63065 12.7791 6.61205L11.0476 6.36025L10.273 4.79084C10.2154 4.67524 10.0998 4.60364 9.97124 4.60364C9.84244 4.60364 9.72664 4.67544 9.66944 4.79124L8.89464 6.36005L7.16323 6.61185C7.03583 6.63085 6.93203 6.71865 6.89163 6.84125ZM11.1154 8.04906C11.0354 8.12646 10.9998 8.23826 11.0192 8.34726L11.2293 9.57386L10.1274 8.99426C10.0792 8.96926 10.025 8.95606 9.97124 8.95606C9.91664 8.95606 9.86244 8.96926 9.81404 8.99426L8.71304 9.57386L8.92304 8.34766C8.94204 8.23826 8.90584 8.12686 8.82664 8.04946L7.93563 7.18085L9.16644 7.00165C9.27584 6.98625 9.37064 6.91725 9.42044 6.81745L9.97084 5.70144L10.5212 6.81745C10.5704 6.91725 10.666 6.98625 10.7752 7.00125L12.0063 7.18065L11.1154 8.04906Z"
        fill="#884DBD"
      />
      <path
        d="M1.52085 7.07965L2.10206 8.16165C2.16086 8.27105 2.27446 8.33905 2.39866 8.33905H2.40826H2.40986C2.53706 8.33505 2.65306 8.25725 2.70566 8.14065L3.21086 7.02165L4.41987 6.80284C4.54667 6.77964 4.64747 6.68844 4.68327 6.56444C4.71867 6.44224 4.68047 6.30804 4.58627 6.22284L3.67786 5.39584L3.84346 4.17923C3.86086 4.05123 3.80546 3.92683 3.69806 3.85423C3.59366 3.78323 3.45146 3.77843 3.34306 3.84143L2.27626 4.45003L1.17045 3.91663C1.05765 3.86083 0.91585 3.87623 0.81645 3.95423C0.715049 4.03343 0.668049 4.16103 0.694049 4.28763L0.94305 5.49024L0.093646 6.37784C0.00464558 6.47124 -0.0235546 6.60464 0.0204457 6.72604C0.0640459 6.84504 0.173846 6.93125 0.300047 6.94484L1.52085 7.07965ZM1.49205 4.81923L2.14326 5.13324C2.24146 5.17964 2.36206 5.17724 2.45646 5.12204L3.08426 4.76383L2.98666 5.48004C2.97166 5.58984 3.01166 5.70004 3.09386 5.77504L3.62866 6.26144L2.91686 6.39024C2.80786 6.41004 2.71546 6.48184 2.66986 6.58304L2.37246 7.24205L2.03066 6.60484C1.97886 6.50824 1.88185 6.44264 1.77085 6.42984L1.05225 6.35024L1.55205 5.82824C1.62885 5.74864 1.66105 5.63584 1.63885 5.52684L1.49205 4.81923Z"
        fill="#884DBD"
      />
      <path
        d="M19.8896 10.6051L18.981 9.77865L19.1472 8.56145C19.1644 8.43405 19.109 8.30965 19.0016 8.23645C18.8958 8.16584 18.7546 8.16164 18.6456 8.22405L17.5796 8.83205L16.4736 8.29845C16.3608 8.24545 16.22 8.25885 16.1202 8.33665C16.019 8.41585 15.972 8.54325 15.997 8.67025L16.2466 9.87285L15.3976 10.7599C15.309 10.8517 15.28 10.9885 15.324 11.1089C15.3692 11.2299 15.4764 11.3137 15.6036 11.3277L16.8244 11.4625L17.4058 12.5443C17.4642 12.6537 17.5778 12.7217 17.7022 12.7217H17.7122H17.714C17.8414 12.7171 17.9576 12.6391 18.0092 12.5235L18.515 11.4039L19.723 11.1857C19.851 11.1625 19.9524 11.0707 19.9868 10.9469C20.0218 10.8251 19.984 10.6907 19.8896 10.6051ZM16.7956 9.20205L17.4468 9.51625C17.5444 9.56265 17.6664 9.55965 17.7596 9.50505L18.3876 9.14645L18.2898 9.86305C18.2748 9.97365 18.3152 10.0839 18.3978 10.1575L18.932 10.6443L18.2218 10.7731C18.1116 10.7917 18.0186 10.8639 17.974 10.9661L17.6762 11.6249L17.334 10.9879C17.2814 10.8907 17.1846 10.8249 17.074 10.8123L16.3556 10.7331L16.8548 10.2117C16.931 10.1329 16.9638 10.0205 16.9426 9.90985L16.7956 9.20205Z"
        fill="#884DBD"
      />
      <path
        d="M12.8543 5.27459C13.5265 5.27459 14.0741 4.72799 14.0741 4.05559C14.0741 3.38318 13.5267 2.83618 12.8543 2.83618C12.1819 2.83618 11.6353 3.38318 11.6353 4.05559C11.6353 4.72799 12.1819 5.27459 12.8543 5.27459ZM12.8543 3.50999C13.1551 3.50999 13.4001 3.75499 13.4001 4.05579C13.4001 4.35679 13.1551 4.60119 12.8543 4.60119C12.5539 4.60119 12.3093 4.35679 12.3093 4.05579C12.3093 3.75479 12.5539 3.50999 12.8543 3.50999Z"
        fill="#884DBD"
      />
      <path
        d="M3.05104 15.2701C2.37883 15.2701 1.83203 15.8167 1.83203 16.4891C1.83203 17.1616 2.37883 17.7082 3.05104 17.7082C3.72344 17.7082 4.27024 17.1616 4.27024 16.4891C4.27024 15.8167 3.72344 15.2701 3.05104 15.2701ZM3.05104 17.0346C2.75024 17.0346 2.50543 16.7899 2.50543 16.4891C2.50543 16.1883 2.75024 15.9433 3.05104 15.9433C3.35184 15.9433 3.59664 16.1883 3.59664 16.4891C3.59664 16.7899 3.35204 17.0346 3.05104 17.0346Z"
        fill="#884DBD"
      />
      <path
        d="M16.9561 13.8892C16.2833 13.8892 15.7363 14.4362 15.7363 15.1086C15.7363 15.781 16.2831 16.3274 16.9561 16.3274C17.6283 16.3274 18.1751 15.781 18.1751 15.1086C18.1751 14.4362 17.6285 13.8892 16.9561 13.8892ZM16.9561 15.6538C16.6551 15.6538 16.4107 15.4092 16.4107 15.1084C16.4107 14.8074 16.6551 14.5626 16.9561 14.5626C17.2569 14.5626 17.5015 14.8076 17.5015 15.1084C17.5015 15.4094 17.2569 15.6538 16.9561 15.6538Z"
        fill="#884DBD"
      />
      <path
        d="M2.31505 11.7543C2.31505 11.1823 2.78045 10.7167 3.35265 10.7167C3.53825 10.7167 3.68945 10.5655 3.68945 10.3801C3.68945 10.1945 3.53845 10.0433 3.35265 10.0433C2.78525 10.0433 2.32005 9.58145 2.31565 9.01385C2.31425 8.82924 2.16245 8.67944 1.97784 8.67944C1.79344 8.67944 1.64224 8.82964 1.64104 9.01385C1.63664 9.58065 1.17184 10.0427 0.593638 10.0431C0.407837 10.0431 0.256836 10.1943 0.256836 10.3807C0.256836 10.5127 0.340236 10.6333 0.466437 10.6861C0.482037 10.6935 0.496237 10.6965 0.517637 10.7021C0.531037 10.7067 0.549037 10.7133 0.571837 10.7153L0.604038 10.7167C1.17604 10.7167 1.64144 11.1823 1.64144 11.7543C1.64144 11.9397 1.79244 12.0909 1.97824 12.0909C2.16405 12.0909 2.31505 11.9397 2.31505 11.7543ZM1.97824 10.7359C1.87764 10.6003 1.75684 10.4799 1.62124 10.3791C1.75704 10.2779 1.87764 10.1575 1.97824 10.0219C2.07884 10.1575 2.19965 10.2785 2.33525 10.3791C2.19985 10.4799 2.07924 10.6005 1.97824 10.7359Z"
        fill="#884DBD"
      />
      <path
        d="M5.10993 3.56823C5.12153 3.57324 5.13413 3.57683 5.15953 3.58323C5.17333 3.58783 5.19133 3.59443 5.21373 3.59643L5.24593 3.59783C5.81793 3.59783 6.28353 4.06344 6.28353 4.63544C6.28353 4.82084 6.43413 4.97204 6.62013 4.97204C6.80553 4.97204 6.95673 4.82084 6.95673 4.63544C6.95673 4.06344 7.42254 3.59783 7.99494 3.59783C8.18034 3.59783 8.33154 3.44663 8.33154 3.26103C8.33154 3.07503 8.18054 2.92403 7.99494 2.92403C7.42774 2.92403 6.96233 2.46203 6.95753 1.89483C6.95633 1.71063 6.80473 1.56042 6.61973 1.56042C6.43513 1.56042 6.28433 1.71063 6.28273 1.89483C6.27853 2.46203 5.81313 2.92403 5.23593 2.92403C5.04993 2.92403 4.89893 3.07503 4.89893 3.26163C4.89893 3.39383 4.98213 3.51423 5.10993 3.56823ZM6.26293 3.26043C6.39893 3.15923 6.51953 3.03883 6.62013 2.90323C6.72073 3.03883 6.84133 3.15963 6.97733 3.26043C6.84173 3.36123 6.72073 3.48163 6.62013 3.61723C6.51953 3.48163 6.39893 3.36103 6.26293 3.26043Z"
        fill="#884DBD"
      />
      <path
        d="M15.3769 4.93562C15.3927 4.94282 15.4065 4.94642 15.4289 4.95222C15.4423 4.95702 15.4625 4.96402 15.4845 4.96522L15.5163 4.96682C16.0881 4.96682 16.5536 5.43222 16.5536 6.00422C16.5536 6.19023 16.7044 6.34143 16.8898 6.34143C17.0758 6.34143 17.2274 6.19023 17.2274 6.00422C17.2274 5.43242 17.6926 4.96682 18.2648 4.96682C18.4504 4.96682 18.6014 4.81582 18.6014 4.62982C18.6014 4.44422 18.4504 4.29322 18.2648 4.29322C17.6974 4.29322 17.2322 3.83121 17.2274 3.26381C17.226 3.07941 17.0746 2.92981 16.8898 2.92981C16.7056 2.92981 16.555 3.07941 16.5536 3.26381C16.549 3.83061 16.0839 4.29242 15.5055 4.29282C15.3201 4.29282 15.1689 4.44402 15.1689 4.63062C15.1689 4.76282 15.2525 4.88342 15.3769 4.93562ZM16.5338 4.62942C16.669 4.52822 16.7894 4.40782 16.8902 4.27182C16.9912 4.40782 17.1116 4.52862 17.2468 4.62942C17.1116 4.72962 16.9912 4.85022 16.8902 4.98622C16.7894 4.85062 16.669 4.73002 16.5338 4.62942Z"
        fill="#884DBD"
      />
      <path
        d="M9.97079 11.2223C9.78519 11.2223 9.63379 11.3733 9.63379 11.5593V18.6333C9.63379 18.8189 9.78519 18.9695 9.97079 18.9695C10.1562 18.9695 10.3078 18.8189 10.3078 18.6333V11.5593C10.3078 11.3733 10.1564 11.2223 9.97079 11.2223Z"
        fill="#884DBD"
      />
      <path
        d="M16.1375 7.72589C16.2041 7.66549 16.2433 7.58249 16.2477 7.49249C16.2523 7.40309 16.2213 7.31689 16.1611 7.24989C16.0413 7.11809 15.8189 7.10589 15.6855 7.22569C12.8177 9.8137 11.1729 13.5117 11.1729 17.3715C11.1729 17.7981 11.1935 18.2329 11.2331 18.6649C11.2503 18.8389 11.3947 18.9695 11.5689 18.9695L11.6007 18.9683C11.6901 18.9597 11.7713 18.9171 11.8285 18.8475C11.8857 18.7781 11.9129 18.6909 11.9047 18.6017C11.8665 18.1925 11.8471 17.7785 11.8471 17.3717C11.8469 13.7015 13.4111 10.1857 16.1375 7.72589Z"
        fill="#884DBD"
      />
      <path
        d="M16.9098 12.5583C16.9226 12.4693 16.9004 12.3805 16.8466 12.3083C16.739 12.1645 16.5176 12.1321 16.3754 12.2397C14.576 13.5783 13.3156 15.5233 12.8268 17.7161C12.7622 18.0063 12.711 18.3007 12.6744 18.5911C12.652 18.7755 12.7828 18.9441 12.9644 18.9669C12.979 18.9687 12.9944 18.9695 13.0092 18.9695C13.178 18.9695 13.321 18.8431 13.3424 18.6751C13.3778 18.3993 13.4254 18.1259 13.4844 17.8629C13.9372 15.8279 15.1066 14.0229 16.7774 12.7799C16.8494 12.7261 16.8968 12.6473 16.9098 12.5583Z"
        fill="#884DBD"
      />
      <path
        d="M3.5532 12.2393C3.4096 12.1331 3.18899 12.1653 3.08199 12.3087C2.97119 12.4575 3.00239 12.6689 3.15119 12.7799C4.8216 14.0225 5.99081 15.8275 6.44401 17.8629C6.50381 18.1323 6.55161 18.4057 6.58561 18.6751C6.60721 18.8431 6.75101 18.9695 6.91961 18.9695C6.93381 18.9695 6.94802 18.9687 6.96182 18.9669C7.05142 18.9557 7.13102 18.9107 7.18662 18.8395C7.24162 18.7681 7.26582 18.6797 7.25422 18.5907C7.21602 18.2917 7.16462 17.9971 7.10142 17.7161C6.61301 15.5237 5.35301 13.5787 3.5532 12.2393Z"
        fill="#884DBD"
      />
      <path
        d="M4.23502 7.2256C4.10202 7.1058 3.87881 7.1172 3.75941 7.2498C3.69901 7.3168 3.66821 7.403 3.67281 7.493C3.67741 7.5828 3.71661 7.6654 3.78361 7.7258C6.50963 10.186 8.07344 13.7014 8.07344 17.3714C8.07344 17.7822 8.05444 18.1963 8.01623 18.6011C8.00764 18.6907 8.03464 18.7781 8.09144 18.8473C8.14924 18.9171 8.23004 18.9597 8.31784 18.9679L8.35144 18.9695C8.52624 18.9695 8.66984 18.8383 8.68664 18.6645C8.72664 18.2367 8.74684 17.802 8.74684 17.3714C8.74704 13.5114 7.10243 9.81361 4.23502 7.2256Z"
        fill="#884DBD"
      />
    </SvgIcon>
  );
}
