import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: '5px',
    },
    editTitle: {
      marginTop: '20px',
    },
    buttonLabel: {
      textTransform: 'capitalize',
    },
    button: {
      background: theme.palette.success.main,
      borderRadius: 6,
      border: 0,
      color: 'white',
      height: 39,
      padding: '0 42px',
      boxShadow: 'none',
      marginTop: theme.spacing(1),

      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    buttonsBlock: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    delteButton: {
      borderRadius: 6,
      color: theme.palette.error.main,
      height: 39,
      padding: '0 42px',
      boxShadow: 'none',
      marginTop: theme.spacing(1),
      marginRight: '25px',
      border: `1px solid ${theme.palette.error.main}`,

      '&:hover': {
        background: theme.palette.error.main,
        color: theme.palette.common.white,
      },
    },
    menu: {
      marginTop: 9,
      marginLeft: '-20px',
    },
    reportInputWrapper: {
      marginTop: '0px',
    },
    reportInput: {
      marginTop: '0px',

      '& .MuiSelect-icon': {
        display: 'none',
      },
      '& .MuiInputBase-input': {
        fontSize: '15px',
        lineHeight: '21px',
        marginLeft: '12px',
        marginBottom: '5px',
        paddingRight: '0px',
        fontFamily: 'BrandonText',
        '&:focus': {
          background: 'none',
        },
      },

      '& .MuiInput-underline:after': {
        borderBottom: '1px solid #884DBD',
        transform: 'none',
      },
      '& .MuiSvgIcon-root': {
        marginLeft: '5px',
        width: '30px',
        marginBottom: '10px',
      },
    },
    descriptionInput: {
      borderRadius: '6px',

      '& .MuiInputBase-root, .MuiInputBase-root:after, .MuiInputBase-root:focus': {
        border: '1px solid #884DBD',
      },

      '& .MuiInputBase-input': {
        height: '140px !important',
        overflow: 'auto !important',
      },
    },
    helperText: {
      fontSize: '14px',
      lineHeight: '19px',
      margin: 0,
      marginTop: '4px',
      color: '#D55F4D !important',
    },
    timePicker: { maxHeight: '38px', minHeight: '38px' },
  }),
);

export default useStyles;
