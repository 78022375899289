import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    latestReportDate: {
      color: theme.palette.grey[500],
    },
    paper: {
      top: 0,
      transformOrigin: '0px !important',
      marginTop: '5px',
      '& .MuiList-padding': {
        padding: '0px',
      },
      '& .MuiMenuItem-root': {
        padding: '0px',
        borderRadius: '0px',
        '&:first-child': {
          paddingTop: '9px',
        },
        '&:last-child': {
          paddingBottom: '9px',
        },
      },
    },
    wrapper: {
      marginTop: '20px',

      '& .MuiFormControl-root.MuiFormControl-marginNormal': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    inputMargin: {
      margin: '0 20px',
    },
    search: {
      height: '48px',
      background: 'rgba(175, 175, 175, 0.15)',
      '& .MuiOutlinedInput-root': {
        height: '48px',
      },
      '& .MuiOutlinedInput-input': {
        marginTop: '-5px',
      },
    },
    datePeriodBlock: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '32px',
    },
    datePeriodData: {
      margin: '0px 6px',
      fontWeight: 600,
    },
    arrowButtonRight: {
      marginLeft: '1.5px',
    },
    arrowButtonLeft: {
      marginRight: '1.5px',
    },
    arrowButton: {
      width: '20px',
      height: '20px',
      color: theme.palette.common.white,
    },
    chevronIcon: {
      width: '18px',
      minWidth: '18px !important',
      height: '18px',
      borderRadius: '50% !important',
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
    checked: {
      border: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.primary.light,
    },
    maskWrapper: {
      marginTop: '3px',
    },
    label: {
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(0.5),
      '&.MuiInputLabel-marginDense': {
        marginTop: '-30px',
        marginLeft: '-10px',
      },
    },
    icon: {
      color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.54)' : 'white',
    },
    input: {
      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(1),
        minHeight: '20px',
        lineHeight: '20px',
        maxWidth: '100%',
      },
      '& .MuiChip-root': {
        color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.54)' : 'white',
        backgroundColor: theme.palette.type === 'light' ? '#e0e0e0' : '#616161',
      },
      '&.MuiSelect fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '& label.Mui-focused': {
        color: theme.palette.primary.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.primary.main,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.grey[100],
        },
        '&:hover fieldset': {
          borderColor: theme.palette.grey[900],
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '& .MuiSelect-selectMenu': {
          whiteSpace: 'inherit',
        },
      },
    },
    PopoverClasses: {
      transformOrigin: '0px',
    },
    select: {
      transformOrigin: '0px',
    },
    helperText: {
      fontSize: '14px',
      lineHeight: '19px',
      margin: 0,
      marginTop: '4px',
    },
    redColored: {
      color: '#D55F4D',
    },
    hiddenInput: {
      display: 'none',
    },
    displayTime: {
      position: 'absolute',
      fontSize: '15px',
      pointerEvents: 'none',
      display: 'flex',
    },
    timeInput: {
      '& .MuiInputBase-input': {
        color: theme.palette.background.paper,
      },
      '& .Mui-disabled': {
        color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.54)' : 'white',
        cursor: 'text',
      },
    },
    datePicker: {
      '& .MuiFormControl-marginNormal': {
        marginTop: '5px',
        border: `1px solid ${theme.palette.grey[100]}`,
        padding: theme.spacing(0.5),
        paddingLeft: '8px',
        borderRadius: '4px',
      },
      '& .MuiInput-underline:before': {
        borderBottom: 'none',
        '&:focus, &:active, &:hover': {
          borderBottom: 'none',
        },
      },
      '& .MuiInput-underline:hover:before': {
        display: 'none',
      },
      '& .MuiInput-underline:after': {
        transition: 'none',
        borderBottom: 'none',
      },
    },
    checkBox: {
      '&.Mui-checked': {
        color: theme.palette.primary.main,
      },
    },
    fullWidth: {
      width: '100%',
    },
    hoursBlock: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '215px',
      width: '60px',
      overflowX: 'auto',
      '& .MuiButton-root': {
        minWidth: '40px',
      },
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.main,
      },
    },
    menu: {
      position: 'absolute',
    },
    minutesBlock: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '215px',
      width: '50px',
      overflow: 'hidden',
      '& .MuiButton-root': {
        minWidth: '50px',
        paddingLeft: '0',
        paddingRight: '0',
      },
    },
    timePaper: {
      display: 'flex',
      flexDirection: 'row',
    },
    button: {
      zIndex: 1000,
      display: 'flex',
      width: '120px',
      marginLeft: '21px',
      marginTop: '-33.5px',
      height: '21px',
    },
    avatar: {
      width: '30px',
      height: '30px',
      fontSize: '14px',
      marginRight: theme.spacing(1.75),
    },
    selectedUser: {
      color: '#775DC6',
    },
    user: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1.25),
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
    searchIcon: {
      color: '#AFAFAF',
      marginTop: '-5px',
    },
    phoneInput: {
      '& .form-control': {
        width: '100%',
        background: 'none',
        color: theme.palette.text.primary,
        height: '39px',
        border: `1px solid ${theme.palette.grey[100]}`,
      },
      '& .special-label': {
        display: 'none',
      },
    },
    listBox: {
      background: theme.palette.type === 'light' ? 'white' : '#151529',
      color: theme.palette.type === 'light' ? '#151529' : 'white',
      display: 'flex',
      flexDirection: 'column',
      '& .MuiAutocomplete-noOptions': {
        background: theme.palette.type === 'light' ? 'white' : '#151529',
        color: theme.palette.type === 'light' ? '#151529' : 'white',
      },
    },
    listOption: {
      display: 'flex',
      flexDirection: 'row',
      padding: '3px 16px',
      alignItems: 'center',
    },
  }),
);

export default useStyles;
