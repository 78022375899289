import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function LayersIcon({ viewBox = '0, 0, 32, 32', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M16 2.66666L2.66667 9.33332L16 16L29.3333 9.33332L16 2.66666Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66667 22.6667L16 29.3333L29.3333 22.6667"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66667 16L16 22.6667L29.3333 16"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
