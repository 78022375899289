import { Grid, IconButton, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { IEvent } from '@/redux';
import { camelCaseToWords } from '@/utils';
import useStyles from './styles';
import EditPanIcon from '../Icons/EditPenIcon';
import { TrashIcon } from '../Icons';
import { ICalendarTooltipProps } from '../Calendar/types';

const EventTooltip = ({
  data,
  editEventHandler,
  removeEventHandler,
  ...props
}: ICalendarTooltipProps<IEvent>): JSX.Element => {
  const localClasses = useStyles();
  const renderData = data ?? [];
  return (
    <Grid className={localClasses.root} {...props}>
      {renderData.map((item, index) => (
        <Grid container key={index}>
          {item.type === 'Birthday' || item.type === 'Event' ? (
            <Grid item xs={12} className={localClasses.notVacation}>
              <div
                className={clsx(
                  localClasses.icon,
                  item.type === 'Birthday' ? localClasses.bDay : localClasses.sun,
                )}
              />
              <Typography className={clsx(localClasses.itemText, localClasses.eventText)}>
                {item.eventName}
                {item.type === 'Event' ? (
                  <div className={localClasses.iconBlock}>
                    <IconButton
                      onClick={() => editEventHandler?.(item)}
                      className={localClasses.eventIconButton}
                    >
                      <EditPanIcon className={localClasses.eventIcon} />
                    </IconButton>
                    <IconButton
                      onClick={() => removeEventHandler?.(item?.id ?? '')}
                      className={localClasses.eventIconButton}
                    >
                      <TrashIcon className={localClasses.eventIcon} />
                    </IconButton>
                  </div>
                ) : (
                  <></>
                )}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12} className={localClasses.vacation}>
              <Typography className={clsx(localClasses.itemText, localClasses.contentStart)}>
                {item.eventName}
              </Typography>
              <Typography className={clsx(localClasses.itemText, localClasses.contentEnd)}>
                {item.type.toLowerCase()} ({camelCaseToWords(item?.paymentType ?? '').toLowerCase()}{' '}
                )
              </Typography>
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default EventTooltip;
