import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, { chartHeight: number | undefined }>(() =>
  createStyles({
    root: {
      height: (props) => `${props.chartHeight ?? 300}px !important`,
      marginBottom: '-20px',
    },
    tooltip: {
      margin: '0px',
      padding: '10px',
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid rgb(204, 204, 204)',
      whiteSpace: 'nowrap',
      color: '#5435B8',
    },
  }),
);

export default useStyles;
