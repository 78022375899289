import auth from '@DevimaSolutions/o-auth';
import { AxiosResponse } from 'axios';
import {
  IAttachmentData,
  IDetachmentData,
  IEditAttachmentData,
  IProject,
  IProjectReportInfo,
  IShortProjectInfo,
} from '@/interfaces';
import {
  IProjectsWithPagination,
  IUserRateData,
  LoadProjectsByUserActionProps,
  LoadUsersActionProps,
  LoadUsersRatesActionProps,
  SearchReportActionProps,
} from '@/redux';

const loadProjects = async (
  payload: LoadUsersActionProps & { page: number },
): Promise<IProjectsWithPagination> => {
  const response = await auth()
    .axios.get(`/admin/projects`, { params: payload })
    .then(({ data }: AxiosResponse<IProjectsWithPagination>) => data);

  return response;
};

const loadProject = async (projectId: string): Promise<IProject> => {
  const response = await auth()
    .axios.get(`/admin/projects/${projectId}`)
    .then(({ data }: AxiosResponse<IProject>) => data);

  return response;
};

const loadUserProjects = async (
  params: SearchReportActionProps = {},
): Promise<IProjectReportInfo[]> => {
  const response = await auth()
    .axios.get(`/user/projects/`, { params })
    .then(({ data }: AxiosResponse<IProjectReportInfo[]>) => data);

  return response;
};

const loadProjectsByUser = async ({
  userId,
  ...params
}: LoadProjectsByUserActionProps): Promise<IProjectReportInfo[]> => {
  const response = await auth()
    .axios.get(`/admin/user/projects/${userId}`, {
      params,
    })
    .then(({ data }: AxiosResponse<IProjectReportInfo[]>) => data);
  return response;
};

const createProject = async ({
  projectName,
  status,
  description,
}: IShortProjectInfo): Promise<IProject> => {
  const response = await auth()
    .axios.post(`/admin/projects`, { projectName, status, description })
    .then((data: AxiosResponse<IProject>) => data);

  return response.data;
};

const editProject = async ({
  projectName,
  status,
  description,
  id,
}: IShortProjectInfo): Promise<IProject> => {
  const response = await auth()
    .axios.put(`/admin/projects/${id}`, { projectName, status, description })
    .then((data: AxiosResponse<IProject>) => data);

  return response.data;
};

const attachUser = async ({ projectId, users }: IAttachmentData): Promise<IAttachmentData> => {
  const response = await auth()
    .axios.post(`/admin/projects/attach/${projectId}`, { users })
    .then((data: AxiosResponse<IAttachmentData>) => data);

  return response.data;
};

const detachUser = async ({ projectId, userId }: IDetachmentData): Promise<IDetachmentData> => {
  const response = await auth()
    .axios.put(`/admin/projects/detach`, { projectId, userId })
    .then((data: AxiosResponse<IDetachmentData>) => data);

  return response.data;
};

const loadUsersRates = async ({
  projectId,
  userId,
}: LoadUsersRatesActionProps): Promise<IUserRateData> => {
  const response = await auth()
    .axios.get(`/admin/project-rate-history/${projectId}`, { params: userId })
    .then(({ data }: AxiosResponse<IUserRateData>) => data);

  return response;
};

const editAttachedUser = async ({
  projectId,
  userId,
  rate,
}: IEditAttachmentData): Promise<IEditAttachmentData> => {
  const response = await auth()
    .axios.put(`/admin/projects/attach/${projectId}`, { userId, rate })
    .then(({ data }: AxiosResponse<IEditAttachmentData>) => data);

  return response;
};

const deleteUserRate = async (rateId: string): Promise<IEditAttachmentData> => {
  const response = await auth()
    .axios.delete(`/admin/project-rate-history/${rateId}`)
    .then(({ data }: AxiosResponse<IEditAttachmentData>) => data);

  return response;
};

export default {
  loadProjects,
  loadProject,
  createProject,
  editProject,
  attachUser,
  detachUser,
  loadUsersRates,
  editAttachedUser,
  loadUserProjects,
  loadProjectsByUser,
  deleteUserRate,
};
