import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function ClockIcon({ viewBox = '0, 0, 35, 35', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M17.5001 32.0834C25.5542 32.0834 32.0834 25.5542 32.0834 17.5001C32.0834 9.44593 25.5542 2.91675 17.5001 2.91675C9.44593 2.91675 2.91675 9.44593 2.91675 17.5001C2.91675 25.5542 9.44593 32.0834 17.5001 32.0834Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 8.75V17.5L23.3333 20.4167"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
