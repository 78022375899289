import { createSelector } from 'reselect';
import { IApplicationState } from '../types';
import { IResultsState } from './types';

export const resultsStateSelector = (state: IApplicationState): IResultsState => state.results;

export const paginatedReportsSelector = createSelector(
  resultsStateSelector,
  (state) => state.results.paginatedReports,
);

export const overallSalarySelector = createSelector(
  resultsStateSelector,
  (state) => state.results.salary,
);

export const overallMinutesSelector = createSelector(
  resultsStateSelector,
  (state) => state.results.workedMinutes,
);

export const paginatedResultsSelector = createSelector(
  resultsStateSelector,
  (state) => state.results.paginatedResults,
);

export const resultsLoadingSelector = createSelector(
  resultsStateSelector,
  (state) => state.isLoading,
);

export const resultsPaginationSelector = createSelector(
  resultsStateSelector,
  (state) => state.pagination,
);

export const earningCardSelector = createSelector(
  resultsStateSelector,
  (state) => state.results.earningCard,
);

export const statisticSelector = createSelector(
  resultsStateSelector,
  (state) => state.results.statistic,
);
