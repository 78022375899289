import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      minWidth: '8.75rem',
      padding: 0,
    },
    listIcon: {
      minWidth: '14px',
      marginRight: '7px',
      '& > svg': {
        width: '14px',
        height: '14px',
      },
    },
    userBlock: {
      display: 'flex',
    },
    listText: {
      fontSize: '13px',
    },
    listLabel: {
      fontSize: '13px',
      marginLeft: '0 !important',
    },
    listItem: {
      '&:not(:first-child)': {
        marginTop: '7px',
      },
      '&:not(:last-child)': {
        marginBottom: '7px',
      },
    },
    avatar: {
      width: '40px',
      height: '40px',
    },
    userName: {
      marginRight: '8px',
    },
    btn: {
      height: '40px',
      textTransform: 'none',
    },
  }),
);

export default useStyles;
