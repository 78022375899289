import { useDispatch, useSelector } from 'react-redux';
import { changePasswordLoadingSelector, changeUserPassword } from '@/redux';
import { IUserChangePassword } from '@/interfaces';
import { useFormik } from 'formik';
import { changePasswordSchema } from '@/validations';

const useChangePasswordForm = () => {
  const dispatch = useDispatch();

  const changePasswordLoading = useSelector(changePasswordLoadingSelector);

  const onSubmitHandler = (values: IUserChangePassword) => {
    dispatch(changeUserPassword(values));
  };

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik<IUserChangePassword>({
      validationSchema: changePasswordSchema,
      onSubmit: onSubmitHandler,
      initialValues: { password: '', passwordConfirmation: '', oldPassword: '' },
      enableReinitialize: true,
    });

  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    changePasswordLoading,
  };
};

export default useChangePasswordForm;
