export const SET_LOADING_RESULTS = 'RESULTS/SET_LOADING_RESULTS';

export const STORE_RESULT_REPORTS = 'RESULTS/STORE_REPORTS';
export const CLEAR_RESULT_REPORTS = 'RESULTS/CLEAR_REPORTS';
export const LOAD_RESULTS = 'RESULTS/LOAD_RESULTS';
export const STORE_RESULTS = 'RESULTS/STORE_RESULTS';
export const CLEAR_RESULTS = 'RESULTS/CLEAR_RESULTS';
export const LOAD_RESULT_REPORTS = 'RESULTS/LOAD_REPORTS';
export const LOAD_USER_RESULT = 'RESULTS/LOAD_USER_RESULT';
export const STORE_USER_RESULT = 'RESULTS/STORE_USER_RESULT';
export const CLEAR_USER_RESULT = 'RESULTS/CLEAR_USER_RESULT';
export const LOAD_USERS_RESULT = 'RESULTS/LOAD_USERS_RESULT';
export const STORE_USERS_RESULT = 'RESULTS/STORE_USERS_RESULT';
export const STORE_OVERALL_SALARY = 'RESULTS/STORE_OVERALL_SALARY';
export const STORE_OVERALL_MINUTES = 'RESULTS/STORE_OVERALL_MINUTES';
export const CLEAR_USERS_RESULT = 'RESULTS/CLEAR_USERS_RESULT';
export const LOAD_OVERALL_SALARY = 'RESULTS/LOAD_OVERALL_SALARY';
export const LOAD_USER_OVERALL_SALARY = 'RESULTS/LOAD_USER_OVERALL_SALARY';
export const LOAD_USER_OVERALL_STATISTIC = 'RESULTS/LOAD_USER_OVERALL_STATISTIC';
export const LOAD_USER_OVERALL_MINUTES = 'RESULTS/LOAD_USER_OVERALL_MINUTES';
export const LOAD_OVERALL_MINUTES = 'RESULTS/LOAD_OVERALL_MINUTES';
export const STORE_EARNING_CARD_DATA = 'RESULTS/STORE_EARNING_CARD_DATA';
export const STORE_STATISTIC = 'RESULTS/STORE_STATISTIC';
export const LOAD_EARNING_CARD = 'RESULT/LOAD_EARNING_CARD';
