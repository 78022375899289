import { AppBar, Tab, Tabs } from '@material-ui/core';
import clsx from 'clsx';
import { useCallback } from 'react';
import useStyles from './styles';
import { ICustomTabsDataProps } from '../types';
import useCustomTabs from './useCustomTabs';

export const CustomTabs = ({
  labels,
  content,
  className,
  tabClass,
  maxWidth,
  value: controlledValue,
  onChange,
  variant,
  scrollButtons,
  ...props
}: ICustomTabsDataProps) => {
  const classes = useStyles({ content: content.length });
  const { value, handleChange } = useCustomTabs(controlledValue, onChange);

  const createTab = useCallback(
    (label: React.ReactNode, index: number) => {
      const tab = (
        <Tab
          className={classes.label}
          key={index}
          label={label}
          disableRipple
          id={`custom-tab-${index}`}
          aria-controls={`custom-tabpanel-${index}`}
        />
      );
      return tab;
    },
    [classes],
  );

  return (
    <div className={clsx(classes.appRoot, className)} {...props}>
      <AppBar className={classes.appBar} position="static">
        <Tabs
          variant={variant}
          scrollButtons={scrollButtons}
          classes={{ indicator: classes.indicator }}
          value={value}
          onChange={handleChange}
          aria-label="custom tabs"
        >
          {labels.map(createTab)}
        </Tabs>
      </AppBar>
      {content.map((item, index) => (
        <div
          className={clsx(tabClass)}
          key={index}
          role="tabpanel"
          hidden={value !== index}
          id={`custom-tabpanel-${index}`}
          aria-labelledby={`custom-tab-${index}`}
        >
          {value === index && item}
        </div>
      ))}
    </div>
  );
};

export default CustomTabs;
