import { string, object, ref, SchemaOf } from 'yup';
import { regPass } from '@/constants';
import { IUserChangePassword } from '@/interfaces';

const changePasswordSchema: SchemaOf<IUserChangePassword> = object().shape({
  oldPassword: string().required('The field Old password is required'),
  password: string()
    .min(6)
    .required('The field New password is required')
    .matches(regPass, 'Password is not strong enough'),
  passwordConfirmation: string()
    .required('The field New password is required')
    .when('newPass', {
      is: (val: string) => Boolean(val?.length),
      then: string().oneOf([ref('password')], 'Both password need to be the same'),
    }),
});

export default changePasswordSchema;
