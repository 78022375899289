import axios from 'axios';
import { toast } from 'react-toastify';
import { messages } from '@/constants';

export function getRequestErrorMessage(error: Error) {
  if (axios.isAxiosError(error)) {
    return error.response?.data?.message || error.message || messages.somethingWentWrong;
  }

  return messages.somethingWentWrong;
}

export function handleRequestError(error: Error) {
  toast.error(getRequestErrorMessage(error));
}
