import { Modal, Card, CardContent, IconButton } from '@material-ui/core';
import { CloseIcon } from '@/components/Icons';
import SetProjectForUserForm from '@/components/Forms/SetProjectForUserForm';
import useStyles from '../styles';
import { ISetProjectForUserModalProps } from '../types';

const SetProjectForUserModal = ({
  open,
  user,
  handleClose,
  searchName,
  setSearchName,
}: ISetProjectForUserModalProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modalWrap}
      disableEnforceFocus
      disableAutoFocus
    >
      <Card className={classes.modalCard}>
        <CardContent className={classes.modalCardContent}>
          <IconButton
            aria-label="delete"
            className={classes.closeIconWrap}
            size="small"
            onClick={handleClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
          <SetProjectForUserForm
            user={user}
            searchName={searchName}
            setSearchName={setSearchName}
            handleClose={handleClose}
          />
        </CardContent>
      </Card>
    </Modal>
  );
};

export default SetProjectForUserModal;
