import { useState, useCallback, useEffect } from 'react';
import { Typography, Grid, Button, Avatar } from '@material-ui/core';
import { useFormik } from 'formik';
import { CustomInput } from '@/components/FormItems';
import { AddIcon } from '@/components/Icons';
import { editUserRateSchema } from '@/validations';
import {
  deleteUserRate,
  detachUser,
  eventsLoadingSelector,
  loadUsersRates,
  userRatesSelector,
} from '@/redux';
import FullScreenDialog from '@/components/FullScreenDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IShortTeamMemberInfo } from '@/interfaces';
import clsx from 'clsx';
import { IApplicationState } from '@/redux/types';
import UserRatesTable from '@/components/Tables/UserRatesTable';
import useStyles from './styles';
import { IEditUserRateDialogProps } from './types';

const EditUserRateDialog = ({
  openDialog,
  user,
  projectId,
  onSubmitHandler,
  handleEditModalClose,
  ...props
}: IEditUserRateDialogProps): JSX.Element => {
  const classes = useStyles();
  const [showUserRateInput, setShowUserRateInput] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (openDialog) {
      dispatch(loadUsersRates({ projectId, userId: user.user.id }));
    }
  }, [dispatch, projectId, user.user, openDialog]);

  const onDeleteRate = useCallback(
    (rateId: string) => {
      dispatch(deleteUserRate({ rateId, projectId }));
    },
    [dispatch, projectId],
  );

  const eventsLoading = useSelector(eventsLoadingSelector);
  const userRates = useSelector((state: IApplicationState) =>
    userRatesSelector(state, user.user.id),
  );

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik<IShortTeamMemberInfo>({
      validationSchema: editUserRateSchema,
      onSubmit: onSubmitHandler,
      initialValues: {
        userId: user.user.id,
        rate: user.rate,
      },
      enableReinitialize: true,
    });

  const onDetachUserSubmitHandler = useCallback(
    (data: { userId: string; projectId: string }) => {
      dispatch(detachUser({ onSuccess: handleEditModalClose, ...data }));
    },
    [dispatch, handleEditModalClose],
  );

  const rateInput = () => {
    setShowUserRateInput((value) => !value);
  };

  return (
    <FullScreenDialog open={openDialog} handleDialogClose={handleEditModalClose}>
      <div {...props}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Avatar
                alt={user?.user?.firstName}
                src={user?.user?.photo || '/src/assets/svg/logo.svg'}
                className={classes.avatar}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.userName} variant="body1">
                {user ? `${user?.user?.firstName} ${user?.user?.lastName}` : 'Name LastName'}
              </Typography>
            </Grid>
            <div className={classes.date}>
              {userRates && (
                <UserRatesTable
                  userRates={userRates.history}
                  rateInput={rateInput}
                  onDelete={onDeleteRate}
                />
              )}
            </div>

            <Grid item xs={5}>
              <div
                className={clsx(
                  classes.baseAddRateBlock,
                  showUserRateInput ? classes.showAddRateBlock : '',
                )}
              >
                <CustomInput
                  id="rate"
                  label="Rate"
                  name="rate"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.rate ?? ''}
                  error={touched.rate && !!errors?.rate}
                  helperText={touched.rate && errors?.rate}
                />
                <Button
                  disableRipple
                  disabled={eventsLoading}
                  className={classes.addRateBtn}
                  type="submit"
                >
                  <AddIcon className={classes.addRateIcon} />
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                className={classes.detachBtn}
                onClick={() => onDetachUserSubmitHandler({ userId: user?.user?.id, projectId })}
                classes={{
                  root: classes.button,
                  label: classes.buttonLabel,
                }}
                disabled={eventsLoading}
              >
                Detach
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </FullScreenDialog>
  );
};

export default EditUserRateDialog;
