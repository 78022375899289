import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    value: {
      fontWeight: 500,
      fontSize: 32,
      lineHeight: '40px',
      letterSpacing: '0.16px',
    },
    statusChip: {
      height: '23px',
      fontSize: '11px',
      backgroundColor: theme.palette.success.main,
    },
    statusChipError: {
      backgroundColor: theme.palette.error.main,
    },
    upcomingColor: {
      color: theme.palette.success.main,
    },
    closeCoopColor: {
      color: theme.palette.error.main,
    },
  }),
);

export default useStyles;
