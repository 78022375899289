import {
  SAVE_BLUR,
  SAVE_SIDEBAR,
  SAVE_THEME,
  SaveBlurAction,
  SaveSidebarAction,
  SaveThemeAction,
} from '@/redux/settings';

export function saveSidebar(payload: boolean): SaveSidebarAction {
  return {
    type: SAVE_SIDEBAR,
    payload,
  };
}

export function saveTheme(payload: string): SaveThemeAction {
  return {
    type: SAVE_THEME,
    payload,
  };
}

export function saveBlur(payload: boolean): SaveBlurAction {
  return {
    type: SAVE_BLUR,
    payload,
  };
}
