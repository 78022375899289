import { Grid } from '@material-ui/core';
import { ResultCard } from '@/components/Result';
import InfiniteScroll from 'react-infinite-scroll-component';
import { resultsPaginationSelector } from '@/redux';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import ResultTotal from '../Total';
import { IResultListProps } from '../types';

function ResultList({ usersResult, fetchData }: IResultListProps) {
  const classes = useStyles();

  const pagination = useSelector(resultsPaginationSelector);

  return (
    <>
      <InfiniteScroll
        dataLength={usersResult?.results.length ?? 0}
        next={fetchData}
        loader={null}
        hasMore={pagination.hasNext}
        style={{ overflow: 'hidden' }}
      >
        <Grid container className={classes.list} spacing={3}>
          {usersResult &&
            usersResult.results.map((result) => (
              <Grid item xs={12} sm={6} lg={4} key={result.id}>
                <ResultCard result={result} />
              </Grid>
            ))}
        </Grid>
      </InfiniteScroll>
      <ResultTotal hours={usersResult?.totalHours} salary={usersResult?.totalSalary} />
    </>
  );
}

export default ResultList;
