import { Avatar, Card, CardContent, Typography } from '@material-ui/core';

import { ClockIcon, DollarIcon } from '@/components/Icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { blurSelector } from '@/redux';
import clsx from 'clsx';
import useStyles from './styles';
import { IResultCardProps } from '../types';

export default function ResultCard({ result }: IResultCardProps): JSX.Element {
  const classes = useStyles();

  const showBlur = useSelector(blurSelector);

  return (
    <Link className={classes.cardLink} to={`/results/${result.id}`}>
      <Card className={classes.card}>
        <CardContent className={classes.contentCard}>
          <Avatar
            className={classes.avatar}
            alt={result.fullName}
            src={result.photo || '/src/assets/svg/logo.svg'}
          />
          <Typography className={classes.name} variant="body1" component="p">
            {result.fullName}
          </Typography>
          <div className={classes.info}>
            <Typography variant="body1" component="span" className={classes.infoTitle}>
              <ClockIcon strokeWidth={2} className={classes.infoIcon} /> Time:
            </Typography>
            <Typography variant="body1" component="span">
              {result.hours || '00:00'}
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography variant="body1" component="span" className={classes.infoTitle}>
              <DollarIcon className={classes.infoIcon} /> Salary:
            </Typography>
            <Typography
              variant="body1"
              component="span"
              className={clsx({ [classes.blurred]: showBlur })}
            >
              {result.salary || '0.0'}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </Link>
  );
}
