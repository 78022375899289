import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        width: 'inherit',
      },
    },
    popper: {
      '& .MuiPaper-root': {
        marginTop: '8px',
        boxShadow: 'none',
        backgroundColor: theme.palette.type === 'light' ? '#FFF' : '#151529',
        borderRadius: '7px',
      },
    },
    paperContent: {
      '& > div': {
        '& > div': {
          '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
            borderRadius: 0,
            '& .MuiButtonBase-root:hover, .MuiButtonBase-root:focus': {
              borderRadius: '50%',
            },
          },
          '& .MuiDateRangePickerDay-rangeIntervalDayHighlightStart': {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
          },
          '& .MuiDateRangePickerDay-rangeIntervalDayHighlightEnd': {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
          },
          '&:first-child': {
            '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
              borderTop: '1px solid #FFF',
              borderBottom: '1px solid #FFF',
              backgroundColor: '#FFFFFF26',
              color: '#000 !important',
              '&:hover': {
                color: '#000',
              },
              '& .MuiButtonBase-root:hover, .MuiButtonBase-root:focus': {
                backgroundColor: '#FFF',
              },
            },
            '& .MuiDateRangePickerDay-dayInsideRangeInterval:hover': {
              color: '#000 !important',
            },
            '& .Mui-selected': {
              backgroundColor: '#FFF',
              color: '#000 !important',
              '&:focus, &:hover': { backgroundColor: '#FFF' },
              '& .button': {
                color: '#000 !important',
              },
            },
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
            backgroundColor: '#5435B8CC',
            '& *': {
              color: '#FFF',
            },
          },
          '&:last-child': {
            '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
              borderTop: '1px solid #5435B8',
              borderBottom: '1px solid #5435B8',
              backgroundColor: '#5435B826',
              color: '#FFF !important',
              '&:hover': {
                color: '#FFF',
              },
              '& .MuiButtonBase-root:hover, .MuiButtonBase-root:focus': {
                backgroundColor: '#775DC6',
              },
            },
            '& .MuiDateRangePickerDay-dayInsideRangeInterval:hover': {
              color: '#FFF !important',
            },
            '& .Mui-selected': {
              backgroundColor: '#775DC6',
              color: '#FFF !important',
              '&:focus, &:hover': { backgroundColor: '#775DC6' },
              '& .button': {
                color: '#FFF !important',
              },
            },
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
            '& *': {
              color: theme.palette.type === 'light' ? '#000' : '#FFF',
            },
          },
        },
      },
    },
    dateTextField: {
      borderColor: theme.palette.type === 'light' ? '#000' : '#FFF',
      '& .MuiInputLabel-root': { color: theme.palette.type === 'light' ? '#000' : '#FFF' },
      '& .MuiInputBase-root': {
        '&:hover fieldset': {
          borderColor: theme.palette.type === 'light' ? '#000' : '#FFF',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1976d2',
        },
      },
      '& .MuiInputBase-input': { color: theme.palette.type === 'light' ? '#000' : '#FFF' },
      '& fieldset': {
        borderColor: theme.palette.type === 'light' ? '#000' : '#FFF',
      },
    },
  }),
);

export default useStyles;
