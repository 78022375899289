import { Box, Chip, Typography } from '@material-ui/core';
import StatisticCard from '@/components/Cards/StatisticCard';
import clsx from 'clsx';
import { addDays, format } from 'date-fns';
import { ContractStatusEnum } from '@/types/contract';
import { useSelector } from 'react-redux';
import { statisticSelector } from '@/redux';
import useStyles from './styles';

const ContractsCard = () => {
  const styles = useStyles();
  const {
    contractStatus,
    contractExpirationDate,
    upcomingContractStartDate,
    upcomingContractEndDate,
  } = useSelector(statisticSelector);

  if (!contractExpirationDate || !contractStatus) {
    return null;
  }

  return (
    <StatisticCard
      title="Contracts"
      badge={
        <Chip
          className={clsx(styles.statusChip, {
            [styles.statusChipError]: contractStatus !== ContractStatusEnum.Active,
          })}
          label={contractStatus}
          color="primary"
        />
      }
      overtimeTitle={
        contractStatus === ContractStatusEnum.Closed ? (
          <Typography className={styles.closeCoopColor}>
            Cooperation is terminated from{' '}
            {format(addDays(new Date(contractExpirationDate), 1), 'dd.MM.yyyy')}
          </Typography>
        ) : (
          upcomingContractStartDate &&
          upcomingContractEndDate && (
            <Typography className={styles.upcomingColor}>
              Upcoming contract signed: {format(new Date(upcomingContractStartDate), 'dd.MM.yyyy')}{' '}
              - {format(new Date(upcomingContractEndDate), 'dd.MM.yyyy')}
            </Typography>
          )
        )
      }
    >
      <Box>
        <Typography>Contract expiration date:</Typography>
        <Typography component="span" className={styles.value}>
          {format(new Date(contractExpirationDate), 'LLLL d y')}
        </Typography>
      </Box>
    </StatisticCard>
  );
};

export default ContractsCard;
