import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import sunIcon from '@/assets/svg/icons/sun.svg';
import bDayIcon from '@/assets/svg/icons/birthday.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      textAlign: 'left',
      marginBottom: theme.spacing(1),
    },
    description: {
      fontWeight: 'bold',
      marginBottom: '1px',
    },
    icon: {
      minHeight: '16px',
      minWidth: '16px',
      width: '16px',
      height: '16px',
      marginRight: '4px',
    },
    preview: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    sun: {
      backgroundImage: `url(${sunIcon})`,
    },
    bDay: {
      backgroundImage: `url(${bDayIcon})`,
    },
    itemText: {
      fontSize: '9px',
      lineHeight: '13px',
      letterSpacing: '0.16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textDecoration: 'none',
    },
  }),
);

export default useStyles;
