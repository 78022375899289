import { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { blurSelector, loadOverallSalary, loadUserOverallSalary, usersSelector } from '@/redux';
import { DateFormatUtil } from '@/utils';
import api from '@/services/api';
import { Periods } from '@/types/periods';
import { IDisplaySalary, IFormatFromPeriod, IOverallSalary, ISalary } from '@/interfaces';
import { userRoles } from '@/constants';

const useRevenueCard = (
  period: Periods,
  overallSalary: IOverallSalary | null,
  userRole: string | undefined,
  year: number,
  month: number,
  isDesktop?: boolean,
) => {
  const dispatch = useDispatch();

  const router = useRouteMatch<{ id: string }>();

  const users = useSelector(usersSelector);

  const formatFromPeriod: IFormatFromPeriod[] = [
    {
      period: Periods.Month,
      format: (date: string) => {
        return DateFormatUtil.formatToMonth(date);
      },
    },
    {
      period: Periods.Year,
      format: (date: string) => {
        return DateFormatUtil.formatToYear(date);
      },
    },
    {
      period: Periods.Week,
      format: (date: string) => {
        return isDesktop
          ? DateFormatUtil.formatToWeek(date)
          : DateFormatUtil.formatToShortWeek(date);
      },
    },
  ];

  const formatDate = overallSalary?.filterDate.map((el) => ({
    date: formatFromPeriod.find((format) => format.period === period)?.format(el.date),
    salary: el.salary,
  }));
  const prevFormatDate = overallSalary?.prevFilterDate.map((el) => ({
    date: formatFromPeriod.find((format) => format.period === period)?.format(el.date),
    salary: el.salary,
  }));

  const currentUser = useMemo(
    () => users.find((user) => user.id === router.params.id) ?? users[0],
    [router.params.id, users],
  );

  // TODO: fix dates comparison
  const displayedData = useMemo(() => {
    const display = { formatDate, prevFormatDate };
    const result =
      display.prevFormatDate
        ?.filter((prevDate) => !display.formatDate?.some((date) => date.date === prevDate.date))
        .map((el) => ({
          date: el.date,
          salary: '0',
          prevSalary: el.salary,
        })) ?? [];
    result.push(
      ...(display.formatDate?.map((el) => ({
        date: el.date,
        salary: el.salary,
        prevSalary:
          display.prevFormatDate?.find((prevDate) => prevDate.date === el.date)?.salary ?? '0',
      })) ?? []),
    );
    return result;
  }, [formatDate, prevFormatDate]);

  const allMonth = useMemo(() => {
    return displayedData.reduce((acc: IDisplaySalary, el: ISalary) => {
      if (!el.date) {
        return acc;
      }
      const value = {
        date: el.date,
        salary: el.salary,
        prevSalary: el.prevSalary,
      };
      if (acc[el.date]) {
        acc[el.date].push(value);
        return acc;
      }

      return {
        ...acc,
        [el.date]: [value],
      };
    }, {});
  }, [displayedData]);

  const sumOfMonth = useMemo(() => {
    return Object.entries(allMonth).map(([, salaries]) =>
      salaries.reduce((acc, item) => {
        return {
          date: item.date,
          salary: (parseFloat(acc.salary) + parseFloat(item.salary)).toString(),
          prevSalary: (parseFloat(acc.prevSalary) + parseFloat(item.prevSalary)).toString(),
        };
      }),
    );
  }, [allMonth]);

  const data = useMemo(
    () =>
      api.getDateByPeriod(
        period,
        !isDesktop,
        period === 'Year' ? sumOfMonth : displayedData,
        month,
      ),
    [period, isDesktop, sumOfMonth, displayedData, month],
  );

  const totalEarning = useMemo(() => {
    return (
      overallSalary?.filterDate.reduce<number>((sum, prev) => {
        return sum + (parseFloat(prev.salary) || 0);
      }, 0) ?? 0
    ).toFixed(2);
  }, [overallSalary?.filterDate]);

  const totalPrevEarning = useMemo(() => {
    return (
      overallSalary?.prevFilterDate.reduce<number>((sum, prev) => {
        return sum + (parseFloat(prev.salary) || 0);
      }, 0) ?? 0
    ).toFixed(2);
  }, [overallSalary?.prevFilterDate]);

  useEffect(() => {
    if (userRole === userRoles.admin && !currentUser?.id) {
      return;
    }
    const overall = {
      year: year.toString(),
      id: userRole === userRoles.admin ? currentUser?.id : undefined,
      type: period.toLowerCase(),
      month,
    };
    dispatch(
      userRole === userRoles.admin ? loadOverallSalary(overall) : loadUserOverallSalary(overall),
    );
  }, [currentUser?.id, dispatch, month, period, userRole, year]);

  const isDashboardVisible = useSelector(blurSelector);

  return {
    data,
    year,
    totalEarning,
    totalPrevEarning,
    isDesktop,
    isVisible: !isDashboardVisible,
  };
};

export default useRevenueCard;
