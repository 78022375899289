import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: '5px',
    },
    editTitle: {
      marginTop: '20px',
    },
    buttonLabel: {
      textTransform: 'capitalize',
    },
    inputLabel: {
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '21px',
      color: theme.palette.text.primary,
    },
    label: {
      color: '#AFAFAF',
      fontSize: '14px',
    },
    totalInfo: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '78px',
    },
    vacationInfo: {
      fontSize: '18px',
      fontWeight: 600,
      color: theme.palette.text.primary,
      marginTop: '5px',
      whiteSpace: 'nowrap',
    },
    button: {
      background: theme.palette.common.white,
      borderRadius: 6,
      border: 0,
      color: '#AFAFAF',
      height: 39,
      padding: '0 35px',
      boxShadow: 'none',
      marginTop: theme.spacing(1),
      marginLeft: '15px',
      '&:hover': {
        background: theme.palette.common.white,
      },
    },
    subTitle: {
      fontWeight: 600,
      marginTop: '30px',
      fontSize: '18px',
      marginBottom: '7px',
    },
    descriptionBlock: {
      width: '100%',
      marginTop: '70px',
    },
    submitButton: {
      color: 'white',
      background: theme.palette.success.main,
      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    typeBlock: {
      marginTop: '10px',
      marginLeft: '12px',
    },
    menu: {
      marginTop: 9,
      marginLeft: '-20px',
    },
    reportInputWrapper: {
      marginTop: '0px',
    },
    reportInput: {
      marginTop: '0px',

      '& .MuiSelect-icon': {
        display: 'none',
      },
      '& .MuiInputBase-input': {
        fontSize: '15px',
        lineHeight: '21px',
        marginLeft: '12px',
        marginBottom: '5px',
        paddingRight: '0px',
        fontFamily: 'BrandonText',
        '&:focus': {
          background: 'none',
        },
      },

      '& .MuiInput-underline:after': {
        borderBottom: '1px solid #884DBD',
        transform: 'none',
      },
      '& .MuiSvgIcon-root': {
        marginLeft: '5px',
        width: '30px',
        marginBottom: '10px',
      },
    },
    fullHeight: {
      height: '100%',
    },
    buttonGroup: {
      display: 'flex',
      height: '100%',
      alignItems: 'flex-end',
      width: '100%',
      paddingRight: '15px',
      paddingBottom: '25px',
    },
    descriptionInput: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#fff',
      },
    },
    helperText: {
      fontSize: '14px',
      lineHeight: '19px',
      margin: 0,
      marginTop: '4px',
      color: '#D55F4D',
    },
  }),
);

export default useStyles;
