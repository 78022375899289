import { IEvent, IReport } from '@/redux';
import { CalendarContentType } from '@/types/calendar';
import { FC, HTMLAttributes } from 'react';
import { RangeModifier } from 'react-day-picker';

export enum CalendarTypes {
  month = 'month',
  week = 'week',
}

export interface ICalendarTooltipProps<T> extends HTMLAttributes<HTMLDivElement> {
  data?: T[];
  editEventHandler?: (editableEvent: IEvent) => void;
  removeEventHandler?: (id: string) => void;
}
export interface ICalendarContentProps<T> extends ICalendarTooltipProps<T> {
  contentType: CalendarContentType;
  recordsLimit?: number;
  classes?: Partial<Record<'root' | 'itemText', string>>;
}

export interface ICalendarProps<T extends CalendarContentType>
  extends HTMLAttributes<HTMLDivElement> {
  onCellClick?: (date: Date) => void;
  CellContentComponent: FC<
    ICalendarContentProps<T extends CalendarContentType.Reports ? IReport : IEvent>
  >;
  TooltipComponent?: FC<
    ICalendarTooltipProps<T extends CalendarContentType.Reports ? IReport : IEvent>
  >;
  records: Record<string, Array<T extends CalendarContentType.Reports ? IReport : IEvent>>;
  contentType: T;
  isOpenReportModal?: boolean;
  openReportModal?: (date: Date) => void;
  setEventDate?: (date: RangeModifier) => void;
  editEventHandler?: (editableEvent: IEvent) => void;
  removeEventHandler?: (id: string) => void;
}
