import React, { MouseEvent, useCallback, useEffect } from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { ToggleButtonGroup } from '@mui/lab';
import ToggleButton from '@/components/Buttons/ToggleButton';
import {
  addMonths,
  addWeeks,
  format,
  lastDayOfWeek,
  startOfWeek,
  subMonths,
  subWeeks,
  endOfMonth,
  startOfMonth,
} from 'date-fns';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { IHeaderProps } from './types';
import { CalendarTypes } from '../../types';
import useStyles from './styles';

const Header: React.FC<IHeaderProps> = ({
  date,
  type,
  contentType,
  onDateChange,
  onTypeChange,
  setEventDate,
  matches,
  ...props
}) => {
  const classes = useStyles();
  const typeChangeHandler = useCallback(
    (_event: MouseEvent<HTMLElement>, newType: CalendarTypes | null) => {
      if (newType) {
        onTypeChange(newType);
      }
    },
    [onTypeChange],
  );

  useEffect(() => {
    setEventDate?.({ from: startOfMonth(date), to: endOfMonth(date) });
  }, [date, setEventDate]);

  const prevDateHandler = useCallback(() => {
    onDateChange(type === CalendarTypes.month ? subMonths(date, 1) : subWeeks(date, 1));
  }, [date, onDateChange, type]);

  const nextDateHandler = useCallback(() => {
    onDateChange(type === CalendarTypes.month ? addMonths(date, 1) : addWeeks(date, 1));
  }, [date, onDateChange, type]);

  return (
    <Grid container spacing={matches ? 2 : 0} {...props}>
      <Grid item xs={12} md={8}>
        {contentType === 'reports' && matches && (
          <ToggleButtonGroup
            value={type}
            exclusive
            color="primary"
            onChange={typeChangeHandler}
            aria-label="calendar-type"
          >
            <ToggleButton value={CalendarTypes.week} color="primary" aria-label="left aligned">
              <Typography>Week</Typography>
            </ToggleButton>
            <ToggleButton value={CalendarTypes.month} color="primary" aria-label="right aligned">
              <Typography>Month</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </Grid>
      <Grid container item xs={12} md={4} justifyContent={matches ? 'flex-end' : 'center'}>
        <Grid container item xs={2} justifyContent="center">
          <IconButton color="primary" onClick={prevDateHandler}>
            <ArrowBackIos fontSize="small" />
          </IconButton>
        </Grid>
        <Grid container item xs={8} md={6} justifyContent="center">
          <Typography className={classes.switchTitle}>
            {type === CalendarTypes.month
              ? format(date, 'MMMM yyyy')
              : `${format(startOfWeek(date), 'MMMM dd')} - ${format(
                  lastDayOfWeek(date),
                  'MMMM dd',
                )}`}
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="center">
          <IconButton color="primary" onClick={nextDateHandler}>
            <ArrowForwardIos fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
