import { addDays, addMonths, addQuarters, isPast, startOfQuarter } from 'date-fns';

export const getNextTaxEventDate = (fromDate?: Date) => {
  const ensureDate = fromDate ?? new Date();

  // be default is should be paid by the 20th (+2-3 days gap)
  // of the month following the reporting quarter.

  let taxPaymentDueDate = addDays(addMonths(startOfQuarter(ensureDate), 1), 17);
  if (isPast(taxPaymentDueDate)) {
    taxPaymentDueDate = addQuarters(taxPaymentDueDate, 1);
  }

  return taxPaymentDueDate;
};
