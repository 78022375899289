import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pendingVacations: {
      marginBottom: 32,
      marginTop: 20,
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
      },
    },
    pendingVacation: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.type === 'light' ? 'rgba(84, 53, 184, 0.2)' : '#322D59',
      paddingRight: '21px',
      paddingLeft: '21px',
      paddingTop: '10px',
      paddingBottom: '11px',
      borderRadius: '7px',
      marginBottom: '10px',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    itemText: {
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '0.16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textDecoration: 'none',
      color: theme.palette.type === 'light' ? '#5435B8' : '#987BF4',
    },
    button: {
      width: '70px',
      height: '20px',
      textTransform: 'none',
      fontSize: '11px',
      lineHeight: '15px',
      letterSpacing: '0.16px',
      borderRadius: '21px',
    },
    reject: {
      border: theme.palette.type === 'light' ? '1px solid #D55F4D' : '1px solid #F78585',
      marginRight: '5px',
      color: theme.palette.type === 'light' ? '#575757' : '#F78585',
    },
    approve: {
      border: '1px solid #61D8A9',
      color: theme.palette.type === 'light' ? '#575757' : '#61D8A9',
    },
  }),
);

export default useStyles;
