import { useCallback, useMemo, useState } from 'react';
import {
  Modal,
  Card,
  CardContent,
  Typography,
  FormGroup,
  Grid,
  Button,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import { CloseIcon } from '@/components/Icons';
import { CustomInput, MultiSelect } from '@/components/FormItems';
import { IUser, IUserRate, IUserRatesValues } from '@/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { attachUser, LoadUsersActionProps, projectsLoadingSelector, usersSelector } from '@/redux';
import clsx from 'clsx';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { attachUsersSchema } from '@/validations';
import useFetchUsers from '@/hooks/useFetchUsers';
import { userRoles } from '@/constants';
import useStyles from '../styles';
import { IAttachUserModalProps } from '../types';

export default function AttachUserModal({
  open,
  closeModalHandler,
  projectRow,
}: IAttachUserModalProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    closeModalHandler(false);
  }, [closeModalHandler]);

  const [searchName, setSearchName] = useState('');

  const onSubmitHandler = useCallback(
    ({ members, rates }: IUserRatesValues, { resetForm }: FormikHelpers<IUserRatesValues>) => {
      const ratesArr: IUserRate[] = members.map((member) => {
        return {
          userId: member.id,
          rate: rates?.[member.id] ?? member.rate,
        };
      });
      dispatch(
        attachUser({ users: ratesArr, projectId: projectRow?.id ?? '', onSuccess: closeModal }),
      );
      resetForm();
    },
    [closeModal, dispatch, projectRow],
  );

  const projectsLoading = useSelector(projectsLoadingSelector);
  const users = useSelector(usersSelector);
  const teamMembers = projectRow?.teamMembers.map((el) => el?.user?.id);
  const newUsers = teamMembers ? users.filter((user) => !teamMembers.includes(user?.id)) : users;

  const close = useCallback(() => {
    closeModalHandler(false);
  }, [closeModalHandler]);

  const fetchUsersOptions = useMemo<LoadUsersActionProps>(
    () => ({
      limit: 100,
      status: 'Active',
      orderBy: 'firstName',
      order: 'asc',
      roles: [userRoles.user, userRoles.trainee],
    }),
    [],
  );

  useFetchUsers(searchName, fetchUsersOptions);

  return (
    <Modal
      open={open}
      onClose={close}
      className={classes.modalWrap}
      disableEnforceFocus
      disableAutoFocus
    >
      <Card className={classes.modalCard}>
        <CardContent className={classes.modalCardContent}>
          <IconButton
            aria-label="delete"
            className={classes.closeIconWrap}
            size="small"
            onClick={close}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
          <div>
            <Typography component="h2" variant="h1" className={classes.title}>
              Add a member
            </Typography>
            <Formik<IUserRatesValues>
              validationSchema={attachUsersSchema}
              onSubmit={onSubmitHandler}
              initialValues={{
                members: [],
                rates: null,
              }}
              enableReinitialize
            >
              {({ handleBlur, setFieldValue, values, errors, handleSubmit, handleChange }) => (
                <Form onSubmit={handleSubmit}>
                  <FieldArray
                    name="rates"
                    render={() => (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormGroup>
                            <MultiSelect
                              id="members"
                              label="Members"
                              name="members"
                              searchName={searchName}
                              setSearchName={setSearchName}
                              options={newUsers}
                              clearOnBlur={false}
                              multiple
                              disableClearable
                              getOptionLabel={(option) => option.fullName}
                              isOptionEqualToValue={(option, value) =>
                                option ? option.id === value.id : false
                              }
                              renderOption={(renderProps, option, { selected }) => (
                                <li {...renderProps} className={classes.listOption}>
                                  <Checkbox
                                    value={option.id}
                                    className={classes.checkBox}
                                    checked={selected}
                                  />

                                  {option.fullName}
                                </li>
                              )}
                              onChange={(_e, field, val) => setFieldValue(field, val)}
                              onBlur={handleBlur}
                              error={!!errors.members}
                            />
                          </FormGroup>
                        </Grid>
                        {values.members.map((user: IUser) => (
                          <Grid key={user.id} item xs={12}>
                            <FormGroup>
                              <div className={classes.dateRow}>
                                <div className={clsx(classes.dateBlock, classes.nameBlock)}>
                                  <Typography className={classes.data} variant="body1">
                                    {user.fullName}
                                  </Typography>
                                </div>
                                <CustomInput
                                  id={`rates.${user.id}`}
                                  label="Rate"
                                  name={`rates.${user.id}`}
                                  onChange={handleChange}
                                  defaultValue={user.rate}
                                  required
                                  type="number"
                                />
                              </div>
                            </FormGroup>
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            type="submit"
                            classes={{
                              root: classes.button,
                              label: classes.buttonLabel,
                            }}
                            disabled={projectsLoading}
                          >
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </CardContent>
      </Card>
    </Modal>
  );
}
