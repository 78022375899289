import {
  Modal,
  Card,
  CardContent,
  Typography,
  FormGroup,
  Grid,
  Button,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import { CloseIcon } from '@/components/Icons';
import { CustomInput } from '@/components/FormItems';
import { useFormik } from 'formik';
import { IShortProjectInfo } from '@/interfaces';
import { projectSchema } from '@/validations';
import { useDispatch, useSelector } from 'react-redux';
import { createProject, editProject, projectByIdSelector, projectsLoadingSelector } from '@/redux';
import { defaultProjectValues, statusOptions } from '@/constants';
import { IApplicationState } from '@/redux/types';
import useStyles from '../styles';
import { IProjectModalProps } from '../types';

export default function ProjectModal({
  open,
  closeModalHandler,
  setProjectId,
  projectId,
  onSuccess,
}: IProjectModalProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmitHandler = (values: IShortProjectInfo) => {
    const payload = {
      project: values,
      onSuccess: () => {
        closeModalHandler(false);
        setProjectId(null);
        onSuccess();
      },
    };
    dispatch(projectId ? editProject(payload) : createProject(payload));
  };

  const project = useSelector((state: IApplicationState) => projectByIdSelector(state, projectId));
  const projectsLoading = useSelector(projectsLoadingSelector);

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik<IShortProjectInfo>({
      validationSchema: projectSchema,
      onSubmit: onSubmitHandler,
      initialValues: project || defaultProjectValues,
      enableReinitialize: true,
    });

  const closeModal = () => {
    closeModalHandler(false);
    setProjectId(null);
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      className={classes.modalWrap}
      disableEnforceFocus
      disableAutoFocus
    >
      <Card className={classes.modalCard}>
        <CardContent className={classes.modalCardContent}>
          <IconButton
            aria-label="delete"
            className={classes.closeIconWrap}
            size="small"
            onClick={closeModal}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
          <div>
            <Typography component="h2" variant="h1" className={classes.title}>
              {projectId ? 'Edit the project' : 'Create a new project'}
            </Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormGroup>
                    <CustomInput
                      id="projectName"
                      label="Project Name"
                      name="projectName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.projectName || ''}
                      error={touched?.projectName && !!errors?.projectName}
                      helperText={touched?.projectName && errors?.projectName}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <CustomInput
                      select
                      id="status"
                      label="Status"
                      name="status"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.status ?? ''}
                      error={touched.status && !!errors?.status}
                      helperText={touched.status && errors?.status}
                    >
                      {statusOptions.map((option) => (
                        <MenuItem value={option} key={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    classes={{
                      root: classes.button,
                      label: classes.buttonLabel,
                    }}
                    disabled={projectsLoading}
                  >
                    {projectId ? 'Edit' : 'Add'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </CardContent>
      </Card>
    </Modal>
  );
}
