import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { projectsService } from '@/services';
import { toast } from 'react-toastify';
import logError from '@/utils/errors';
import {
  LOAD_PROJECTS,
  CreateProjectAction,
  CREATE_PROJECT,
  EDIT_PROJECT,
  AttachUserAction,
  storeProjects,
  setLoadingProjects,
  ATTACH_USER,
  EditUserRateAction,
  EDIT_USER_RATE,
  EditProjectAction,
  DetachUserAction,
  IProjectsWithPagination,
  projectsPaginationSelector,
  LoadProjectsAction,
  storeProject,
  LOAD_USERS_RATES,
  LoadUsersRatesAction,
  storeUsersRates,
  IUserRateData,
  LoadProjectAction,
  LOAD_PROJECT,
  LOAD_USER_PROJECTS,
  storeUserProjects,
  LoadProjectsByUserAction,
  LOAD_PROJECTS_BY_USER,
  storeProjectsByUser,
  DeleteUserRateAction,
  DELETE_USER_RATE,
} from '@/redux/projects';
import { IPagination, IProject } from '@/interfaces';
import { DETACH_USER } from './actionTypes';

function* loadProjects({ payload }: LoadProjectsAction): SagaIterator {
  try {
    yield put(setLoadingProjects(true));

    const projectsPagination: IPagination = yield select(projectsPaginationSelector);
    const pageIteration =
      typeof projectsPagination.currentPage === 'number' ? projectsPagination.currentPage + 1 : 0;
    const projects: IProjectsWithPagination = yield call(projectsService.loadProjects, {
      ...payload,
      page: typeof payload.page === 'undefined' ? pageIteration : payload.page,
    });
    yield put(storeProjects(projects));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingProjects(false));
  }
}

function* loadProject(action: LoadProjectAction): SagaIterator {
  try {
    yield put(setLoadingProjects(true));
    const project: IProject = yield call(projectsService.loadProject, action.payload);
    yield put(storeProject(project));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingProjects(false));
  }
}

function* loadProjectsByUser(action: LoadProjectsByUserAction): SagaIterator {
  try {
    yield put(setLoadingProjects(true));
    const project: IProject[] = yield call(projectsService.loadProjectsByUser, action.payload);
    yield put(storeProjectsByUser(project));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingProjects(false));
  }
}

function* loadUserProjects(): SagaIterator {
  try {
    yield put(setLoadingProjects(true));
    const projects: IProject[] = yield call(projectsService.loadUserProjects);
    yield put(storeUserProjects(projects));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingProjects(false));
  }
}

function* createProject(action: CreateProjectAction): SagaIterator {
  try {
    yield put(setLoadingProjects(true));
    const newProject: IProject = yield call(projectsService.createProject, action.payload.project);
    yield put(storeProject(newProject));
    action.payload.onSuccess?.();
    toast.success('Project was successfully created');
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingProjects(false));
  }
}

function* editProject(action: EditProjectAction): SagaIterator {
  try {
    yield put(setLoadingProjects(true));
    const editedProject: IProject = yield call(projectsService.editProject, action.payload.project);
    yield put(storeProject(editedProject));
    action.payload.onSuccess?.();
    toast.success('Project was successfully edited');
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingProjects(false));
  }
}

function* attachUser(action: AttachUserAction): SagaIterator {
  try {
    yield put(setLoadingProjects(true));
    const editedProject: IProject = yield call(projectsService.attachUser, action.payload);
    yield put(storeProject(editedProject));
    action.payload.onSuccess?.();
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingProjects(false));
  }
}

function* detachUser(action: DetachUserAction): SagaIterator {
  try {
    yield put(setLoadingProjects(true));
    const editedProject: IProject = yield call(projectsService.detachUser, action.payload);
    yield put(storeProject(editedProject));
    action.payload.onSuccess?.();
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingProjects(false));
  }
}

function* loadUsersRates(action: LoadUsersRatesAction): SagaIterator {
  try {
    yield put(setLoadingProjects(true));
    const usersRates: IUserRateData[] = yield call(projectsService.loadUsersRates, action.payload);
    yield put(storeUsersRates(usersRates));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingProjects(false));
  }
}

function* editUserRate(action: EditUserRateAction): SagaIterator {
  try {
    yield put(setLoadingProjects(true));
    const editedProject: IProject = yield call(projectsService.editAttachedUser, action.payload);
    yield put(storeProject(editedProject));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingProjects(false));
  }
}

function* deleteUserRate(action: DeleteUserRateAction): SagaIterator {
  try {
    yield put(setLoadingProjects(true));
    yield call(projectsService.deleteUserRate, action.payload.rateId);
    const editedProject: IProject = yield call(
      projectsService.loadProject,
      action.payload.projectId,
    );
    yield put(storeProject(editedProject));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingProjects(false));
  }
}

function* projectsRootSaga() {
  yield all([
    takeLatest(LOAD_PROJECTS, loadProjects),
    takeLatest(LOAD_PROJECT, loadProject),
    takeLatest(CREATE_PROJECT, createProject),
    takeLatest(EDIT_PROJECT, editProject),
    takeLatest(ATTACH_USER, attachUser),
    takeLatest(DETACH_USER, detachUser),
    takeLatest(LOAD_USERS_RATES, loadUsersRates),
    takeLatest(EDIT_USER_RATE, editUserRate),
    takeLatest(DELETE_USER_RATE, deleteUserRate),
    takeLatest(LOAD_USER_PROJECTS, loadUserProjects),
    takeLatest(LOAD_PROJECTS_BY_USER, loadProjectsByUser),
  ]);
}

export default projectsRootSaga;
