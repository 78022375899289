import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '32px',
    },
    titleWrap: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '20px',
      justifyContent: 'space-between',
      width: '100%',
    },
    title: {
      fontSize: '28px',
      color: theme.palette.text.primary,
    },
    memberTooltip: {
      fontSize: '14px',
      cursor: 'pointer',
      '&.MuiTooltip-tooltipPlacementBottom': {
        padding: '10px',
        marginTop: '14px',
        color: theme.palette.text.primary,
        background: theme.palette.background.default,
      },
    },
    currentUser: {
      margin: '0px 24px 0px 20px',
      width: 50,
      height: 50,
    },
    currentUserBlock: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '100%',
      maxHeight: '0',
      transition: 'max-height 0.4s ease-in-out',
      overflow: 'hidden',
      opacity: 0,
      '&$showCurrentUserBlock': {
        transition: 'max-height 1s ease-in-out',
        opacity: '1',
        maxHeight: '100vmax',
        width: '100%',
        marginBottom: '32px',
      },
    },
    showCurrentUserBlock: {},
    backButton: {
      marginLeft: 32,
      maxWidth: 113,
      width: '100%',
      textTransform: 'none',
      '&.MuiButton-root': {
        padding: '10px 28px',
      },
    },
    buttonLink: {
      textDecoration: 'none',
      color: theme.palette.common.white,
      borderRadius: '50%',
      '&:focus, &:active, &:hover': {
        zIndex: '101 !important',
        cursor: 'pointer',
      },
    },
    datePicker: {
      margin: '0px 0px 24px 20px',
      display: 'flex',
    },
    userList: {
      display: 'flex',
    },
    active: {
      position: 'relative',
      '&:after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 10,
        height: 10,
        borderRadius: '50%',
        background: '#60d8a9',
        top: 2,
        right: 2,
      },
    },
  }),
);

export default useStyles;
