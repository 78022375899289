import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      boxShadow: '3px 0px 15px rgba(78, 81, 97, 0.14)',
      width: '100%',
    },
    table: {
      minWidth: 800,
      '& .MuiTableCell-root': {
        fontSize: '14px',
      },
    },
    tableItem: {
      padding: '8px',
    },
    tableChip: {
      height: '23px',
      width: '70px',
      fontSize: '11px',
      backgroundColor: theme.palette.success.main,
    },
    tableChipError: {
      backgroundColor: theme.palette.error.main,
    },
    tableWrap: {
      boxShadow: 'none',
    },
    tableHeader: {
      padding: '14px 8px',
      fontWeight: 'bold',
    },
    tableRowName: {
      display: 'flex',
      alignItems: 'center',
    },
    tableRowAvatar: {
      marginRight: '10px',
      width: '30px',
      height: '30px',
    },
    button: {
      padding: '12px 27px',
      textTransform: 'capitalize',
      lineHeight: '19px',
      width: '100%',
      maxWidth: '108px',
      marginBottom: '-19px',
      marginRight: '16px',
      maxHeight: '39px',
    },

    content: {
      display: 'flex',
      alignItems: 'flex-end',
      width: '100%',
      flexDirection: 'column',
      marginTop: '-15px',
    },
    memberCell: {
      display: 'flex',
      alignItems: 'center',
    },
    idColumn: {
      width: '300px',
    },
    memberAvatar: {
      transitionDuration: '0.5s',
      '&:focus, &:active, &:hover': {
        [theme.breakpoints.up('sm')]: {
          transform: 'scale(1.3)',
          transitionDuration: '0.5s',
        },
      },
    },
    buttonLink: {
      textDecoration: 'none',
      color: theme.palette.common.white,
    },
    memberTooltip: {
      fontSize: '14px',
      cursor: 'pointer',
      '&.MuiTooltip-tooltipPlacementBottom': {
        padding: '10px',
        marginTop: '14px',
      },
    },

    projectName: {
      textTransform: 'none',
      minWidth: '10px',
      padding: 0,
      color: theme.palette.primary.main,
    },
    task: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '20px',
    },
    bugFixing: {
      color: '#EBB9AE',
    },
  }),
);

export default useStyles;
