import { Box, Typography } from '@material-ui/core';
import CircularProgress from '@/components/CircularProgress';
import StatisticCard from '@/components/Cards/StatisticCard';
import type { IHoursCardProps } from './types';
import useStyles from './styles';
import useHoursCard from './useHoursCard';

const HoursCard = ({ user }: IHoursCardProps): JSX.Element => {
  const styles = useStyles();
  const { time, expected, overtime, progress, percent } = useHoursCard(user);

  return (
    <StatisticCard
      title="Hours"
      expected={`${expected} h`}
      overtimeTitle={`Overtime hours (${percent}%)`}
      overtimeValue={overtime}
    >
      <Box>
        <Typography component="span" className={styles.value}>
          {time.h}&nbsp;
          <Box component="span" className={styles.label}>
            h&nbsp;
          </Box>
        </Typography>

        <Typography component="span" className={styles.value}>
          {time.m}&nbsp;
          <Box component="span" className={styles.label}>
            min
          </Box>
        </Typography>
      </Box>
      <CircularProgress value={progress} size={52} />
    </StatisticCard>
  );
};

export default HoursCard;
