import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    addVacation: {
      borderRadius: '50%',
      width: '70px',
      height: '70px',
      position: 'fixed',
      bottom: '100px',
      right: '17px',
      '& .MuiButton-label': {
        width: '70px',
        height: '70px',
      },
      '& .MuiSvgIcon-root': {
        width: '70px',
        height: '70px',
      },
    },
    vacationListHeader: {
      fontWeight: 'bold',
      padding: '20px 20px 8px 20px',
      width: '100%',
    },
    vacationListContainer: {
      display: 'flex',
      flexDirection: 'column-reverse',
      width: '100%',
    },
  }),
);

export default useStyles;
