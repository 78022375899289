import { useSelector } from 'react-redux';
import { statisticSelector } from '@/redux';
import { useMemo } from 'react';
import { IAuthUser } from '@/types/user';
import { IUser } from '@/interfaces';

const toHoursAndMinutes = (minutes: number) => {
  const h = Math.floor(minutes / 60);
  const m = Math.floor(minutes % 60);
  return { h, m };
};

const useHoursCard = (user: IAuthUser | IUser) => {
  const { limit, minutes } = useSelector(statisticSelector);
  const expected = useMemo(() => Math.round(limit / 60), [limit]);

  const overtime = useMemo(() => {
    const userOvertimePercent = user?.overTimePercent ? user.overTimePercent / 100 : 0;

    const h = Math.floor((limit * userOvertimePercent) / 60);
    return `${h} h`;
  }, [limit, user?.overTimePercent]);

  const progress = useMemo(() => Math.round((minutes / 60 / expected) * 100), [expected, minutes]);

  const time = useMemo(() => toHoursAndMinutes(minutes), [minutes]);

  return { time, expected, overtime, progress, percent: user?.overTimePercent };
};

export default useHoursCard;
