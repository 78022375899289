import enGB from 'date-fns/locale/en-GB';
import { getDaysInMonth } from 'date-fns';
import { RangeModifier } from 'react-day-picker';

const getWeekDays = (short: boolean): string[] => {
  const orderedDays = [...Array(7).keys()].map((elem) =>
    String(enGB.localize?.day(elem, short ? { width: 'abbreviated' } : undefined)),
  );
  const sunday = orderedDays.shift();
  orderedDays.push(sunday ?? '');
  return orderedDays;
};

const getMonths = (short: boolean): string[] => {
  return [...Array(12).keys()].map((elem) =>
    String(enGB.localize?.month(elem, short ? { width: 'abbreviated' } : undefined)),
  );
};

const daysInMouths = (date?: Date) => {
  return [...Array(getDaysInMonth(date ?? new Date()))].map((e, i) => String(i + 1));
};

const toCalendarRange = (dateRange: RangeModifier) => {
  if (dateRange.from && dateRange.to) {
    const startWeekDay = dateRange.from.getDay();
    const endWeekDay = dateRange.to.getDay();

    dateRange.from.setDate(dateRange.from.getDate() - startWeekDay + 1);
    dateRange.to.setDate(dateRange.to.getDate() + (7 - endWeekDay));
  }
  return dateRange;
};

const formattedTime = (time: number | string) => {
  let result = time;
  result = result !== 0 && result < 10 ? `0${result}` : result;
  result = result === 0 ? '00' : result;
  return result;
};

export default {
  getWeekDays,
  getMonths,
  daysInMouths,
  toCalendarRange,
  formattedTime,
};
