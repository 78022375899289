import FullScreenDialog from '@/components/FullScreenDialog';
import { ProjectForm, AttachUserForm } from '@/components/Forms';
import { useSelector } from 'react-redux';
import { IApplicationState } from '@/redux/types';
import { projectByIdSelector } from '@/redux';
import useStyles from './styles';
import { IProjectDialogProps } from './types';

const ProjectDialog = ({
  openDialog,
  handleEditModalClose,
  projectId,
  setEditProjectId,
  searchName,
  setSearchName,
  onSuccess,
  ...props
}: IProjectDialogProps): JSX.Element => {
  const classes = useStyles();

  const project = useSelector((state: IApplicationState) => projectByIdSelector(state, projectId));

  return (
    <FullScreenDialog open={openDialog} handleDialogClose={handleEditModalClose}>
      <div {...props}>
        <ProjectForm project={project} onSuccess={onSuccess} />

        <div className={classes.attachUserBlock}>
          {project?.status === 'Open' && (
            <AttachUserForm
              searchName={searchName}
              setSearchName={setSearchName}
              onSubmit={handleEditModalClose}
              project={project}
            />
          )}
        </div>
      </div>
    </FullScreenDialog>
  );
};

export default ProjectDialog;
