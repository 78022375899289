import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function AlertIcon({ viewBox = '0, 0, 20, 20', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M8.57514 3.28507L1.51681 15.0684C1.37128 15.3204 1.29428 15.6062 1.29346 15.8972C1.29265 16.1882 1.36805 16.4743 1.51216 16.7272C1.65627 16.98 1.86408 17.1907 2.1149 17.3383C2.36571 17.4859 2.65081 17.5652 2.94181 17.5684H17.0585C17.3495 17.5652 17.6346 17.4859 17.8854 17.3383C18.1362 17.1907 18.344 16.98 18.4881 16.7272C18.6322 16.4743 18.7076 16.1882 18.7068 15.8972C18.706 15.6062 18.629 15.3204 18.4835 15.0684L11.4251 3.28507C11.2766 3.04016 11.0674 2.83767 10.8178 2.69714C10.5682 2.55661 10.2866 2.48279 10.0001 2.48279C9.7137 2.48279 9.43208 2.55661 9.18248 2.69714C8.93287 2.83767 8.7237 3.04016 8.57514 3.28507V3.28507Z"
        stroke="#D55F4D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 7.56836V10.9017"
        stroke="#D55F4D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.235H10.0083"
        stroke="#D55F4D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
