import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionStyle: {
      background:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.background.paper,
      padding: '0px 20px',

      '&.MuiAccordion-root': {
        '&:before': {
          backgroundColor: 'unset',
        },
      },
      '&.MuiAccordion-root.Mui-expanded': {
        margin: 0,
      },
      '&.MuiPaper-elevation1': {
        boxShadow: 'unset',
      },
      '& .MuiAccordionSummary-content': {
        justifyContent: 'space-between',
      },
      '& .MuiAccordionSummary-expandIcon': {
        marginLeft: '-10px',
      },
      '& .MuiAccordionSummary-root': {
        padding: 0,
        flexDirection: 'row-reverse',
      },
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: ' rotate(90deg) ',
      },
    },
    divider: {
      margin: '0px 20px',
      backgroundColor: theme.palette.grey[100],
      height: '1px',
    },
    arrow: {
      transform: ' rotate(180deg) ',
    },
    name: {
      fontWeight: 700,
      fontSize: '15px',
      lineHeight: '21px',
      color: theme.palette.text.primary,
    },
    userInfoRow: {
      display: 'flex',
      marginBottom: '7px',
    },
    accordionBlock: {
      width: '100%',
      background: theme.palette.grey[50],
    },
    infoText: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      color: theme.palette.text.primary,
    },
    userBlock: {
      marginLeft: '36px',
      width: '100%',
    },
    editIcon: {
      '&.MuiIconButton-root': {
        padding: 0,
      },
    },
    titleField: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    task: {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '18px',
      paddingRight: '10px',
      width: '80px',
    },
    reportInfo: {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '18px',
      color: theme.palette.text.primary,
      opacity: '0.8',
      padding: '0 10px',
      borderLeft: `1px solid ${theme.palette.grey[100]}`,
    },
    reportBlock: {
      width: '80%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    development: {
      color: '#CFC6EB',
    },
  }),
);

export default useStyles;
