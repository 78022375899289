import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    layout: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '83px',
      height: '100vh',
      [theme.breakpoints.up('md')]: {
        paddingBottom: '0px',
      },
    },
    root: {
      display: 'flex',
      flexGrow: 1,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      maxWidth: '100%',
      padding: 0,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3.75),
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3.75, 0),
      },
    },
    decs: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    mob: {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

export default useStyles;
