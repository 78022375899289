import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Card,
  CardContent,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useDevimaWiki from '@/views/DevimaWiki/useDevimaWiki';
import React from 'react';
import VacationWiki from '@/assets/images/vacation-wiki.jpg';
import Salary from '@/assets/images/salary.jpg';
import Reporting from '@/assets/images/reporting.jpg';
import useStyles from './styles';

const DevimaWiki = () => {
  const classes = useStyles();
  const { expanded, handleChange } = useDevimaWiki();

  return (
    <>
      <Typography variant="h1" component="h1" className={classes.title}>
        Devima Wiki
      </Typography>
      <Card className={classes.card}>
        <CardContent>
          <Accordion
            square
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            className={classes.accordion}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<ExpandMoreIcon />}
              className={classes.summary}
            >
              <Typography variant="h3" component="span">
                Відпустки
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Typography>
                Річна оплачувана відпустка для працівників, чий час роботи в компанії становить від
                року часу чи більше налічує <b>20 вихідних днів,</b> та нараховуються <b>1 січня</b>{' '}
                кожного року. За кожен відпрацьований рік в компанії нараховується додатково по
                одному вихідному дню до відпустки. <br /> Хто працює в компанії менше року - за
                кожен відпрацьований місяць отримує <b>по 1,65 вихідного дня.</b> Відпустка є
                обовʼязковою для використання впродовж року, оскільки в кінці року (31 грудня)
                невикористані вихідні дні будуть <b>анульовані.</b> Планувати та букати її бажано
                завчасно. Відпустка не поєднується з роботою. Тобто, працівник чи працівниця, котрі
                беруть відпустку не можуть працювати в паралель з відпусткою. <br />
                Відпустка <b>оплачується по середньому арифметичному</b> від заробітної плати
                працівника взятому за останні 12 робочих місяців
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="center">
                <img src={VacationWiki} alt="" className={classes.img} />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            className={classes.accordion}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
              expandIcon={<ExpandMoreIcon />}
              className={classes.summary}
            >
              <Typography variant="h3" component="span">
                Заробітня плата
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Typography>
                Починаючи з <b>1 січня 2023</b> року працюємо по моделі{' '}
                <b>[Місячна норма робочих годин] + 10%.</b> Де 10% - це похибка місячної норми
                годин. Це оплачуваний час для овертаймів за бажанням.
                <br /> Щомісячно трекалка визначає норму робочих годин, яку потрібно відпрацювати.
                Максимальний розмір заробітньої плати ={' '}
                <b>місячна норма годин + 10% за овертайм.</b>
                Робота більше місячної норми <b>+ 10% не оплачується.</b>
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="center">
                <img src={Salary} alt="" className={classes.img} />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
            className={classes.accordion}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
              expandIcon={<ExpandMoreIcon />}
              className={classes.summary}
            >
              <Typography variant="h3" component="span">
                Звітування
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Typography>
                Кожен звітує в проект чи проекти, над якими працює. Якщо проект, над яким працює
                працівник не є заасайнений на нього в звітувалці - <b>прохання написати Максиму,</b>{' '}
                для максимально швидкого вирішення даного питання.
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="center">
                <img src={Reporting} alt="" className={classes.img} />
              </Box>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </>
  );
};

export default DevimaWiki;
