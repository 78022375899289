import { INotification } from '@/redux';
import { durationMassage, IDuration } from '@/constants/duration.constants';
import DateFormatUtil from './date';

export const durationFormat = (notification: INotification) => {
  const duration = DateFormatUtil.duration(new Date(notification.createdAt ?? ''), new Date());

  const result = Object.entries(duration).reduce<IDuration>(
    (acc, [key, value]) => {
      if (!value) {
        return acc;
      }
      const currentMessage = durationMassage[key];
      if (currentMessage.priority < acc.priority) {
        return acc;
      }
      return { ...currentMessage, value };
    },
    { priority: -1, message: '', value: 0 },
  );
  return `${result.value} ${result.message}`;
};
