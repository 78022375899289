import { IUserChangePassword, IUserProfile } from '@/interfaces';
import { IAuthUser } from '@/types/user';
import { AxiosResponse } from 'axios';
import auth from '@DevimaSolutions/o-auth';

const forgotPassword = async (email: string) => {
  const response = await auth()
    .axios.post(`/auth/forgot`, { email })
    .then(({ data }) => data);
  return response.data ?? [];
};

const resetPassword = async (password: string, passwordConfirmation: string, token: string) => {
  const response = await auth()
    .axios.post(`/auth/reset/${token}`, { password, passwordConfirmation })
    .then(({ data }) => data);
  return response.data ?? [];
};

const loadUser = async (): Promise<IAuthUser> => {
  const response = await auth()
    .axios.get(`/auth/user`)
    .then((data: AxiosResponse<IAuthUser>) => data)
    .then(({ data }) => data);
  return response;
};

const editUserProfile = async (user: IUserProfile): Promise<IUserProfile> => {
  const response = await auth()
    .axios.put(`/user/profile`, user)
    .then((data: AxiosResponse<IUserProfile>) => data)
    .then(({ data }) => data);
  return response;
};

const changeUserPassword = async (values: IUserChangePassword): Promise<IUserChangePassword> => {
  const response = await auth()
    .axios.put(`/user/password`, values)
    .then(({ data }) => data);
  return response;
};

const changePhoto = async (values: File): Promise<string> => {
  const formData = new FormData();
  formData.append('file', values);
  const response = await auth()
    .axios.post(`/user/set-photo`, formData)
    .then(({ data }) => data);
  return response;
};

export default {
  forgotPassword,
  resetPassword,
  editUserProfile,
  loadUser,
  changeUserPassword,
  changePhoto,
};
