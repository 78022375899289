import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      marginRight: '16px',
      width: '56px',
      height: '56px',
    },
    userBlock: {
      padding: '16px 0px 17px 32px',
      width: '100%',
      display: 'flex',
      background: theme.palette.background.paper,
      alignItems: 'center',
    },
    infoBlock: {
      display: 'flex',
      width: '100%',
      background: theme.palette.background.paper,
    },
    infoField: {
      alignItems: 'center',
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '21px',
      flexDirection: 'column',
    },
    middleInfoField: {
      alignItems: 'center',
      width: '50%',
      display: 'flex',
      borderLeft: `1px solid ${theme.palette.divider}`,
      justifyContent: 'center',
      marginBottom: '21px',
      flexDirection: 'column',
    },
    blodText: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
    infoBlodText: {
      fontWeight: 'bold',
      marginTop: '10px',
      color: theme.palette.text.primary,
    },
    icon: {
      width: '25px',
      color: theme.palette.text.primary,
    },
    divider: {
      color: theme.palette.grey[100],
      width: '80%',
      margin: 'auto',
    },
    cardLink: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
    blurred: {
      filter: `blur(10px)`,
    },
  }),
);

export default useStyles;
