import {
  BirthDayIcon,
  UserGroupIcon,
  SuccessIcon,
  EventIcon,
  TimeOffIcon,
  AlertIcon,
} from '@/components/Icons';
import { ReactNode } from 'react';

export enum NotificationsTypeEnum {
  Birthday = 'birthday',
  Warning = 'warning',
  Meeting = 'meeting',
  Approval = 'approval',
  Requested = 'requested',
  Event = 'event',
}

export const notificationTypeIcon: INotificaionType[] = [
  {
    type: 'Birthday',
    class: NotificationsTypeEnum.Birthday,
    content: <BirthDayIcon />,
  },
  {
    type: 'Event',
    class: NotificationsTypeEnum.Event,
    content: <EventIcon />,
  },
  {
    type: 'Approval',
    class: NotificationsTypeEnum.Approval,
    content: <SuccessIcon />,
  },
  {
    type: 'Meeting',
    class: NotificationsTypeEnum.Meeting,
    content: <UserGroupIcon />,
  },
  {
    type: 'Warning',
    class: NotificationsTypeEnum.Warning,
    content: <AlertIcon />,
  },
  {
    type: 'Requested',
    class: NotificationsTypeEnum.Requested,
    content: <TimeOffIcon />,
  },
];

interface INotificaionType {
  type: string;
  class: NotificationsTypeEnum;
  content: ReactNode;
}
