import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import reportsService from '@/services/reports.service';
import { toast } from 'react-toastify';
import logError from '@/utils/errors';
import {
  CreateReportAction,
  LOAD_REPORTS,
  CREATE_REPORT,
  IReportStore,
  EditReportAction,
  EDIT_REPORT,
  LoadReportsByDateAction,
  LOAD_REPORTS_BY_DATE,
  DeleteReportAction,
  DELETE_REPORT,
} from '@/redux/reports';
import { storeReports, setLoadingReports } from './actions';

function* loadReports(): SagaIterator {
  try {
    yield put(setLoadingReports(true));

    const reports: IReportStore = yield call(reportsService.loadReports);
    yield put(storeReports(reports));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingReports(false));
  }
}

function* deleteReport(action: DeleteReportAction): SagaIterator {
  try {
    yield put(setLoadingReports(true));
    yield call(reportsService.deleteReport, action.payload.id);
    action.payload.onSuccess?.();
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingReports(false));
  }
}

function* loadReportsByDate(action: LoadReportsByDateAction): SagaIterator {
  try {
    yield put(setLoadingReports(true));

    const reports: IReportStore = yield call(reportsService.loadReportsByDate, action.payload);
    yield put(storeReports(reports));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingReports(false));
  }
}

function* createReport(action: CreateReportAction): SagaIterator {
  try {
    yield put(setLoadingReports(true));
    yield call(reportsService.createReport, action.payload.report);
    toast.success('Report was successfully created');
    action.payload.onSuccess?.();
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingReports(false));
  }
}

function* editReport(action: EditReportAction): SagaIterator {
  try {
    yield put(setLoadingReports(true));
    yield call(reportsService.editReport, action.payload.report);
    toast.success('Report was successfully edited');
    action.payload.onSuccess?.();
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingReports(false));
  }
}

function* reportsRootSaga() {
  yield all([
    takeLatest(LOAD_REPORTS, loadReports),
    takeLatest(CREATE_REPORT, createReport),
    takeLatest(EDIT_REPORT, editReport),
    takeLatest(LOAD_REPORTS_BY_DATE, loadReportsByDate),
    takeLatest(DELETE_REPORT, deleteReport),
  ]);
}

export default reportsRootSaga;
