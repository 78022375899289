import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ICustomTabsDataProps } from '../types';

const useCustomTabs = (
  controlledValue: ICustomTabsDataProps['value'],
  onChange: ICustomTabsDataProps['onChange'],
) => {
  const [value, setValue] = useState(0);
  const handleChange = useCallback(
    (event: ChangeEvent<unknown>, newValue: number) => {
      if (typeof controlledValue === 'undefined') {
        setValue(newValue);
      }
      onChange?.(newValue);
    },
    [controlledValue, onChange],
  );

  useEffect(() => {
    if (typeof controlledValue !== 'undefined') {
      setValue(controlledValue);
    }
  }, [controlledValue]);

  return {
    handleChange,
    value,
  };
};

export default useCustomTabs;
