import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, { chartHeight: number | undefined }>(() =>
  createStyles({
    root: {
      width: '100%',
      height: (props) => `${props.chartHeight ?? 300}px`,
      marginBottom: '-20px',
    },
  }),
);

export default useStyles;
