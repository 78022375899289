import React, { useCallback, useMemo, useState } from 'react';
import Header from '@/components/Header';
import { Grid, useMediaQuery, Theme, FormControlLabel, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { clearProjects, projectsPaginationSelector, projectsSelector } from '@/redux';
import AddIcon from '@material-ui/icons/Add';
import { ProjectModal } from '@/components/Modals';
import { ProjectsTable } from '@/components/Tables';
import { RouteComponentProps } from 'react-router-dom';
import ProjectsLayout from '@/components/Mobile/ProjectsLayout';
import useFetchProjects from '@/hooks/useFetchProjects';
import useStyles from './styles';

const Projects: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const dispatch = useDispatch();

  const [searchName, setSearchName] = useState('');
  const [showActiveOnly, setShowActiveOnly] = useState(true);

  const projects = useSelector(projectsSelector);
  const projectsPagination = useSelector(projectsPaginationSelector);

  const [openModal, setOpenModal] = useState(false);
  const [openProjectDialog, setOpenProjectDialog] = useState(false);

  const [editProjectId, setEditProjectId] = useState<string | null>(null);

  const projectFetchOptions = useMemo(
    () => ({
      limit: 100,
      status: showActiveOnly ? 'Open' : undefined,
    }),
    [showActiveOnly],
  );
  const fetchProjects = useFetchProjects(searchName, projectFetchOptions);

  const handleShowActiveOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowActiveOnly(event.target.checked);
  };

  const onSuccessProject = useCallback(() => {
    dispatch(clearProjects());
    fetchProjects();
  }, [dispatch, fetchProjects]);

  return (
    <Grid container>
      {isDesktop ? (
        <>
          <Grid item xs={12}>
            <Header
              title="Projects"
              total={projectsPagination.total}
              search={{ searchValue: searchName, handleSearch: setSearchName }}
              intermediateContent={
                <FormControlLabel
                  className={classes.activeSwitch}
                  control={
                    <Switch
                      checked={showActiveOnly}
                      onChange={handleShowActiveOnlyChange}
                      color="primary"
                    />
                  }
                  label="Show active projects only"
                />
              }
            />
            <ProjectsTable
              projects={projects}
              fetchData={fetchProjects}
              editProjectId={setEditProjectId}
              toggleModal={setOpenModal}
              tableButton={{
                title: 'Add',
                startIcon: <AddIcon />,
                action: () => setOpenModal(true),
              }}
            />
          </Grid>
          <ProjectModal
            onSuccess={onSuccessProject}
            open={openModal}
            setProjectId={setEditProjectId}
            projectId={editProjectId}
            closeModalHandler={setOpenModal}
          />
        </>
      ) : (
        <>
          <Header
            mobileButton={{
              title: 'Add',
              startIcon: <AddIcon />,
              action: () => setOpenProjectDialog(true),
            }}
            title="Projects"
            total={projectsPagination.total}
            search={{ searchValue: searchName, handleSearch: setSearchName }}
            intermediateContent={
              <FormControlLabel
                className={classes.activeSwitch}
                control={
                  <Switch
                    checked={showActiveOnly}
                    onChange={handleShowActiveOnlyChange}
                    color="primary"
                  />
                }
                label="Show active projects only"
              />
            }
          />
          <ProjectsLayout
            onSuccessProject={onSuccessProject}
            openDialog={openProjectDialog}
            toggleProjectDialog={setOpenProjectDialog}
            hasNext={projectsPagination.hasNext}
            fetchProjects={fetchProjects}
            editProjectId={editProjectId}
            setEditProjectId={setEditProjectId}
            projects={projects}
          />
        </>
      )}
    </Grid>
  );
};

export default Projects;
