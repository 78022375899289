import { Box, Tooltip, Typography } from '@material-ui/core';
import StatisticCard from '@/components/Cards/StatisticCard';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import useStyles from './styles';
import useTaxesCard from './useTaxesCard';

const TaxesCard = (): JSX.Element => {
  const styles = useStyles();
  const { expectedTaxes, preciseTaxAmountUah, targetDateStr, helpMessage, previousQuarterString } =
    useTaxesCard();

  return (
    <StatisticCard
      title="Taxes"
      expected={
        <>
          ${expectedTaxes}{' '}
          <Tooltip title={helpMessage}>
            <HelpOutlineIcon className={styles.helpIcon} />
          </Tooltip>
        </>
      }
      overtimeTitle="Tax payment due date"
      overtimeValue={targetDateStr}
    >
      <Box>
        {preciseTaxAmountUah && (
          <>
            <Typography>Exact amount to be paid for {previousQuarterString}:</Typography>
            <Typography component="span" className={styles.value}>
              {preciseTaxAmountUah}
              <Box component="span" className={styles.label}>
                UAH
              </Box>
            </Typography>
          </>
        )}
      </Box>
    </StatisticCard>
  );
};

export default TaxesCard;
