import { useCallback } from 'react';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { PasswordInput } from '@/components/FormItems';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@/components/Buttons/Button';
import { useFormik } from 'formik';
import { resetPassword } from '@/redux';
import { useRouteMatch } from 'react-router-dom';
import { IResetPassword } from './types';
import useStyles from './styles';
import validationSchema from './validations';

export default function ResetPassword(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouteMatch<{ token: string }>();

  const onSubmitHandler = useCallback(
    (values: { password: string; passwordConfirmation: string; token: string }) => {
      dispatch(resetPassword(values));
    },
    [dispatch],
  );

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik<IResetPassword>({
      validationSchema,
      onSubmit: onSubmitHandler,
      initialValues: {
        password: '',
        passwordConfirmation: '',
        token: router.params.token,
      },
    });

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={false} md={6} className={classes.bg}>
        <div className={classes.image} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.wrapper}>
            <Typography className={classes.formTitle} component="h1" variant="h1">
              Reset Password
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <Grid className={classes.input} item xs={12}>
                <PasswordInput
                  required
                  fullWidth
                  labelClass={classes.label}
                  id="password"
                  label="Password"
                  name="password"
                  placeholder="••••••••••••"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && !!errors.password}
                  helperText={touched.password && !!errors.password ? errors.password : null}
                />
              </Grid>
              <Grid className={classes.input} item xs={12}>
                <PasswordInput
                  required
                  fullWidth
                  labelClass={classes.label}
                  id="passwordConfirmation"
                  label="Password Confirmation"
                  name="passwordConfirmation"
                  placeholder="••••••••••••"
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.passwordConfirmation && !!errors.passwordConfirmation}
                  helperText={
                    touched.passwordConfirmation && !!errors.passwordConfirmation
                      ? errors.passwordConfirmation
                      : null
                  }
                />
              </Grid>

              <Grid container justifyContent="flex-end">
                <Link href="/" variant="inherit" className={classes.link}>
                  Login here
                </Link>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Reset
                </Button>
              </Grid>
            </form>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
