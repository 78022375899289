import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    attachUserBlock: {
      marginTop: '40px',
      marginBottom: '40px',
    },
    wrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    avatar: {
      width: '124px',
      height: '124px',
    },
    headerStyle: {
      marginLeft: '-20px',
    },
    editPhoto: {
      position: 'absolute',
      background: theme.palette.common.white,
      borderRadius: '50%',
      width: '32px',
      height: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '68px',
    },
    photoBlock: {
      width: 'fit-content',
    },
  }),
);

export default useStyles;
