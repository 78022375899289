import FullScreenDialog from '@/components/FullScreenDialog';
import { ProfileForm, ChangePasswordForm } from '@/components/Forms';
import { Avatar, ButtonBase } from '@material-ui/core';
import Header from '@/components/Header';
import { CameraIcon } from '@/components/Icons';
import useStyles from './styles';
import { IProfileDialogProps } from './types';

const ProfileDialog = ({
  user,
  handleDialogClose,
  openDialog,
  userInfo,
  handleAvatarChange,
  ...props
}: IProfileDialogProps): JSX.Element => {
  const classes = useStyles();

  return (
    <FullScreenDialog open={openDialog} handleDialogClose={handleDialogClose}>
      <div {...props}>
        <Header className={classes.headerStyle} title="Edit profile" />

        <div className={classes.wrapper}>
          <ButtonBase component="label" disableRipple>
            <Avatar
              className={classes.avatar}
              alt={userInfo?.fullName}
              src={userInfo?.photo || '/src/assets/svg/logo.svg'}
            />
            <input
              accept="image/x-png,image/jpeg"
              type="file"
              hidden
              onChange={handleAvatarChange}
            />
          </ButtonBase>

          <div className={classes.editPhoto}>
            <CameraIcon />
          </div>
        </div>

        <div className={classes.attachUserBlock}>
          <ProfileForm user={user} />
        </div>

        <ChangePasswordForm />
      </div>
    </FullScreenDialog>
  );
};

export default ProfileDialog;
