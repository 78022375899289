import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikHelpers, useFormik } from 'formik';
import { userProjectsSelector, loadUserProjects, eventsLoadingSelector } from '@/redux';
import { VacationPaymentType, VacationType } from '@/types/vacation';
import { IVacationData } from '@/interfaces/app/entities/vacation.interface';
import { vacationSchema } from '@/validations';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import {
  differenceInBusinessDays,
  isBefore,
  isValid as isDataValid,
  subDays,
  isSameYear,
} from 'date-fns';
import { IAuthUser } from '@/types/user';

const useVacationForm = (
  onSubmitHandler: (data: IVacationData, resetForm: FormikHelpers<IVacationData>) => void,
  date: DateRange<Date | null>,
  user: IAuthUser | null,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserProjects());
  }, [dispatch]);

  const projects = useSelector(userProjectsSelector);
  const eventsLoading = useSelector(eventsLoadingSelector);
  const vacationLeft = Math.floor(user?.vacationLeft || 0);
  const total = useMemo(() => {
    const start =
      date?.[0] && isDataValid(new Date(date[0]))
        ? new Date(date[0])
        : new Date().setHours(0, 0, 0, 0);
    const end =
      date?.[1] && isDataValid(new Date(date[1]))
        ? new Date(date[1])
        : subDays(new Date().setHours(0, 0, 0, 0), 1);

    return isBefore(end, start) ? 0 : differenceInBusinessDays(end, start) + 1;
  }, [date]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    isValid,
  } = useFormik<IVacationData>({
    validationSchema: vacationSchema,
    onSubmit: onSubmitHandler,
    initialValues: {
      type: VacationType.DayOff,
      isPayment: false,
      startDate: '',
      endDate: '',
      comment: '',
      paymentType: VacationPaymentType.Paid,
    },
  });

  const isDisabled = useMemo(() => {
    const start = values?.startDate !== '' ? new Date(values.startDate as string) : new Date();
    const end = values?.endDate !== '' ? new Date(values.endDate as string) : new Date();

    return (
      eventsLoading ||
      !isValid ||
      total <= 0 ||
      (!values.isPayment && total > vacationLeft) ||
      !isSameYear(start, end) ||
      !isSameYear(start, new Date()) ||
      !isSameYear(end, new Date())
    );
  }, [
    eventsLoading,
    isValid,
    total,
    vacationLeft,
    values.endDate,
    values.isPayment,
    values.startDate,
  ]);

  useEffect(() => {
    setFieldValue('startDate', date[0]);
    setFieldValue('endDate', date[1]);
  }, [date, setFieldValue]);

  return {
    total,
    vacationLeft,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    projects,
    setFieldValue,
    isDisabled,
  };
};

export default useVacationForm;
