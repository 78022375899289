import { FormGroup, Grid, Button, Typography, TextField, InputLabel } from '@material-ui/core';
import { CheckboxGroup, CustomCheckBox } from '@/components/FormItems';
import { vacationTypeOptions } from '@/types/vacation';
import VacationRangeDatePicker from '@/components/VacationRangeDatePicker';
import clsx from 'clsx';
import pluralize from 'pluralize';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import useVacationForm from './useVacationForm';
import useStyles from './styles';
import { IVacationFormProps } from './types';

const VacationForm = ({
  onSubmitHandler,
  closeModal,
  user,
  date,
  setDate,
  ...props
}: IVacationFormProps): JSX.Element => {
  const classes = useStyles();

  const {
    total,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    setFieldValue,
    vacationLeft,
    isDisabled,
  } = useVacationForm(onSubmitHandler, date, user);

  return (
    <div {...props} className={classes.fullHeight}>
      <form onSubmit={handleSubmit} className={classes.fullHeight}>
        <Grid container spacing={2} className={classes.fullHeight}>
          <Grid container item xs={12} className={classes.fullHeight}>
            <Grid item xs={3}>
              <div className={classes.typeBlock}>
                <Typography className={classes.label}>Left</Typography>
                <Typography className={classes.vacationInfo}>
                  {vacationLeft} {pluralize('day', vacationLeft)}
                </Typography>
                <InputLabel
                  htmlFor="type"
                  className={clsx(classes.subTitle)}
                  error={!!errors?.type}
                >
                  Type
                </InputLabel>
                <FormGroup>
                  <CheckboxGroup
                    name="type"
                    options={vacationTypeOptions}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    values={values.type}
                    icon={<RadioButtonUncheckedOutlinedIcon />}
                    checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                  />
                </FormGroup>
                <FormGroup>
                  <CustomCheckBox
                    name="isPayment"
                    checked={values.isPayment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Self paid"
                    value={values.isPayment}
                  />
                </FormGroup>
              </div>
            </Grid>
            <Grid item xs={8}>
              <VacationRangeDatePicker
                date={date}
                setDate={setDate}
                componentChildren={
                  <div className={classes.totalInfo}>
                    <Typography className={classes.label}>Total</Typography>
                    <Typography className={classes.vacationInfo}>
                      {total} {pluralize('day', total)}
                    </Typography>
                  </div>
                }
              />
              {!date.includes(null) &&
              !date.find((dateInst) => dateInst && Number.isNaN(dateInst.getTime())) ? (
                <Grid container className={classes.descriptionBlock}>
                  <Grid item xs={7}>
                    <TextField
                      className={classes.descriptionInput}
                      multiline
                      fullWidth
                      name="comment"
                      label="Comment"
                      id="comment"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.comment ?? ''}
                      maxRows={4}
                      rows={4}
                      error={!!errors.comment}
                      helperText={errors?.comment ?? ' '}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <div className={classes.buttonGroup}>
                      <Button
                        variant="contained"
                        onClick={closeModal}
                        classes={{
                          root: classes.button,
                          label: classes.buttonLabel,
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        classes={{
                          root: clsx(classes.button, classes.submitButton),
                          label: classes.buttonLabel,
                        }}
                        disabled={isDisabled}
                      >
                        Submit
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default VacationForm;
