import { object, date, number } from 'yup';

export const taxEventSchema = object().shape({
  targetDate: date()
    .required('Tax payment date is required!')
    .typeError('Tax payment date is required!'),
  amountUah: number()
    // .min(0, 'Tax amount must be greater than or equal to 0 UAH')
    .required('Tax amount UAH is required!'),
});
