import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveSidebar, saveTheme } from '@/redux/settings/actions';
import { settingsSelector } from '@/redux/settings/selectors';
import { userSelector } from '@/redux';

const useNavbar = () => {
  const dispatch = useDispatch();

  const { sidebar, theme } = useSelector(settingsSelector);
  const user = useSelector(userSelector);
  const toggleSidebar = useCallback(() => {
    dispatch(saveSidebar(!sidebar));
  }, [dispatch, sidebar]);

  const toggleTheme = useCallback(() => {
    dispatch(saveTheme(theme === 'light' ? 'dark' : 'light'));
  }, [dispatch, theme]);

  return {
    sidebar,
    theme,
    toggleSidebar,
    toggleTheme,
    user,
  };
};

export default useNavbar;
