import {
  IUsersState,
  UserAction,
  STORE_USERS,
  SET_LOADING_USERS,
  CLEAR_USERS,
  STORE_USER,
  SET_FILTERS,
  RESET_FILTERS,
  UPDATE_PHOTO_USER,
} from '@/redux/users';
import { userRoles } from '@/constants';

const initialState: IUsersState = {
  isLoading: false,
  users: [],
  currentUser: null,
  filters: {
    limit: 100,
    orderBy: 'hireDate',
    order: 'asc',
    roles: [userRoles.user, userRoles.trainee],
  },
  pagination: {
    hasNext: true,
    currentPage: null,
    total: 0,
  },
};

function usersReducer(state: IUsersState = initialState, action: UserAction): IUsersState {
  switch (action.type) {
    case STORE_USERS: {
      const usersIdSet = new Set(action.payload.users.map((user) => user.id));
      return {
        ...state,
        users: [...state.users.filter((user) => !usersIdSet.has(user.id)), ...action.payload.users],
        pagination: {
          hasNext: action.payload.hasNext,
          currentPage: action.payload.currentPage,
          total: action.payload.total,
        },
      };
    }
    case SET_LOADING_USERS:
      return { ...state, isLoading: action.payload };
    case SET_FILTERS:
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case RESET_FILTERS:
      return {
        ...state,
        filters: {
          limit: 100,
          orderBy: 'hireDate',
          order: 'desc',
        },
      };
    case CLEAR_USERS:
      return {
        ...state,
        users: [],
        pagination: {
          hasNext: true,
          currentPage: null,
          total: 0,
        },
      };
    case STORE_USER: {
      const userIndex = state.users.findIndex((user) => user.id === action.payload.id);
      const newUsers = [...state.users];
      if (userIndex !== -1) {
        newUsers[userIndex] = action.payload;
      } else {
        newUsers.unshift(action.payload);
      }
      return {
        ...state,
        currentUser: action.payload,
        users: newUsers,
      };
    }
    case UPDATE_PHOTO_USER: {
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.id === action.payload.id) {
            return { ...user, photo: action.payload.photo };
          }
          return user;
        }),
      };
    }
    default:
      return state;
  }
}

export default usersReducer;
