import { makeStyles, Theme } from '@material-ui/core/styles';

import lineDark from '@/assets/svg/dashboard/line-dark.svg';
import lineLight from '@/assets/svg/dashboard/line-light.svg';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    overflow: 'visible',
    marginTop: '62px',
    borderRadius: '6px',
    border: '1px solid #fff',
  },
  contentCard: {
    padding: '0 20px 20px',
  },
  avatar: {
    width: '124px',
    height: '124px',
  },
  wrapper: {
    margin: '0 auto -62px',
    top: '-62px',
    display: 'flex',
    width: 'min-content',
    justifyContent: 'center',
  },
  name: {
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    paddingBottom: '6px',
    lineHeight: '29px',
    marginTop: '2px',
  },
  role: {
    textAlign: 'center',
    fontSize: '15px',
    lineHeight: '21px',
    marginTop: '40px',
  },
  dateOfBirth: {
    fontWeight: 500,
    textAlign: 'center',
    fontSize: '21px',
    lineHeight: '29px',
  },
  divider: {
    marginTop: '20px',
    height: 5,
    width: '100%',
    backgroundImage: `url(${theme.palette.type === 'light' ? lineLight : lineDark})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
}));

export default useStyles;
