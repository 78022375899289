import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {
  ISettingsState,
  SAVE_BLUR,
  SAVE_SIDEBAR,
  SAVE_THEME,
  SettingsAction,
} from '@/redux/settings';

const initialState: ISettingsState = {
  sidebar: true,
  blur: true,
  theme: 'light',
};

export function settingsReducer(
  state: ISettingsState = initialState,
  action: SettingsAction,
): ISettingsState {
  switch (action.type) {
    case SAVE_SIDEBAR:
      return { ...state, sidebar: action.payload };
    case SAVE_THEME:
      return { ...state, theme: action.payload };

    case SAVE_BLUR:
      return { ...state, blur: action.payload };
    default:
      return state;
  }
}

export default persistReducer(
  {
    key: 'settings',
    storage,
    // blacklist: ['blur'],
    stateReconciler: autoMergeLevel2,
  },
  settingsReducer,
);
