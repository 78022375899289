import { format, parse, parseISO, intervalToDuration } from 'date-fns';

const formatDate = (date: Date) => {
  return format(new Date(date), 'dd.MM.yyyy');
};

const formatToDate = (date: string) => {
  return format(parseISO(`${date}`), 'dd.MM.yyyy');
};

const formatToMonth = (date: string) => {
  return format(new Date(date), 'd');
};

const formatToMonthNumber = (date: Date) => {
  return parseInt(format(date, 'M'), 10);
};

const formatToExactDay = (date: string) => {
  return format(new Date(date), 'do');
};

const formatToExactMonth = (date: string) => {
  return format(new Date(date), 'MMMM');
};

const formatToYear = (date: string) => {
  return format(new Date(date), 'LLL');
};

const formatToYearNumber = (date: Date) => {
  return parseInt(format(date, 'y'), 10);
};

const formatToWeek = (date: string) => {
  return format(new Date(date), 'EEEE');
};

const formatToShortWeek = (date: string) => {
  return format(new Date(date), 'EEE');
};

const parseDate = (date: string) => {
  return parse(date, 'dd.MM.yyyy', new Date());
};

const duration = (startDate: Date, endDate: Date) => {
  return intervalToDuration({ start: startDate, end: endDate });
};

export default {
  formatDate,
  parseDate,
  formatToDate,
  duration,
  formatToMonth,
  formatToWeek,
  formatToYear,
  formatToYearNumber,
  formatToMonthNumber,
  formatToExactDay,
  formatToExactMonth,
  formatToShortWeek,
};
