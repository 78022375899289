import { IAttachmentProjectData, IUser } from '@/interfaces';
import {
  ADD_USER,
  AddUserAction,
  ATTACH_PROJECT,
  AttachProjectAction,
  CHANGE_USER_PHOTO,
  ChangeUserPhotoAction,
  CLEAR_USERS,
  ClearUsersAction,
  EDIT_USER,
  EditUserAction,
  IUsersWithPagination,
  LOAD_USER,
  LOAD_USERS,
  LoadUserAction,
  LoadUsersAction,
  LoadUsersActionProps,
  SET_FILTERS,
  SET_LOADING_USERS,
  SetFilterAction,
  SetLoadingUsersAction,
  SingleUserActionProps,
  STORE_USER,
  STORE_USERS,
  StoreUserAction,
  StoreUsersAction,
  UPDATE_PHOTO_USER,
  UpdateUserPhotoAction,
} from '@/redux/users';

export function loadUsers(payload?: LoadUsersActionProps): LoadUsersAction {
  return {
    type: LOAD_USERS,
    payload,
  };
}

export function storeUsers(users: IUsersWithPagination): StoreUsersAction {
  return {
    type: STORE_USERS,
    payload: users,
  };
}

export function clearUsers(): ClearUsersAction {
  return {
    type: CLEAR_USERS,
  };
}

export function setLoadingUsers(isLoading: boolean): SetLoadingUsersAction {
  return {
    type: SET_LOADING_USERS,
    payload: isLoading,
  };
}

export function loadUser(payload: string): LoadUserAction {
  return {
    type: LOAD_USER,
    payload,
  };
}

export function userEdit(payload: SingleUserActionProps): EditUserAction {
  return {
    type: EDIT_USER,
    payload,
  };
}

export function userAdd(payload: SingleUserActionProps): AddUserAction {
  return {
    type: ADD_USER,
    payload,
  };
}

export function storeUser(user: IUser): StoreUserAction {
  return {
    type: STORE_USER,
    payload: user,
  };
}

export function updateUserPhoto(payload: { photo: string; id: string }): UpdateUserPhotoAction {
  return {
    type: UPDATE_PHOTO_USER,
    payload,
  };
}

export function attachProject(payload: IAttachmentProjectData): AttachProjectAction {
  return {
    type: ATTACH_PROJECT,
    payload,
  };
}

export function storeUserFilters(payload: LoadUsersActionProps): SetFilterAction {
  return {
    type: SET_FILTERS,
    payload,
  };
}

export function changeUserPhoto(values: { file: File; id: string }): ChangeUserPhotoAction {
  return {
    type: CHANGE_USER_PHOTO,
    payload: values,
  };
}
