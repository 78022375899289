import { forwardRef, ReactElement, Ref } from 'react';
import { IconButton, Dialog, Slide } from '@material-ui/core';
import { CloseIcon } from '@/components/Icons';
import { TransitionProps } from '@material-ui/core/transitions';
import useStyles from './styles';
import { IFullScreenDialogProps } from './types';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = ({
  handleDialogClose,
  open,
  children,
  ...props
}: IFullScreenDialogProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Dialog
      {...props}
      fullScreen
      open={open}
      onClose={handleDialogClose}
      TransitionComponent={Transition}
    >
      <IconButton
        disableRipple
        className={classes.closeButton}
        onClick={handleDialogClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <div className={classes.formWrapper}>{children}</div>
    </Dialog>
  );
};

export default FullScreenDialog;
