import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function ProjectIcon({ viewBox = '0, 0, 26, 26', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon classes={classes} viewBox={viewBox} {...rest}>
      <path
        d="M21.6667 7.58331H4.33335C3.13674 7.58331 2.16669 8.55336 2.16669 9.74998V20.5833C2.16669 21.7799 3.13674 22.75 4.33335 22.75H21.6667C22.8633 22.75 23.8334 21.7799 23.8334 20.5833V9.74998C23.8334 8.55336 22.8633 7.58331 21.6667 7.58331Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3334 22.75V5.41667C17.3334 4.84203 17.1051 4.29093 16.6988 3.8846C16.2924 3.47827 15.7413 3.25 15.1667 3.25H10.8334C10.2587 3.25 9.70762 3.47827 9.30129 3.8846C8.89496 4.29093 8.66669 4.84203 8.66669 5.41667V22.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
