import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 8,
      marginTop: 20,
    },
    title: {
      marginBottom: '20px',
      marginTop: '20px',
      fontSize: '28px',
      color: theme.palette.text.primary,
      fontWeight: 600,
      lineHeight: '39px',
    },
  }),
);

export default useStyles;
