import { useCallback, ChangeEvent, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
  Avatar,
  Divider,
  Button,
} from '@material-ui/core';
import { BackArrow, EditIcon } from '@/components/Icons';
import { DateFormatUtil } from '@/utils';
import { EditUserRateDialog } from '@/components/MobileDialogs';
import { useDispatch } from 'react-redux';
import { editUserRate } from '@/redux';
import { IShortTeamMemberInfo } from '@/interfaces';
import useStyles from './styles';
import { IProjectAccordionProps } from './types';

const ProjectAccordion = ({
  project,
  setEditProjectId,
  toggleProjectDialog,
  openDialog,
  searchName,
  setSearchName,
  ...props
}: IProjectAccordionProps): JSX.Element => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [currentTeamMemberIdx, setCurrentTeamMemberIdx] = useState(0);
  const [userRateDialog, setUserRateDialog] = useState(false);

  const currentUser = project?.teamMembers[currentTeamMemberIdx];

  const onUserRateModalSubmitHandler = useCallback(
    (values: IShortTeamMemberInfo) => {
      dispatch(editUserRate({ ...values, projectId: project.id }));
    },
    [dispatch, project.id],
  );

  const handleAccordionChange = useCallback(
    (panel: string) => (event: ChangeEvent<unknown>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : null);
    },
    [],
  );
  const editUserRateHandler = (idx: number) => {
    setCurrentTeamMemberIdx(idx);
    setUserRateDialog(true);
  };

  const handleEditProject = useCallback(() => {
    setEditProjectId(project.id);
    toggleProjectDialog(true);
  }, [setEditProjectId, toggleProjectDialog, project.id]);

  return (
    <div className={classes.accordionBlock} {...props}>
      <Accordion
        className={classes.accordionStyle}
        square
        expanded={expanded === project.id}
        onChange={handleAccordionChange(project.id)}
      >
        <AccordionSummary
          expandIcon={<BackArrow className={classes.arrow} />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <div className={classes.projectBlock}>
            <Typography className={classes.name} component="span">
              {project.projectName}
            </Typography>
            <Typography className={classes.date} variant="body2" component="span">
              {DateFormatUtil.formatDate(project.createdAt) ?? 0} - now
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.infoBlock}>
          <div className={classes.statusBlock}>
            <div>
              <div className={classes.userInfoRow}>
                <Typography className={classes.infoTitle}>Status:</Typography>
                <Typography className={classes.infoText}>{project.status}</Typography>
              </div>
              <Typography className={classes.infoTitle}>Team members:</Typography>
            </div>

            <IconButton className={classes.editIcon} color="inherit">
              <EditIcon onClick={handleEditProject} />
            </IconButton>
          </div>
          {project.teamMembers
            .filter((teamMember) => teamMember.user.status !== 'Inactive')
            .map((teamMember, idx) => (
              <div key={teamMember?.user?.id ?? idx} className={classes.userBlock}>
                <Button
                  disableRipple
                  className={classes.userInfo}
                  onClick={() => editUserRateHandler(idx)}
                >
                  <Avatar
                    className={classes.avatar}
                    alt={teamMember.user?.firstName}
                    src={teamMember.user?.photo || '/src/assets/svg/logo.svg'}
                  />
                  <Typography className={classes.userName} variant="body2" component="span">
                    {teamMember?.user?.firstName} {teamMember?.user?.lastName}
                  </Typography>
                </Button>
                <Typography className={classes.date} variant="body2" component="span">
                  {DateFormatUtil.formatDate(project.createdAt) ?? 0} - now
                </Typography>
              </div>
            ))}
        </AccordionDetails>
      </Accordion>
      <div className={classes.dividerBlock}>
        <Divider className={classes.divider} />
      </div>
      {currentUser && (
        <EditUserRateDialog
          openDialog={userRateDialog}
          user={currentUser}
          onSubmitHandler={onUserRateModalSubmitHandler}
          handleEditModalClose={() => setUserRateDialog(false)}
          projectId={project.id}
        />
      )}
    </div>
  );
};

export default ProjectAccordion;
