import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import {
  AuthorizationAction,
  AUTHORIZE,
  IAuthorizationState,
  LOG_OUT,
  USER_STORE,
  SET_LOADING_USER,
  SET_CHANGE_PASSWORD_LOADING,
} from '@/redux/authorization';

const initialState: IAuthorizationState = {
  user: null,
  isLoading: false,
  isLoadingChangePassword: false,
};

const authReducer = (
  state: IAuthorizationState = initialState,
  action: AuthorizationAction,
): IAuthorizationState => {
  switch (action.type) {
    case AUTHORIZE:
      return { ...state, user: action.payload };
    case LOG_OUT:
      return { ...state, user: null };
    case USER_STORE: {
      const { firstName, lastName, email, phoneNumber, dateOfBirth } = action.payload;

      return {
        ...state,
        user: {
          ...action.payload,
          firstName,
          lastName,
          email,
          phoneNumber,
          dateOfBirth,
        },
      };
    }
    case SET_LOADING_USER:
      return { ...state, isLoading: action.payload };
    case SET_CHANGE_PASSWORD_LOADING:
      return { ...state, isLoadingChangePassword: action.payload };
    default:
      return state;
  }
};

export default persistReducer(
  {
    key: 'authorization',
    storage,
    stateReconciler: autoMergeLevel2,
  },
  authReducer,
);
