import {
  InputLabel,
  FormGroup,
  FormControl,
  TextField,
  InputAdornment,
  Typography,
  ButtonBase,
} from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Autocomplete from '@mui/lab/Autocomplete';
import clsx from 'clsx';
import { IUser } from '@/interfaces';
import { SearchIcon } from '@/components/Icons';
import { Avatar } from '@mui/material';
import { debounce } from 'throttle-debounce';
import useStyles from '../styles';
import { IUserSelectProps } from './types';

export default function UserSearch({
  label,
  id,
  error,
  data,
  className,
  placeholder,
  searchName,
  setSearchName,
  link,
  fetchUsers,
  usersPagination,
  ...props
}: IUserSelectProps): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  const router = useRouteMatch<{ id: string }>();

  const handleChange = (event: React.ChangeEvent<unknown>, value: IUser) => {
    history.push(`${link}/${value.id}`);
  };

  const onSearchNameChange = debounce(500, (e) => {
    setSearchName(e.target.value);
  });

  return (
    <FormGroup className={classes.inputMargin}>
      <FormControl
        className={clsx(classes.wrapper, classes.input)}
        variant="outlined"
        error={!!error}
      >
        {label && (
          <InputLabel margin="dense" htmlFor={id} className={classes.label} error={!!error}>
            {label}
          </InputLabel>
        )}
        <Autocomplete
          disableClearable
          options={data}
          className={classes.input}
          onChange={handleChange}
          getOptionLabel={(option) => option.fullName}
          isOptionEqualToValue={(option, value) => (option ? option.id === value.id : false)}
          PaperComponent={(paperProps) => <div {...paperProps} className={classes.listBox} />}
          renderOption={(renderProps, option) => (
            <li {...renderProps} className={classes.listOption}>
              <ButtonBase disableRipple className={classes.user} key={option.id}>
                <Avatar
                  alt={option.fullName}
                  src={option.photo || '/src/assets/svg/logo.svg'}
                  className={classes.avatar}
                />
                <Typography
                  variant="body1"
                  component="p"
                  className={clsx({ [classes.selectedUser]: router.params.id === option.id })}
                >
                  {option.fullName}
                </Typography>
              </ButtonBase>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              value={searchName}
              onChange={onSearchNameChange}
              placeholder="Search"
              {...params}
              {...props}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
              className={classes.search}
            />
          )}
        />
      </FormControl>
    </FormGroup>
  );
}
