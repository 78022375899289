import InfiniteScroll from 'react-infinite-scroll-component';
import { resultsPaginationSelector } from '@/redux';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import { IUsersResultAccordionProps } from './types';
import UserResultAccordion from './UserResultAccordion';

const UsersResultAccordion = ({
  user,
  userResult,
  fetchData,
  setReport,
  isUser = false,
  dateFilteringEnabled,
  ...props
}: IUsersResultAccordionProps): JSX.Element => {
  const classes = useStyles();

  const pagination = useSelector(resultsPaginationSelector);

  return (
    <div {...props} className={classes.accordionBlock}>
      {fetchData ? (
        <InfiniteScroll
          dataLength={userResult?.reports.length ?? 0}
          next={fetchData}
          hasMore={pagination.hasNext}
          loader={null}
        >
          {userResult?.reports.map((report) => (
            <UserResultAccordion
              key={report.id}
              report={report}
              setReport={setReport}
              user={user}
              isUser={isUser}
              dateFilteringEnabled={dateFilteringEnabled}
            />
          ))}
        </InfiniteScroll>
      ) : (
        userResult?.reports.map((report) => (
          <UserResultAccordion
            key={report.id}
            report={report}
            setReport={setReport}
            user={user}
            isUser={isUser}
            dateFilteringEnabled={dateFilteringEnabled}
          />
        ))
      )}
    </div>
  );
};

export default UsersResultAccordion;
