import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalCardContent: {
      position: 'relative',
      padding: theme.spacing(4, 7, 6),

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
      },
    },
    cancelButton: {
      textWrap: 'nowrap',
      background: theme.palette.error.main,
      borderRadius: 6,
      border: 0,
      color: 'white',
      height: 39,
      padding: '0 42px',
      boxShadow: 'none',
      marginTop: theme.spacing(1),

      '&:hover': {
        background: theme.palette.error.main,
      },
    },
    buttonSmallPadding: {
      padding: 0,
      width: '100%',
    },
    buttonCommonLabel: { textTransform: 'none' },
    doubleCellsWrapper: {
      display: 'flex',
      width: 'calc(100% + 16px)',
      gap: '10px',
    },
    hidden: {
      visibility: 'hidden',
    },
    endedContract: {
      color: '#8C8D98',
    },
    divider: {
      backgroundColor: 'white',
      marginTop: '26px',
      marginBottom: '14px',
    },
    contractsContainer: {
      maxHeight: '300px',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '13px',
    },
    singleContractContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    contractText: {
      fontSize: '12.5px',
      lineHeight: 'normal',
      fontWeight: 390,
      letterSpacing: '0.16px',
    },
    trashIcon: {
      width: '20px',
      height: '20px',
    },
  }),
);

export default useStyles;
