import { createSelector } from 'reselect';
import { IApplicationState } from '../types';
import { IUsersState } from './types';

export const usersStateSelector = (state: IApplicationState): IUsersState => state.users;

export const usersSelector = createSelector(usersStateSelector, (state) => state.users);
export const userStateSelector = createSelector(usersStateSelector, (state) => state.currentUser);
export const usersLoadingSelector = createSelector(usersStateSelector, (state) => state.isLoading);

export const userByIdSelector = createSelector(
  usersStateSelector,
  (_s: IApplicationState, id: string) => id,
  (state, id) => state.users.find((project) => project.id === id) ?? null,
);

export const usersPaginationSelector = createSelector(
  usersStateSelector,
  (state) => state.pagination,
);

export const usersFiltersSelector = createSelector(usersStateSelector, (state) => state.filters);
