import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: '45px',
      marginTop: '20px',
      fontSize: '28px',
      color: theme.palette.text.primary,
      fontWeight: 600,
      lineHeight: '39px',
    },
    input: {
      marginBottom: '15px',
    },
    buttonLabel: {
      textTransform: 'capitalize',
    },
    buttonBlock: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    inputLabel: {
      fontSize: '15px',
      lineHeight: '21px',
      color: theme.palette.text.primary,
      marginTop: '10px',
    },
    label: {
      color: '#AFAFAF',
      fontSize: '14px',
    },
    button: {
      background: theme.palette.common.white,
      borderRadius: 6,
      border: 0,
      color: '#AFAFAF',
      height: 39,
      padding: '0 35px',
      boxShadow: 'none',
      marginTop: '25px',
      '&:hover': {
        background: theme.palette.common.white,
      },
    },
    submitButton: {
      color: 'white',
      background: theme.palette.success.main,
      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    descriptionInput: {
      borderRadius: '6px',
      width: '100%',
      '& .MuiOutlinedInput-multiline': {
        padding: '10px 15px',
        border: '1px solid #E8E9E8',
      },
      '& .MuiOutlinedInput-multiline.Mui-error': {
        border: 'none',
      },
      '& .MuiOutlinedInput-multiline.Mui-focused': {
        border: 'none',
      },
      '& .MuiInputBase-input': {
        overflow: 'auto !important',
        minHeight: '66px',
        maxHeight: '66px',
        height: '66px',
      },
    },
    helperText: {
      fontSize: '14px',
      lineHeight: '19px',
      margin: 0,
      marginTop: '4px',
      color: '#D55F4D',
    },
  }),
);

export default useStyles;
