import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  clearResultReports,
  loadProject,
  loadProjects,
  loadResultReports,
  projectByIdSelector,
} from '@/redux';
import { Grid } from '@material-ui/core';
import ProjectHeader from '@/components/ProjectHeader';
import { ProjectReportsTable } from '@/components/Tables';
import { getCurrentMonthRangeDatePickerState } from '@/constants';
import { IApplicationState } from '@/redux/types';
import { DateRange } from '@mui/lab';
import { dateRangeValidationSchema } from '@/validations';

export default function Project(): JSX.Element {
  const dispatch = useDispatch();
  const router = useRouteMatch<{ id: string; userId: string }>();

  const [date, setDate] = useState<DateRange<Date | null>>(getCurrentMonthRangeDatePickerState());

  const [activeUsers, setActiveUsers] = useState<string[]>([]);

  useEffect(() => {
    dispatch(loadProjects({ limit: 100 }));
  }, [dispatch]);

  const currentProject = useSelector((state: IApplicationState) =>
    projectByIdSelector(state, router.params.id),
  );

  const fetchReports = useCallback(async () => {
    if (currentProject) {
      const dateRange = { from: date[0], to: date[1] };
      const isValid = await dateRangeValidationSchema.isValid(dateRange);
      if (!isValid) {
        return;
      }

      dispatch(
        loadResultReports({
          ...(activeUsers.length && { userId: activeUsers }),
          date: dateRange,
          projectIds: [router.params.id],
          limit: 40,
        }),
      );
    }
  }, [currentProject, date, dispatch, activeUsers, router.params.id]);

  useEffect(() => {
    dispatch(clearResultReports());
    fetchReports();
  }, [dispatch, fetchReports]);

  useEffect(() => {
    dispatch(loadProject(router.params.id));
    return () => {
      dispatch(clearResultReports());
    };
  }, [dispatch, router]);

  useEffect(() => {
    if (currentProject) {
      setDate((prev) => [new Date(currentProject.createdAt), prev[1]]);
    }
  }, [currentProject]);

  if (!currentProject) {
    return <></>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ProjectHeader
          activeUsers={activeUsers}
          setActiveUsers={setActiveUsers}
          currentProject={currentProject}
          date={date}
          setDate={setDate}
        />
        <ProjectReportsTable fetchData={fetchReports} />
      </Grid>
    </Grid>
  );
}
