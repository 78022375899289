import {
  IProjectsState,
  ProjectAction,
  STORE_PROJECTS,
  SET_LOADING_PROJECTS,
  CLEAR_PROJECTS,
  STORE_PROJECT,
  STORE_USERS_RATES,
  STORE_USER_PROJECTS,
  STORE_PROJECTS_BY_USER,
  CLEAR_PROJECTS_BY_USER,
} from '@/redux/projects';

const initialState: IProjectsState = {
  isLoading: false,
  projects: [],
  usersRates: [],
  userProjects: [],
  projectsByUser: [],
  pagination: {
    hasNext: true,
    currentPage: null,
    total: 0,
  },
};

function projectsReducer(
  state: IProjectsState = initialState,
  action: ProjectAction,
): IProjectsState {
  switch (action.type) {
    case STORE_PROJECTS: {
      const projectsIdSet = new Set(action.payload.projects.map((projectId) => projectId.id));
      return {
        ...state,
        projects: [
          ...state.projects.filter((project) => !projectsIdSet.has(project.id)),
          ...action.payload.projects,
        ],
        pagination: {
          hasNext: action.payload.hasNext,
          currentPage: action.payload.currentPage,
          total: action.payload.total,
        },
      };
    }
    case STORE_PROJECT:
      if (!state.projects.find((project) => project.id === action.payload.id)) {
        return {
          ...state,
          projects: [action.payload, ...state.projects],
          pagination: { ...state.pagination, total: state.pagination.total + 1 },
        };
      }
      return {
        ...state,
        projects: state.projects.map((project) =>
          project.id === action.payload.id ? action.payload || project : project,
        ),
      };
    case CLEAR_PROJECTS: {
      return {
        ...state,
        projects: [],
        pagination: {
          hasNext: true,
          currentPage: null,
          total: 0,
        },
      };
    }
    case STORE_USER_PROJECTS: {
      return {
        ...state,
        userProjects: action.payload,
      };
    }
    case STORE_PROJECTS_BY_USER: {
      return {
        ...state,
        projectsByUser: action.payload,
      };
    }
    case CLEAR_PROJECTS_BY_USER: {
      return {
        ...state,
        projectsByUser: [],
      };
    }
    case SET_LOADING_PROJECTS:
      return { ...state, isLoading: action.payload };
    case STORE_USERS_RATES:
      return { ...state, usersRates: action.payload };
    default:
      return state;
  }
}
export default projectsReducer;
