import {
  Modal,
  Card,
  CardContent,
  Typography,
  FormGroup,
  Grid,
  Button,
  IconButton,
  Divider,
  Tooltip,
  Box,
} from '@material-ui/core';
import { CloseIcon, TrashIcon } from '@/components/Icons';
import { CustomInput, DatePicker } from '@/components/FormItems';
import { useFormik } from 'formik';
import { IContract } from '@/interfaces';
import { contractSchema } from '@/validations';
import { defaultContractValues } from '@/constants';
import clsx from 'clsx';
import { format, isFuture, isPast, isWithinInterval } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { contractsService } from '@/services';
import axios from 'axios';
import { ContractStatusEnum, contractStatusMapping } from '@/types/contract';
import useStyles from '../styles';
import useLocalStyles from './styles';
import { IContractsModalProps } from '../types';

export default function ContractsModal({
  userId,
  open,
  closeModalHandler,
}: IContractsModalProps): JSX.Element {
  const classes = useStyles();
  const localClasses = useLocalStyles();

  const [contracts, setContracts] = useState<IContract[]>([]);

  const fetchContracts = async (user?: string): Promise<IContract[]> => {
    try {
      if (user) {
        return await contractsService.getUserContracts(user);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e.response?.data.message);
      } else if (typeof e === 'object' && e && 'message' in e) {
        toast.error(e.message);
      }
    }
    return [];
  };

  const getStatusText = (contract: IContract) => {
    let statusText = contractStatusMapping[contract.status];
    if (contract.status === ContractStatusEnum.Active) {
      if (isFuture(new Date(contract.startAt))) {
        statusText = 'Upcoming';
      } else if (isPast(new Date(contract.endAt))) {
        statusText = 'Expired';
      }
    }
    return statusText;
  };

  const onSubmitHandler = async ({ id, ...data }: IContract) => {
    try {
      if (!userId) {
        return;
      }
      await contractsService.createContract({
        ...data,
        status: ContractStatusEnum.Active,
        user: userId,
      });

      fetchContracts(userId).then((res) => setContracts(res));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e.response?.data.message);
      } else if (typeof e === 'object' && e && 'message' in e) {
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    fetchContracts(userId).then((res) => setContracts(res));
  }, [userId]);

  // current contract is calculated by date
  const initialContractValue = useMemo(
    () =>
      contracts.length
        ? contracts.find((contract) =>
            isWithinInterval(new Date(), {
              start: new Date(contract.startAt),
              end: new Date(contract.endAt),
            }),
          ) ?? contracts[0]
        : defaultContractValues,
    [contracts],
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    resetForm,
    values,
    touched,
    errors,
  } = useFormik<IContract>({
    validationSchema: contractSchema,
    onSubmit: onSubmitHandler,
    initialValues: initialContractValue,
    enableReinitialize: true,
  });

  const closeModal = () => {
    closeModalHandler(false);
    resetForm();
  };

  const handleCloseCooperation = async () => {
    try {
      if (!userId) {
        return;
      }
      // initialContract is current active contract
      const editedContract = await contractsService.editContract({
        ...initialContractValue,
        status: ContractStatusEnum.Closed,
      });
      setContracts((oldContracts) =>
        oldContracts.map((contract) =>
          contract.id === editedContract.id ? editedContract : contract,
        ),
      );
      setFieldValue('status', ContractStatusEnum.Closed);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e.response?.data.message);
      } else if (typeof e === 'object' && e && 'message' in e) {
        toast.error(e.message);
      }
    }
  };

  const handleDeleteContract = async (contractId: string) => {
    try {
      await contractsService.deleteContract(contractId);
      setContracts((oldContracts) => oldContracts.filter((contract) => contract.id !== contractId));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e.response?.data.message);
      } else if (typeof e === 'object' && e && 'message' in e) {
        toast.error(e.message);
      }
    }
  };

  const handleStartDateChange = (date: Date | null) => {
    setFieldValue('startAt', date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setFieldValue('endAt', date);
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      className={classes.modalWrap}
      disableEnforceFocus
      disableAutoFocus
    >
      <Card className={classes.modalCard}>
        <CardContent className={localClasses.modalCardContent}>
          <IconButton
            aria-label="delete"
            className={classes.closeIconWrap}
            size="small"
            onClick={closeModal}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
          <div>
            <Typography component="h2" variant="h1" className={classes.title}>
              {contracts.length ? 'Current Contract details' : 'Add a Contract'}
            </Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormGroup>
                    <CustomInput
                      id="contractName"
                      label="Contract Name"
                      name="contractName"
                      placeholder="DEV-n, Agreement n"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contractName || ''}
                      error={touched.contractName && !!errors.contractName}
                      helperText={touched.contractName && errors.contractName}
                    />
                  </FormGroup>
                </Grid>
                <Grid item className={localClasses.doubleCellsWrapper}>
                  <FormGroup>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      placeholder="dd.mm.yyyy"
                      id="startAt"
                      label="From"
                      name="startAt"
                      onChange={handleStartDateChange}
                      onBlur={handleBlur}
                      value={values.startAt}
                      helperText={touched.startAt && errors.startAt}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      placeholder="dd.mm.yyyy"
                      id="endAt"
                      label="To"
                      name="endAt"
                      onChange={handleEndDateChange}
                      onBlur={handleBlur}
                      value={values.endAt}
                      helperText={touched.endAt && errors.endAt}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item className={localClasses.doubleCellsWrapper}>
                  <Button
                    variant="contained"
                    type="submit"
                    classes={{
                      root: clsx(classes.button, localClasses.buttonSmallPadding),
                      label: localClasses.buttonCommonLabel,
                    }}
                  >
                    Add a new contract
                  </Button>
                  <Button
                    variant="contained"
                    type="button"
                    onClick={handleCloseCooperation}
                    classes={{
                      root: clsx(
                        localClasses.cancelButton,
                        localClasses.buttonSmallPadding,
                        !contracts.length && localClasses.hidden,
                      ),
                      label: localClasses.buttonCommonLabel,
                    }}
                    disabled={values.status === ContractStatusEnum.Closed}
                  >
                    {values.status === ContractStatusEnum.Closed
                      ? 'Cooperation closed'
                      : 'Close cooperation'}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={localClasses.divider} />
                </Grid>
                <Grid item xs={12} className={localClasses.contractsContainer}>
                  <Typography component="h2" variant="h1">
                    Contracts History
                  </Typography>
                  {contracts.length ? (
                    contracts.map((con) => (
                      <Box key={con.id} className={localClasses.singleContractContainer}>
                        <Typography
                          className={clsx(
                            con.status === ContractStatusEnum.Closed && localClasses.endedContract,
                            localClasses.contractText,
                          )}
                        >
                          {`${con.contractName}: ${format(
                            new Date(con.startAt),
                            'dd.MM.yyyy',
                          )} - ${format(new Date(con.endAt), 'dd.MM.yyyy')} (${getStatusText(
                            con,
                          )})`}
                        </Typography>
                        <Tooltip title="Delete contract">
                          <IconButton
                            size="small"
                            disableRipple
                            onClick={() => handleDeleteContract(con.id)}
                          >
                            <TrashIcon className={localClasses.trashIcon} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ))
                  ) : (
                    <Typography>This user doesn’t have any active contract yet.</Typography>
                  )}
                </Grid>
              </Grid>
            </form>
          </div>
        </CardContent>
      </Card>
    </Modal>
  );
}
