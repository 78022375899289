import { combineReducers } from 'redux';
import settings from './settings/reducers';
import authorization from './authorization/reducers';
import reports from './reports/reducers';
import results from './results/reducers';
import users from './users/reducers';
import projects from './projects/reducers';
import timeOffs from './timeOffs/reducers';
import notifications from './notifications/reducers';

export default combineReducers({
  settings,
  authorization,
  reports,
  results,
  users,
  projects,
  timeOffs,
  notifications,
});
