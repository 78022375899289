import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(5.5),
      [theme.breakpoints.up('md')]: {
        fontWeight: 'bold',
      },
    },
    buttonLabel: {
      textTransform: 'capitalize',
    },
    button: {
      background: theme.palette.success.main,
      borderRadius: 6,
      border: 0,
      color: 'white',
      height: 39,
      padding: '0 42px',
      boxShadow: 'none',
      marginTop: theme.spacing(1),

      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    checkBox: {
      '&.Mui-checked': {
        color: theme.palette.primary.main,
      },
    },
    helperText: {
      fontSize: '14px',
      lineHeight: '19px',
      margin: 0,
      marginTop: '4px',
      color: '#D55F4D',
    },
    listOption: {
      display: 'flex',
      flexDirection: 'row',
      padding: '6px 16px',
      alignItems: 'center',
    },
  }),
);

export default useStyles;
