import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    vacationTableHeader: {
      fontWeight: 'bold',
      padding: '20px 24px 8px 24px',
      width: '100%',
    },
  }),
);

export default useStyles;
