import { useState, MouseEvent, useCallback } from 'react';
import { ClockIcon } from '@/components/Icons';
import { Button, Popover, InputAdornment, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { hoursToDisplayFormat } from '@/utils';
import CustomInput from '../CustomInput';
import useStyles from '../styles';
import { ITimePickerProps } from '../types';

export default function TimePicker({
  label,
  id,
  error,
  hours,
  minutes,
  inputClass,
  wrapper,
  onMinuteChange,
  minuteValue,
  minuteInputName,
  minuteInputId,
  handleTimeChange,
  hourValue,
  value, // Not pass value to second input
  ...rest
}: ITimePickerProps): JSX.Element {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const togglePopoverHandler = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  return (
    <div>
      <CustomInput
        {...rest}
        className={clsx(classes.timeInput, inputClass)}
        wrapper={wrapper}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ClockIcon />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.menu }}
      >
        <div className={classes.timePaper}>
          <div className={classes.hoursBlock}>
            {hours.map((hour) => (
              <Button
                key={hour}
                onClick={() => {
                  handleTimeChange('workerdHours', hour);
                }}
              >
                {hoursToDisplayFormat(hour)}
              </Button>
            ))}
          </div>
          <div className={classes.minutesBlock}>
            {minutes.map((minute) => (
              <Button
                key={minute}
                onClick={() => {
                  handleTimeChange('workedMinutes', minute);
                }}
              >
                {hoursToDisplayFormat(minute)}
              </Button>
            ))}
          </div>
        </div>
      </Popover>
      <Button onClick={togglePopoverHandler} className={classes.button}>
        <Typography className={classes.displayTime}>
          {hoursToDisplayFormat(hourValue as number)} : {hoursToDisplayFormat(minuteValue)}
        </Typography>
      </Button>

      <TextField
        id={minuteInputId}
        name={minuteInputName}
        onChange={onMinuteChange}
        value={minuteValue}
        className={classes.hiddenInput}
      />
    </div>
  );
}
