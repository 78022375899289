import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        width: 'inherit',
      },
    },
    popper: {
      '& .MuiPaper-root': {
        marginTop: '8px',
        boxShadow: 'none',
        backgroundColor: theme.palette.type === 'light' ? '#FFF' : '#151529',
      },
    },
    paperContent: {
      borderRadius: '7px',
      border: '1px solid #5435B8',
      '& > div': {
        '& > div': {
          '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
            borderRadius: 0,
            borderTop: '1px solid #5435B8',
            borderBottom: '1px solid #5435B8',
            backgroundColor: '#5435B826',
            color: '#FFF !important',
            '&:hover': {
              color: '#FFF',
            },
            '& .MuiButtonBase-root:hover, .MuiButtonBase-root:focus': {
              backgroundColor: '#775DC6',
              borderRadius: '50%',
            },
          },
          '& .MuiDateRangePickerDay-rangeIntervalDayHighlightStart': {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
          },
          '& .MuiDateRangePickerDay-rangeIntervalDayHighlightEnd': {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
          },
          '& .MuiDateRangePickerDay-dayInsideRangeInterval:hover': {
            color: '#FFF !important',
          },
          '& .Mui-selected': {
            backgroundColor: '#775DC6',
            color: '#FFF !important',
            '&:focus, &:hover': { backgroundColor: '#775DC6' },
            '& .button': {
              color: '#FFF !important',
            },
          },
          borderRadius: '7px',
          '&:first-child': {
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
            borderRight: '1px solid #5435B8',
            '& *': {
              color: theme.palette.type === 'light' ? '#000' : '#FFF',
            },
          },
          '&:last-child': {
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
            '& *': {
              color: theme.palette.type === 'light' ? '#000' : '#FFF',
            },
          },
        },
      },
    },
    dateTextField: {
      borderColor: theme.palette.type === 'light' ? '#000' : '#FFF',
      '& .MuiInputLabel-root': { color: theme.palette.type === 'light' ? '#000' : '#FFF' },
      '& .MuiInputBase-root': {
        '&:hover fieldset': {
          borderColor: theme.palette.type === 'light' ? '#000' : '#FFF',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1976d2',
        },
      },
      '& .MuiInputBase-input': { color: theme.palette.type === 'light' ? '#000' : '#FFF' },
      '& fieldset': { borderColor: theme.palette.type === 'light' ? '#000' : '#FFF' },
    },
  }),
);

export default useStyles;
