import React, { useMemo } from 'react';
import { Typography, useTheme } from '@material-ui/core';
import Slider, { Settings } from 'react-slick';
import { ClockIcon, DollarIcon } from '@/components/Icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useStyles from './styles';
import { IResultSliderProps } from './types';

const ResultSlider: React.FC<IResultSliderProps> = ({ time, salary, ...props }): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const configs: Settings = useMemo(() => {
    return {
      focusOnSelect: true,
      arrows: false,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 2.1,
          },
        },
        {
          breakpoint: theme.breakpoints.values.sm,
          settings: {
            slidesToShow: 2.5,
          },
        },
        {
          breakpoint: theme.breakpoints.values.md,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    };
  }, [theme.breakpoints.values.md, theme.breakpoints.values.sm]);

  return (
    <div {...props}>
      <Slider {...configs}>
        <div>
          <div className={classes.slideBlock}>
            <ClockIcon className={classes.icon} />
            <div>
              <Typography className={classes.slideTitle} variant="body1" component="h5">
                {time}
              </Typography>
              <Typography variant="body1" component="h5">
                hours
              </Typography>
            </div>
          </div>
        </div>
        <div>
          <div className={classes.slideBlock}>
            <DollarIcon className={classes.icon} />
            <div>
              <Typography className={classes.slideTitle} variant="body1" component="h5">
                {salary}
              </Typography>
              <Typography variant="body1" component="h5">
                salary
              </Typography>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default ResultSlider;
