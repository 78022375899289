import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userInfo } from '@/constants';
import {
  changePhoto,
  loadUserEvents,
  loadUserProfile,
  userProfileSelector,
  userSelector,
  nextEventsSelector,
  loadNextEvents,
} from '@/redux';
import { DateFormatUtil } from '@/utils';
import auth from '@DevimaSolutions/o-auth';
import { Theme, useMediaQuery } from '@material-ui/core';
import pluralize from 'pluralize';

const useProfile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserProfile());
    dispatch(loadUserEvents());
    dispatch(loadNextEvents());
  }, [dispatch]);

  const logOutHandler = useCallback(() => {
    auth().signOut();
  }, []);

  const userProfileInfo = useSelector(userProfileSelector);
  const user = useSelector(userSelector);
  const nextEvents = useSelector(nextEventsSelector);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [firstEvent, secondEvent] = nextEvents;
  const [showProfileDialg, setShowProfileDialg] = useState(false);

  const handleDialogClose = useCallback(() => {
    setShowProfileDialg(false);
  }, [setShowProfileDialg]);

  const durationTime = DateFormatUtil.duration(
    user?.hireDate ? new Date(user.hireDate) : new Date(),
    new Date(),
  );

  const handleAvatarChange = useCallback(
    (e) => {
      if (e.target.files.length === 0) {
        return;
      }
      dispatch(changePhoto(e.target.files?.[0]));
    },
    [dispatch],
  );
  const userInfoData = {
    [userInfo.position]: user?.position ?? '',
    [userInfo.hireDate]: `${DateFormatUtil.formatDate(new Date(user?.hireDate ?? new Date()))}`,
    [userInfo.timeOfService]: `${durationTime.years} ${pluralize('year', durationTime.years)}, ${
      durationTime.months
    } ${pluralize('month', durationTime.months)}, ${durationTime.days} ${pluralize(
      'day',
      durationTime.days,
    )}`,
    [userInfo.timeType]: user?.timeType ?? '',
  };

  return {
    userProfileInfo,
    user,
    userInfoData,
    handleAvatarChange,
    firstEvent,
    secondEvent,
    isDesktop,
    showProfileDialg,
    setShowProfileDialg,
    durationTime,
    logOutHandler,
    handleDialogClose,
  };
};

export default useProfile;
