import { Card, CardContent } from '@material-ui/core';
import { ResultTotal } from '@/components/Result';
import InfiniteScroll from 'react-infinite-scroll-component';
import { resultsPaginationSelector } from '@/redux';
import { useSelector } from 'react-redux';
import ReportTable from '../ReportTable';
import { IPaginatedReportsTableProps } from './types';
import useStyles from './styles';

export default function UserResult({
  user,
  userResult,
  fetchData,
  ...props
}: IPaginatedReportsTableProps): JSX.Element {
  const classes = useStyles();

  const pagination = useSelector(resultsPaginationSelector);

  return (
    <Card className={classes.card} {...props}>
      <CardContent>
        <InfiniteScroll
          dataLength={userResult?.reports.length ?? 0}
          next={fetchData}
          hasMore={pagination.hasNext}
          loader={null}
        >
          <ReportTable user={user} reports={userResult?.reports} />
        </InfiniteScroll>
        <ResultTotal
          remoteHours={userResult?.totalRemoteHours ?? '0:0'}
          hours={userResult?.totalHours ?? '0:0'}
          salary={userResult?.totalSalary ?? '0.00'}
        />
      </CardContent>
    </Card>
  );
}
