import { createSelector } from 'reselect';
import { IApplicationState } from '../types';
import { INotificationsState } from './types';

export const notificationsStateSelector = (state: IApplicationState): INotificationsState =>
  state.notifications;

export const notificationsSelector = createSelector(
  notificationsStateSelector,
  (state) => state.notifications,
);

export const newNotificationsSelector = createSelector(
  notificationsStateSelector,
  (state) => state.newNotifications,
);

export const shortNotificationsSelector = createSelector(
  notificationsStateSelector,
  (state) => state.shortNotifications,
);

export const notificationsCounterSelector = createSelector(
  notificationsStateSelector,
  (state) => state.newNotificationsCount,
);
