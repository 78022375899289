import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '105px',
    height: '39px',
    fontSize: '14px',
    padding: '10px',
    textTransform: 'none',
  },
  datePickerBlock: {
    width: '100%',
    margin: '0px 20px',
  },
  slider: {
    width: 'inherit',
    marginTop: '18px',
    padding: '10px 0px 28px 20px',
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  total: {
    fontWeight: 'bold',
    fontSize: '15px',
    marginBottom: '10px',
  },
  contentCard: {
    padding: '50px 20px',
    paddingBottom: '35px !important',
    minHeight: '600px',
  },
  userBlock: {
    width: '100%',
  },
  datePicker: {
    display: 'flex',
  },
  titleDatePicker: { fontSize: '15px', lineHeight: '21px', fontWeight: 500, marginBottom: '19px' },
  sort: {
    marginTop: '20px',
    maxWidth: '436px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '20px',
      marginTop: 0,
      maxWidth: 'auto',
    },
  },
  sortInput: {
    fontSize: '1rem',
    '& > .MuiInputLabel-root': {
      fontSize: '1em',
    },
    '& > .MuiOutlinedInput-root': {
      fontSize: '1em',
    },
  },
}));

export default useStyles;
