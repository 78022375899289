import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  headerStyle: {
    marginBottom: 0,
  },
  headerButton: {
    marginTop: '30px',
    textTransform: 'none',
    fontSize: '13px',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  type: {
    whiteSpace: 'break-spaces',
    textTransform: 'none',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    height: '88px',
    padding: '4px 16px',
  },
  messageBlock: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '232px',
  },
  message: {
    maxWidth: '232px',
    textAlign: 'start',
    margin: '0 13px',
  },
  birthday: {
    color: theme.palette.basic.blue,
  },
  warning: {
    color: theme.palette.error.main,
  },
  meeting: {
    color: theme.palette.basic.blue,
  },
  approval: {
    color: theme.palette.success.main,
  },
  requested: {
    color: theme.palette.basic.blue,
  },
  event: {
    color: theme.palette.basic.purple,
  },
  listItem: {
    background: theme.palette.background.paper,
    width: '100%',
    borderRadius: 0,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    minHeight: '50px',
  },
  divider: {
    color: theme.palette.divider,
    maxWidth: '335px',
    margin: 'auto',
  },
  notificationBlock: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column-reverse',
  },
}));

export default useStyles;
