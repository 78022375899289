import { useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadNewNotifications,
  loadNotifications,
  newNotificationsSelector,
  notificationsCounterSelector,
  notificationsSelector,
  shortNotificationsSelector,
} from '@/redux';
import { useHistory } from 'react-router-dom';
import { Theme, useMediaQuery } from '@material-ui/core';

const useNotification = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  useEffect(() => {
    dispatch(loadNewNotifications(true));
    if (isDesktop) {
      history.push('/');
    }
  }, [dispatch, history, isDesktop]);

  const notificationsCount = useSelector(notificationsCounterSelector);

  const [isShowAll, setIsShowAll] = useState(false);

  const notifications = useSelector(notificationsSelector);
  const newNotificationsArray = useSelector(newNotificationsSelector);
  const newNotifications = useMemo(() => {
    return notificationsCount.count !== 0 ? newNotificationsArray : [];
  }, [newNotificationsArray, notificationsCount]);
  const [open, setOpen] = useState(false);

  const handleNotificationsShow = () => {
    if (!isShowAll) {
      dispatch(loadNotifications());
    }
    setIsShowAll(!isShowAll);
  };

  const shortNewNotificationArray = useSelector(shortNotificationsSelector);
  const onClickHandler = useCallback(
    (path: string) => {
      setOpen(false);
      history.push(path);
    },
    [setOpen, history],
  );

  return {
    notifications,
    onClickHandler,
    open,
    setOpen,
    newNotifications,
    handleNotificationsShow,
    isShowAll,
    shortNewNotificationArray,
  };
};

export default useNotification;
