import { DateFormatUtil } from '@/utils';
import { Avatar, Card, CardContent, Typography, ButtonBase } from '@material-ui/core';
import { useCallback } from 'react';
import { roleOptions } from '@/constants/options.constansts';
import useStyles from './style';
import { IUserCardProps } from './types';

export default function UserCard({ user, handleAvatarChange }: IUserCardProps): JSX.Element {
  const classes = useStyles();
  const convertRole = useCallback((role: string) => {
    return roleOptions.find((item) => item.value === role)?.label || '';
  }, []);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.contentCard}>
        <ButtonBase className={classes.wrapper} component="label" disableRipple>
          <Avatar
            className={classes.avatar}
            alt={user?.fullName}
            src={user?.photo || '/src/assets/svg/logo.svg'}
          />
          <input accept="image/x-png,image/jpeg" type="file" hidden onChange={handleAvatarChange} />
        </ButtonBase>
        <Typography className={classes.role} variant="body1" component="p">
          {convertRole(user?.role ?? '')}
        </Typography>
        <Typography className={classes.name} variant="body1" component="h3">
          {user?.firstName} {user?.lastName}
        </Typography>
        <Typography className={classes.dateOfBirth} variant="body1" component="h3">
          {user?.dateOfBirth ? DateFormatUtil.formatToDate(user.dateOfBirth) : ''}
        </Typography>
        <div className={classes.divider} />
      </CardContent>
    </Card>
  );
}
