export const LOAD_USERS = 'USERS/LOAD_USERS';
export const SET_LOADING_USERS = 'USERS/SET_LOADING_USERS';
export const STORE_USERS = 'USERS/STORE_USERS';
export const CLEAR_USERS = 'USERS/CLEAR_USERS';
export const LOAD_USER = 'USERS/LOAD_USER';
export const STORE_USER = 'USERS/STORE_USER';
export const UPDATE_PHOTO_USER = 'USERS/UPDATE_PHOTO_USER';
export const EDIT_USER = 'USERS/EDIT_USER';
export const ADD_USER = 'USERS/ADD_USER';
export const SET_FILTERS = 'USERS/SET_FILTERS';
export const RESET_FILTERS = 'USERS/RESET_FILTERS';
export const ATTACH_PROJECT = 'USERS/ATTACH_PROJECT';

export const CHANGE_USER_PHOTO = 'USERS/CHANGE_PHOTO';
