import React, { useCallback } from 'react';
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
} from 'recharts';
import useStyles from './styles';
import { IChartProps } from '../../types';

const ChartComponent: React.FC<IChartProps> = ({
  period,
  isDesktop,
  chartHeight,
  overallSalary,
}: IChartProps) => {
  const formatTik = useCallback((value: number) => {
    if (value >= 1000) return `$ ${value / 1000}k`;
    return `$ ${value}`;
  }, []);

  const classes = useStyles({ chartHeight });
  return (
    <div className={classes.root}>
      <ResponsiveContainer>
        <AreaChart
          data={overallSalary ?? []}
          margin={{
            top: 15,
            right: isDesktop ? 20 : 6,
            left: -15,
            bottom: 25,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#5435B8" stopOpacity={0.5} />
              <stop offset="95%" stopColor="#5435B8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#5435B8" stopOpacity={0.5} />
              <stop offset="95%" stopColor="#5435B8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            fontFamily="Cuprum"
            dataKey="key"
            interval={period === 'Month' && !isDesktop ? 1 : 0}
            tickMargin={10}
          />
          <YAxis fontFamily="Cuprum" tickCount={7} tickFormatter={formatTik} />
          <CartesianGrid vertical={false} stroke="rgba(232, 233, 232, 0.8)" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="prev"
            stroke="#F09438"
            strokeWidth={3}
            fillOpacity={1}
            fill="url(#colorPv)"
          />
          <Area
            type="monotone"
            dataKey="current"
            stroke="#5435B8"
            strokeWidth={3}
            fillOpacity={1}
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartComponent;
