import { Grid, Button, Typography, Box } from '@material-ui/core';
import UserTimeOffsCalendar from '@/components/UserTimeOffsCalendar';
import Header from '@/components/Header';
import { RouteComponentProps } from 'react-router-dom';
import TimeOffsProfileHeader from '@/components/TimeOffsHeaders/TimeOffsProfileHeader';
import VacationModal from '@/components/Modals/VacationModal';
import { VacationTable } from '@/components/Tables';
import TimeOffsAccordion from '@/components/Accordions/TimeOffsAccordion';
import { VacationDialog } from '@/components/MobileDialogs';
import { AddCircleIcon } from '@/components/Icons';
import { userRoles } from '@/constants';
import { RejectVacationModal } from '@/components/Modals';
import useTimeOff from './useTimeOff';
import useStyles from './styles';

const TimeOff: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  const {
    openVacationModal,
    setOpenVacationModal,
    onSubmitHandler,
    setDate,
    date,
    events,
    user,
    vacationsDetails,
    selectedYear,
    setEventDate,
    isDesktop,
    toggleDialog,
    openDialog,
    closeModal,
    onCancelVacation,
    vacationToReject,
    closeRejectVacationModal,
    onConfirmCancelVacation,
  } = useTimeOff();

  return (
    <Grid container spacing={isDesktop ? 2 : 0}>
      <Grid item xs={12}>
        {!isDesktop && <Header title="Time off" />}
        <TimeOffsProfileHeader />
        <UserTimeOffsCalendar
          tableButton={{ title: 'Request time off', action: () => setOpenVacationModal(true) }}
          toggleModal={setOpenVacationModal}
          hasButton={user?.role !== userRoles.trainee}
          records={events}
          setEventDate={setEventDate}
        />
      </Grid>
      {vacationsDetails.length > 0 && isDesktop && (
        <VacationTable
          vacationsDetails={vacationsDetails}
          selectedYear={selectedYear}
          onCancelVacation={onCancelVacation}
        />
      )}
      {vacationsDetails.length > 0 && !isDesktop && (
        <>
          <Typography variant="h3" className={classes.vacationListHeader}>
            {selectedYear} vacations
          </Typography>
          <Box className={classes.vacationListContainer}>
            {vacationsDetails.map((vacationsDetail, idx) => (
              <TimeOffsAccordion
                idx={idx}
                vacationDetails={vacationsDetail}
                openDialog={openDialog}
                toggleDialog={toggleDialog}
                onCancelVacation={onCancelVacation}
              />
            ))}
          </Box>
        </>
      )}
      {!isDesktop && user?.role !== userRoles.trainee && (
        <Button className={classes.addVacation} onClick={toggleDialog}>
          <AddCircleIcon />
        </Button>
      )}
      <VacationModal
        open={openVacationModal}
        closeModalHandler={setOpenVacationModal}
        onSubmitHandler={onSubmitHandler}
        setDate={setDate}
        date={date}
        user={user}
      />
      <VacationDialog
        openDialog={openDialog}
        handleDialogClose={toggleDialog}
        closeModal={closeModal}
        onSubmitHandler={onSubmitHandler}
        setDate={setDate}
        date={date}
        user={user}
      />
      <RejectVacationModal
        open={!!vacationToReject}
        closeModalHandler={closeRejectVacationModal}
        vacation={vacationToReject}
        onConfirm={onConfirmCancelVacation}
      />
    </Grid>
  );
};

export default TimeOff;
