import { useCallback, useEffect, useMemo } from 'react';
import { Typography, FormGroup, Grid, Button, Checkbox } from '@material-ui/core';
import { MultiSelect } from '@/components/FormItems';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  attachProject,
  clearProjectsByUser,
  loadProjectsByUser,
  projectsSelector,
  usersLoadingSelector,
} from '@/redux';
import { AttachProject, IAttachmentProjectsData } from '@/interfaces';
import { setProjectsSchema } from '@/validations';
import useFetchProjects from '@/hooks/useFetchProjects';
import useStyles from './styles';
import { ISetProjectForUserFormProps } from './types';

const SetProjectForUserForm = ({
  user,
  handleClose,
  searchName,
  setSearchName,
  ...props
}: ISetProjectForUserFormProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmitHandler = useCallback(
    (
      { userId, projects }: IAttachmentProjectsData,
      { resetForm }: FormikHelpers<IAttachmentProjectsData>,
    ) => {
      const ratesArr: AttachProject[] = projects.map((project) => {
        return {
          projectId: project.id,
          rate: parseInt(user?.rate ? user?.rate : '0', 10),
        };
      });
      dispatch(attachProject({ projects: ratesArr, userId }));
      resetForm();
      handleClose?.();
    },
    [dispatch, user?.rate, handleClose],
  );

  useEffect(() => {
    dispatch(loadProjectsByUser({ userId: user.id }));

    return () => {
      dispatch(clearProjectsByUser());
    };
  }, [dispatch, user.id]);

  const usersLoading = useSelector(usersLoadingSelector);
  const projects = useSelector(projectsSelector);

  const validProjects = useMemo(() => {
    return projects.filter(
      (project) => !project.teamMembers.some((member) => member.user.id === user.id),
    );
  }, [user.id, projects]);

  const projectFetchOptions = useMemo(
    () => ({
      limit: 100,
      page: 0,
      status: 'Open',
    }),
    [],
  );

  useFetchProjects(searchName, projectFetchOptions);

  return (
    <div {...props}>
      <Typography component="h2" variant="h1" className={classes.title}>
        Add a projects
      </Typography>
      <Formik<IAttachmentProjectsData>
        validationSchema={setProjectsSchema}
        onSubmit={onSubmitHandler}
        initialValues={{
          projects: [],
          userId: user.id,
        }}
        enableReinitialize
      >
        {({ handleBlur, setFieldValue, errors, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FieldArray
              name="rates"
              render={() => (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormGroup>
                      <MultiSelect
                        id="projects"
                        label="Projects"
                        name="projects"
                        searchName={searchName}
                        setSearchName={setSearchName}
                        onBlur={handleBlur}
                        error={!!errors.projects}
                        options={validProjects}
                        clearOnBlur={false}
                        multiple
                        disableClearable
                        getOptionLabel={(option) => option.projectName}
                        isOptionEqualToValue={(option, value) =>
                          option ? option.id === value.id : false
                        }
                        renderOption={(renderProps, option, { selected }) => (
                          <li {...renderProps} className={classes.listOption}>
                            <Checkbox
                              value={option.id}
                              className={classes.checkBox}
                              checked={selected}
                            />

                            {option.projectName}
                          </li>
                        )}
                        onChange={(_e, name, val) => {
                          setFieldValue(name, val);
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      classes={{
                        root: classes.button,
                        label: classes.buttonLabel,
                      }}
                      disabled={usersLoading}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SetProjectForUserForm;
