import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(5.5),
    },
    buttonLabel: {
      textTransform: 'capitalize',
    },
    button: {
      background: theme.palette.success.main,
      borderRadius: 6,
      border: 0,
      color: 'white',
      height: 39,
      padding: '0 42px',
      boxShadow: 'none',
      marginTop: theme.spacing(1),

      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    phoneInput: {
      width: '100%',
      '& .form-control': {
        height: '36px',
        fontSize: '14px',
      },
    },
  }),
);

export default useStyles;
