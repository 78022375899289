import { LoadResultReportsActionProps } from '@/interfaces';
import {
  lastDayOfMonth,
  lastDayOfWeek,
  startOfWeek,
  startOfMonth,
  format,
  addDays,
  subMinutes,
} from 'date-fns';

export function dateToQueryRange(date: Date) {
  const monthStart = startOfMonth(date);
  const monthEnd = lastDayOfMonth(date);

  const firstDayInGrid = startOfWeek(monthStart);
  const lastDayInGrid = lastDayOfWeek(monthEnd);

  return `?from=${format(firstDayInGrid, 'yyyy-MM-dd')}&to=${format(
    subMinutes(addDays(lastDayInGrid, 1), 1),
    'yyyy-MM-dd',
  )}`;
}

export function reportsFiltersToQuery({
  page,
  limit,
  userId,
  projectIds,
  date,
  isVacationShow,
}: LoadResultReportsActionProps & { page: number }) {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  params.append('page', page.toString());

  if (date?.from && date?.to) {
    params.append('from', format(date.from, 'yyyy-MM-dd'));
    params.append('to', format(date.to, 'yyyy-MM-dd'));
  }

  if (userId) {
    if (Array.isArray(userId)) {
      userId.map((id, index) => {
        return params.append(`userId[${index}]`, id);
      });
    } else {
      params.append('userId', userId);
    }
  }

  if (isVacationShow === false) {
    params.append('isVacationShow', `${isVacationShow}`);
  }

  if (projectIds) {
    projectIds.forEach((projectId) => params.append('projectId', projectId));
  }

  return { params };
}

export function userReportsFiltersToQuery({
  limit,
  projectIds,
  date,
  isVacationShow,
}: LoadResultReportsActionProps) {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());

  if (date?.from && date?.to) {
    params.append('from', format(date.from, 'yyyy-MM-dd'));
    params.append('to', format(date.to, 'yyyy-MM-dd'));
  }

  if (isVacationShow === false) {
    params.append('isVacationShow', `${isVacationShow}`);
  }

  if (projectIds) {
    projectIds.forEach((projectId) => params.append('projectId', projectId));
  }

  return { params };
}

export function userReportsExportFiltersToQuery({
  userId,
  projectIds,
  date,
  isVacationShow,
}: Omit<LoadResultReportsActionProps, 'limit'>) {
  const params = new URLSearchParams();

  if (date?.from && date?.to) {
    params.append('from', format(date.from, 'yyyy-MM-dd'));
    params.append('to', format(date.to, 'yyyy-MM-dd'));
  }

  if (isVacationShow === false) {
    params.append('isVacationShow', `${isVacationShow}`);
  }

  if (projectIds) {
    projectIds.forEach((projectId) => params.append('projectId', projectId));
  }

  if (userId) {
    params.append('userId', userId.toString());
  }

  return { params };
}
