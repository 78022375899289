import { Box, Typography } from '@material-ui/core';
import CircularProgress from '@/components/CircularProgress';
import StatisticCard from '@/components/Cards/StatisticCard';
import useStyles from './styles';
import useBalanceCard from './useBalanceCard';
import { IBalanceCardProps } from './types';

const BalanceCard = ({ user }: IBalanceCardProps): JSX.Element => {
  const styles = useStyles();
  const { salary, expected, overtime, progress } = useBalanceCard();

  return (
    <StatisticCard
      title="Balance"
      expected={`$ ${expected.toFixed(2)}`}
      overtimeTitle={`Overtime balance (${user?.overTimePercent ?? 0}%)`}
      overtimeValue={`$ ${overtime.toFixed(2)}`}
    >
      <Typography component="span" className={styles.value}>
        <Box component="sup" className={styles.sup}>
          $
        </Box>
        {salary.toFixed(2)}
      </Typography>
      <CircularProgress value={progress} size={52} />
    </StatisticCard>
  );
};

export default BalanceCard;
