import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadUser,
  loadVacationsDetails,
  vacationsDetailsSelector,
  ICreateEvent,
  createEvent,
  loadAllEvents,
  loadVacationsByUser,
  usersSelector,
  usersPaginationSelector,
  userStateSelector,
  approveVacation,
  rejectVacation,
  removeEvent,
  editEvent,
  IEvent,
  IVacationDetails,
} from '@/redux';

import { useRouteMatch } from 'react-router-dom';
import { useMediaQuery, Theme } from '@material-ui/core';
import useFetchUsers from '@/hooks/useFetchUsers';
import { endOfYear, format, startOfYear } from 'date-fns';
import { RangeModifier } from 'react-day-picker';
import DateUtil from '@/utils/dates';
import timeOffsService from '@/services/timeOffs.service';
import logError from '@/utils/errors';

const useTimeOff = () => {
  const router = useRouteMatch<{ id: string }>();
  const dispatch = useDispatch();

  const [eventDate, setEventDate] = useState<RangeModifier>({
    from: undefined,
    to: undefined,
  });

  const [vacationToReject, setVacationToReject] = useState<IVacationDetails | null>(null);
  const [event, setEvent] = useState<IEvent | null>(null);
  const [openEventModal, setOpenEventModal] = useState(false);
  const [searchName, setSearchName] = useState('');
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const users = useSelector(usersSelector);
  const usersPagination = useSelector(usersPaginationSelector);
  const user = useSelector(userStateSelector);

  const vacationsDetails = useSelector(vacationsDetailsSelector);
  const data = useMemo(
    () => ({
      date: eventDate,
      isAdmin: true,
    }),
    [eventDate],
  );
  const fetchUsers = useFetchUsers(searchName, { limit: 100 });

  const handleEventModal = useCallback(() => {
    setOpenEventModal((value) => !value);
  }, []);

  const onHandleVacation = useCallback(
    (id: string, type: 'reject' | 'approve') => {
      dispatch(
        type === 'reject' ? rejectVacation({ id, ...data }) : approveVacation({ id, ...data }),
      );
    },
    [data, dispatch],
  );

  useEffect(() => {
    if (router.params.id !== undefined) {
      dispatch(loadUser(router.params.id));
      // Load all vacations for this year into the table
      dispatch(
        loadVacationsDetails({
          userId: router.params.id,
          date: {
            from: data.date.from ? startOfYear(data.date.from) : undefined,
            to: data.date.from ? endOfYear(data.date.from) : undefined,
          },
        }),
      );
      dispatch(
        loadVacationsByUser({
          userId: router.params.id,
          date: DateUtil.toCalendarRange(data.date),
        }),
      );
      return;
    }
    dispatch(loadAllEvents({ date: DateUtil.toCalendarRange(data.date), isAdmin: data.isAdmin }));
  }, [data, dispatch, router.params.id]);

  const closeModalHandler = useCallback(() => {
    setOpenEventModal(false);
    setEvent(null);
  }, []);

  const onSubmitHandler = useCallback(
    (values: ICreateEvent) => {
      const payload = {
        ...values,
        date: format(new Date(values.date), 'yyyy-MM-dd'),
        monthDate: data.date,
        isAdmin: data.isAdmin,
      };

      dispatch(event ? editEvent({ id: event.id, ...payload }) : createEvent(payload));
      closeModalHandler();
      dispatch(loadAllEvents({ date: DateUtil.toCalendarRange(data.date), isAdmin: data.isAdmin }));
    },
    [closeModalHandler, data, dispatch, event],
  );

  const removeEventHandler = useCallback(
    (id: string) => {
      dispatch(removeEvent({ id, ...data }));
    },
    [data, dispatch],
  );

  const editEventHandler = useCallback((editableEvent: IEvent) => {
    setOpenEventModal(true);
    setEvent(editableEvent);
  }, []);

  const onCancelVacation = (vacation: IVacationDetails) => {
    // Cancel vacation by admin
    // by showing confirmation modal
    setVacationToReject(vacation);
  };
  const onConfirmCancelVacation = async (vacation: IVacationDetails) => {
    try {
      await timeOffsService.handleVacation(vacation.id, 'reject');
    } catch (e) {
      logError(e);
      throw e;
    }

    dispatch(loadUser(router.params.id));
    dispatch(
      loadVacationsDetails({
        userId: router.params.id,
        date: {
          from: data.date.from ? startOfYear(data.date.from) : undefined,
          to: data.date.from ? endOfYear(data.date.from) : undefined,
        },
      }),
    );
    dispatch(
      loadVacationsByUser({
        userId: router.params.id,
        date: DateUtil.toCalendarRange(data.date),
      }),
    );

    setVacationToReject(null);
  };

  const closeRejectVacationModal = () => {
    setVacationToReject(null);
  };

  const selectedYear = startOfYear(data.date.from ?? new Date()).getFullYear();

  return {
    vacationsDetails,
    setOpenEventModal,
    onSubmitHandler,
    router,
    openEventModal,
    closeModalHandler,
    setEventDate,
    isDesktop,
    users,
    setSearchName,
    searchName,
    fetchUsers,
    usersPagination,
    user,
    handleEventModal,
    onHandleVacation,
    removeEventHandler,
    editEventHandler,
    event,
    selectedYear,
    onCancelVacation,
    vacationToReject,
    closeRejectVacationModal,
    onConfirmCancelVacation,
  };
};

export default useTimeOff;
