import { Grid, Typography } from '@material-ui/core';
import TimeOffsCalendar from '@/components/TimeOffsCalendar';
import AddIcon from '@material-ui/icons/Add';
import Header from '@/components/Header';
import { UserSearch } from '@/components/FormItems';
import TimeOffsAdminHeader from '@/components/TimeOffsHeaders/TimeOffsAdminHeader';
import useStyles from '../styles';
import { IMobileTimeOffProps } from './types';

export default function MobileTimeOff({
  router,
  setOpenEventModal,
  setEventDate,
  isDesktop,
  users,
  usersPagination,
  fetchUsers,
  setSearchName,
  searchName,
  user,
  editEventHandler,
  removeEventHandler,
  onHandleVacation,
}: IMobileTimeOffProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <div className={classes.header}>
        <Header
          title="Time off"
          mobileButton={{
            title: 'Add',
            startIcon: <AddIcon />,
            action: () => setOpenEventModal(),
          }}
        />
        <div className={classes.searchInput}>
          <UserSearch
            data={users}
            link="/time-off"
            variant="outlined"
            fetchUsers={fetchUsers}
            usersPagination={usersPagination}
            setSearchName={setSearchName}
            searchName={searchName}
          />
        </div>
      </div>
      <Grid item xs={12} md={9}>
        <div className={classes.pendingVacations}>
          <TimeOffsAdminHeader onHandleVacation={onHandleVacation} />
        </div>
        <Typography className={classes.mobileUserTitle}>{user?.fullName} </Typography>
        <TimeOffsCalendar
          editEventHandler={editEventHandler}
          removeEventHandler={removeEventHandler}
          setEventDate={setEventDate}
          tableButton={{
            title: 'Add',
            startIcon: <AddIcon />,
            action: () => {
              setOpenEventModal();
            },
          }}
          hasButton={!router.params.id && isDesktop}
          userId={router.params.id}
        />
      </Grid>
    </>
  );
}
