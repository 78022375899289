import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
  },
  searchInput: {
    marginTop: '-30px',
  },
  mobileUserTitle: {
    fontSize: '20px',
    marginLeft: '26px',
    fontWeight: 600,
    lineHeight: '28px',
    marginTop: '32px',
    marginBottom: '19px',
  },
  pendingVacations: {
    paddingRight: '20px',
    paddingLeft: '20px',
  },
}));

export default useStyles;
