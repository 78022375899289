import { useCallback, useEffect, useState } from 'react';
import {
  Modal,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Avatar,
  Button,
} from '@material-ui/core';
import { CloseIcon, AddIcon } from '@/components/Icons';
import { CustomInput } from '@/components/FormItems';
import { useFormik } from 'formik';
import { IShortTeamMemberInfo } from '@/interfaces';
import { editUserRateSchema } from '@/validations';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteUserRate,
  loadUsersRates,
  projectsLoadingSelector,
  userRatesSelector,
} from '@/redux';
import clsx from 'clsx';
import { IApplicationState } from '@/redux/types';
import UserRatesTable from '@/components/Tables/UserRatesTable';
import useStyles from '../styles';
import { IUserInfoModalProps } from '../types';

export default function UserRateModal({
  open,
  closeModalHandler,
  onSubmitHandler,
  user,
  projectId,
  onDetachUserSubmitHandler,
}: IUserInfoModalProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const projectsLoading = useSelector(projectsLoadingSelector);
  const userRates = useSelector((state: IApplicationState) =>
    userRatesSelector(state, user.user.id),
  );

  useEffect(() => {
    dispatch(loadUsersRates({ projectId, userId: user.user.id }));
  }, [dispatch, projectId, user]);

  const [showUserRateInput, setShowUserRateInput] = useState(false);

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik<IShortTeamMemberInfo>({
      validationSchema: editUserRateSchema,
      onSubmit: onSubmitHandler,
      initialValues: {
        userId: user.user.id ?? '',
        rate: user.rate ?? 0,
      },
      enableReinitialize: true,
    });

  const onDeleteRate = useCallback(
    (rateId: string) => {
      dispatch(deleteUserRate({ rateId, projectId }));
    },
    [dispatch, projectId],
  );

  const closeModal = () => {
    closeModalHandler(false);
    setShowUserRateInput(false);
  };

  const rateInput = () => {
    setShowUserRateInput((value) => !value);
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      className={classes.modalWrap}
      disableEnforceFocus
      disableAutoFocus
    >
      <Card className={classes.modalCard}>
        <CardContent className={classes.modalCardContent}>
          <IconButton
            aria-label="delete"
            className={classes.closeIconWrap}
            size="small"
            onClick={closeModal}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
          <div>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Avatar
                    alt={user.user.firstName}
                    src={user.user.photo || '/src/assets/svg/logo.svg'}
                    className={classes.avatar}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.userName} variant="body1">
                    {`${user?.user?.firstName} ${user?.user?.lastName}`}
                  </Typography>
                </Grid>
                {userRates && (
                  <UserRatesTable
                    userRates={userRates.history}
                    rateInput={rateInput}
                    onDelete={onDeleteRate}
                  />
                )}
                <Grid item xs={5}>
                  <div
                    className={clsx(
                      classes.baseAddRateBlock,
                      showUserRateInput ? classes.showAddRateBlock : '',
                    )}
                  >
                    <CustomInput
                      id="rate"
                      label="Rate"
                      name="rate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.rate ?? ''}
                      error={touched.rate && !!errors?.rate}
                      helperText={touched.rate && errors?.rate}
                    />
                    <Button
                      disableRipple
                      disabled={projectsLoading}
                      className={classes.addRateBtn}
                      type="submit"
                    >
                      <AddIcon className={classes.addRateIcon} />
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    className={classes.detachBtn}
                    onClick={() =>
                      onDetachUserSubmitHandler({
                        userId: user.user.id,
                        projectId,
                      })
                    }
                    classes={{
                      root: classes.button,
                      label: classes.buttonLabel,
                    }}
                    disabled={projectsLoading}
                  >
                    Detach
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </CardContent>
      </Card>
    </Modal>
  );
}
