import { all, call, spawn } from 'redux-saga/effects';
import reportsSagas from './reports/sagas';
import resultsSagas from './results/sagas';
import usersSagas from './users/sagas';
import projectsRootSaga from './projects/sagas';
import timeOffsRootSaga from './timeOffs/sagas';
import authorizationRootSaga from './authorization/sagas';
import notificationsRootSaga from './notifications/sagas';

const sagas = [
  reportsSagas,
  resultsSagas,
  usersSagas,
  projectsRootSaga,
  authorizationRootSaga,
  timeOffsRootSaga,
  notificationsRootSaga,
];

export default function* rootSaga() {
  yield all(
    sagas.map((saga) =>
      // eslint-disable-next-line
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            // eslint-disable-next-line
            if (e instanceof Error) {
              // eslint-disable-next-line no-console
              console.log(e.message);
            }
          }
        }
      }),
    ),
  );
}
