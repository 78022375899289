import { Grid } from '@material-ui/core';
import TimeOffsCalendar from '@/components/TimeOffsCalendar';
import AddIcon from '@material-ui/icons/Add';
import SidebarUsers from '@/components/SidebarUsers';
import TimeOffsAdminHeader from '@/components/TimeOffsHeaders/TimeOffsAdminHeader';
import TimeOffsUserHeader from '@/components/TimeOffsHeaders/TimeOffsUserHeader';
import { VacationTable } from '@/components/Tables';
import { IDesktopTimeOffProps } from './types';

export default function DesktopTimeOff({
  vacationsDetails,
  selectedYear,
  onCancelVacation,
  router,
  setOpenEventModal,
  setEventDate,
  onHandleVacation,
  editEventHandler,
  removeEventHandler,
}: IDesktopTimeOffProps): JSX.Element {
  return (
    <>
      <Grid item xs={12} md={9}>
        {router.params.id ? (
          <TimeOffsUserHeader />
        ) : (
          <TimeOffsAdminHeader onHandleVacation={onHandleVacation} />
        )}
        <TimeOffsCalendar
          setEventDate={setEventDate}
          editEventHandler={editEventHandler}
          removeEventHandler={removeEventHandler}
          tableButton={{
            title: 'Add',
            startIcon: <AddIcon />,
            action: () => {
              setOpenEventModal();
            },
          }}
          hasButton={!router.params.id}
          userId={router.params.id}
        />
        {vacationsDetails.length > 0 && router.params.id && (
          <VacationTable
            vacationsDetails={vacationsDetails}
            selectedYear={selectedYear}
            onCancelVacation={onCancelVacation}
          />
        )}
      </Grid>
      <Grid item xs={12} md={3}>
        <SidebarUsers isTimeOff linkTo="time-off" />
      </Grid>
    </>
  );
}
