import { Typography, FormGroup, Grid, Button, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import { CustomInput } from '@/components/FormItems';
import { projectSchema } from '@/validations';
import { defaultProjectValues, statusOptions } from '@/constants';
import { IShortProjectInfo } from '@/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { createProject, editProject, projectsLoadingSelector } from '@/redux';
import useStyles from './styles';
import { IProjectFormProps } from './types';

const ProjectForm = ({ project, onSuccess, ...props }: IProjectFormProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const projectsLoading = useSelector(projectsLoadingSelector);

  const onSubmitHandler = (values: IShortProjectInfo) => {
    const payload = {
      project: values,
      onSuccess,
    };
    dispatch(project ? editProject(payload) : createProject(payload));
  };

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik<IShortProjectInfo>({
      validationSchema: projectSchema,
      onSubmit: onSubmitHandler,
      initialValues: project || defaultProjectValues,
      enableReinitialize: true,
    });
  return (
    <div {...props}>
      <Typography component="h2" variant="h1" className={classes.title}>
        {project ? 'Edit the project' : 'Create a new project'}
      </Typography>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormGroup>
              <CustomInput
                id="projectName"
                label="Project Name"
                name="projectName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.projectName || ''}
                error={touched?.projectName && !!errors?.projectName}
                helperText={touched?.projectName && errors?.projectName}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <CustomInput
                select
                id="status"
                label="Status"
                name="status"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.status ?? ''}
                error={touched.status && !!errors?.status}
                helperText={touched.status && errors?.status}
              >
                {statusOptions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomInput>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              classes={{
                root: classes.button,
                label: classes.buttonLabel,
              }}
              disabled={projectsLoading}
            >
              {project ? 'Edit' : 'Add'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ProjectForm;
