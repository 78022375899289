import { phoneRegExp } from '@/utils';
import { string, object } from 'yup';

export const userProfileSchema = object().shape({
  firstName: string().required('First name is required!'),
  lastName: string().required('Last name is required!'),
  email: string().email('Invalid email address').required('Email is required!'),
  phoneNumber: string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone number is required!'),
  dateOfBirth: string(),
});

export default userProfileSchema;
