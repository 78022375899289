import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.palette.success.main,
      borderRadius: 6,
      border: 0,
      color: 'white',
      height: 39,
      padding: '0 42px',
      boxShadow: 'none',
      marginTop: theme.spacing(1),

      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    penIcon: {
      width: '20px',
      height: '20px',
      marginLeft: '16px',
      cursor: 'pointer',
    },
    buttonLabel: { textTransform: 'capitalize' },
    divider: {
      color: theme.palette.grey[400],
      maxWidth: '404px',
      width: '100%',
      marginTop: '5px',
      marginBottom: '28px',
    },
    dateDivider: {
      color: theme.palette.grey[100],
      maxWidth: '404px',
      width: '100%',
      marginTop: '5px',
      marginBottom: '28px',
    },
    data: {
      color: theme.palette.grey[900],
    },
    date: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    rate: {
      color: theme.palette.grey[900],
      marginBottom: '14px',
      marginLeft: '5px',
    },
    dateRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
    },
    rateBlock: {
      display: 'flex',
      marginRight: '14px',
    },
    dateBlock: {
      display: 'flex',
      marginLeft: '15px',
    },
    nameBlock: {
      display: 'flex',
      height: '100%',
      alignSelf: 'center',
    },
    userRatesBlock: {
      width: '100%',
    },
    buttonBlock: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '16px',
    },
    fullSizeDivider: {
      maxWidth: '100%',
    },
  }),
);

export default useStyles;
