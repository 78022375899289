import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ICalendarContentProps } from '@/components/Calendar/types';
import { IEvent } from '@/redux';
import clsx from 'clsx';
import useStyles from './styles';

const EventContent: React.FC<ICalendarContentProps<IEvent>> = ({
  data,
  recordsLimit = 3,
  classes,
  contentType,
  ...props
}) => {
  const localClasses = useStyles();
  const renderData = data ?? [];

  return (
    <Grid container className={classes?.root} {...props}>
      <Grid item xs={12}>
        {renderData
          .filter((_item, index) => index < recordsLimit)
          .map((item, index) => (
            <Grid container key={index} className={localClasses.body}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} className={localClasses.preview}>
                    {['Birthday', 'Event'].includes(item.type) && (
                      <div
                        className={clsx(
                          localClasses.icon,
                          item.type === 'Birthday' ? localClasses.bDay : localClasses.sun,
                        )}
                      />
                    )}
                    <Typography className={localClasses.itemText}>{item.eventName}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        {renderData.length > recordsLimit && (
          <Typography className={classes?.itemText}>
            ... +{renderData.length - recordsLimit}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default EventContent;
