import PhoneInput from 'react-phone-input-2';
import clsx from 'clsx';
import 'react-phone-input-2/lib/material.css';
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import useStyles from '../styles';
import { IPhoneInputFieldProps } from '../types';
import usePhoneInputField from './usePhoneInputField';

export const PhoneInputField = ({
  label,
  className,
  name,
  onChange,
  error,
  id,
  ...props
}: IPhoneInputFieldProps) => {
  const classes = useStyles();
  const { handleChange } = usePhoneInputField({ onChange, name });

  return (
    <FormControl
      className={clsx(classes.phoneInput, classes.wrapper, className)}
      variant="outlined"
      error={!!error}
    >
      {label && (
        <InputLabel margin="dense" htmlFor={id} className={clsx(classes.label)} error={!!error}>
          {label}
        </InputLabel>
      )}
      <PhoneInput
        country="ua"
        placeholder="(066) 666 66 66"
        onChange={handleChange}
        inputProps={{
          name,
        }}
        defaultErrorMessage={error}
        isValid={!error}
        {...props}
      />
      <FormHelperText error={!!error}>{error}</FormHelperText>
    </FormControl>
  );
};

export default PhoneInputField;
