import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnWrap: {
      display: 'flex',
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      backgroundColor: theme.palette.background.paper,
      paddingBottom: theme.spacing(2),
      position: 'fixed',
      height: '83px',
      paddingTop: '20px',
      bottom: 0,
      justifyContent: 'flex-start',
      width: '100%',
    },
    btn: {
      padding: 0,
      textTransform: 'none',
      '& .MuiListItemIcon-root': {
        minWidth: 'inherit',
      },
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      height: '3px',
      top: 0,
      left: 0,
      transition: theme.transitions.create(['left'], {
        duration: theme.transitions.duration.standard,
      }),
    },
    line: {
      width: '32px',
      height: '3px',
      backgroundColor: theme.palette.text.primary,
    },
    link: {
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.text.primary,
      minWidth: '75px',
      '&:first-child': {
        marginLeft: '67px',
      },
    },
  }),
);

export default useStyles;
