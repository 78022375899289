import { ChangeEvent, useCallback, useState } from 'react';

const useDevimaWiki = () => {
  const [expanded, setExpanded] = useState<string | boolean>('panel1');

  const handleChange = useCallback(
    (panel: string) => (event: ChangeEvent<Record<string, string>>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    },
    [],
  );

  return { expanded, handleChange };
};

export default useDevimaWiki;
