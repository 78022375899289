import { phoneRegExp } from '@/utils';
import { string, object, number, date } from 'yup';

export const editUserSchema = object().shape({
  userId: string()
    .optional()
    .matches(
      /[A-Za-z0-9]$/,
      'Should contain only uppercase and lowercase latin characters and numbers',
    )
    .min(2, 'Should be at least 2 characters long'),
  firstName: string()
    .required('First name is required!')
    .matches(
      /[A-Za-z0-9]$/,
      'Should contain only uppercase and lowercase latin characters and numbers',
    )
    .min(2, 'Should be at least 2 characters long'),
  lastName: string()
    .required('Last name is required!')
    .matches(
      /[A-Za-z0-9]$/,
      'Should contain only uppercase and lowercase latin characters and numbers',
    )
    .min(2, 'Should be at least 2 characters long'),
  email: string().email('Invalid email address').required('Email is required!'),
  phoneNumber: string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone number is required!')
    .min(11, 'Should be at least 11 characters long'),
  role: string().required('Role is required!'),
  status: string().required('Status is required!'),
  timeType: string().required('Time type is required!'),
  hireDate: date()
    .max(new Date(), 'Should be not in the future')
    .required('Hire Date is required')
    .typeError('Hire Date must be a valid Date'),
  rate: number().required('Rate is required!').positive('Should be positive'),
  overTimePercent: number()
    .min(0, 'must be greater than or equal to 0')
    .max(100, 'Must be less than or equal to 100')
    .required('Overtime percent is required!'),
});

export const addUserSchema = object()
  .shape({
    password: string().min(6, 'Too Short!').required('Password is required!'),
  })
  .concat(editUserSchema);
