import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 700,
      fontSize: 13,
      lineHeight: '16px',
      letterSpacing: '0.114286px',
      fill: theme.palette.text.primary,
    },
  }),
);

export default useStyles;
