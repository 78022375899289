import { useCallback, useState } from 'react';
import { Avatar, Switch } from '@material-ui/core';
import { DateFormatUtil } from '@/utils';
import pluralize from 'pluralize';
import { useDispatch, useSelector } from 'react-redux';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import { saveTheme, settingsSelector } from '@/redux';
import clsx from 'clsx';
import InfoField from './components/InfoField';
import useStyles from './style';
import { IMobileUserCardProps } from './types';

export default function MobileUserCard({ user, durationTime }: IMobileUserCardProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { theme } = useSelector(settingsSelector);
  const [light, setLight] = useState(theme === 'light');

  const toggleTheme = useCallback(() => {
    dispatch(saveTheme(theme === 'light' ? 'dark' : 'light'));
    setLight(!light);
  }, [dispatch, light, theme]);
  return (
    <>
      <Avatar
        className={classes.avatar}
        alt={user?.fullName}
        src={user?.photo || '/src/assets/svg/logo.svg'}
      />
      <InfoField infoTitle="Name" infoValue={user?.fullName} />
      <InfoField infoTitle="Email" infoValue={user?.email} />
      <InfoField infoTitle="Phone number" infoValue={user?.phoneNumber} />
      <InfoField
        infoTitle="Date of Birth"
        infoValue={user?.dateOfBirth ? DateFormatUtil.formatToDate(user.dateOfBirth) : ''}
      />
      <InfoField infoTitle="Position" infoValue={user?.position} />
      <InfoField
        infoTitle="Hire date"
        infoValue={user?.hireDate ? DateFormatUtil.formatToDate(user?.hireDate) : ''}
      />
      <InfoField
        infoTitle="Time of service"
        infoValue={`
          ${durationTime?.years} ${pluralize('year', durationTime?.years)},
          ${durationTime?.months} ${pluralize('month', durationTime?.months)},
          ${durationTime?.days} ${pluralize('day', durationTime?.days)}`}
      />
      <div className={classes.toggle}>
        <div className={clsx(theme === 'light' && classes.opacityText)}>
          <Brightness4Icon />
        </div>
        <Switch checked={light} onChange={toggleTheme} name="checkedA" />
        <div className={clsx(theme !== 'light' && classes.opacityText)}>
          <Brightness7Icon />
        </div>
      </div>
    </>
  );
}
