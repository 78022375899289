import { Button, Card, CardContent } from '@material-ui/core';
import Calendar from '@/components/Calendar';
import { CalendarContentType } from '@/types/calendar';
import useStyles from './styles';
import EventContent from '../EventContent';
import UserEventTooltip from '../UserEventTooltip';
import { ITimeOffsCalendarProps } from './types';
import useTimeOffsCalendar from './useUserTimeOffsCalendar';

function UserTimeOffsCalendar({
  className,
  tableButton,
  toggleModal,
  hasButton,
  records,
  setEventDate,
  ...props
}: ITimeOffsCalendarProps): JSX.Element {
  const classes = useStyles();

  useTimeOffsCalendar();

  return (
    <div className={classes.content}>
      {hasButton && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          startIcon={tableButton.startIcon}
          onClick={tableButton.action}
        >
          {tableButton.title}
        </Button>
      )}
      <Card className={className} {...props}>
        <CardContent>
          <Calendar
            CellContentComponent={EventContent}
            TooltipComponent={UserEventTooltip}
            records={records}
            className={classes.calendar}
            contentType={CalendarContentType.UserTimeOffs}
            setEventDate={setEventDate}
          />
        </CardContent>
      </Card>
    </div>
  );
}

export default UserTimeOffsCalendar;
