import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: theme.spacing(0, 1),
      fontSize: '28px',
      lineHeight: '39px',
    },
    allButton: {
      margin: theme.spacing(0, 1),
      textTransform: 'none',
      width: '113px',
    },
    datePicker: {
      margin: '0px 0px 24px 20px',
      display: 'flex',
      alignItems: 'flex-end',
    },
    projectInputBlock: {
      display: 'flex',
      marginLeft: '42px',
      minWidth: '282px',
      width: 'auto',
      '& .MuiOutlinedInput-root': {
        paddingTop: '0px',
        paddingBottom: '0px',
        minHeight: '44px',
      },
    },
    projectInput: {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        '& .MuiOutlinedInput-input': {
          padding: '11px 8px',
        },
      },
    },
    datePickerBlock: {
      marginTop: '-25px',
      width: '100%',
      margin: '0px 20px',
    },
  }),
);

export default useStyles;
