import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function EditIcon({ viewBox = '0, 0, 20, 20', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M9.16669 3.33337H3.33335C2.89133 3.33337 2.4674 3.50897 2.15484 3.82153C1.84228 4.13409 1.66669 4.55801 1.66669 5.00004V16.6667C1.66669 17.1087 1.84228 17.5327 2.15484 17.8452C2.4674 18.1578 2.89133 18.3334 3.33335 18.3334H15C15.442 18.3334 15.866 18.1578 16.1785 17.8452C16.4911 17.5327 16.6667 17.1087 16.6667 16.6667V10.8334"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4167 2.08332C15.7482 1.7518 16.1978 1.56555 16.6667 1.56555C17.1355 1.56555 17.5852 1.7518 17.9167 2.08332C18.2482 2.41484 18.4345 2.86448 18.4345 3.33332C18.4345 3.80216 18.2482 4.2518 17.9167 4.58332L10 12.5L6.66669 13.3333L7.50002 9.99999L15.4167 2.08332Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
