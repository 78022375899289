import { Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from '../../style';
import { IInfoFieldProps } from './types';

export default function MobileUserCard({ infoTitle, infoValue }: IInfoFieldProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <div className={classes.info}>
        <Typography className={classes.infoText}>{infoTitle}</Typography>
        <Typography className={clsx(classes.infoText, classes.opacityText)}>{infoValue}</Typography>
      </div>
      <Divider variant="middle" className={classes.divider} />
    </>
  );
}
