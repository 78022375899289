import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function UserGroupIcon({ viewBox = '0, 0, 20, 21', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M14.1668 17.5684V15.9017C14.1668 15.0176 13.8156 14.1698 13.1905 13.5447C12.5654 12.9195 11.7176 12.5684 10.8335 12.5684H4.16683C3.28277 12.5684 2.43493 12.9195 1.80981 13.5447C1.18469 14.1698 0.833496 15.0176 0.833496 15.9017V17.5684"
        stroke="#93B7FA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49984 9.23503C9.34079 9.23503 10.8332 7.74264 10.8332 5.90169C10.8332 4.06074 9.34079 2.56836 7.49984 2.56836C5.65889 2.56836 4.1665 4.06074 4.1665 5.90169C4.1665 7.74264 5.65889 9.23503 7.49984 9.23503Z"
        stroke="#93B7FA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1665 17.5683V15.9016C19.166 15.1631 18.9201 14.4456 18.4676 13.8619C18.0152 13.2782 17.3816 12.8613 16.6665 12.6766"
        stroke="#93B7FA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3335 2.67664C14.0505 2.86022 14.686 3.27722 15.1399 3.86189C15.5937 4.44657 15.84 5.16566 15.84 5.9058C15.84 6.64595 15.5937 7.36504 15.1399 7.94971C14.686 8.53439 14.0505 8.95139 13.3335 9.13497"
        stroke="#93B7FA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
