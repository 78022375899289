import { Tooltip, withStyles } from '@material-ui/core';

const LightTooltip = withStyles({
  tooltip: {
    color: '#575757',
    backgroundColor: '#FAFAFA',
    pointerEvents: 'auto',
    maxWidth: 500,
    width: '100%',
  },
  arrow: {
    color: '#FAFAFA',
  },
})(Tooltip);

export default LightTooltip;
