import { useDispatch, useSelector } from 'react-redux';
import { IconButton, IconButtonProps } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import clsx from 'clsx';
import { blurSelector, saveBlur } from '@/redux';
import { useCallback } from 'react';

const DashboardVisibilityButton = ({ className, ...props }: IconButtonProps) => {
  const showBlur = useSelector(blurSelector);
  const dispatch = useDispatch();

  const toggleBlur = useCallback(() => {
    dispatch(saveBlur(!showBlur));
  }, [dispatch, showBlur]);

  return (
    <IconButton {...props} className={clsx(className)} onClick={toggleBlur}>
      {showBlur ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  );
};

export default DashboardVisibilityButton;
