import { IEvent } from '@/redux';

export const mergeEvents = (
  events: Record<string, IEvent[]>,
  vacations: Record<string, IEvent[]>,
) => {
  const mergeMap = new Map(Object.entries(events));

  Object.entries(vacations).forEach(([key, value]) => {
    if (mergeMap.has(key)) {
      mergeMap.set(key, [...(mergeMap.get(key) ?? []), ...value]);
      return;
    }
    mergeMap.set(key, value);
  });

  return Object.fromEntries(mergeMap);
};
