import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadUserEvents } from '@/redux';

const useTimeOffsCalendar = () => {
  const dispatch = useDispatch();

  const fetchEvents = useCallback(() => {
    dispatch(loadUserEvents());
  }, [dispatch]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);
};

export default useTimeOffsCalendar;
