import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import tapeIcon from '@/assets/svg/icons/tape.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: '5px',
    },
    editTitle: {
      marginTop: '20px',
    },
    buttonLabel: {
      textTransform: 'capitalize',
    },
    inputLabel: {
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '21px',
      color: theme.palette.text.primary,
    },
    label: {
      color: '#AFAFAF',
      fontSize: '14px',
    },
    totalInfo: {
      color: theme.palette.type === 'light' ? '#5535B8' : '#C8BFE6',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '21px',
    },
    tape: {
      backgroundImage: `url(${tapeIcon})`,
      width: '101px',
      height: '30px',
      backgroundRepeat: 'no-repeat',
      marginRight: '-13px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    leftInfo: {
      color: theme.palette.type === 'light' ? '#5535B8' : '#C8BFE6',
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '21px',
    },
    vacantionInfo: {
      fontSize: '18px',
      fontWeight: 600,
      color: theme.palette.text.primary,
      marginTop: '5px',
    },
    button: {
      background: theme.palette.common.white,
      borderRadius: 6,
      border: 0,
      color: '#AFAFAF',
      height: 39,
      padding: '0 35px',
      boxShadow: 'none',
      marginTop: theme.spacing(1),
      marginLeft: '15px',
      '&:hover': {
        background: theme.palette.common.white,
      },
    },
    subTitle: {
      fontWeight: 600,
      marginTop: '30px',
    },
    descriptionBlock: {
      marginTop: '15px',
    },
    submitButton: {
      color: 'white',
      background: theme.palette.success.main,
      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    typeBlock: {
      marginTop: '10px',
      marginBottom: '20px',
    },
    menu: {
      marginTop: 9,
      marginLeft: '-20px',
    },
    reportInputWrapper: {
      marginTop: '0px',
    },
    reportInput: {
      marginTop: '0px',

      '& .MuiSelect-icon': {
        display: 'none',
      },
      '& .MuiInputBase-input': {
        fontSize: '15px',
        lineHeight: '21px',
        marginLeft: '12px',
        marginBottom: '5px',
        paddingRight: '0px',
        fontFamily: 'BrandonText',
        '&:focus': {
          background: 'none',
        },
      },

      '& .MuiInput-underline:after': {
        borderBottom: '1px solid #884DBD',
        transform: 'none',
      },
      '& .MuiSvgIcon-root': {
        marginLeft: '5px',
        width: '30px',
        marginBottom: '10px',
      },
    },
    fullHeight: {
      height: '100%',
    },
    buttonGroup: {
      display: 'flex',
      marginTop: '15px',
      justifyContent: 'flex-end',
      width: '100%',
      paddingRight: '15px',
    },
    titleBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    descriptionInput: {
      border: '1px solid #E8E9E8',
      borderRadius: '6px',
      width: '100%',
      maxWidth: '441px',
      '& .MuiOutlinedInput-multiline': {
        padding: '10px 15px',
      },
      '& .MuiInputBase-input': {
        overflow: 'auto !important',
        minHeight: '66px',
        maxHeight: '66px',
        height: '66px',
      },
    },
    mobileTitle: {
      fontSize: '24px',
      lineHeight: '31px',
      color: theme.palette.text.primary,
    },
    helperText: {
      fontSize: '14px',
      lineHeight: '19px',
      margin: 0,
      marginTop: '4px',
      color: '#D55F4D',
    },
  }),
);

export default useStyles;
