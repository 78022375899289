import { SagaIterator } from 'redux-saga';
import { all, call, takeLatest, put } from 'redux-saga/effects';
import { authorizationService } from '@/services';
import { toast } from 'react-toastify';
import logError from '@/utils/errors';
import { handleRequestError } from '@/utils';
import {} from '@/redux/projects';
import {
  SEND_FORGOT_PASSWORD,
  SendForgotPasswordAction,
  RESET_PASSWORD,
  ResetPasswordAction,
  EditUserProfileAction,
  EDIT_USER_PROFILE,
  LOAD_USER_PROFILE,
  CHANGE_PASSWORD,
  ChangePasswordAction,
  ChangePhotoAction,
  CHANGE_PHOTO,
} from '@/redux/authorization';
import { IAuthUser } from '@/types/user';
import { userStore, setLoadingUser, setChangePasswordLoading } from './actions';

function* sendForgotPassword(action: SendForgotPasswordAction): SagaIterator {
  try {
    yield call(authorizationService.forgotPassword, action.payload);
    toast.success('Password reset link has been sent. Check your inbox.');
  } catch (e) {
    logError(e);
  }
}

function* resetPassword(action: ResetPasswordAction): SagaIterator {
  try {
    yield call(
      authorizationService.resetPassword,
      action.payload.password,
      action.payload.passwordConfirmation,
      action.payload.token,
    );
    toast.success('Password changed successfully');
  } catch (e) {
    logError(e);
  }
}

function* loadUserProfile(): SagaIterator {
  try {
    yield put(setLoadingUser(true));
    const user: IAuthUser = yield call(authorizationService.loadUser);
    yield put(userStore(user));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingUser(false));
  }
}

function* editUserProfile(action: EditUserProfileAction): SagaIterator {
  try {
    yield put(setLoadingUser(true));
    yield call(authorizationService.editUserProfile, action.payload);
    const user: IAuthUser = yield call(authorizationService.loadUser);
    yield put(userStore(user));
    toast.success('Successful profile update');
  } catch (e) {
    if (e instanceof Error) {
      handleRequestError(e);
    }
    logError(e);
  } finally {
    yield put(setLoadingUser(false));
  }
}

function* changeUserPassword(action: ChangePasswordAction): SagaIterator {
  try {
    yield put(setChangePasswordLoading(true));
    yield call(authorizationService.changeUserPassword, action.payload);
    const user: IAuthUser = yield call(authorizationService.loadUser);
    yield put(userStore(user));
    toast.success('Successful password change');
  } catch (e) {
    if (e instanceof Error) {
      handleRequestError(e);
    }
    logError(e);
  } finally {
    yield put(setChangePasswordLoading(false));
  }
}

function* changePhoto(action: ChangePhotoAction): SagaIterator {
  try {
    yield put(setLoadingUser(true));
    yield call(authorizationService.changePhoto, action.payload);
    const user: IAuthUser = yield call(authorizationService.loadUser);
    yield put(userStore(user));
    toast.success('Successful photo change');
  } catch (e) {
    if (e instanceof Error) {
      handleRequestError(e);
    }
    logError(e);
  } finally {
    yield put(setLoadingUser(false));
  }
}

function* authorizationRootSaga() {
  yield all([
    takeLatest(SEND_FORGOT_PASSWORD, sendForgotPassword),
    takeLatest(RESET_PASSWORD, resetPassword),
    takeLatest(EDIT_USER_PROFILE, editUserProfile),
    takeLatest(LOAD_USER_PROFILE, loadUserProfile),
    takeLatest(CHANGE_PASSWORD, changeUserPassword),
    takeLatest(CHANGE_PHOTO, changePhoto),
  ]);
}

export default authorizationRootSaga;
