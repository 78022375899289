import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 800,
      '& .MuiTableCell-root': {
        fontSize: '14px',
      },
    },
    tableItem: {
      padding: '8px',
    },
    blurred: {
      filter: `blur(10px)`,
    },
    tableWrap: {
      boxShadow: 'none',
    },
    tableHeader: {
      padding: '14px 8px',
      fontWeight: 'bold',
    },
    tableRowAvatar: {
      marginRight: '10px',
      width: '30px',
      height: '30px',
    },
    userBlock: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    task: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '20px',
    },
    bugFixing: {
      color: '#EBB9AE',
    },
  }),
);

export default useStyles;
