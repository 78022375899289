import { TextField, InputLabel, FormGroup, FormControl } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from '../styles';
import { ICustomInputProps } from '../types';

export default function CustomInput({
  label,
  id,
  error,
  labelClass,
  wrapper,
  inputClass,
  ...rest
}: ICustomInputProps): JSX.Element {
  const classes = useStyles();

  return (
    <FormGroup>
      <FormControl className={clsx(classes.wrapper, wrapper)} variant="outlined" error={!!error}>
        {label && (
          <InputLabel
            margin="dense"
            htmlFor={id}
            className={clsx(classes.label, labelClass)}
            error={!!error}
          >
            {label}
          </InputLabel>
        )}
        <TextField
          variant="outlined"
          id={id}
          className={clsx(classes.input, inputClass)}
          error={error}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          {...rest}
        />
      </FormControl>
    </FormGroup>
  );
}
