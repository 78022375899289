import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function TaskIcon({ viewBox = '0, 0, 32, 32', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon classes={classes} viewBox={viewBox} {...rest}>
      <path
        d="M25.3333 28L16 21.3333L6.66666 28V6.66667C6.66666 5.95942 6.94761 5.28115 7.4477 4.78105C7.9478 4.28095 8.62608 4 9.33332 4H22.6667C23.3739 4 24.0522 4.28095 24.5523 4.78105C25.0524 5.28115 25.3333 5.95942 25.3333 6.66667V28Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
