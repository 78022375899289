import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function TimeOffIcon({ viewBox = '0, 0, 20, 20', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M15.8333 3.33337H4.16667C3.24619 3.33337 2.5 4.07957 2.5 5.00004V16.6667C2.5 17.5872 3.24619 18.3334 4.16667 18.3334H15.8333C16.7538 18.3334 17.5 17.5872 17.5 16.6667V5.00004C17.5 4.07957 16.7538 3.33337 15.8333 3.33337Z"
        stroke="#93B7FA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3335 1.66663V4.99996"
        stroke="#93B7FA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6665 1.66663V4.99996"
        stroke="#93B7FA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 8.33337H17.5"
        stroke="#93B7FA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_5067:1928)">
        <path
          d="M8.81413 16.1719H8.3592C8.31349 16.1719 8.26851 16.162 8.22838 16.1433C8.18825 16.1245 8.15424 16.0974 8.12947 16.0645C8.1047 16.0316 8.08996 15.9938 8.0866 15.9548C8.08323 15.9157 8.09134 15.8765 8.1102 15.8408L9.21181 13.7571L7.55769 13.7256L6.95441 14.3521C6.8394 14.476 6.74763 14.5312 6.5135 14.5312H6.20725C6.15876 14.5326 6.11061 14.5239 6.06688 14.5059C6.02314 14.4879 5.98512 14.4611 5.95603 14.4278C5.91535 14.3808 5.87536 14.3011 5.91433 14.1875L6.25305 13.1474C6.25561 13.1396 6.25869 13.1319 6.2621 13.1243C6.26227 13.1235 6.26227 13.1228 6.2621 13.1221C6.25858 13.1145 6.25555 13.1067 6.25305 13.0988L5.91398 12.0521C5.87724 11.9407 5.9174 11.8627 5.95773 11.8169C5.98482 11.7861 6.01996 11.7612 6.06032 11.7441C6.10069 11.7271 6.14516 11.7184 6.19016 11.7188H6.5135C6.68833 11.7188 6.85803 11.786 6.95783 11.8945L7.54863 12.5105L9.21181 12.4894L8.11054 10.4093C8.09166 10.3736 8.08351 10.3345 8.08684 10.2954C8.09018 10.2563 8.10488 10.2186 8.12961 10.1857C8.15435 10.1527 8.18832 10.1256 8.22843 10.1068C8.26853 10.088 8.31349 10.0782 8.3592 10.0781H8.81909C8.88325 10.0792 8.94629 10.0927 9.00354 10.1176C9.06079 10.1424 9.11077 10.178 9.14978 10.2217L11.2869 12.4482L12.2741 12.426C12.3464 12.4226 12.5467 12.4214 12.593 12.4214C13.5374 12.4219 14.1014 12.6847 14.1014 13.125C14.1014 13.2636 14.0368 13.5205 13.6046 13.684C13.3494 13.7807 13.009 13.8296 12.5927 13.8296C12.5469 13.8296 12.3471 13.8284 12.2738 13.825L11.2867 13.8025L9.14431 16.0291C9.10527 16.0725 9.05533 16.108 8.99818 16.1327C8.94103 16.1574 8.87813 16.1708 8.81413 16.1719Z"
          fill="#93B7FA"
        />
      </g>
      <defs>
        <clipPath id="clip0_5067:1928">
          <rect width="8.75" height="7.5" fill="white" transform="translate(5.625 9.375)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
