const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again.';
const INVALID_TOKEN = 'Invalid verification token.';
const EMAIL_VERIFIED = 'Email verified!';
const EMAIL_VERIFICATION_FAILED = 'Email verification failed!';
export default {
  somethingWentWrong: SOMETHING_WENT_WRONG,
  invalidToken: INVALID_TOKEN,
  emailVerified: EMAIL_VERIFIED,
  emailVerificationFailed: EMAIL_VERIFICATION_FAILED,
};
