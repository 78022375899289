import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    calendar: {
      boxShadow: 'none',
    },
  }),
);

export default useStyles;
