import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  intermediateWrap: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sortInput: {
    marginRight: 16,
    '& .MuiSelect-outlined': {
      paddingTop: 10.7,
      paddingBottom: 10.7,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '17px',
      '& .MuiSelect-outlined': {
        paddingTop: 15.7,
        paddingBottom: 15.7,
      },
    },
  },
  rolesInput: {
    marginRight: 16,
    '& .MuiSelect-select': {
      paddingTop: 10.7,
      paddingBottom: 10.7,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '17px',
      '& .MuiSelect-select': {
        paddingTop: 15.7,
        paddingBottom: 15.7,
      },
    },
  },
  rolesControl: {
    width: 172,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
