export const AUTHORIZE = 'AUTHORIZATION/AUTHORIZE';
export const LOG_OUT = 'AUTHORIZATION/LOG_OUT';
export const SEND_FORGOT_PASSWORD = 'AUTHORIZATION/SEND_FORGOT_PASSWORD';
export const RESET_PASSWORD = 'AUTHORIZATION/RESET_PASSWORD';
export const EDIT_USER_PROFILE = 'AUTHORIZATION/EDIT_PROFILE';
export const USER_STORE = 'AUTHORIZATION/USER_STORE';
export const SET_LOADING_USER = 'AUTHORIZATION/SET_LOADING_USER';
export const LOAD_USER_PROFILE = 'AUTHORIZATION/LOAD_USER_PROFILE';
export const SET_CHANGE_PASSWORD_LOADING = 'AUTHORIZATION/SET_CHANGE_PASSWORD_LOADING';
export const CHANGE_PASSWORD = 'AUTHORIZATION/CHANGE_PASSWORD';
export const CHANGE_PHOTO = 'AUTHORIZATION/CHANGE_PHOTO';
