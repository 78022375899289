import { clearProjects, loadProjects } from '@/redux';
import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

interface IFetchProjectsOptions {
  limit: number;
  status?: string;
  userId?: string;
}

const useFetchProjects = (searchName: string, options: IFetchProjectsOptions): (() => void) => {
  const dispatch = useDispatch();

  const fetchProjects = useCallback(() => {
    const trimmedName = searchName.trim();
    dispatch(loadProjects({ ...(trimmedName && { name: trimmedName }), ...options }));
  }, [dispatch, searchName, options]);

  useEffect(() => {
    dispatch(clearProjects());
    fetchProjects();
  }, [dispatch, fetchProjects]);

  useEffect(() => {
    return () => {
      dispatch(clearProjects());
    };
  }, [dispatch]);

  return fetchProjects;
};

export default useFetchProjects;
