import { createSelector } from 'reselect';
import { IApplicationState } from '../types';
import { IProjectsState } from './types';

export const projectsStateSelector = (state: IApplicationState): IProjectsState => state.projects;

export const projectsSelector = createSelector(projectsStateSelector, (state) => state.projects);
export const projectsByUserSelector = createSelector(
  projectsStateSelector,
  (state) => state.projectsByUser,
);

export const projectByIdSelector = createSelector(
  projectsStateSelector,
  (_s: IApplicationState, id: string | null) => id,
  (state, id) => (id ? state.projects.find((project) => project.id === id) ?? null : null),
);

export const usersRatesSelector = createSelector(
  projectsStateSelector,
  (state) => state.usersRates,
);

export const userProjectsSelector = createSelector(
  projectsStateSelector,
  (state) => state.userProjects,
);

export const userRatesSelector = createSelector(
  projectsStateSelector,
  (_s: IApplicationState, id: string) => id,
  (state, id) => state.usersRates.find((usersRate) => usersRate.id === id) ?? null,
);
export const projectsLoadingSelector = createSelector(
  projectsStateSelector,
  (state) => state.isLoading,
);
export const projectsPaginationSelector = createSelector(
  projectsStateSelector,
  (state) => state.pagination,
);
