import { createTheme, Theme } from '@material-ui/core';

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#5435B8',
    },
    success: {
      main: '#61D8A9',
      contrastText: '#fff',
    },
    info: {
      main: '#F09438',
    },
    error: {
      main: '#D55F4D',
    },
    warning: {
      main: '#F09438',
    },
    text: {
      primary: '#323232',
      secondary: '#575757',
      disabled: '#AFAFAF',
    },
    background: {
      default: '#fcfcfe',
    },
    grey: {
      50: '#FAFAFA',
      100: '#E8E9E8',
      400: '#AFAFAF',
      800: '#575757',
      900: '#323232',
    },
    basic: {
      pink: '#C86DD7',
      purple: '#884DBD',
      blue: '#93B7FA',
      lilith: '#AFA8DE',
      white: '#EDEBFB',
    },
    progress: {
      primary: '#5435B8',
      secondary: '#AFA8DE',
    },
    task: {
      estimating: '#C2D1F6',
      fixing: '#EBB9AE',
      studying: '#7F759E',
      meeting: '#EBCBAE',
      development: '#CFC6EB',
    },
    white: '#ffffff',
    type: 'light',
  },
  typography: {
    fontSize: 14,
    fontFamily: ['BrandonText', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(', '),
    h1: {
      fontSize: '28px',
      lineHeight: '39px',
      letterSpacing: '0.16px',
    },
    h2: {
      fontSize: '24px',
      lineHeight: '33px',
      letterSpacing: '0.16px',
    },
    h3: {
      fontSize: '21px',
      lineHeight: '29px',
      letterSpacing: '0.16px',
    },
    h4: {
      fontSize: '18px',
      lineHeight: '25px',
      letterSpacing: '0.16px',
    },
    h5: {
      fontSize: '15px',
      lineHeight: '21px',
      letterSpacing: '0.16px',
    },
    body1: {
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '0.16px',
    },
    body2: {
      fontSize: '11px',
      lineHeight: '15px',
      letterSpacing: '0.16px',
    },
    caption: {
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0.16px',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: '#323232',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '8.4px',
      },
    },
    MuiListItem: {
      root: {
        borderRadius: '6px',
        '&$selected': {
          backgroundColor: '#F5F5F5',
        },
      },
    },
    MuiDrawer: {
      paper: {
        borderRight: 'none !important',
        boxShadow: '0 0 15px rgba(0, 0, 0, 0.05)',
      },
    },
    MuiAppBar: {
      root: {
        borderRadius: 'none',
        '&$colorDefault': {
          boxShadow: 'none',
          color: '#575757',
          background:
            'linear-gradient(180deg, rgba(248, 248, 248, 0.95) 44%, rgba(248, 248, 248, 0.46) 73%, rgba(255, 255, 255, 0))',
        },
      },
    },
    MuiToolbar: {
      root: {
        borderRadius: '7px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
      },
    },
    MuiCard: {
      root: {
        boxShadow: '1px 2px 10px rgba(80, 78, 97, 0.2), -2px -2px 15px rgba(80, 78, 97, 0.21)',
        borderRadius: '7px',
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#775DC6',
    },
    success: {
      main: '#61D8A9',
      contrastText: '#fff',
    },
    background: {
      default: '#191a31',
      paper: '#151529',
    },
    info: {
      main: '#F09438',
    },
    error: {
      main: '#D55F4D',
    },
    warning: {
      main: '#F09438',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
      disabled: '#AFAFAF',
    },
    grey: {
      50: '#FAFAFA',
      100: '#E8E9E8',
      400: '#FFFFFF',
      800: '#FFFFFF',
      900: '#FFFFFF',
    },

    basic: {
      pink: '#C86DD7',
      purple: '#884DBD',
      blue: '#93B7FA',
      lilith: '#AFA8DE',
      white: '#EDEBFB',
    },
    progress: {
      primary: '#5435B8',
      secondary: '#EDEBFB',
    },
    task: {
      estimating: '#C2D1F6',
      fixing: '#EBB9AE',
      studying: '#7F759E',
      meeting: '#EBCBAE',
      development: '#CFC6EB',
    },
    white: '#ffffff',

    type: 'dark',
  },
  typography: {
    fontFamily: ['BrandonText', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(', '),
    fontSize: 14,
    h1: {
      fontSize: '28px',
      lineHeight: '39px',
      letterSpacing: '0.16px',
    },
    h2: {
      fontSize: '24px',
      lineHeight: '33px',
      letterSpacing: '0.16px',
    },
    h3: {
      fontSize: '21px',
      lineHeight: '29px',
      letterSpacing: '0.16px',
    },
    h4: {
      fontSize: '18px',
      lineHeight: '25px',
      letterSpacing: '0.16px',
    },
    h5: {
      fontSize: '15px',
      lineHeight: '21px',
      letterSpacing: '0.16px',
    },
    body1: {
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '0.16px',
    },
    body2: {
      fontSize: '11px',
      lineHeight: '15px',
      letterSpacing: '0.16px',
    },
    caption: {
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0.16px',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '8.4px',
      },
    },
    MuiListItem: {
      root: {
        borderRadius: '6px',
      },
    },
    MuiDrawer: {
      paper: {
        borderRight: 'none !important',
        boxShadow: '0 0 15px rgba(0, 0, 0, 0.05)',
      },
    },
    MuiAppBar: {
      root: {
        borderRadius: 'none',
        '&$colorDefault': {
          boxShadow: 'none',
          color: '#fff',
          background: '#191a31',
        },
      },
    },
    MuiToolbar: {
      root: {
        borderRadius: '7px',
        backgroundColor: '#151529',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
      },
    },
    MuiCard: {
      root: {
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
        borderRadius: '7px',
      },
    },
  },
});

const themes: { [index: string]: Theme } = {
  light: lightTheme,
  dark: darkTheme,
};

export default themes;
