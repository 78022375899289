import React, { useMemo, useState } from 'react';
import { Badge, Button, ListItemIcon } from '@material-ui/core';
import { IRoutes } from '@/types/router';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import defaultRoutes from '@/router/routes';
import { userRoleSelector } from '@/redux/authorization/selectors';
import Notification from '@/views/Notification';
import Profile from '@/views/User/Profile';
import AdminProfile from '@/views/Admin/Profile';
import { NotificationsNone } from '@material-ui/icons';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { notificationsCounterSelector } from '@/redux';
import { userRoles } from '@/constants';
import useStyles from './styles';

const MobileMenu: React.FC = () => {
  const classes = useStyles();

  const location = useLocation();
  const userRole = useSelector(userRoleSelector);
  const notificationCount = useSelector(notificationsCounterSelector);
  const buttonRoutes = useMemo(() => {
    return defaultRoutes.filter((route) => route.isSidebar);
  }, []);

  const notification: IRoutes = useMemo(
    () => ({
      path: '/notification',
      name: 'Notifications',
      component: Notification,
      exact: true,
      isUnauthorized: true,
      isPublic: true,
      isSidebar: false,
      icon: NotificationsNone,
      permissions: [],
    }),
    [],
  );
  const profile: IRoutes = useMemo(
    () => ({
      path: '/profile',
      name: 'Profile',
      component: Profile,
      exact: true,
      isUnauthorized: false,
      isPublic: false,
      isSidebar: false,
      icon: PersonOutlineIcon,
      permissions: [userRoles.trainee, userRoles.user],
    }),
    [],
  );
  const adminProfile: IRoutes = useMemo(
    () => ({
      path: '/profile',
      name: 'Profile',
      component: AdminProfile,
      exact: true,
      isUnauthorized: false,
      isPublic: false,
      isSidebar: false,
      icon: PersonOutlineIcon,
      permissions: [userRoles.admin],
    }),
    [],
  );
  const [selectTab, setSelectTab] = useState(
    buttonRoutes.find(
      (el) => userRole && el.permissions.includes(userRole) && el.path === location.pathname,
    )?.path,
  );
  const { routes, pathNames, widthIndicator } = useMemo(() => {
    return {
      routes: buttonRoutes.filter((r) => userRole && r.permissions.includes(userRole)),
      pathNames: buttonRoutes
        .filter((r) => userRole && r.permissions.includes(userRole))
        .map((el) => el.path),
      widthIndicator: 100,
    };
  }, [buttonRoutes, userRole]);
  const navRouters = useMemo(() => {
    if (userRole === userRoles.admin) {
      routes.splice(1, 0, notification);
      pathNames.splice(1, 0, notification.path);
      routes.splice(5, 0, adminProfile);
      pathNames.splice(5, 0, adminProfile.path);
    } else {
      routes.splice(3, 0, notification, profile);
      pathNames.splice(3, 0, notification.path, profile.path);
    }
    return routes;
  }, [userRole, routes, notification, pathNames, adminProfile, profile]);
  return (
    <div className={classes.btnWrap}>
      <div
        className={classes.indicator}
        style={{
          width: `${widthIndicator / pathNames.length}%`,
          left: `${(widthIndicator / pathNames.length) * pathNames.indexOf(selectTab ?? '')}%`,
        }}
      >
        <div className={classes.line} />
      </div>
      {navRouters.map(({ name, path, icon: Icon }) => (
        <Link
          className={classes.link}
          style={{
            width: `${widthIndicator / pathNames.length}%`,
          }}
          key={path}
          to={path}
        >
          <Button
            disableRipple
            color={selectTab === path ? 'primary' : 'default'}
            className={classes.btn}
            onClick={() => setSelectTab(path)}
          >
            {name === 'Notifications' ? (
              <Badge badgeContent={notificationCount.count} color="primary">
                <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
              </Badge>
            ) : (
              <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
            )}
            {name}
          </Button>
        </Link>
      ))}
    </div>
  );
};

export default MobileMenu;
