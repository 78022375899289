import { Card, CardContent } from '@material-ui/core';
import { useEffect, useMemo } from 'react';
import { ResultTotal } from '@/components/Result';
import { ReportTable } from '../Tables';
import useStyles from './styles';
import { IUserReportTableProps } from './types';

export default function UserReportTable({
  reports,
  fetchResults,
}: IUserReportTableProps): JSX.Element {
  const classes = useStyles();

  const allReports = useMemo(() => Object.values(reports?.reports).flat(), [reports]);

  useEffect(() => {
    fetchResults();
  }, [fetchResults]);

  return (
    <Card className={classes.card}>
      <CardContent>
        <ReportTable reports={allReports} />
        <ResultTotal
          hours={reports?.totalHours ?? '0:0'}
          remoteHours={reports?.totalRemoteHours ?? '0:0'}
          salary={reports?.totalSalary ?? '0.00'}
        />
      </CardContent>
    </Card>
  );
}
