import { LoadResultReportsActionProps } from '@/interfaces';
import {
  LoadReportsAction,
  LOAD_REPORTS,
  StoreReportsAction,
  STORE_REPORTS,
  SetLoadingReportsAction,
  SET_LOADING_REPORTS,
  SingleReportActionProps,
  CREATE_REPORT,
  CreateReportAction,
  IReportStore,
  SET_REPORT_STORE,
  SetReportStoreAction,
  EDIT_REPORT,
  EditReportAction,
  LoadReportsByDateAction,
  LOAD_REPORTS_BY_DATE,
  ISetReport,
  DELETE_REPORT,
  DeleteReportAction,
  DeleteReportActionProps,
} from '@/redux/reports';

export function loadReports(): LoadReportsAction {
  return {
    type: LOAD_REPORTS,
  };
}

export function deleteReport(payload: DeleteReportActionProps): DeleteReportAction {
  return {
    type: DELETE_REPORT,
    payload,
  };
}

export function loadReportsByDate(data: LoadResultReportsActionProps): LoadReportsByDateAction {
  return {
    type: LOAD_REPORTS_BY_DATE,
    payload: data,
  };
}

export function createReport(payload: SingleReportActionProps): CreateReportAction {
  return {
    type: CREATE_REPORT,
    payload,
  };
}

export function editReport(payload: SingleReportActionProps): EditReportAction {
  return {
    type: EDIT_REPORT,
    payload,
  };
}

export function storeReports(reports: IReportStore): StoreReportsAction {
  return {
    type: STORE_REPORTS,
    payload: reports,
  };
}

export function setReportStore(report: ISetReport): SetReportStoreAction {
  return {
    type: SET_REPORT_STORE,
    payload: report,
  };
}

export function setLoadingReports(isLoading: boolean): SetLoadingReportsAction {
  return {
    type: SET_LOADING_REPORTS,
    payload: isLoading,
  };
}
