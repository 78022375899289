import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    marginLeft: 0,
    marginTop: theme.spacing(3),
    padding: '10px',
    width: '100%',
  },
}));

export default useStyles;
