import {
  SET_LOADING_RESULTS,
  LOAD_RESULT_REPORTS,
  STORE_RESULT_REPORTS,
  CLEAR_RESULT_REPORTS,
  LOAD_RESULTS,
  STORE_RESULTS,
  CLEAR_RESULTS,
  LoadResultReportsAction,
  StoreResultReportsAction,
  SetLoadingResultsAction,
  ClearResultReportsAction,
  StoreResultsAction,
  LoadResultsAction,
  ClearResultsAction,
  STORE_OVERALL_SALARY,
  LOAD_OVERALL_SALARY,
  LoadOverallSalaryAction,
  StoreOverallSalaryAction,
  STORE_OVERALL_MINUTES,
  StoreOverallMinutesAction,
  LOAD_OVERALL_MINUTES,
  LoadOverallMinutesAction,
  LOAD_USER_OVERALL_SALARY,
  LoadUserOverallSalaryAction,
  LOAD_USER_OVERALL_MINUTES,
  LoadUserOverallMinutesAction,
  STORE_EARNING_CARD_DATA,
  StoreEarningCardDataAction,
  LOAD_EARNING_CARD,
  LoadEarningCardActionProps,
  LoadEarningCardAction,
  LOAD_USER_OVERALL_STATISTIC,
  LoadUserOverallStatisticAction,
  STORE_STATISTIC,
  StoreStatisticAction,
} from '@/redux/results';
import {
  LoadResultReportsActionProps,
  LoadResultsActionProps,
  IPaginatedReports,
  IPaginatedResults,
  IOverallMinutes,
  IOverallSalary,
  IUserDateResult,
  IOverallStatistic,
} from '@/interfaces';

export function setLoadingResults(isLoading: boolean): SetLoadingResultsAction {
  return {
    type: SET_LOADING_RESULTS,
    payload: isLoading,
  };
}

export function loadResultReports(payload: LoadResultReportsActionProps): LoadResultReportsAction {
  return {
    type: LOAD_RESULT_REPORTS,
    payload,
  };
}

export function storeResultReports(payload: IPaginatedReports): StoreResultReportsAction {
  return {
    type: STORE_RESULT_REPORTS,
    payload,
  };
}

export function clearResultReports(): ClearResultReportsAction {
  return {
    type: CLEAR_RESULT_REPORTS,
  };
}

export function loadResults(payload: LoadResultsActionProps): LoadResultsAction {
  return {
    type: LOAD_RESULTS,
    payload,
  };
}

export function storeResults(result: IPaginatedResults): StoreResultsAction {
  return {
    type: STORE_RESULTS,
    payload: result,
  };
}

export function storeStatistic(result: IOverallStatistic): StoreStatisticAction {
  return {
    type: STORE_STATISTIC,
    payload: result,
  };
}

export function clearResults(): ClearResultsAction {
  return {
    type: CLEAR_RESULTS,
  };
}
export function storeOverallSalary(salary: IOverallSalary): StoreOverallSalaryAction {
  return {
    type: STORE_OVERALL_SALARY,
    payload: salary,
  };
}

export function storeOverallMinutes(workedMinutes: IOverallMinutes): StoreOverallMinutesAction {
  return {
    type: STORE_OVERALL_MINUTES,
    payload: workedMinutes,
  };
}

export function storeEarningCardData(payload: {
  overallSalary: IOverallSalary;
  overallMinutes: IOverallMinutes;
}): StoreEarningCardDataAction {
  return {
    type: STORE_EARNING_CARD_DATA,
    payload,
  };
}

export function loadEarningCardData(payload: LoadEarningCardActionProps): LoadEarningCardAction {
  return {
    type: LOAD_EARNING_CARD,
    payload,
  };
}

export function loadOverallSalary(data: IUserDateResult): LoadOverallSalaryAction {
  return {
    type: LOAD_OVERALL_SALARY,
    payload: data,
  };
}

export function loadUserOverallSalary(data: IUserDateResult): LoadUserOverallSalaryAction {
  return {
    type: LOAD_USER_OVERALL_SALARY,
    payload: data,
  };
}

export function loadUserOverallStatistic(data: IUserDateResult): LoadUserOverallStatisticAction {
  return {
    type: LOAD_USER_OVERALL_STATISTIC,
    payload: data,
  };
}

export function loadOverallMinutes(data: IUserDateResult): LoadOverallMinutesAction {
  return {
    type: LOAD_OVERALL_MINUTES,
    payload: data,
  };
}

export function loadUserOverallMinutes(data: IUserDateResult): LoadUserOverallMinutesAction {
  return {
    type: LOAD_USER_OVERALL_MINUTES,
    payload: data,
  };
}
