import { InputLabel, FormGroup, FormControl } from '@material-ui/core';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { enGBLocale } from '@/constants';
import useStyles from '../styles';

export default function DatePicker({
  label,
  id,
  error,
  ...rest
}: KeyboardDatePickerProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.datePicker}>
      <FormGroup>
        <FormControl className={classes.wrapper} variant="outlined">
          {label && (
            <InputLabel error={!!error} margin="dense" htmlFor={id} className={clsx(classes.label)}>
              {label}
            </InputLabel>
          )}
          <MuiPickersUtilsProvider locale={enGBLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              error={!!error}
              {...rest}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </FormGroup>
    </div>
  );
}
