import { string, object, number } from 'yup';

const reportSchema = object().shape({
  project: string().required('The field Project is required'),
  taskType: string().required('The field Task is required'),
  workerdHours: number().when('workedMinutes', {
    is: 0,
    then: number().required('The field Time is required').min(1, 'The field Time is required'),
  }),
  workedMinutes: number(),
  description: string().required('The field Description is required'),
  workMode: string().required('The field WorkMode is required'),
});

export default reportSchema;
