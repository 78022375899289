import auth from '@DevimaSolutions/o-auth';
import { ITaxData } from '@/components/Tables/TaxesTable/types';
import { AxiosResponse } from 'axios';

export interface ITaxEventQuery {
  userId?: string;
  from?: Date | string;
  to?: Date | string;
}

export interface ICreateTaxEventRequest {
  targetDate: Date;
  amountUah: number;
  user: string;
}

const getTaxEvents = async (params?: ITaxEventQuery) => {
  const response = await auth().axios.get<ITaxData[]>('/tax-events', { params });
  return response.data ?? [];
};

const createTaxEvent = async (data: ICreateTaxEventRequest) => {
  const response = await auth().axios.post<ITaxData>('/tax-events', data);
  return response.data;
};

const editTaxEvent = async (taxEventId: string, data: ICreateTaxEventRequest) => {
  const response = await auth().axios.put<ITaxData>(`/tax-events/${taxEventId}`, data);

  return response.data;
};

const getUpcomingTaxEventByUser = async (userId?: string) => {
  const response = await auth().axios.get<ITaxData | null>('/tax-events/upcoming', {
    params: { userId },
  });

  return response.data;
};

const deleteTaxEvent = async (taxEventId: string): Promise<string> => {
  const response = await auth()
    .axios.delete(`/tax-events/${taxEventId}`)
    .then((data: AxiosResponse<string>) => data);
  return response.data ?? {};
};

export default {
  getTaxEvents,
  createTaxEvent,
  editTaxEvent,
  getUpcomingTaxEventByUser,
  deleteTaxEvent,
};
