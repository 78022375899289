import { FormikHelpers, useFormik } from 'formik';
import { ICreateEvent, IEvent, projectsLoadingSelector } from '@/redux';
import { eventSchema } from '@/validations';
import { useSelector } from 'react-redux';

const useCreateEvent = (
  onSubmitHandler: (data: ICreateEvent, resetForm: FormikHelpers<ICreateEvent>) => void,
  event: IEvent | null,
) => {
  const projectsLoading = useSelector(projectsLoadingSelector);

  const { handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue } =
    useFormik<ICreateEvent>({
      validationSchema: eventSchema,
      onSubmit: onSubmitHandler,
      initialValues: {
        name: event?.eventName ?? '',
        date: event?.date ?? new Date().toString(),
        description: event?.description ?? '',
      },
    });

  const handleDateChange = (date: Date | null) => {
    setFieldValue('date', date);
  };

  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    handleDateChange,
    projectsLoading,
  };
};

export default useCreateEvent;
