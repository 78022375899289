import FullScreenDialog from '@/components/FullScreenDialog';
import SetProjectForUserForm from '@/components/Forms/SetProjectForUserForm';
import { IAttachProjectDialogProps } from './types';

const AttachProjectDialog = ({
  user,
  handleDialogClose,
  openDialog,
  searchName,
  setSearchName,
  ...props
}: IAttachProjectDialogProps): JSX.Element => {
  return (
    <FullScreenDialog open={openDialog} handleDialogClose={handleDialogClose}>
      <div {...props}>
        <SetProjectForUserForm
          user={user}
          searchName={searchName}
          setSearchName={setSearchName}
          handleClose={handleDialogClose}
        />
      </div>
    </FullScreenDialog>
  );
};

export default AttachProjectDialog;
