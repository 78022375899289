import { Card, CardContent } from '@material-ui/core';
import Calendar from '@/components/Calendar';
import ReportContent from '@/components/ReportContent';
import { CalendarContentType } from '@/types/calendar';
import { UsersResultAccordion } from '@/components/Accordions';
import useReportsCalendar from './useReportsCalendar';
import useStyles from './styles';
import { IReportsCalendarProps } from './types';
import ReportModal from '../Modals/ReportModal';
import { ReportDialog } from '../MobileDialogs';

const ReportsCalendar: React.FC<IReportsCalendarProps> = ({
  className,
  reports,
  user,
  ...props
}) => {
  const classes = useStyles();

  const {
    isOpenReportModal,
    onCellClick,
    setDateSelected,
    closeReportModal,
    setReport,
    onSubmitHandler,
    openReportModal,
    dayReports,
    reportForModal,
    dialogHandler,
    isOpenReportDialog,
    handleDialogClose,
    report,
    isDesktop,
    fetchReports,
  } = useReportsCalendar(reports);

  return (
    <>
      <Card className={className} {...props}>
        <CardContent>
          <Calendar
            CellContentComponent={ReportContent}
            setEventDate={setDateSelected}
            records={reports.reports}
            className={classes.calendar}
            contentType={CalendarContentType.Reports}
            onCellClick={onCellClick}
            isOpenReportModal={isOpenReportModal}
            openReportModal={openReportModal}
          />
        </CardContent>
      </Card>
      {!isDesktop && (
        <UsersResultAccordion
          userResult={reportForModal}
          setReport={dialogHandler}
          isUser
          dateFilteringEnabled
        />
      )}

      <ReportDialog
        user={user}
        openDialog={isOpenReportDialog}
        handleDialogClose={handleDialogClose}
        onSubmitHandler={onSubmitHandler}
        report={report}
        onSuccessDelete={fetchReports}
      />
      <ReportModal
        user={user}
        open={isOpenReportModal}
        closeModalHandler={closeReportModal}
        reports={dayReports}
        setReport={setReport}
        onSubmitHandler={onSubmitHandler}
        onSuccessDelete={fetchReports}
      />
    </>
  );
};

export default ReportsCalendar;
