import { Card, CardContent, Typography } from '@material-ui/core';
import useStyles from './style';
import { IInfoCardProps } from './types';

export default function InfoCard({ data }: IInfoCardProps): JSX.Element {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.contentCard}>
        {Object.entries(data).map(([key, desc]) => (
          <div key={key} className={classes.infoRow}>
            <Typography className={classes.title} variant="body1" component="p">
              {key}
            </Typography>
            <Typography className={classes.description} variant="body1" component="p">
              {desc}
            </Typography>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}
