import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      boxShadow: '3px 0px 15px rgba(78, 81, 97, 0.14)',
      width: '100%',
    },
  }),
);

export default useStyles;
