import { Periods } from '@/types/periods';
import dateUtils from '@/utils/dates';

export interface IPeriodResponse {
  key: string;
  current: number;
  prev?: number;
  isVacation?: boolean;
}

const periodMap = {
  [Periods.Week]: (matches: boolean) => dateUtils.getWeekDays(matches),
  [Periods.Month]: (_: boolean, month?: number) =>
    dateUtils.daysInMouths(month ? new Date(new Date().setMonth(month)) : undefined),
  [Periods.Year]: () => dateUtils.getMonths(true),
};

const getDateByPeriod = (
  period: Periods,
  matches: boolean,
  data: {
    date: string | undefined;
    salary?: string;
    prevSalary?: string;
    workedHours?: string;
    isVacation?: boolean;
  }[],
  month?: number,
): IPeriodResponse[] => {
  const date = periodMap[period](matches, month);

  return date.map((el) => ({
    key: el,
    current: parseFloat(data?.find((value) => el === value?.date)?.salary ?? '0'),
    prev: parseFloat(data?.find((value) => el === value?.date)?.prevSalary ?? '0'),
    workedHours: parseFloat(data?.find((value) => el === value?.date)?.workedHours ?? '0'),
    isVacation: Boolean(data?.find((value) => el === value?.date)?.isVacation ?? false),
  }));
};

export default {
  getDateByPeriod,
};
