import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editUserProfile, loadUserProfile, userLoadingSelector } from '@/redux';
import { IUserProfile } from '@/interfaces';
import { useFormik } from 'formik';
import { userProfileSchema } from '@/validations';
import { Theme, useMediaQuery } from '@material-ui/core';
import { format } from 'date-fns';

const useProfileForm = (user: IUserProfile) => {
  const dispatch = useDispatch();

  const onSubmitHandler = (values: IUserProfile) => {
    dispatch(
      editUserProfile({
        ...values,
        ...(values.dateOfBirth && {
          dateOfBirth: format(new Date(values.dateOfBirth), 'yyyy-MM-dd'),
        }),
      }),
    );
    dispatch(loadUserProfile());
  };
  const { handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue } =
    useFormik<IUserProfile>({
      validationSchema: userProfileSchema,
      onSubmit: onSubmitHandler,
      initialValues: user,
      enableReinitialize: true,
    });
  const selectedDate = useMemo(
    () => (values.dateOfBirth ? new Date(values.dateOfBirth) : null),
    [values.dateOfBirth],
  );

  const userLoading = useSelector(userLoadingSelector);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleDateChange = (date: Date | null) => {
    setFieldValue('dateOfBirth', date);
  };

  return {
    handleDateChange,
    selectedDate,
    handleSubmit,
    handleChange,
    handleBlur,
    isDesktop,
    values,
    touched,
    errors,
    setFieldValue,
    userLoading,
  };
};

export default useProfileForm;
