import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.type === 'light' ? 'rgba(84, 53, 184, 0.3)' : '#322D59',
      height: 80,
      borderRadius: '7px',
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '21px',
      paddingRight: '13px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '20px',
      '& .MuiTypography-body1': {
        fontWeight: 500,
      },
    },
    avatar: {
      width: '50px',
      height: '50px',
      marginRight: '8px',
    },
    divider: {
      margin: '0 30px',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '1px',
    },
    userBlock: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

export default useStyles;
