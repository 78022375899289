import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionBlock: {
      width: '100%',
    },
    title: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(5.5),
    },
    accordionStyle: {
      background:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.background.paper,
      padding: '0px 20px',

      '&.MuiAccordion-root': {
        '&:before': {
          backgroundColor: 'unset',
        },
      },
      '&.MuiAccordion-root.Mui-expanded': {
        margin: 0,
      },
      '&.MuiPaper-elevation1': {
        boxShadow: 'unset',
      },
      '& .MuiAccordionSummary-content': {
        justifyContent: 'space-between',
        margin: 0,
      },
      '& .MuiAccordionSummary-expandIcon': {
        marginLeft: '-10px',
      },
      '& .MuiAccordionSummary-root': {
        padding: 0,
        flexDirection: 'row-reverse',
      },
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: ' rotate(90deg) ',
      },
    },
    divider: {
      margin: '0px 20px',
      backgroundColor: theme.palette.grey[100],
      height: '1px',
    },
    dividerBlock: {
      background: theme.palette.grey[50],
    },
    arrow: {
      transform: ' rotate(180deg) ',
    },
    avatar: {
      marginRight: '10px',
      width: '24px',
      height: '24px',
    },
    name: {
      fontWeight: 700,
      fontSize: '15px',
      lineHeight: '21px',
      color: theme.palette.text.primary,
    },
    userInfoRow: {
      display: 'flex',
    },
    userInfo: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '5px',
      marginBottom: '7px',
      minWidth: '0px',
      textTransform: 'none',
    },
    userBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    infoText: {
      fontWeight: 300,
      fontSize: '13px',
      lineHeight: '18px',
      color: theme.palette.text.primary,
      textTransform: 'lowercase',
    },
    infoTitle: {
      marginRight: '20px',
      fontWeight: 700,
      fontSize: '13px',
      lineHeight: '18px',
      color: theme.palette.text.primary,
      marginBottom: '7px',
    },
    editIcon: {
      '&.MuiIconButton-root': {
        padding: 0,
      },
    },
    button: {
      background: theme.palette.success.main,
      borderRadius: 6,
      border: 0,
      color: 'white',
      height: 39,
      padding: '0 42px',
      boxShadow: 'none',
      marginTop: theme.spacing(1),

      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    projectBlock: {
      display: 'flex',
      flexDirection: 'column',
      margin: '23px 0px 23px 36px',
      width: '100%',
    },
    date: {
      fontSize: '13px',
      lineHeight: '18px',
      color: theme.palette.text.primary,
      opacity: 0.6,
    },
    infoBlock: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    statusBlock: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    userName: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    status: {
      display: 'flex',
      justifyContent: 'center',
      padding: '4px 8px',
      borderRadius: '21px',
    },
    pending: {
      color: '#F09438',
      background: 'rgba(240, 148, 56, 0.1)',
    },
    approved: {
      color: '#61D8A9',
      background: 'rgba(97, 216, 169, 0.1)',
    },
    rejected: {
      color: '#D55F4D',
      background: 'rgba(216, 80, 60, 0.1)',
    },
    vacationActions: {
      display: 'flex',
      gap: 16,
      '& > button': { fontSize: '11px', lineHeight: '15px' },
    },
  }),
);

export default useStyles;
