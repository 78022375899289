import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Avatar,
  TableRow,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DateFormatUtil, formatMinutesToHoursPeriod } from '@/utils';
import clsx from 'clsx';
import { ResultTotal } from '@/components/Result';
import { useSelector } from 'react-redux';
import { blurSelector, paginatedReportsSelector, resultsPaginationSelector } from '@/redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IProjectReportsTableProps } from './types';
import useStyles from '../styles';

const tableHead = [
  { name: 'User', align: 'left', numeric: true, id: 'user' },
  { name: 'Date', align: 'left', numeric: false, id: 'createdAt' },
  { name: 'Time', align: 'left', numeric: false, id: 'time' },
  { name: 'Rate', align: 'left', numeric: false, id: 'rate' },
  { name: 'Salary', align: 'left', numeric: false, id: 'salary' },
  { name: 'Task', align: 'left', numeric: false, id: 'task' },
  { name: 'Description', align: 'left', numeric: false, id: 'description' },
] as const;

export default function ProjectReportsTable({
  fetchData,
  ...props
}: IProjectReportsTableProps): JSX.Element {
  const classes = useStyles();

  const showBlur = useSelector(blurSelector);

  const projectResult = useSelector(paginatedReportsSelector);

  const pagination = useSelector(resultsPaginationSelector);

  return (
    <div {...props}>
      <Card className={classes.card}>
        <CardContent>
          <InfiniteScroll
            dataLength={projectResult?.reports?.length ?? 0}
            loader={null}
            hasMore={pagination.hasNext}
            next={fetchData}
          >
            <TableContainer component={Paper} className={classes.tableWrap}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {tableHead.map((el) => (
                      <TableCell key={el.id} align={el.align} className={classes.tableHeader}>
                        {el.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectResult &&
                    projectResult.reports.map((report) => (
                      <TableRow key={report.id}>
                        <TableCell scope="row" className={classes.tableItem}>
                          <Tooltip
                            key={report.id}
                            className={classes.memberTooltip}
                            classes={{ tooltip: classes.memberTooltip }}
                            title={`${report?.fullName}`}
                          >
                            <Avatar
                              alt={report.fullName}
                              src={report.photo ?? '/src/assets/svg/logo.svg'}
                              className={classes.tableRowAvatar}
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableItem}>
                          {DateFormatUtil.formatDate(report.workedDay)}
                        </TableCell>
                        <TableCell className={classes.tableItem}>
                          {formatMinutesToHoursPeriod(report.workedMinutes)}
                        </TableCell>
                        <TableCell
                          className={clsx(classes.tableItem, { [classes.blurred]: showBlur })}
                        >
                          {Number(report.rate).toFixed(2)}
                        </TableCell>
                        <TableCell
                          className={clsx(classes.tableItem, { [classes.blurred]: showBlur })}
                        >
                          {Number(report.salary).toFixed(2)}
                        </TableCell>
                        <TableCell className={classes.tableItem}>
                          <Typography className={clsx(classes.task, classes.bugFixing)}>
                            {report.taskType}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.tableItem}>{report.description}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
          <ResultTotal
            hours={projectResult?.totalHours ?? '0:0'}
            salary={projectResult?.totalSalary ?? '0.00'}
            horizontal
          />
        </CardContent>
      </Card>
    </div>
  );
}
