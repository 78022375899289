import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadPendingVacations, pendingVacationsSelector } from '@/redux';

const useTimeOffsAdminHeader = () => {
  const dispatch = useDispatch();

  const pendingVacations = useSelector(pendingVacationsSelector);

  useEffect(() => {
    dispatch(loadPendingVacations());
  }, [dispatch]);

  return {
    pendingVacations,
  };
};

export default useTimeOffsAdminHeader;
