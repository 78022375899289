import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 'calc(100vh - 83px)',
      padding: '30px 0',
    },
    logOut: {
      marginTop: '25px',
      textTransform: 'none',
      background: theme.palette.error.main,
      width: '172px',
      height: '39px',
      color: theme.palette.common.white,
    },
    toggle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiSwitch-colorSecondary.Mui-checked': {
        color: theme.palette.primary.main,
      },
    },
    opacityText: {
      opacity: 0.5,
    },
  }),
);

export default useStyles;
