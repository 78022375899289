import { useEffect } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import UserPopper from '@/components/UserPopper';
import { notificationsCounterSelector, newNotificationsCount } from '@/redux';
import { useDispatch, useSelector } from 'react-redux';
import DashboardVisibilityButton from '@/components/Buttons/DashboardVisibilityButton';
import useNavbar from './useNavbar';
import useStyles from './styles';
import NotificationPopper from '../NotificationPopper';

const Navbar: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { sidebar, theme, toggleSidebar, toggleTheme, user } = useNavbar();
  useEffect(() => {
    dispatch(newNotificationsCount());
  }, [dispatch]);

  const notificationCount = useSelector(notificationsCounterSelector);
  return (
    <AppBar
      color="default"
      position="fixed"
      className={clsx(classes.appBar, { [classes.appBarShift]: sidebar })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open main navigation"
          edge="start"
          className={clsx(classes.menuButton)}
          onClick={toggleSidebar}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.nav}>
          <NotificationPopper notificationsCount={notificationCount.count} />
          <Tooltip title="Toggle light/dark theme">
            <IconButton aria-label="Toggle light/dark theme" color="inherit" onClick={toggleTheme}>
              {theme === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
            </IconButton>
          </Tooltip>
          <DashboardVisibilityButton />
          <UserPopper user={user} />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
