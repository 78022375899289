import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { Fragment } from 'react';
import useTimeOffsAdminHeader from './useTimeOffsAdminHeader';
import useStyles from './styles';
import { ITimeOffsAdminHeader } from './types';

export default function TimeOffsAdminHeader({
  onHandleVacation,
}: ITimeOffsAdminHeader): JSX.Element {
  const classes = useStyles();

  const { pendingVacations } = useTimeOffsAdminHeader();

  return (
    <div className={classes.pendingVacations}>
      {pendingVacations.map((pendingVacation, index) => (
        <div className={classes.pendingVacation} key={index}>
          <Typography className={classes.itemText}>
            {`${pendingVacation.fullName} requests ${
              pendingVacation.paymentType === 'Paid' ? 'paid' : 'self paid'
            } vacation from ${format(
              parseISO(pendingVacation.startDate),
              "MMMM 'the' do",
            )} to ${format(parseISO(pendingVacation.endDate), "MMMM 'the' do")}.`}
            <br />
            Comment:{pendingVacation.comment.includes('\n') ? <br /> : ' '}
            {pendingVacation.comment.split('\n').map((line, idx) => (
              <Fragment key={idx}>
                {idx !== 0 && <br />}
                {line}
              </Fragment>
            ))}
          </Typography>
          <Box sx={{ flexShrink: 0 }}>
            <Button
              onClick={() => onHandleVacation?.(pendingVacation.id, 'reject')}
              className={clsx(classes.button, classes.reject)}
              variant="outlined"
            >
              Reject
            </Button>
            <Button
              onClick={() => onHandleVacation?.(pendingVacation.id, 'approve')}
              className={clsx(classes.button, classes.approve)}
              variant="outlined"
            >
              Approve
            </Button>
          </Box>
        </div>
      ))}
    </div>
  );
}
