import React, { useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { PublicLayout, PrivateLayout } from '@/components/Layouts';
import { useSelector } from 'react-redux';
import { userRoleSelector } from '@/redux/authorization/selectors';
import defaultRoutes from './routes';

const unauthorizedRoutes = defaultRoutes.filter((r) => r.isUnauthorized);

const AppRouter: React.FC = (): JSX.Element => {
  const userRole = useSelector(userRoleSelector);
  const routes = useMemo(() => {
    if (!userRole) {
      return unauthorizedRoutes;
    }

    return defaultRoutes.filter(
      (r) => !r.isUnauthorized && r.permissions.some((p) => p === userRole),
    );
  }, [userRole]);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path={routes.filter((route) => route.isPublic).map((pathName) => pathName.path)}
          render={() => (
            <PublicLayout>
              <Switch>
                {routes
                  .filter((route) => route.isPublic)
                  .map(({ name, isPublic, isSidebar, icon, component: Component, ...rest }) => (
                    <Route key={name} {...rest} render={(props) => <Component {...props} />} />
                  ))}
              </Switch>
            </PublicLayout>
          )}
        />

        <Route
          path={routes.filter((route) => !route.isPublic).map((pathName) => pathName.path)}
          render={() => (
            <PrivateLayout>
              <Switch>
                {routes
                  .filter((route) => !route.isPublic)
                  .map(({ name, isPublic, isSidebar, icon, component: Component, ...rest }) => (
                    <Route key={name} {...rest} render={(props) => <Component {...props} />} />
                  ))}
              </Switch>
            </PrivateLayout>
          )}
        />

        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
};

export default AppRouter;
