import { BarChart, Bar, XAxis, YAxis, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import ChartTooltip from './ChartTooltip';
import { IChartProps } from '../types';
import useStyles from './chart-style';

export default function ChartComponent({
  period,
  isDesktop,
  chartHeight,
  overallHours,
  ...props
}: IChartProps): JSX.Element {
  const classes = useStyles({ chartHeight });
  return (
    <ResponsiveContainer className={classes.root} {...props}>
      <BarChart
        data={overallHours ?? []}
        margin={{
          top: 15,
          right: isDesktop ? 20 : 6,
          left: -15,
          bottom: 25,
        }}
      >
        <XAxis
          fontFamily="Cuprum"
          dataKey="key"
          interval={period === 'Month' && !isDesktop ? 1 : 0}
          tickMargin={10}
        />
        <YAxis
          fontFamily="Cuprum"
          allowDataOverflow
          tickMargin={10}
          textAnchor="end"
          allowDecimals={false}
          tickCount={7}
          interval={0}
          tickFormatter={(value) => `${value}h`}
        />
        <Tooltip content={<ChartTooltip />} />
        <Bar dataKey="workedHours" fill="#CFC6EB">
          {(overallHours ?? []).map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.isVacation ? 'rgb(84, 53, 184)' : '#CFC6EB'} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
