import useCircularProgress from '@/components/CircularProgress/useCircularProgress';
import { useTheme } from '@material-ui/core';
import { CircularProgressProps } from './types';

import useStyles from './styles';

const CircularProgress = ({
  value,
  size = 44,
  thickness = 5,
  linecap = 'round',
  color = '#5435B8',
}: CircularProgressProps) => {
  const theme = useTheme();
  const styles = useStyles();

  const { radius, offset, circumference, SIZE } = useCircularProgress({
    value,
    thickness,
    linecap,
    color,
  });
  return (
    <span style={{ width: size, height: size }}>
      <svg viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}>
        <circle
          strokeWidth={thickness}
          fill="transparent"
          r={radius}
          cx={SIZE}
          cy={SIZE}
          stroke={theme.palette.progress.secondary}
          strokeOpacity={0.3}
        />
        <circle
          fill="transparent"
          strokeWidth={thickness}
          r={radius}
          cx={SIZE}
          cy={SIZE}
          stroke={color}
          strokeLinecap="round"
          strokeDasharray={circumference.toFixed(3)}
          strokeDashoffset={offset}
          style={{ transform: 'rotate(-90deg)', transformOrigin: '100% 100%' }}
        />
        <text
          x={SIZE}
          y={SIZE + 1}
          className={styles.title}
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {value}%
        </text>
      </svg>
    </span>
  );
};

export default CircularProgress;
