import { FormGroup, Grid, Button } from '@material-ui/core';
import { CustomInput, DatePicker, PhoneInputField } from '@/components/FormItems';
import useStyles from './styles';
import { IProfileFormProps } from './types';
import useProfileForm from './useProfileForm';

const ProfileForm = ({ user, ...props }: IProfileFormProps): JSX.Element => {
  const classes = useStyles();

  const {
    handleDateChange,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isDesktop,
    setFieldValue,
    userLoading,
  } = useProfileForm(user);
  return (
    <div {...props}>
      <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={isDesktop ? 2 : 3}>
          <Grid container item spacing={3} xs={12}>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <CustomInput
                  id="firstName"
                  label="First Name*"
                  name="firstName"
                  placeholder="John"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.firstName || ''}
                  error={touched?.firstName && !!errors?.firstName}
                  helperText={touched?.firstName && errors?.firstName}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <CustomInput
                  id="lastName"
                  label="Last Name*"
                  name="lastName"
                  placeholder="Krasavchik"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.lastName || ''}
                  error={touched?.lastName && !!errors?.lastName}
                  helperText={touched?.lastName && errors?.lastName}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container item spacing={3} xs={12}>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <CustomInput
                  id="email"
                  label="Email*"
                  name="email"
                  placeholder="Johnkrasava@gmail.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.email || ''}
                  error={touched?.email && !!errors?.email}
                  helperText={touched?.email && errors?.email}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormGroup>
                <PhoneInputField
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Phone Number*"
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  value={values?.phoneNumber || ''}
                  error={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : undefined}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container item spacing={3} xs={12}>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <DatePicker
                  disableFuture
                  variant="inline"
                  format="dd.MM.yyyy"
                  margin="normal"
                  id="dateOfBirth"
                  label="Date of Birth*"
                  name="dateOfBirth"
                  onChange={handleDateChange}
                  onBlur={handleBlur}
                  value={values.dateOfBirth}
                  helperText={touched.dateOfBirth && errors?.dateOfBirth}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              classes={{
                root: classes.button,
                label: classes.buttonLabel,
              }}
              disabled={userLoading}
            >
              Update profile
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ProfileForm;
