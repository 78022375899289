import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalWrap: {
      boxShadow: '3px 0px 15px rgba(78, 81, 97, 0.14)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiCardContent-root': {
        maxHeight: '100vh',
        overflow: 'auto',
      },
    },
    phoneInput: {
      width: '100%',
      '& .form-control': {
        height: '36px',
        fontSize: '14px',
      },
    },
    checkBox: {
      '&.Mui-checked': {
        color: theme.palette.primary.main,
      },
    },
    modalCard: {
      width: 475,
      maxWidth: 'calc(100% - 30px)',
      '&.MuiCard-root': {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    bigModalCard: {
      width: 947,
      // height: 500,
      maxWidth: 'calc(100% - 30px)',
      '&.MuiCard-root': {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    resize: {
      height: 300,
    },
    smallModalCard: {
      width: 435,
      maxWidth: 'calc(100% - 30px)',
      '&.MuiCard-root': {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    selectOptions: {
      overflow: 'auto',
    },
    modalCardContent: {
      position: 'relative',
      padding: theme.spacing(3, 11, 4),

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
      },
    },
    smallModalCardContent: {
      position: 'relative',
      padding: theme.spacing(3, 3.25, 4),

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
      },
    },
    title: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(5.5),
    },
    label: {
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(0.5),
    },
    input: {
      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(1),
        height: '20px',
      },
      '.MuiFormHelperText-root': {
        fontSize: '14px',
        margin: 0,
      },
      '& label.Mui-focused': {
        color: theme.palette.primary.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.primary.main,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.grey[100],
        },
        '&:hover fieldset': {
          borderColor: theme.palette.grey[900],
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
        },
      },
    },
    button: {
      textWrap: 'nowrap',
      background: theme.palette.success.main,
      borderRadius: 6,
      border: 0,
      color: 'white',
      height: 39,
      padding: '0 42px',
      boxShadow: 'none',
      marginTop: theme.spacing(1),

      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    iconPassword: {
      fontSize: 20,
      cursor: 'pointer',
    },
    closeIcon: {
      fontSize: 32,
    },
    closeIconWrap: {
      position: 'absolute',
      right: 27,
      top: 27,
    },
    outModalCloseIconWrap: {
      position: 'absolute',
      right: 27,
      top: 77,
    },
    penIcon: {
      width: '20px',
      height: '20px',
      marginLeft: '16px',
      cursor: 'pointer',
    },
    buttonLabel: { textTransform: 'capitalize' },
    avatar: {
      width: '124px',
      height: '124px',
      margin: 'auto',
      marginTop: '36px',
    },
    editIcon: {
      position: 'absolute',
      top: 7,
      right: 5,
      zIndex: 1,
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 5,
      backgroundColor: theme.palette.text.primary,
      borderRadius: '50%',
      color: theme.palette.background.default,
    },
    avatarBordered: {
      width: 100,
      height: 100,
      margin: 'auto',
      border: '2px solid white',
    },
    userName: {
      textAlign: 'center',
      paddingTop: '8px',
      color: theme.palette.text.secondary,
    },
    divider: {
      color: theme.palette.grey[400],
      maxWidth: '404px',
      width: '100%',
      marginTop: '5px',
      marginBottom: '28px',
    },
    dateDivider: {
      color: theme.palette.grey[100],
      maxWidth: '404px',
      width: '100%',
      marginTop: '5px',
      marginBottom: '28px',
    },
    data: {
      color: theme.palette.grey[900],
    },
    date: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    rate: {
      color: theme.palette.grey[900],
      marginBottom: '14px',
      marginLeft: '5px',
    },
    dateRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
    },
    rateBlock: {
      display: 'flex',
      marginRight: '50px',
    },
    lastRateBlock: {
      display: 'flex',
      marginRight: '14px',
    },
    dateBlock: {
      display: 'flex',
      marginLeft: '15px',
    },
    nameBlock: {
      display: 'flex',
      height: '100%',
      alignSelf: 'center',
    },
    helperText: {
      fontSize: '14px',
      lineHeight: '19px',
      margin: 0,
      marginTop: '4px',
      color: '#D55F4D',
    },
    detachBtn: {
      backgroundColor: theme.palette.error.main,
    },
    addRateBtn: {
      width: '27px',
      height: '27px',
      minWidth: '27px',
      minHeight: '27px',
      marginLeft: '10px',
      marginBottom: '5px',
      margin: 0,
      padding: 0,
      '&:focus, &:active, &:hover': {
        background: 'none',
      },
    },
    addRateIcon: {
      width: '27px',
      height: '27px',
    },
    baseAddRateBlock: {
      height: '100%',
      maxHeight: '0',
      transition: 'max-height 0.4s ease-in-out',
      display: 'flex',
      alignItems: 'flex-end',
      overflow: 'hidden',
      opacity: 0,
      '&$showAddRateBlock': {
        transition: 'max-height 1s ease-in-out',
        opacity: '1',
        maxHeight: '100vmax',
        width: '100%',
      },
    },
    showAddRateBlock: {},
    invisible: {
      display: 'none',
    },
    nonOverflowedModal: {
      '& .MuiCardContent-root': {
        overflow: 'unset',
      },
    },
    range: {
      '&.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover':
        {
          backgroundColor: `${theme.palette.primary.main}26`,
          borderRadius: '50%',
        },

      '& .DayPicker-Day--selected': {
        '&:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
          backgroundColor: `${theme.palette.primary.main}26`,

          '&:hover': {
            backgroundColor: `${theme.palette.primary.main}26`,
            color: theme.palette.white,

            '&::before': {
              content: '""',
              width: '100%',
              height: '100%',
              backgroundColor: `${theme.palette.primary.main}`,
              position: 'absolute',
              top: 0,
              left: 0,
              borderRadius: '50%',
              zIndex: '0',
            },
          },
        },

        '&:not(.DayPicker-Day--start):not(.DayPicker-Day--end)': {
          backgroundColor: `${theme.palette.primary.main}26`,
          color: theme.palette.text.primary,
          borderTop: `1px solid ${theme.palette.primary.main}`,
          borderBottom: `1px solid ${theme.palette.primary.main}`,

          '&:last-child': {
            borderRight: `1px solid ${theme.palette.primary.main}`,
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
          },
          '&:first-of-type': {
            borderLeft: `1px solid ${theme.palette.primary.main}`,
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
          },
        },

        '&.DayPicker-Day--start': {
          borderRight: 'none',
          border: `1px solid ${theme.palette.primary.main}`,
          borderTopLeftRadius: '50%',
          borderBottomLeftRadius: '50%',
        },

        '&.DayPicker-Day--end': {
          borderLeft: 'none',
          border: `1px solid ${theme.palette.primary.main}`,
          borderTopRightRadius: '50%',
          borderBottomRightRadius: '50%',

          '&.DayPicker-Day--outside': {
            color: theme.palette.text.primary,

            '&::before': {
              display: 'none',
            },
          },
        },

        '&.DayPicker-Day--start, &.DayPicker-Day--end': {
          position: 'relative',
          backgroundColor: `${theme.palette.primary.main}26`,
          color: theme.palette.white,

          '&::before': {
            content: '""',
            width: '100%',
            height: '100%',
            backgroundColor: `${theme.palette.primary.main}`,
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: '50%',
            zIndex: '0',
          },
        },
      },

      '& .DayPicker-Day': {
        borderRadius: 0,
        width: '33px',
        height: '33px',
        color: theme.palette.text.primary,
        fontSize: '14px',

        '& span': {
          position: 'relative',
          zIndex: 1,
        },

        '&--today': {
          color: theme.palette.primary.main,
        },
      },

      '& .DayPicker-Week': {
        marginTop: '4px',
      },

      '& .DayPicker-Weekday': {
        fontSize: '11px',
        fontWeight: '800',
        color: theme.palette.text.secondary,
      },

      '& .DayPicker-Month': {
        borderCollapse: 'separate',
        borderSpacing: '0 4px',
      },

      '& .DayPicker-Months': {
        margin: '0 -1rem',
      },

      '& .DayPicker-Caption': {
        textAlign: 'center',
      },

      '& .DayPicker-NavBar': {
        position: 'absolute',
        top: 8,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 17px',
      },

      '& .DayPicker-wrapper': {
        padding: 0,
      },
    },
    listOption: {
      display: 'flex',
      flexDirection: 'row',
      padding: '6px 16px',
      alignItems: 'center',
    },
  }),
);

export default useStyles;
