import { userRoles } from '@/constants';
import { IVacationDetails, userSelector } from '@/redux';
import { ValueOf } from '@/types/value-of';
import { useSelector } from 'react-redux';

const canRejectByRole: Partial<
  Record<ValueOf<typeof userRoles>, (vacation: IVacationDetails) => boolean>
> = {
  [userRoles.admin]: (vacation: IVacationDetails) =>
    // Allow to cancel pending
    vacation.status === 'Pending' ||
    // and approved, but only if approved vacation has not yet ended
    (vacation.status === 'Approved' && vacation.left > 0),
  [userRoles.user]: (vacation: IVacationDetails) => vacation.status === 'Pending',
};

const useCanRejectVacations = () => {
  const user = useSelector(userSelector);

  const canRejectVacations = (vacation: IVacationDetails) => {
    if (!user) {
      return false;
    }
    const validationPredicate = canRejectByRole[user.role as ValueOf<typeof userRoles>];
    return validationPredicate?.(vacation) ?? false;
  };

  return canRejectVacations;
};

export default useCanRejectVacations;
