import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { useMemo } from 'react';
import dateUtils from '@/utils/dates';
import useStyles from './chart-style';

export default function ChartTooltip<TValue extends ValueType, TName extends NameType>({
  payload,
  label,
}: TooltipProps<TValue, TName>) {
  const classes = useStyles({ chartHeight: 189 });
  const result = useMemo(() => {
    const time = payload?.[0]?.value as number;
    if (!time && time <= 0) {
      return 0;
    }
    const hours = dateUtils.formattedTime(Math.trunc(time));
    const minutes = dateUtils.formattedTime((time * 60) % 60);
    return `${hours}:${minutes}`;
  }, [payload]);

  return (
    <div className={classes.tooltip}>
      <p>{label}</p>
      <p>Worked hours: {`${result}`}</p>
    </div>
  );
}
