import { Button, Card, CardContent } from '@material-ui/core';
import Calendar from '@/components/Calendar';
import { CalendarContentType } from '@/types/calendar';
import useStyles from './styles';
import EventContent from '../EventContent';
import EventTooltip from '../EventTooltip';
import { ITimeOffsCalendarProps } from './types';
import useTimeOffsCalendar from './useTimeOffsCalendar';

function TimeOffsCalendar({
  className,
  tableButton,
  userId,
  hasButton,
  setEventDate,
  editEventHandler,
  removeEventHandler,
  ...props
}: ITimeOffsCalendarProps): JSX.Element {
  const classes = useStyles();

  const { events, vacations } = useTimeOffsCalendar();

  return (
    <div className={classes.content}>
      {hasButton && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          startIcon={tableButton.startIcon}
          onClick={tableButton.action}
        >
          {tableButton.title}
        </Button>
      )}
      <Card className={className} {...props}>
        <CardContent>
          <Calendar
            CellContentComponent={EventContent}
            TooltipComponent={EventTooltip}
            records={userId ? vacations : events}
            className={classes.calendar}
            contentType={userId ? CalendarContentType.UserTimeOffs : CalendarContentType.TimeOffs}
            setEventDate={setEventDate}
            editEventHandler={editEventHandler}
            removeEventHandler={removeEventHandler}
          />
        </CardContent>
      </Card>
    </div>
  );
}

export default TimeOffsCalendar;
