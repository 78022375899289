import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ICalendarContentProps } from '@/components/Calendar/types';
import { IReport } from '@/redux/reports/types';
import { formatMinutesToHoursPeriod } from '@/utils/time';
import clsx from 'clsx';
import useStyles from './styles';

const ReportContent: React.FC<ICalendarContentProps<IReport>> = ({
  data,
  recordsLimit = 2,
  classes,
  contentType,
  ...props
}) => {
  const localClasses = useStyles();
  const renderData = data ?? [];
  return (
    <Grid container className={classes?.root} {...props}>
      <Grid item xs={12}>
        {renderData
          .filter((_item, index) => index < recordsLimit)
          .map((item, index) => (
            <Grid container key={index} className={localClasses.body}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes?.itemText}>
                      {formatMinutesToHoursPeriod(item.workedMinutes)} {item.taskType}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={clsx(classes?.itemText, localClasses.description)}>
                      {item?.project?.projectName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        {renderData.length > recordsLimit && (
          <Typography className={classes?.itemText}>
            ... +{renderData.length - recordsLimit}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ReportContent;
