export const SET_LOADING_EVENTS = 'TIME-OFFS/SET_LOADING_EVENTS';
export const LOAD_VACATIONS = 'TIME-OFFS/LOAD_VACATIONS';
export const LOAD_VACATIONS_BY_USER = 'TIME-OFFS/LOAD_VACATIONS_BY_USER';
export const LOAD_VACATIONS_DETAILS = 'TIME-OFFS/LOAD_VACATIONS_DETAILS';
export const LOAD_USER_VACATIONS_DETAILS = 'TIME-OFFS/LOAD_USER_VACATIONS_DETAILS';
export const LOAD_USER_EVENTS = 'TIME-OFFS/LOAD_USER_EVENTS';
export const LOAD_ALL_EVENTS = 'TIME-OFFS/LOAD_ALL_EVENTS';
export const LOAD_NEXT_EVENTS = 'TIME-OFFS/LOAD_NEXT_EVENTS';
export const STORE_EVENTS = 'TIME-OFFS/STORE_EVENTS';
export const STORE_NEXT_EVENTS = 'TIME-OFFS/STORE_NEXT_EVENTS';
export const STORE_VACATIONS_DETAILS = 'TIME-OFFS/STORE_VACATIONS_DETAILS';
export const ALL_EVENTS_STORE = 'TIME-OFFS/ALL_EVENTS_STORE';
export const STORE_VACATIONS = 'TIME-OFFS/STORE_VACATIONS';
export const LOAD_PENDING_VACATIONS = 'TIME-OFFS/LOAD_PENDING_VACATIONS';
export const STORE_PENDING_VACATIONS = 'TIME-OFFS/STORE_PENDING_VACATIONS';
export const REJECT_VACATION = 'TIME-OFFS/REJECT_VACATION';
export const APPROVE_VACATION = 'TIME-OFFS/APPROVE_VACATION';
export const RESOLVE_VACATION = 'TIME-OFFS/RESOLVE_VACATION';
export const CREATE_VACATION = 'TIME-OFFS/CREATE_VACATION';
export const CREATE_EVENT = 'TIME-OFFS/CREATE_EVENT';
export const EDIT_EVENT = 'TIME-OFFS/EDIT_EVENT';
export const REMOVE_EVENT = 'TIME-OFFS/REMOVE_EVENT';
export const LOAD_USER_VACATIONS = 'TIME-OFFS/LOAD_USER_VACATIONS';
