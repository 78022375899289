import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  card: {
    overflow: 'visible',
    marginTop: '24px',
    borderRadius: '6px',
    border: '1px solid #fff',
  },
  contentCard: {
    padding: '37px 22px',
  },
  title: {
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '2px',
    minWidth: '95px',
    marginRight: '11px',
  },
  description: {
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '2px',
    fontWeight: 600,
  },
  infoRow: {
    display: 'flex',
  },
}));

export default useStyles;
