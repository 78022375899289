import { makeStyles, createStyles } from '@material-ui/core/styles';

import sunIcon from '@/assets/svg/icons/sun.svg';
import bDayIcon from '@/assets/svg/icons/birthday.svg';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: '6px',
      '& :not(:last-child)': {
        marginBottom: 5,
        '& > div': {
          borderBottom: '1px solid #EDF2F7',
        },
      },
    },
    iconBlock: {
      display: 'flex',
      '& :not(:last-child)': {
        marginBottom: 0,
      },
    },
    icon: {
      marginTop: '12px',
      minHeight: '16px',
      minWidth: '16px',
      width: '16px',
      height: '16px',
      marginRight: '6px',
    },
    itemText: {
      fontSize: '11px',
      lineHeight: '15.29px',
      letterSpacing: '0.16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    notVacation: {
      height: '20.5px',
      paddingBottom: '8px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    sun: {
      backgroundImage: `url(${sunIcon})`,
    },
    bDay: {
      backgroundImage: `url(${bDayIcon})`,
    },
    eventIconButton: {
      width: '20px',
      height: '20px',
      marginLeft: '15px',
    },
    eventIcon: {
      width: '20px',
      height: '20px',
      marginBottom: 0,
      color: '#323232',
    },
    vacation: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      '& :first-child': {
        borderBottom: '0px',
      },
      '& :last-child': {
        marginBottom: 5,
      },
    },
    eventText: {
      marginTop: '5px',
    },

    contentStart: {
      justifyContent: 'flex-start',
    },
    contentCenter: {
      justifyContent: 'center',
    },
    contentEnd: {
      justifyContent: 'flex-end',
    },
  }),
);

export default useStyles;
