import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridHeader: {
      border: 'none',
      padding: '0px',
      [theme.breakpoints.up('md')]: {
        border: '1px solid rgba(224, 224, 224, 1)',
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        padding: 'inherit',
      },
    },
    itemText: {
      fontSize: '9px',
      lineHeight: '13px',
      letterSpacing: '0.16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    statuses: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    status: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    statusText: {
      fontSize: '11px',
      lineHeight: '15.29px',
      letterSpacing: '0.3px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme.palette.text.primary,
      marginLeft: '6px',
      marginRight: '20px',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    indicator: {
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    pending: {
      backgroundColor: theme.palette.type === 'light' ? '#F09438' : '#F5BA7F',
    },
    approved: {
      backgroundColor: '#61D8A9',
    },
    pendingCell: {
      backgroundColor: theme.palette.type === 'light' ? 'rgba(240, 148, 56, 0.3)' : '#6F574B',
    },
    Pending: {
      backgroundColor: theme.palette.type === 'light' ? 'rgba(240, 148, 56, 0.3)' : '#6F574B',
    },
    approvedCell: {
      backgroundColor: theme.palette.type === 'light' ? 'rgba(97, 216, 169, 0.3)' : '#61D8A9',
    },
    Approved: {
      backgroundColor: theme.palette.type === 'light' ? 'rgba(97, 216, 169, 0.3)' : '#61D8A9',
    },
    rejectedCell: {
      color: theme.palette.type === 'light' ? '#D55F4D' : '#fff',
      backgroundColor:
        theme.palette.type === 'light' ? 'rgba(216, 80, 60, 0.1)' : 'rgba(216, 80, 60, 0.1)',
    },
    Rejected: {
      backgroundColor: theme.palette.type === 'light' ? '#D55F4D' : '#F78585',
    },
  }),
);

export default useStyles;
