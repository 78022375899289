import { EditPenIcon, TrashIcon } from '@/components/Icons';
import { DateFormatUtil } from '@/utils';
import { Button, Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useState } from 'react';
import useStyles from './styles';
import { IUserRatesTableProps } from './types';

const UserRatesTable = ({ userRates, rateInput, onDelete }: IUserRatesTableProps) => {
  const classes = useStyles();

  const lastUserRate = userRates[userRates.length - 1];
  const restUserRates = userRates.slice(0, -1);

  const [previousShown, setPreviousShown] = useState(false);

  return (
    <div className={classes.userRatesBlock}>
      {restUserRates.length > 0 && (
        <div className={classes.buttonBlock}>
          <Button
            variant="contained"
            onClick={() => setPreviousShown((prevPreviousShown) => !prevPreviousShown)}
            classes={{
              root: classes.button,
              label: classes.buttonLabel,
            }}
          >
            {previousShown ? 'Hide previous' : 'Show previous'}
          </Button>
        </div>
      )}
      <Divider className={clsx(classes.divider, classes.fullSizeDivider)} />
      <div className={classes.date}>
        {previousShown &&
          restUserRates.map((el) => (
            <div key={el.id}>
              <div className={classes.dateRow}>
                <div className={classes.dateBlock}>
                  <Typography className={classes.data} variant="body1">
                    {DateFormatUtil.formatDate(el.openAt)}
                  </Typography>
                  <Typography className={classes.data} variant="body1">
                    -
                  </Typography>
                  <Typography className={classes.data} variant="body1">
                    {el.closedAt && DateFormatUtil.formatDate(el.closedAt)}
                  </Typography>
                </div>
                <div className={classes.rateBlock}>
                  <Typography className={classes.rate} variant="body1">
                    Rate
                  </Typography>
                  <Typography className={classes.rate} variant="body1">
                    {el.rate}
                  </Typography>
                  <TrashIcon onClick={() => onDelete(el.id)} className={classes.penIcon} />
                </div>
              </div>
              <Divider className={clsx(classes.dateDivider, classes.fullSizeDivider)} />
            </div>
          ))}
        {lastUserRate && (
          <div>
            <div className={classes.dateRow}>
              <div className={classes.dateBlock}>
                <Typography className={classes.data} variant="body1">
                  {DateFormatUtil.formatDate(lastUserRate.openAt)}
                </Typography>
                <Typography className={classes.data} variant="body1">
                  -
                </Typography>
                <Typography className={classes.data} variant="body1">
                  now
                </Typography>
              </div>
              <div className={classes.rateBlock}>
                <Typography className={classes.rate} variant="body1">
                  Rate
                </Typography>
                <Typography className={classes.rate} variant="body1">
                  {lastUserRate.rate}
                </Typography>
                <EditPenIcon onClick={rateInput} className={classes.penIcon} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserRatesTable;
