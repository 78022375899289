import { useSelector } from 'react-redux';
import { statisticSelector } from '@/redux';
import { useMemo } from 'react';

const useBalanceCard = () => {
  const {
    salary,
    expectedSalary: expected,
    overtimeSalary: overtime,
  } = useSelector(statisticSelector);

  const progress = useMemo(
    () => (expected > 0 ? Math.round((salary / expected) * 100) : 0),
    [expected, salary],
  );

  return { salary, expected, overtime, progress };
};

export default useBalanceCard;
