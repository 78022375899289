import { userStateSelector } from '@/redux';
import { Avatar, Divider, Typography } from '@material-ui/core';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import useStyles from './styles';

const TimeOffsUserHeader: React.FC = () => {
  const classes = useStyles();

  const currentUser = useSelector(userStateSelector);

  const vacationLeft = Math.floor(currentUser?.vacationLeft || 0);
  const vacationTotal = Math.floor(currentUser?.vacationTotal || 0);

  return (
    <div className={classes.root}>
      <div className={classes.userBlock}>
        <Avatar
          alt={currentUser?.fullName}
          src={currentUser?.photo ?? '/src/assets/svg/logo.svg'}
          className={classes.avatar}
        />
        <Typography>{currentUser?.fullName}</Typography>
      </div>
      <Divider className={classes.divider} orientation="vertical" flexItem />
      <Typography>
        Left: {vacationLeft} {pluralize('day', vacationLeft)}
      </Typography>
      <Divider className={classes.divider} orientation="vertical" flexItem />
      <Typography>
        Total: {vacationTotal} {pluralize('day', vacationTotal)}
      </Typography>
    </div>
  );
};

export default TimeOffsUserHeader;
