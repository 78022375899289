import { useCallback } from 'react';
import { IUsePhoneInputFieldProps } from '../types';

const usePhoneInputField = ({ onChange, name }: IUsePhoneInputFieldProps) => {
  const handleChange = useCallback(
    (val) => {
      onChange(name, val);
    },
    [name, onChange],
  );

  return {
    handleChange,
  };
};

export default usePhoneInputField;
