import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function DollarIcon({ viewBox = '0, 0, 14, 24', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M14 16.2128C14 17.5234 13.4715 18.6383 12.4146 19.5574C11.3576 20.4596 9.80806 20.9617 7.76584 21.0638V24H6.42227V21.0894C5.11452 21.0213 3.87844 20.783 2.71401 20.3745C1.5675 19.9489 0.662828 19.4128 0 18.766L0.779271 17.3106C1.40627 17.8894 2.22137 18.3745 3.22457 18.766C4.24568 19.1574 5.31158 19.3872 6.42227 19.4553V12.7404C5.18618 12.4511 4.15611 12.1447 3.33205 11.8213C2.508 11.4809 1.8183 10.9957 1.26296 10.366C0.725528 9.71915 0.456814 8.86809 0.456814 7.81277C0.456814 6.50213 0.958413 5.40426 1.96161 4.51915C2.96481 3.61702 4.4517 3.08936 6.42227 2.93617V0H7.76584V2.91064C8.78695 2.94468 9.77223 3.10638 10.7217 3.39574C11.6891 3.66809 12.5221 4.03404 13.2207 4.49362L12.5489 6C11.8145 5.55745 11.0352 5.20851 10.2111 4.95319C9.38708 4.69787 8.57198 4.55319 7.76584 4.51915V11.2851C9.09149 11.5915 10.1753 11.9064 11.0173 12.2298C11.8592 12.5362 12.5669 13.0128 13.1401 13.6596C13.7134 14.3064 14 15.1574 14 16.2128ZM2.41843 7.7617C2.41843 8.64681 2.7588 9.31915 3.43954 9.77872C4.1382 10.2383 5.13244 10.6298 6.42227 10.9532V4.57021C5.09661 4.67234 4.09341 5.02128 3.41267 5.61702C2.74984 6.19574 2.41843 6.91064 2.41843 7.7617ZM7.76584 19.4553C9.18106 19.3532 10.247 19.0213 10.9635 18.4596C11.6801 17.8979 12.0384 17.183 12.0384 16.3149C12.0384 15.3957 11.6711 14.7064 10.9367 14.2468C10.2022 13.7872 9.14523 13.3957 7.76584 13.0723V19.4553Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
