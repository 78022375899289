import { userSelector } from '@/redux';
import { Divider, Typography, useMediaQuery, Theme } from '@material-ui/core';
import clsx from 'clsx';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import useStyles from './styles';

const TimeOffsProfileHeader: React.FC = () => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const user = useSelector(userSelector);
  const vacationLeft = Math.floor(user?.vacationLeft || 0);
  const vacationTotal = Math.floor(user?.vacationTotal || 0);

  return (
    <div>
      {isDesktop ? (
        <div className={classes.root}>
          <Typography className={classes.text}>
            Left: {vacationLeft} {pluralize('day', vacationLeft)}
          </Typography>
          <Divider className={classes.divider} orientation="vertical" flexItem />
          <Typography className={classes.text}>
            Total: {vacationTotal} {pluralize('day', vacationTotal)}
          </Typography>
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.block}>
            <Typography className={clsx(classes.text, classes.title, classes.mobileText)}>
              Total:
            </Typography>
            <Typography className={clsx(classes.text, classes.mobileText)}>
              {vacationTotal} {pluralize('day', vacationTotal)}
            </Typography>
          </div>
          <div className={classes.block}>
            <Typography className={clsx(classes.text, classes.title, classes.mobileText)}>
              Left:
            </Typography>
            <Typography className={clsx(classes.text, classes.mobileText)}>
              {vacationLeft} {pluralize('day', vacationLeft)}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeOffsProfileHeader;
