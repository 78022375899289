export const workModes = [
  {
    label: 'On-Site (Office)',
    value: 'office',
  },
  {
    label: 'Remote',
    value: 'remote',
  },
];
