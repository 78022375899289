import { createSelector } from 'reselect';
import { IApplicationState } from '../types';

const authorizationSelector = (state: IApplicationState) => state.authorization;

export const userSelector = createSelector(
  authorizationSelector,
  (authorization) => authorization.user,
);

export const userProfileSelector = createSelector(authorizationSelector, (authorization) => ({
  firstName: authorization.user?.firstName ?? '',
  lastName: authorization.user?.lastName ?? '',
  email: authorization.user?.email ?? '',
  phoneNumber: authorization.user?.phoneNumber ?? '',
  dateOfBirth: authorization.user?.dateOfBirth ?? '',
}));

export const userRoleSelector = createSelector(
  authorizationSelector,
  (authorization) => authorization.user?.role,
);

export const userLoadingSelector = createSelector(
  authorizationSelector,
  (state) => state.isLoading,
);

export const changePasswordLoadingSelector = createSelector(
  authorizationSelector,
  (state) => state.isLoadingChangePassword,
);
