import React, { useCallback, useState } from 'react';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import AppPopper from '@/components/AppPopper';
import { IconButton } from '@material-ui/core';
import { ThreeDotsIcon } from '@/components/Icons';
import { userRoles } from '@/constants';
import { IUserActionsPopperProps } from './types';
import useStyles from './styles';

const UserActionsPopper: React.FC<IUserActionsPopperProps> = ({
  user,
  className,
  onEditUser,
  onEditContracts,
  onAttachProject,
  ...props
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const openPopperHandler = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  return (
    <AppPopper
      placement="bottom-end"
      className={className}
      open={open}
      onClose={() => setOpen(false)}
      content={
        <MenuList className={classes.list}>
          <MenuItem onClick={onEditUser} className={classes.listItem}>
            <ListItemText disableTypography className={classes.listText} primary="User profile" />
          </MenuItem>
          {user.role !== userRoles.admin &&
            user.status !== 'Inactive' && [
              <MenuItem key="contracts" onClick={onEditContracts} className={classes.listItem}>
                <ListItemText disableTypography className={classes.listText} primary="Contracts" />
              </MenuItem>,
              <MenuItem key="add-project" onClick={onAttachProject} className={classes.listItem}>
                <ListItemText
                  disableTypography
                  className={classes.listText}
                  primary="Add a project"
                />
              </MenuItem>,
            ]}
        </MenuList>
      }
      {...props}
    >
      <IconButton disableRipple onClick={openPopperHandler}>
        <ThreeDotsIcon />
      </IconButton>
    </AppPopper>
  );
};

export default UserActionsPopper;
