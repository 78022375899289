export const statusOptions = ['Open', 'Close'];

export enum UserPositionsEnum {
  designer = 'UX/UI Designer',
  developer = 'Developer',
  qa = 'QA',
  pm = 'PM',
  hr = 'HR',
  saleManager = 'Lead generation manager',
}

export const positionOptions = [
  'UX/UI Designer',
  'Developer',
  'QA',
  'PM',
  'HR',
  'Lead generation manager',
];

export const tasksOptions: Record<UserPositionsEnum, Array<string>> = {
  [UserPositionsEnum.designer]: ['Designing', 'Estimating', 'Studying', 'Meeting'],
  [UserPositionsEnum.developer]: ['Estimating', 'Studying', 'Meeting', 'Developing', 'Bug fixing'],
  [UserPositionsEnum.pm]: [
    'Estimating',
    'Studying',
    'Meeting',
    'Testing',
    'Planing',
    'Prepare to demo',
  ],
  [UserPositionsEnum.hr]: [
    'Onboarding',
    'One to one meeting',
    'Studying',
    'Recruit candidates',
    'Survey management',
    'Providing career growth',
  ],
  [UserPositionsEnum.qa]: ['Studying', 'Testing', 'Creating bug report', 'Meeting'],
  [UserPositionsEnum.saleManager]: [
    'Researching',
    'Writing emails',
    'Studying',
    'Reporting',
    'Meeting',
  ],
};
