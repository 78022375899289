import { useState, useEffect, useCallback } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Theme, useMediaQuery } from '@material-ui/core';
import { IApplicationState } from '@/redux/types';
import { getCurrentMonthRangeDatePickerState, resultPageButtons } from '@/constants';
import {
  usersSelector,
  loadResultReports,
  clearResultReports,
  overallSalarySelector,
  overallMinutesSelector,
  paginatedReportsSelector,
  userSelector,
  loadUserProfile,
  loadUser,
  userByIdSelector,
  loadProjectsByUser,
  projectsByUserSelector,
  clearProjectsByUser,
  earningCardSelector,
  loadUserOverallStatistic,
} from '@/redux';
import { IProjectReportInfo, IUserResult } from '@/interfaces';
import { DateRange } from '@mui/lab';
import { dateRangeValidationSchema } from '@/validations';
import reportsService from '@/services/reports.service';
import { startOfMonth, subMonths } from 'date-fns';
import { projectsService } from '@/services';

const useUserResult = (month: number) => {
  const dispatch = useDispatch();
  const router = useRouteMatch<{ id: string }>();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const history = useHistory();
  const users = useSelector(usersSelector);
  const mainUser = useSelector(userSelector);
  const [selectTab, setSelectTab] = useState(resultPageButtons[0]);
  const [searchName, setSearchName] = useState('');
  const onBackToResults = useCallback(() => {
    history.push('/results');
  }, [history]);

  const currentUser = useSelector((state: IApplicationState) =>
    userByIdSelector(state, router.params.id),
  );
  const userResult: IUserResult | null = useSelector((state: IApplicationState) =>
    paginatedReportsSelector(state),
  );
  const projects = useSelector(projectsByUserSelector);

  const [date, setDate] = useState<DateRange<Date | null>>(getCurrentMonthRangeDatePickerState());
  const userRole = mainUser?.role;

  useEffect(() => {
    dispatch(loadUserProfile());
    dispatch(loadUser(router.params.id));
    dispatch(clearProjectsByUser());
  }, [dispatch, router.params.id]);

  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [defaultSelectedProjects, setDefaultSelectedProjects] = useState<
    IProjectReportInfo[] | null
  >(null);

  useEffect(() => {
    if (!currentUser?.id) {
      return () => {};
    }
    let isUnmounted = false;

    const startOfPrevMonth = subMonths(startOfMonth(new Date()), 1);

    const dateRange = { from: date[0], to: date[1] };

    const isValid = dateRangeValidationSchema.isValidSync(dateRange);

    projectsService
      .loadProjectsByUser({
        userId: currentUser.id,
        activityFrom: isValid ? date[0]?.toISOString() : startOfPrevMonth.toISOString(),
        activityTo: isValid ? date[1]?.toISOString() : new Date().toISOString(),
      })
      .then((data) => {
        if (isUnmounted) {
          return;
        }
        setDefaultSelectedProjects(data);
      })
      .catch(() => {});

    return () => {
      isUnmounted = true;
      setDefaultSelectedProjects(null);
    };
  }, [date, currentUser?.id]);

  const fetchReports = useCallback(async () => {
    const dateRange = { from: date[0], to: date[1] };
    const isValid = await dateRangeValidationSchema.isValid(dateRange);
    if (!isValid || !currentUser?.id) {
      return;
    }
    dispatch(
      loadResultReports({
        userId: currentUser?.id,
        date: dateRange,
        projectIds: selectedProjects,
        limit: 40,
      }),
    );
  }, [dispatch, currentUser?.id, date, selectedProjects]);

  const handleExport = useCallback(async () => {
    const dateRange = { from: date[0], to: date[1] };
    const isValid = await dateRangeValidationSchema.isValid(dateRange);
    if (!isValid || !currentUser?.id) {
      return;
    }
    reportsService
      .loadReportsExport({
        userId: currentUser?.id,
        date: dateRange,
        projectIds: selectedProjects,
        isVacationShow: false,
      })
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'reports.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }, [currentUser?.id, date, selectedProjects]);

  const fetchProjects = useCallback(() => {
    if (currentUser?.id) {
      dispatch(loadProjectsByUser({ userId: currentUser.id }));
    }
  }, [dispatch, currentUser?.id]);

  useEffect(() => {
    return () => {
      dispatch(clearResultReports());
    };
  }, [currentUser?.id, dispatch, selectedProjects]);

  const overallSalary = useSelector(overallSalarySelector);
  const overallMinutes = useSelector(overallMinutesSelector);
  const earningCardProps = useSelector(earningCardSelector);

  useEffect(() => {
    dispatch(clearResultReports());
    fetchReports();
  }, [dispatch, fetchReports]);

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(loadUserOverallStatistic({ id: currentUser?.id, month, type: 'month' }));
    }
  }, [currentUser?.id, dispatch, month]);

  return {
    isDesktop,
    selectTab,
    setSelectTab,
    onBackToResults,
    userResult,
    date,
    setDate,
    buttons: resultPageButtons,
    currentUser,
    projects,
    defaultSelectedProjects,
    selectedProjects,
    fetchReports,
    setSelectedProjects,
    overallSalary,
    overallMinutes,
    earningCardProps,
    users,
    searchName,
    setSearchName,
    userRole,
    fetchProjects,
    handleExport,
  };
};

export default useUserResult;
