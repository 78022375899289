import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '30px',
      flexDirection: 'column',
      width: '100%',
      marginTop: '30px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        marginTop: 0,
      },
    },
    infoBlock: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 20px',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
        flexDirection: 'row',
      },
    },
    titleWrap: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        flexDirection: 'row',
        width: 'inherit',
        justifyContent: 'unset',
      },
    },
    titleBox: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '17px',
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: '28px',
      fontWeight: 700,
      [theme.breakpoints.up('md')]: {
        fontWeight: 400,
      },
    },
    search: {
      fontSize: '14px',
      color: '#AFAFAF',
      lineHeight: '20px',
      margin: '17px 20px',
      background: 'rgba(175, 175, 175, 0.15)',

      '& .MuiOutlinedInput-input': {
        padding: '13px 17px',
        paddingLeft: '4px',
        height: '22px',
      },
      [theme.breakpoints.up('md')]: {
        background: 'none',

        width: '100%',
        maxWidth: '306px',
        '& .MuiOutlinedInput-input': {
          padding: '8px 12px',
          paddingLeft: '4px',
        },
      },
    },
    searchIcon: {
      color: '#AFAFAF',
    },
    total: {
      minWidth: '66px',
      fontSize: '18px',
      color: theme.palette.text.primary,
      marginLeft: '6px',
      [theme.breakpoints.up('md')]: {
        marginLeft: 0,
      },
    },
    totalPlaceholder: {
      minWidth: '97px',
    },
    line: {
      display: 'block',
      height: '38px',
      width: '1px',
      backgroundColor: theme.palette.text.primary,
      margin: '0 15px',
      borderRadius: '1px',
    },
    button: {
      padding: '10px 23px',
      textTransform: 'capitalize',
      minWidth: '94px',
      marginTop: '17px',
      [theme.breakpoints.up('md')]: {
        padding: '12px 27px',
        marginTop: 0,
      },
    },
  }),
);

export default useStyles;
