import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      minWidth: '8.75rem',
      padding: '5px 7px 6px 4px',
    },
    listText: {
      fontSize: '15px',
      padding: '2px 8px',
    },
    listItem: {
      padding: 0,
      '&:not(:first-child)': {
        marginTop: '3px',
      },
      '&:not(:last-child)': {
        marginBottom: '3px',
      },
    },
  }),
);

export default useStyles;
