import { useCallback, useEffect, useState } from 'react';
import { EditIcon, AddUserIcon } from '@/components/Icons';
import {
  Card,
  Chip,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Avatar,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Button,
  ButtonBase,
} from '@material-ui/core';
import clsx from 'clsx';
import { DateFormatUtil } from '@/utils';
import { IProject, ITeamMember, IShortTeamMemberInfo, IDetachmentData } from '@/interfaces';
import { AttachUserModal, UserRateModal } from '@/components/Modals';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  editUserRate,
  detachUser,
  projectsPaginationSelector,
  projectByIdSelector,
  loadProject,
} from '@/redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IApplicationState } from '@/redux/types';
import { IProjectsTableProps } from './types';
import useStyles from '../styles';

const tableHead = [
  { name: 'Date', align: 'left', numeric: false, id: 'createdAt' },
  { name: 'Project name', align: 'left', numeric: false, id: 'projectName' },
  { name: 'Team members', align: 'left', numeric: false, id: 'teamMembers' },
  { name: 'Status', align: 'center', numeric: false, id: 'status' },
] as const;
export default function ProjectsTable({
  projects,
  tableButton,
  fetchData,
  editProjectId,
  toggleModal,
  ...props
}: IProjectsTableProps): JSX.Element {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [openAttachUserModal, setAttachUserOpenModal] = useState(false);
  const [openUserRateModal, setUserRateOpenModal] = useState(false);

  const [projectRow, setProjectRow] = useState<IProject | null>(null); // Defines project to attach user
  const [teamMember, setTeamMember] = useState<ITeamMember | null>(null); // Defines current selected user

  const projectsPagination = useSelector(projectsPaginationSelector);

  const projectId = projectRow?.id as string;

  const currentProject = useSelector((state: IApplicationState) =>
    projectByIdSelector(state, projectId),
  );

  const currentUser = currentProject?.teamMembers.find(
    (user) => user?.user?.id === teamMember?.user?.id,
  );

  const addMember = (project: IProject) => {
    setProjectRow(project);
    setAttachUserOpenModal(true);
  };

  const onUserRateModalSubmitHandler = useCallback(
    (values: IShortTeamMemberInfo) => {
      dispatch(editUserRate({ ...values, projectId }));
    },
    [dispatch, projectId],
  );

  const editProjectHandler = (project: IProject) => () => {
    toggleModal?.(true);
    editProjectId(project.id);
  };

  const editUserRateHandler = (user: ITeamMember, project: IProject) => {
    setTeamMember(user);
    setProjectRow(project);
    setUserRateOpenModal(true);
  };

  const closeModal = () => {
    setUserRateOpenModal(false);
  };

  const onDetachUserSubmitHandler = useCallback(
    ({ onSuccess = closeModal, ...data }: IDetachmentData) => {
      dispatch(detachUser({ onSuccess, ...data }));
    },
    [dispatch],
  );

  useEffect(() => {
    loadProject(projectId);
  }, [dispatch, projectId]);

  return (
    <div className={classes.content} {...props}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        startIcon={tableButton.startIcon}
        onClick={() => toggleModal?.(true)}
      >
        {tableButton.title}
      </Button>
      <Card className={classes.card}>
        <CardContent>
          <InfiniteScroll
            dataLength={projects.length}
            loader={null}
            hasMore={projectsPagination.hasNext}
            next={fetchData}
          >
            <TableContainer component={Paper} className={classes.tableWrap}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {tableHead.map((el) => (
                      <TableCell
                        key={el.id}
                        id={el.id}
                        align={el.align}
                        className={classes.tableHeader}
                      >
                        {el.name}
                      </TableCell>
                    ))}
                    <TableCell align="center" className={classes.tableHeader}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projects.map((project) => (
                    <TableRow key={project.id}>
                      <TableCell className={classes.tableItem}>
                        {DateFormatUtil.formatDate(project.createdAt)}
                      </TableCell>
                      <TableCell className={classes.tableItem}>
                        <Link className={classes.buttonLink} to={`project/${project.id}`}>
                          <ButtonBase disableRipple className={classes.projectName}>
                            {project.projectName}
                          </ButtonBase>
                        </Link>
                      </TableCell>
                      <TableCell className={classes.tableItem}>
                        <div className={clsx(classes.memberCell, classes.membersBlock)}>
                          {project.teamMembers
                            .filter((user) => user.user.status !== 'Inactive')
                            .map((user) => (
                              <div className={classes.memberAvatar} key={user.user.id}>
                                <Tooltip
                                  title={user?.user?.firstName ?? 'Name'}
                                  className={classes.memberTooltip}
                                  classes={{ tooltip: classes.memberTooltip }}
                                >
                                  <Avatar
                                    onClick={() => editUserRateHandler(user, project)}
                                    alt={user?.user?.firstName}
                                    src={user?.user?.photo || '/src/assets/svg/logo.svg'}
                                    className={classes.tableRowAvatar}
                                  />
                                </Tooltip>
                              </div>
                            ))}
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.tableItem}>
                        <Chip
                          className={clsx(classes.tableChip, {
                            [classes.tableChipError]: project.status !== 'Open',
                          })}
                          label={project.status}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.tableItem}>
                        <Tooltip title="Edit project">
                          <IconButton onClick={editProjectHandler(project)} color="inherit">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        {project.status === 'Open' && (
                          <Tooltip title="Add team member">
                            <IconButton onClick={() => addMember(project)} color="inherit">
                              <AddUserIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </CardContent>
      </Card>
      <AttachUserModal
        projectRow={projectRow}
        open={openAttachUserModal}
        closeModalHandler={setAttachUserOpenModal}
      />
      {currentUser && (
        <UserRateModal
          user={currentUser}
          onSubmitHandler={onUserRateModalSubmitHandler}
          open={openUserRateModal}
          closeModalHandler={closeModal}
          projectId={projectId}
          onDetachUserSubmitHandler={onDetachUserSubmitHandler}
        />
      )}
    </div>
  );
}
