import { FormGroup, Grid, Button, Typography, InputLabel, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { CustomInput, DatePicker } from '@/components/FormItems';
import useCreateEvent from './useCreateEvent';
import useStyles from './styles';
import { ICreateEventFormProps } from './types';

const EventForm = ({ onSubmitHandler, event, ...props }: ICreateEventFormProps): JSX.Element => {
  const classes = useStyles();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    handleDateChange,
    projectsLoading,
  } = useCreateEvent(onSubmitHandler, event);

  return (
    <div {...props}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Typography className={classes.title}>{event ? 'Edit event' : 'Add an event'}</Typography>
          <Grid item xs={12}>
            <FormGroup className={classes.input}>
              <CustomInput
                id="name"
                label="Name"
                name="name"
                placeholder="Event"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.name || ''}
                error={touched?.name && !!errors?.name}
                helperText={touched?.name && errors?.name}
              />
            </FormGroup>
            <FormGroup>
              <DatePicker
                disableToolbar
                variant="inline"
                format="dd.MM.yyyy"
                margin="normal"
                id="date"
                label="Date"
                name="date"
                onChange={handleDateChange}
                onBlur={handleBlur}
                value={values.date}
                helperText={touched.date && errors?.date}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </FormGroup>
            <FormGroup>
              <InputLabel className={classes.inputLabel}>Comment</InputLabel>
              <TextField
                className={classes.descriptionInput}
                multiline
                name="description"
                id="description"
                variant="outlined"
                onChange={handleChange}
                placeholder="Description"
                onBlur={handleBlur}
                value={values?.description || ''}
                error={touched?.description && !!errors?.description}
                helperText={touched?.description && errors?.description}
                maxRows={4}
              />
            </FormGroup>
            <div className={classes.buttonBlock}>
              <Button
                variant="contained"
                type="submit"
                classes={{
                  root: clsx(classes.button, classes.submitButton),
                  label: classes.buttonLabel,
                }}
                disabled={projectsLoading}
              >
                {event ? 'Edit' : 'Add'}
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default EventForm;
