import { IProject, IProjectReportInfo } from '@/interfaces';
import { InputLabel, FormGroup, FormControl, TextField, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useEffect } from 'react';
import { formatDistance } from 'date-fns';
import useStyles from '../styles';
import { IProjectSelectProps } from './types';

export default function MultiSelect({
  label,
  id,
  error,
  data,
  selectedProjects,
  setSelectedProjects,
  searchName,
  setSearchName,
  className,
  placeholder,
  fetchProjects,
  setVacationsShown,
  defaultValue,
  ...props
}: IProjectSelectProps): JSX.Element {
  const classes = useStyles();

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const handleProjectChange = useCallback(
    (_e, val: IProjectReportInfo[]) => {
      const hasSameLength = val.length === data.length;
      setSelectedProjects(hasSameLength ? [] : val.map((project) => project.id));
      setVacationsShown?.(hasSameLength);
    },
    [data.length, setSelectedProjects, setVacationsShown],
  );

  const formatLastReportedDate = (dateString?: string) => {
    if (!dateString) {
      return ' (never reported)';
    }

    const timeDistance = !dateString
      ? 'never'
      : formatDistance(new Date(dateString), new Date(), {
          addSuffix: true,
        });

    return ` (reported ${timeDistance})`;
  };

  return data.length > 0 ? (
    <FormGroup className={classes.fullWidth}>
      <FormControl
        className={clsx(classes.wrapper, classes.input)}
        variant="outlined"
        error={!!error}
      >
        {label && (
          <InputLabel margin="dense" htmlFor={id} className={classes.label} error={!!error}>
            {label}
          </InputLabel>
        )}
        <Autocomplete
          multiple
          options={data}
          className={classes.input}
          onChange={handleProjectChange}
          disableCloseOnSelect
          PaperComponent={(paperProps) => <div {...paperProps} className={classes.listBox} />}
          getOptionLabel={(option) => option.projectName}
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps} className={classes.listOption}>
              <Checkbox value={option.id} className={classes.checkBox} checked={selected} />

              <span>
                <span>{option.projectName}</span>
                <span className={classes.latestReportDate}>
                  {formatLastReportedDate(option.latestReportDate)}
                </span>
              </span>
            </li>
          )}
          isOptionEqualToValue={(option, value) => (option ? option.id === value.id : false)}
          renderInput={(params) => (
            <TextField
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              {...params}
              {...props}
              className={classes.input}
              placeholder={searchName.trim().length ? '' : 'Please type project...'}
            />
          )}
          defaultValue={(defaultValue as IProject[]) ?? data}
        />
      </FormControl>
    </FormGroup>
  ) : (
    <></>
  );
}
