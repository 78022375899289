import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      boxShadow: 'none',
      borderRadius: 0,
      [theme.breakpoints.up('md')]: {
        boxShadow: '3px 0px 15px rgba(78, 81, 97, 0.14)',
        borderRadius: 7,
      },
      '& .MuiCardContent-root:last-child': {
        padding: 10,
        paddingBottom: 0,
      },
    },
    content: {
      width: '100%',
      transition: 'filter 0.5s',
    },
    header: {
      display: 'flex',
      paddingBottom: '10px',
      justifyContent: 'space-between',
    },
    pediodBlock: {
      marginRight: '32px',
    },
    title: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '21px',
    },
    titleBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    revenueBlock: {
      marginTop: 3,
      marginLeft: '8px',
      display: 'flex',
      fontSize: 14,
      lineHeight: '15px',
    },
    blured: { filter: `blur(10px)` },
    unBlured: { filter: `blur(0px)` },
  }),
);

export default useStyles;
