import React, { useCallback } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { CustomInput } from '@/components/FormItems';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@/components/Buttons/Button';
import { useFormik } from 'formik';
import { sendForgotPassword } from '@/redux';
import useStyles from './styles';
import validationSchema from './validations';

const ForgotPassword: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmitHandler = useCallback(
    (values: { email: string }) => {
      dispatch(sendForgotPassword(values.email));
    },
    [dispatch],
  );

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } = useFormik({
    validationSchema,
    onSubmit: onSubmitHandler,
    initialValues: {
      email: '',
    },
  });

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={false} md={6} className={classes.bg}>
        <div className={classes.image} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.wrapper}>
            <Typography className={classes.formTitle} component="h1" variant="h1">
              Forgot Password
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <Grid item xs={12}>
                <CustomInput
                  required
                  labelClass={classes.label}
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  placeholder="andriykrasava@gmail.com"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.email && !!errors.email ? errors.email : null}
                  error={touched.email && !!errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlined className={classes.icon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid container justifyContent="flex-end">
                <Link href="/" variant="inherit" className={classes.link}>
                  Login here
                </Link>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Reset
                </Button>
              </Grid>
            </form>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
