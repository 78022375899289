import { INotification } from '@/redux';
import auth from '@DevimaSolutions/o-auth';

const loadNotifications = async (): Promise<INotification[]> => {
  const response = await auth()
    .axios.get(`/user/notifications`)
    .then(({ data }) => data);
  return response;
};

const loadNewNotifications = async (payload: boolean): Promise<INotification[]> => {
  const response = await auth()
    .axios.get(`/user/notifications`, {
      params: {
        payload,
      },
    })
    .then(({ data }) => data);
  return response;
};

const countNewNotifications = async (): Promise<{
  count: number;
}> => {
  const response = await auth()
    .axios.get(`/user/new-notifications-count`)
    .then(({ data }) => data);
  return response;
};

export default {
  loadNotifications,
  countNewNotifications,
  loadNewNotifications,
};
