import { useCallback, useState } from 'react';
import { DateFormatUtil } from '@/utils';
import { Periods } from '@/types/periods';

const useDateChanger = () => {
  const [month, setMonth] = useState(DateFormatUtil.formatToMonthNumber(new Date()) - 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [period, setPeriod] = useState(Periods.Month);

  const nextMonth = useCallback(() => {
    setMonth((prevState) => Math.min(11, prevState + 1));
  }, []);

  const prevMonth = useCallback(() => {
    setMonth((prevState) => Math.max(0, prevState - 1));
  }, []);

  const nextYear = useCallback(() => {
    setYear((state) => state + 1);
  }, []);

  const prevYear = useCallback(() => {
    if (year > 2000) {
      setYear((state) => state - 1);
    }
  }, [year]);

  const changePeriod = useCallback((value: Periods) => {
    setPeriod(value);
  }, []);

  return {
    period,
    month,
    year,
    changePeriod,
    nextMonth,
    prevMonth,
    nextYear,
    prevYear,
  };
};

export default useDateChanger;
