import auth from '@DevimaSolutions/o-auth';
import { IAttachmentProjectData, IUser } from '@/interfaces';
import { IUsersWithPagination, LoadUsersActionProps } from '@/redux';

const loadUsers = async (
  payload?: LoadUsersActionProps & { page: number },
): Promise<IUsersWithPagination> => {
  const response = await auth()
    .axios.get(`/admin/users`, {
      params: payload,
    })
    .then(({ data }) => data);
  return response;
};

const loadUsersShorts = async (payload: {
  page: number;
  limit: number;
}): Promise<IUsersWithPagination> => {
  const response = await auth()
    .axios.get(`/admin/users-short`, { params: payload })
    .then(({ data }) => data);
  return response;
};

const loadUser = async (userId: string): Promise<IUser> => {
  const response = await auth()
    .axios.get(`/admin/users/${userId}`)
    .then(({ data }) => data);
  return response;
};

const addUser = async (user: IUser): Promise<IUser[]> => {
  const response = await auth()
    .axios.post(`/auth/user/`, { ...user })
    .then(({ data }) => data);

  return response;
};

const editUser = async (user: IUser): Promise<IUser[]> => {
  const response = await auth()
    .axios.put(`/admin/users/${user.id}`, { ...user })
    .then(({ data }) => data);

  return response;
};

const attachProjects = async ({ projects, userId }: IAttachmentProjectData) => {
  const response = await auth()
    .axios.post(`/admin/users/attach/${userId}`, projects)
    .then((data) => data);

  return response.data;
};

const changePhoto = async (values: { file: File; id: string }): Promise<string> => {
  const formData = new FormData();
  formData.append('file', values.file);
  const response = await auth()
    .axios.post(`/admin/users/set-photo/${values.id}`, formData)
    .then(({ data }) => data);
  return response;
};

export default {
  loadUsers,
  loadUsersShorts,
  loadUser,
  editUser,
  addUser,
  attachProjects,
  changePhoto,
};
