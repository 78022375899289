import { IContract } from '@/interfaces';
import { ContractStatusEnum } from '@/types/contract';
import auth from '@DevimaSolutions/o-auth';
import { AxiosResponse } from 'axios';

export interface ICreateContractRequest {
  user: string;
  contractName: string;
  startAt: string;
  endAt: string;
  status?: ContractStatusEnum;
}

const createContract = async (data: ICreateContractRequest) => {
  const response = await auth().axios.post<IContract>(`/admin/contracts`, data);
  return response.data;
};

const getUserContracts = async (userId: string) => {
  const response = await auth().axios.get<IContract[]>(`/admin/contracts/${userId}`);
  return response.data;
};

const editContract = async (contract: IContract): Promise<IContract> => {
  const response = await auth()
    .axios.put(`/admin/contracts/${contract.id}`, contract)
    .then(({ data }) => data);

  return response;
};

const deleteContract = async (id: string): Promise<string> => {
  const response = await auth()
    .axios.delete(`/admin/contracts/${id}`)
    .then((data: AxiosResponse<string>) => data);
  return response.data ?? {};
};

export default {
  createContract,
  getUserContracts,
  editContract,
  deleteContract,
};
