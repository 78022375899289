import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(5.5),
      [theme.breakpoints.up('md')]: {
        fontWeight: 'bold',
      },
    },
    buttonLabel: {
      textTransform: 'capitalize',
    },
    button: {
      background: theme.palette.success.main,
      borderRadius: 6,
      border: 0,
      color: 'white',
      height: 39,
      padding: '0 42px',
      boxShadow: 'none',
      marginTop: theme.spacing(3),

      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    form: {
      '& .MuiOutlinedInput-root': {
        height: '39px',
      },
    },
  }),
);

export default useStyles;
