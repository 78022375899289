import { Typography, FormGroup, Grid, Button, Checkbox } from '@material-ui/core';
import { CustomInput, MultiSelect } from '@/components/FormItems';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { attachUser, projectsLoadingSelector, usersSelector } from '@/redux';
import { IUser, IUserRate, IUserRatesValues } from '@/interfaces';
import { useCallback } from 'react';
import { attachUsersSchema } from '@/validations';
import useStyles from './styles';
import { IProjectFormProps } from './types';

const AttachUserForm = ({
  project,
  onSubmit,
  searchName,
  setSearchName,
  ...props
}: IProjectFormProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmitHandler = useCallback(
    ({ members, rates }: IUserRatesValues, { resetForm }: FormikHelpers<IUserRatesValues>) => {
      const ratesArr: IUserRate[] = members.map((member) => {
        return {
          userId: member.id,
          rate: rates?.[member.id] ?? member.rate,
        };
      });
      dispatch(attachUser({ users: ratesArr, projectId: project.id, onSuccess: onSubmit }));
      resetForm();
    },
    [dispatch, onSubmit, project.id],
  );

  const projectsLoading = useSelector(projectsLoadingSelector);
  const users = useSelector(usersSelector);

  const teamMembers = project?.teamMembers.map((el) => el?.user?.id);
  const newUsers = teamMembers ? users.filter((user) => !teamMembers.includes(user?.id)) : users;

  return (
    <div {...props}>
      <Typography component="h2" variant="h1" className={classes.title}>
        Add a member
      </Typography>
      <Formik<IUserRatesValues>
        validationSchema={attachUsersSchema}
        onSubmit={onSubmitHandler}
        initialValues={{
          members: [],
          rates: null,
        }}
        enableReinitialize
      >
        {({ handleBlur, setFieldValue, values, errors, handleSubmit, handleChange }) => (
          <Form onSubmit={handleSubmit}>
            <FieldArray
              name="rates"
              render={() => (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormGroup>
                      <MultiSelect
                        id="members"
                        label="Members"
                        name="members"
                        searchName={searchName}
                        setSearchName={setSearchName}
                        onChange={(_e, field, val) => setFieldValue(field, val)}
                        onBlur={handleBlur}
                        options={newUsers}
                        clearOnBlur={false}
                        multiple
                        disableClearable
                        getOptionLabel={(option) => option.fullName}
                        isOptionEqualToValue={(option, value) =>
                          option ? option.id === value.id : false
                        }
                        renderOption={(renderProps, option, { selected }) => (
                          <li {...renderProps} className={classes.listOption}>
                            <Checkbox
                              value={option.id}
                              className={classes.checkBox}
                              checked={selected}
                            />

                            {option.fullName}
                          </li>
                        )}
                        error={!!errors.members}
                      />
                    </FormGroup>
                  </Grid>
                  {values.members.map((user: IUser) => (
                    <Grid item xs={12}>
                      <div className={classes.addUserBlock}>
                        <Typography className={classes.data} variant="body1">
                          {user.fullName}
                        </Typography>
                        <CustomInput
                          id={`rates.${user.id}`}
                          label="Rate"
                          name={`rates.${user.id}`}
                          onChange={handleChange}
                          defaultValue={user.rate}
                          required
                          type="number"
                        />
                      </div>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      classes={{
                        root: classes.button,
                        label: classes.buttonLabel,
                      }}
                      disabled={projectsLoading}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AttachUserForm;
