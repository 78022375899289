import { useSelector } from 'react-redux';
import { allEventsSelector, vacationsSelector } from '@/redux';

const useTimeOffsCalendar = () => {
  const events = useSelector(allEventsSelector);
  const vacations = useSelector(vacationsSelector);

  return {
    events,
    vacations,
  };
};

export default useTimeOffsCalendar;
