import { useSelector } from 'react-redux';
import { statisticSelector } from '@/redux';
import { format, getQuarter, subQuarters } from 'date-fns';
import { getNextTaxEventDate } from '@/constants/tax-event.constants';

const useTaxesCard = () => {
  const { upcomingTaxEvent, expectedTaxes, expectedTaxesQuarterDate } =
    useSelector(statisticSelector);

  const targetDate = upcomingTaxEvent?.targetDate
    ? new Date(upcomingTaxEvent.targetDate)
    : getNextTaxEventDate();
  const targetDateStr = format(targetDate, 'dd.MM.yyyy');

  const expectedTargetDate = new Date(expectedTaxesQuarterDate);

  const prevQuarterFromTargetDate = subQuarters(targetDate, 1);

  const previousQuarterString = `Q${getQuarter(
    prevQuarterFromTargetDate,
  )} ${prevQuarterFromTargetDate.getFullYear()}`;

  const helpMessage = `This is 5% of your expected salary for Q${getQuarter(
    expectedTargetDate,
  )} ${expectedTargetDate.getFullYear()}`;

  const preciseTaxAmountUah = upcomingTaxEvent?.amountUah;

  return {
    expectedTaxes,
    targetDateStr,
    preciseTaxAmountUah,
    helpMessage,
    previousQuarterString,
  };
};

export default useTaxesCard;
