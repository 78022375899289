import {
  Card,
  Chip,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Avatar,
  TableRow,
  Paper,
  Button,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { IUser } from '@/interfaces';

import { format, parseISO } from 'date-fns';
import { usersPaginationSelector } from '@/redux';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useCallback } from 'react';
import { roleOptions } from '@/constants/options.constansts';
import UserActionsPopper from '@/components/UserActionsPopper';
import { IUserTableProps } from './types';
import useStyles from '../styles';

const tableHead = [
  { name: 'Full name', align: 'left', numeric: false, id: 'fullName' },
  { name: 'Email', align: 'left', numeric: false, id: 'email' },
  { name: 'Phone number', align: 'left', numeric: false, id: 'phoneNumber' },
  { name: 'Role', align: 'left', numeric: false, id: 'role' },
  { name: 'Overtime %', align: 'center', numeric: false, id: 'overtimePercent' },
  { name: 'Hire Date', align: 'left', numeric: false, id: 'hireDate' },
  { name: 'Contract expiration', align: 'left', numeric: false, id: 'contractExpiration' },
  { name: 'Status', align: 'center', numeric: false, id: 'status' },
] as const;

export default function UsersTable({
  users,
  tableButton,
  toggleModal,
  editUser,
  fetchData,
  toggleUserProjectModal,
  toggleUserContractsModal,
  ...props
}: IUserTableProps): JSX.Element {
  const classes = useStyles();

  const usersPagination = useSelector(usersPaginationSelector);

  const edit = (user: IUser) => {
    editUser(user);
    toggleModal?.(true);
  };

  const attachProject = useCallback(
    (user: IUser) => {
      editUser(user);
      toggleUserProjectModal?.(true);
    },
    [editUser, toggleUserProjectModal],
  );

  const editContracts = useCallback(
    (user: IUser) => {
      editUser(user);
      toggleUserContractsModal?.(true);
    },
    [editUser, toggleUserContractsModal],
  );
  const convertRole = useCallback((role: string) => {
    return roleOptions.find((item) => item.value === role)?.label || '';
  }, []);

  const contractExpirationDate = useCallback((row: IUser) => {
    if (row?.contractExpirationDate) {
      return format(parseISO(row.contractExpirationDate), 'dd.MM.yyyy');
    }

    if (row?.lastContract) {
      return (
        <Typography color="error">
          {format(parseISO(row.lastContract.endAt), 'dd.MM.yyyy')}
        </Typography>
      );
    }

    return row.role === 'Trainee' ? '' : '-';
  }, []);

  return (
    <div className={classes.content} {...props}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        startIcon={tableButton.startIcon}
        onClick={tableButton.action}
      >
        {tableButton.title}
      </Button>
      <Card className={classes.card}>
        <CardContent>
          <InfiniteScroll
            dataLength={users.length}
            hasMore={usersPagination.hasNext}
            loader={null}
            next={fetchData}
          >
            <TableContainer component={Paper} className={classes.tableWrap}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {tableHead.map((el) => (
                      <TableCell key={el.id} align={el.align} className={classes.tableHeader}>
                        {el.name}
                      </TableCell>
                    ))}
                    <TableCell align="center" className={classes.tableHeader}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell className={classes.tableItem}>
                        <div className={classes.tableRowName}>
                          <Avatar
                            alt={row.fullName}
                            src={row.photo || '/src/assets/svg/logo.svg'}
                            className={classes.tableRowAvatar}
                          />
                          {row.fullName}
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableItem}>{row.email}</TableCell>
                      <TableCell className={classes.tableItem}>{row.phoneNumber}</TableCell>
                      <TableCell className={classes.tableItem}>
                        <Chip
                          className={clsx(classes.tableChipRole, {
                            [classes.tableChipRoleWarning]: row.role === 'Trainee',
                          })}
                          label={convertRole(row.role)}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.tableItem}>
                        {row.overTimePercent}%
                      </TableCell>
                      <TableCell className={classes.tableItem}>
                        {!!row.hireDate && format(parseISO(row.hireDate), 'dd.MM.yyyy')}
                      </TableCell>
                      <TableCell className={classes.tableItem}>
                        {contractExpirationDate(row)}
                      </TableCell>
                      <TableCell align="center" className={classes.tableItem}>
                        <Chip
                          className={clsx(classes.tableChip, {
                            [classes.tableChipError]: row.status !== 'Active',
                          })}
                          label={row.status || 'N/A'}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell align="center" className={clsx(classes.tableItem, classes.action)}>
                        <UserActionsPopper
                          user={row}
                          onEditUser={() => edit(row)}
                          onEditContracts={() => editContracts(row)}
                          onAttachProject={() => attachProject(row)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </CardContent>
      </Card>
    </div>
  );
}
