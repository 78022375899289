import { useState, useEffect, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Grid,
  useMediaQuery,
  Theme,
  Typography,
  Card,
  CardContent,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ResultSlider from '@/components/ResultSlider';
import Header from '@/components/Header';
import { ResultList } from '@/components/Result';
import SidebarUsers from '@/components/SidebarUsers';
import {
  clearResults,
  loadResults,
  resultsPaginationSelector,
  paginatedResultsSelector,
} from '@/redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { UserMobileResultCard } from '@/components/Cards';
import InfiniteScroll from 'react-infinite-scroll-component';
import DesktopRangeDatePicker from '@/components/DesktopRangeDatePicker';
import MobileRangeDatePicker from '@/components/MobileRangeDatePicker';
import { getCurrentMonthRangeDatePickerState } from '@/constants';
import { DateRange } from '@mui/lab';
import { dateRangeValidationSchema } from '@/validations';
import useStyles from './styles';

const buttons = [
  { name: 'All', value: null },
  { name: 'Only active', value: 'Active' },
];

export default function UserResult(): JSX.Element {
  const styles = useStyles();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const usersResult = useSelector(paginatedResultsSelector);
  const resultsPagination = useSelector(resultsPaginationSelector);
  const [selectTab, setSelectTab] = useState(buttons[1]);

  const [date, setDate] = useState<DateRange<Date | null>>(getCurrentMonthRangeDatePickerState());
  const [orderBy, setOrderBy] = useState<'hireDate' | 'firstName' | 'lastName'>('hireDate');
  const fetchResults = useCallback(async () => {
    const dateRange = { from: date[0], to: date[1] };
    const isValid = await dateRangeValidationSchema.isValid(dateRange);
    if (!isValid) {
      return;
    }
    dispatch(
      loadResults({
        ...(selectTab.value && { type: selectTab.value }),
        date: dateRange,
        limit: 100,
        orderBy,
      }),
    );
  }, [date, dispatch, selectTab.value, orderBy]);

  useEffect(() => {
    dispatch(clearResults());
    fetchResults();
  }, [dispatch, fetchResults]);

  useEffect(() => {
    return () => {
      dispatch(clearResults());
    };
  }, [dispatch]);

  return (
    <Grid container spacing={isDesktop ? 3 : 0}>
      {isDesktop ? (
        <>
          <Grid item xs={12} md={9}>
            <Header title="Results">
              <ButtonGroup color="primary" aria-label="button group">
                {buttons.map((button) => (
                  <Button
                    key={button.name}
                    className={styles.button}
                    variant={selectTab === button ? 'contained' : 'outlined'}
                    onClick={() => setSelectTab(button)}
                  >
                    {button.name}
                  </Button>
                ))}
              </ButtonGroup>
            </Header>
            <Card>
              <CardContent className={styles.contentCard}>
                <div className={styles.datePicker}>
                  <DesktopRangeDatePicker date={date} setDate={setDate} />
                  <div className={styles.sort}>
                    <TextField
                      label="Sort"
                      variant="outlined"
                      value={orderBy}
                      className={styles.sortInput}
                      select
                      onChange={(e) =>
                        setOrderBy(e.target.value as 'hireDate' | 'firstName' | 'lastName')
                      }
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        },
                      }}
                    >
                      <MenuItem value="hireDate">Hire Date</MenuItem>
                      <MenuItem value="firstName">First Name</MenuItem>
                      <MenuItem value="lastName">Last Name</MenuItem>
                    </TextField>
                  </div>
                </div>
                <ResultList fetchData={fetchResults} usersResult={usersResult} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <SidebarUsers linkTo="results" />
          </Grid>
        </>
      ) : (
        <>
          <Header title="Results" />
          <div className={styles.datePickerBlock}>
            <Typography className={styles.titleDatePicker}>Time Period</Typography>
            <MobileRangeDatePicker date={date} setDate={setDate} />
            <div className={styles.sort}>
              <TextField
                label="Sort"
                variant="outlined"
                value={orderBy}
                className={styles.sortInput}
                select
                fullWidth
                onChange={(e) =>
                  setOrderBy(e.target.value as 'hireDate' | 'firstName' | 'lastName')
                }
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  },
                }}
              >
                <MenuItem value="hireDate">Hire Date</MenuItem>
                <MenuItem value="firstName">First Name</MenuItem>
                <MenuItem value="lastName">Last Name</MenuItem>
              </TextField>
            </div>
          </div>
          <div className={styles.slider}>
            <Typography className={styles.total} variant="body1" component="h5">
              Total
            </Typography>
            <ResultSlider
              time={usersResult?.totalHours ?? '0.0'}
              salary={usersResult ? +usersResult.totalSalary : 0}
            />
          </div>
          {usersResult && (
            <div className={styles.userBlock}>
              <InfiniteScroll
                dataLength={usersResult.results.length}
                hasMore={resultsPagination.hasNext}
                loader={null}
                next={fetchResults}
              >
                {usersResult.results.map((result) => (
                  <UserMobileResultCard key={result.id} result={result} />
                ))}
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
    </Grid>
  );
}
