import React, { useState, useCallback, useMemo, ChangeEvent } from 'react';
import {
  Grid,
  useMediaQuery,
  Theme,
  Switch,
  FormControlLabel,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  usersSelector,
  usersPaginationSelector,
  usersFiltersSelector,
  storeUserFilters,
} from '@/redux';
import { UsersTable } from '@/components/Tables';
import AddIcon from '@material-ui/icons/Add';
import { UserModal } from '@/components/Modals';
import { UserAccordion } from '@/components/Accordions';
import Header from '@/components/Header';
import { IUser } from '@/interfaces';
import { UserDialog, AttachProjectDialog } from '@/components/MobileDialogs';
import InfiniteScroll from 'react-infinite-scroll-component';
import useFetchUsers from '@/hooks/useFetchUsers';
import SetProjectForUserModal from '@/components/Modals/SetProjectForUserModal';
import ContractsModal from '@/components/Modals/ContractsModal';
import { roleOptions } from '@/constants/options.constansts';
import useStyles from './styles';

const Users: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [openContractsDialog, setOpenContractsDialog] = useState(false);

  const [searchUserName, setUserSearchName] = useState('');
  const [searchProjectName, setProjectSearchName] = useState('');
  const [showActiveOnly, setShowActiveOnly] = useState(true);

  const handleUserDialogClose = () => {
    setOpenUserDialog(false);
  };

  const [currentUser, setCurrentUser] = useState<IUser | null>(null);

  const handleCreateUserDialogOpen = () => {
    setCurrentUser(null);
    setOpenUserDialog(true);
  };

  const users = useSelector(usersSelector);
  const usersPagination = useSelector(usersPaginationSelector);
  const userFilters = useSelector(usersFiltersSelector);
  const [openModal, setOpenModal] = useState(false);
  const [editUser, setEditUser] = useState<IUser | null>(null);
  const [openUserProjectModal, setOpenUserProjectModal] = useState(false);

  const handleUserProjectModalClose = useCallback(() => {
    setOpenUserProjectModal(false);
    setEditUser(null);
  }, []);

  const handleProjectDialogClose = () => {
    setOpenProjectDialog(false);
    setEditUser(null);
  };

  const addUsers = () => {
    setOpenModal(true);
  };
  const handleShowActiveOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowActiveOnly(event.target.checked);
  };

  const handleSortChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const orderBy = e.target.value as
        | 'hireDate'
        | 'firstName'
        | 'lastName'
        | 'contractExpirationDate';
      dispatch(storeUserFilters({ orderBy, limit: 100 }));
    },
    [dispatch],
  );

  const handleRolesChange = useCallback(
    (event: ChangeEvent<{ name?: string; value: unknown | string[] }>) => {
      const { value } = event.target as { name?: string; value: string[] };
      dispatch(
        storeUserFilters({
          roles: Array.isArray(value) ? value : [value],
          limit: 100,
        }),
      );
    },
    [dispatch],
  );

  const fetchUsers = useFetchUsers(searchUserName, {
    limit: 100,
    status: showActiveOnly ? 'Active' : undefined,
    ...userFilters,
  });

  const intermediate = useMemo(() => {
    return (
      <div className={classes.intermediateWrap}>
        <FormControlLabel
          control={
            <Switch
              checked={showActiveOnly}
              onChange={handleShowActiveOnlyChange}
              color="primary"
            />
          }
          label="Show active users only"
        />
        <div>
          <TextField
            label="Sort"
            variant="outlined"
            value={userFilters.orderBy}
            className={classes.sortInput}
            select
            onChange={handleSortChange}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              },
            }}
          >
            <MenuItem value="hireDate">Hire Date</MenuItem>
            <MenuItem value="firstName">First Name</MenuItem>
            <MenuItem value="lastName">Last Name</MenuItem>
            <MenuItem value="contractExpirationDate">Contract expiration</MenuItem>
          </TextField>
        </div>
        <div>
          <FormControl variant="outlined" className={classes.rolesControl}>
            <InputLabel id="filter-by-roles-label">Filter by roles</InputLabel>
            <Select
              labelId="filter-by-roles-label"
              input={<OutlinedInput id="filter-by-roles" label="Filter by roles" />}
              value={userFilters.roles}
              className={classes.rolesInput}
              multiple
              onChange={handleRolesChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {roleOptions.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }, [
    classes.intermediateWrap,
    classes.rolesInput,
    classes.rolesControl,
    classes.sortInput,
    handleRolesChange,
    handleSortChange,
    showActiveOnly,
    userFilters.orderBy,
    userFilters.roles,
  ]);

  return (
    <Grid container>
      {isDesktop ? (
        <Grid item xs={12}>
          <Header
            title="Users"
            total={usersPagination.total}
            search={{ searchValue: searchUserName, handleSearch: setUserSearchName }}
            intermediateContent={intermediate}
          />
          <UsersTable
            users={users}
            editUser={setEditUser}
            fetchData={fetchUsers}
            toggleModal={setOpenModal}
            toggleUserProjectModal={isDesktop ? setOpenUserProjectModal : setOpenProjectDialog}
            toggleUserContractsModal={setOpenContractsDialog}
            tableButton={{ title: 'Add', startIcon: <AddIcon />, action: addUsers }}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Header
            mobileButton={{
              title: 'Add',
              startIcon: <AddIcon />,
              action: handleCreateUserDialogOpen,
            }}
            title="Users"
            total={usersPagination.total}
            search={{ searchValue: searchUserName, handleSearch: setUserSearchName }}
            intermediateContent={intermediate}
          />
          <InfiniteScroll
            dataLength={users.length}
            loader={null}
            hasMore={usersPagination.hasNext}
            next={fetchUsers}
          >
            {users.map((user) => (
              <UserAccordion
                editUser={setCurrentUser}
                toggleModal={setOpenUserDialog}
                toggleUserProjectModal={setOpenProjectDialog}
                key={user.id}
                user={user}
              />
            ))}
          </InfiniteScroll>
        </Grid>
      )}
      <UserDialog
        openDialog={openUserDialog}
        handleEditModalClose={handleUserDialogClose}
        editUser={currentUser}
        fetchUsers={fetchUsers}
      />
      <UserModal
        open={openModal}
        setEditUser={setEditUser}
        closeModalHandler={setOpenModal}
        editUser={users.find((user) => user.id === editUser?.id) ?? null}
        fetchUsers={fetchUsers}
      />
      <SetProjectForUserModal
        open={openUserProjectModal}
        user={editUser ?? users[0]}
        searchName={searchProjectName}
        setSearchName={setProjectSearchName}
        handleClose={handleUserProjectModalClose}
      />
      <AttachProjectDialog
        handleDialogClose={handleProjectDialogClose}
        setSearchName={setProjectSearchName}
        searchName={searchProjectName}
        user={editUser ?? users[0]}
        openDialog={openProjectDialog}
      />
      <ContractsModal
        userId={editUser?.id}
        open={openContractsDialog}
        closeModalHandler={setOpenContractsDialog}
      />
    </Grid>
  );
};

export default Users;
