import Login from '@/views/Login';
import { IRoutes } from '@/types/router';
import ForgotPassword from '@/views/ForgotPassword';
import Dashboard from '@/views/User/Dashboard';
import Reports from '@/views/User/Reports';
import Profile from '@/views/User/Profile';
import AdminProfile from '@/views/Admin/Profile';
import UserTimeOff from '@/views/User/TimeOff';
import Users from '@/views/Admin/Users';
import Results from '@/views/Admin/Results';
import UserResult from '@/views/Admin/UserResult';
import Projects from '@/views/Admin/Projects';
import Project from '@/views/Admin/Project';
import AdminTimeOff from '@/views/Admin/TimeOff';
import ResetPassword from '@/views/ResetPassword';
import Notification from '@/views/Notification';
import DevimaWiki from '@/views/DevimaWiki';

import {
  PeopleOutlineOutlined,
  Dashboard as DashboardIcon,
  Description,
  TrendingUp,
  CardTravel,
  EventNote,
  LiveHelp,
} from '@material-ui/icons';
import { userRoles } from '@/constants';

const routes: IRoutes[] = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    exact: true,
    isUnauthorized: true,
    isPublic: true,
    isSidebar: false,
    permissions: [],
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: ForgotPassword,
    exact: true,
    isUnauthorized: true,
    isPublic: true,
    isSidebar: false,
    permissions: [],
  },
  {
    path: '/',
    name: 'Users',
    component: Users,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: true,
    icon: PeopleOutlineOutlined,
    permissions: [userRoles.admin],
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: true,
    icon: CardTravel,
    permissions: [userRoles.admin],
  },
  {
    path: '/results',
    name: 'Results',
    component: Results,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: true,
    icon: TrendingUp,
    permissions: [userRoles.admin],
  },
  {
    path: '/results/:id',
    name: 'Results',
    component: UserResult,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: false,
    icon: TrendingUp,
    permissions: [userRoles.admin],
  },
  {
    path: '/time-off',
    name: 'Time off',
    component: AdminTimeOff,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: true,
    icon: EventNote,
    permissions: [userRoles.admin],
  },
  {
    path: '/time-off/:id',
    name: 'Time off',
    component: AdminTimeOff,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: false,
    permissions: [userRoles.admin],
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: true,
    icon: DashboardIcon,
    permissions: [userRoles.user, userRoles.trainee],
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: true,
    icon: Description,
    permissions: [userRoles.user, userRoles.trainee],
  },
  {
    path: '/time-off',
    name: 'Time off',
    component: UserTimeOff,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: true,
    icon: EventNote,
    permissions: [userRoles.user, userRoles.trainee],
  },
  {
    path: '/reset/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    exact: true,
    isUnauthorized: true,
    isPublic: true,
    isSidebar: false,
    permissions: [],
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: Project,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: false,
    permissions: [userRoles.admin],
  },
  {
    path: '/project/:id/user/:userId',
    name: 'Project',
    component: Project,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: false,
    permissions: [userRoles.admin],
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: false,
    permissions: [userRoles.user, userRoles.trainee],
  },
  {
    path: '/profile',
    name: 'AdminProfile',
    component: AdminProfile,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: false,
    permissions: [userRoles.admin],
  },
  {
    path: '/notification',
    name: 'Notification',
    component: Notification,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: false,
    permissions: [userRoles.user, userRoles.trainee],
  },
  {
    path: '/notification',
    name: 'Notification',
    component: Notification,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: false,
    permissions: [userRoles.admin],
  },
  {
    path: '/wiki',
    name: 'wiki',
    component: DevimaWiki,
    exact: true,
    isUnauthorized: false,
    isPublic: false,
    isSidebar: true,
    icon: LiveHelp,
    permissions: [userRoles.user, userRoles.trainee, userRoles.admin],
  },
];

export default routes;
