import { RangeModifier } from 'react-day-picker';
import {
  IEvent,
  LoadVacationsAction,
  StoreEventsAction,
  SetLoadingEventsAction,
  LoadPendingVacationsAction,
  StorePendingVacationsAction,
  IPendingVacation,
  ApproveVacationAction,
  RejectVacationAction,
  ResolveVacationAction,
  LoadUserEventsAction,
  CreateVacationAction,
  CreateEventAction,
  LoadUserVacationsAction,
  StoreVacationsAction,
  AllEventsStoreAction,
  LoadAllEventsAction,
  LoadVacationsDetailsAction,
  StoreVacationDetailsAction,
  IVacationDetails,
  LoadVacationsByUserAction,
  ILoadEvents,
  LoadUserVacationsDetailsAction,
  LoadNextEventsAction,
  StoreNextEventsAction,
  ICreateVacation,
  IEventAction,
  EditEventAction,
  RemoveEventAction,
  IEditEvent,
  ILoadUserEvents,
} from './types';
import {
  LOAD_VACATIONS,
  LOAD_USER_EVENTS,
  SET_LOADING_EVENTS,
  STORE_EVENTS,
  STORE_VACATIONS,
  LOAD_PENDING_VACATIONS,
  STORE_PENDING_VACATIONS,
  APPROVE_VACATION,
  REJECT_VACATION,
  RESOLVE_VACATION,
  CREATE_VACATION,
  LOAD_USER_VACATIONS,
  ALL_EVENTS_STORE,
  LOAD_ALL_EVENTS,
  LOAD_VACATIONS_DETAILS,
  STORE_VACATIONS_DETAILS,
  CREATE_EVENT,
  LOAD_VACATIONS_BY_USER,
  LOAD_USER_VACATIONS_DETAILS,
  LOAD_NEXT_EVENTS,
  STORE_NEXT_EVENTS,
  EDIT_EVENT,
  REMOVE_EVENT,
} from './actionTypes';

export function setLoadingEvents(isLoading: boolean): SetLoadingEventsAction {
  return {
    type: SET_LOADING_EVENTS,
    payload: isLoading,
  };
}

export function loadVacationsDetails(payload: ILoadUserEvents): LoadVacationsDetailsAction {
  return {
    type: LOAD_VACATIONS_DETAILS,
    payload,
  };
}

export function loadUserVacationsDetails(payload?: RangeModifier): LoadUserVacationsDetailsAction {
  return {
    type: LOAD_USER_VACATIONS_DETAILS,
    payload,
  };
}

export function loadUserVacations(date?: RangeModifier): LoadUserVacationsAction {
  return {
    type: LOAD_USER_VACATIONS,
    payload: date,
  };
}

export function loadVacationsByUser(payload: ILoadUserEvents): LoadVacationsByUserAction {
  return {
    type: LOAD_VACATIONS_BY_USER,
    payload,
  };
}

export function loadNextEvents(): LoadNextEventsAction {
  return {
    type: LOAD_NEXT_EVENTS,
  };
}

export function loadAllEvents(date: ILoadEvents): LoadAllEventsAction {
  return {
    type: LOAD_ALL_EVENTS,
    payload: date,
  };
}

export function createVacation(data: ICreateVacation): CreateVacationAction {
  return {
    type: CREATE_VACATION,
    payload: data,
  };
}

export function createEvent(data: IEditEvent): CreateEventAction {
  return {
    type: CREATE_EVENT,
    payload: data,
  };
}

export function editEvent(data: IEditEvent): EditEventAction {
  return {
    type: EDIT_EVENT,
    payload: data,
  };
}

export function removeEvent(data: IEventAction): RemoveEventAction {
  return {
    type: REMOVE_EVENT,
    payload: data,
  };
}

export function loadVacations(date?: RangeModifier): LoadVacationsAction {
  return {
    type: LOAD_VACATIONS,
    payload: date,
  };
}

export function loadUserEvents(date?: RangeModifier): LoadUserEventsAction {
  return {
    type: LOAD_USER_EVENTS,
    payload: date,
  };
}

export function allEventsStore(payload: Record<string, IEvent[]>): AllEventsStoreAction {
  return {
    type: ALL_EVENTS_STORE,
    payload,
  };
}

export function storeEvents(payload: Record<string, IEvent[]>): StoreEventsAction {
  return {
    type: STORE_EVENTS,
    payload,
  };
}

export function storeNextEvents(payload: IEvent[]): StoreNextEventsAction {
  return {
    type: STORE_NEXT_EVENTS,
    payload,
  };
}

export function storeVacationDetails(payload: IVacationDetails[]): StoreVacationDetailsAction {
  return {
    type: STORE_VACATIONS_DETAILS,
    payload,
  };
}

export function storeVacations(payload: Record<string, IEvent[]>): StoreVacationsAction {
  return {
    type: STORE_VACATIONS,
    payload,
  };
}

export function loadPendingVacations(): LoadPendingVacationsAction {
  return {
    type: LOAD_PENDING_VACATIONS,
  };
}

export function storePendingVacations(payload: IPendingVacation[]): StorePendingVacationsAction {
  return {
    type: STORE_PENDING_VACATIONS,
    payload,
  };
}

export function rejectVacation(payload: IEventAction): RejectVacationAction {
  return {
    type: REJECT_VACATION,
    payload,
  };
}

export function approveVacation(payload: IEventAction): ApproveVacationAction {
  return {
    type: APPROVE_VACATION,
    payload,
  };
}

export function resolveVacation(payload: IEvent): ResolveVacationAction {
  return {
    type: RESOLVE_VACATION,
    payload,
  };
}
