import { date, object } from 'yup';

const dateRangeValidationSchema = object({
  from: date()
    .test('min', '', (val) => !!val && new Date(2000, 1, 1) < val)
    // min and max is crashing on Safari
    // Change when https://github.com/jquense/yup/issues/731 will be resolved
    .test('max', '', (val) => !!val && new Date(3000, 1, 1) > val)
    .required(),
  to: date()
    .test('min', '', (val) => !!val && new Date(2000, 1, 1) < val)
    .test('max', '', (val) => !!val && new Date(3000, 1, 1) > val)
    .when('from', (from, yup) => from && yup.min(from))
    .required(),
});

export default dateRangeValidationSchema;
