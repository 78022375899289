import { ChangeEvent, useMemo, useState } from 'react';
import {
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  Theme,
  Button,
} from '@material-ui/core';
import { SearchIcon } from '@/components/Icons';
import clsx from 'clsx';
import { debounce } from 'throttle-debounce';
import useStyles from './styles';
import { IHeaderProps } from './types';

function Header({
  mobileButton,
  title,
  total,
  search = undefined,
  children,
  intermediateContent,
  className,
}: IHeaderProps) {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [searchValue, setSearchValue] = useState('');

  const debounceSearch = debounce(500, (e) => search?.handleSearch(e.target.value));

  const onSearchNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    debounceSearch(e);
  };

  const renderTotal = useMemo(() => {
    if (!total) {
      return <div className={classes.totalPlaceholder} />;
    }
    return isDesktop ? (
      <>
        <span className={classes.line} />
        <Typography className={classes.total} variant="h1" component="h1">
          Total {total}
        </Typography>
      </>
    ) : (
      <Typography className={classes.total} variant="h1" component="h1">
        ({total})
      </Typography>
    );
  }, [total, isDesktop, classes.line, classes.total, classes.totalPlaceholder]);
  return (
    <div className={clsx(classes.header, className)}>
      <div className={classes.infoBlock}>
        <div className={classes.titleWrap}>
          <div className={classes.titleBox}>
            <Typography className={classes.title} variant="h1" component="h1">
              {title}
            </Typography>
            {renderTotal}
          </div>

          {!isDesktop && <div>{intermediateContent}</div>}
        </div>
        {!isDesktop && mobileButton && (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            fullWidth
            startIcon={mobileButton?.startIcon}
            onClick={mobileButton?.action}
          >
            {mobileButton?.title}
          </Button>
        )}
      </div>
      {isDesktop && intermediateContent}
      {search && (
        <TextField
          value={searchValue}
          onChange={onSearchNameChange}
          variant="outlined"
          name="search"
          className={classes.search}
          placeholder="Search"
          type="search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      )}

      {children}
    </div>
  );
}

export default Header;
