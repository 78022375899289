import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Avatar,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { DateFormatUtil } from '@/utils';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { blurSelector, userSelector } from '@/redux';
import { getQuarter, isPast, subMonths } from 'date-fns';
import { EditIcon, TrashIcon } from '@/components/Icons';
import { ITaxesTableProps } from './types';
import useStyles from './styles';

export interface ITaxesTableHeadCell {
  name: string;
  align: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  numeric: boolean;
  id: string;
  allowedForRoles?: string[];
}

const tableHead = [
  { name: 'User', align: 'center', numeric: true, id: 'user' },
  { name: 'Date', align: 'left', numeric: false, id: 'createdAt' },
  { name: 'Taxes', align: 'left', numeric: true, id: 'taxes' },
  { name: 'Status', align: 'left', numeric: false, id: 'status' },
  { name: 'Actions', align: 'right', numeric: false, id: 'actions' },
] as ITaxesTableHeadCell[];

export default function TaxesTable({
  taxData,
  user,
  onEdit,
  onDelete,
}: ITaxesTableProps): JSX.Element {
  const classes = useStyles();
  const showBlur = useSelector(blurSelector);
  const authorizedUser = useSelector(userSelector);

  return (
    <TableContainer component={Paper} className={classes.tableWrap}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHead.map((el) =>
              el.allowedForRoles?.length &&
              !el.allowedForRoles.includes(authorizedUser?.role ?? '') ? null : (
                <TableCell key={el.id} align={el.align} className={classes.tableHeader}>
                  {el.name}
                </TableCell>
              ),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {taxData &&
            taxData.map((tax) => (
              <TableRow key={tax.targetDate}>
                <TableCell key={tax.targetDate} scope="row" className={classes.tableItem}>
                  <div className={classes.userBlock}>
                    <Avatar
                      alt={user?.fullName}
                      src={user?.photo ?? '/src/assets/svg/logo.svg'}
                      className={classes.tableRowAvatar}
                    />
                    {user?.fullName}
                  </div>
                </TableCell>
                <TableCell className={classes.tableItem}>
                  {DateFormatUtil.formatToDate(tax.targetDate)}&nbsp; Q(
                  {getQuarter(subMonths(new Date(tax.targetDate), 2))})
                </TableCell>
                <TableCell className={clsx(classes.tableItem, { [classes.blurred]: showBlur })}>
                  {Number(tax.amountUah).toFixed(2)} UAH
                </TableCell>
                <TableCell className={classes.tableItem}>
                  <Typography
                    className={clsx(
                      classes.task,
                      !isPast(new Date(tax.targetDate)) && classes.bugFixing,
                    )}
                  >
                    {isPast(new Date(tax.targetDate)) ? 'Past' : 'Upcoming'}
                  </Typography>
                </TableCell>
                <TableCell align="right" className={clsx(classes.tableItem)}>
                  <Tooltip title="Edit tax?">
                    <IconButton onClick={() => onEdit?.(tax)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete tax">
                    <IconButton onClick={() => onDelete?.(tax)}>
                      <TrashIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
