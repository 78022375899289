import { IAuthUser } from '@/types/user';
import { IResetPassword } from '@/views/ResetPassword/types';
import {
  AuthorizationAction,
  AUTHORIZE,
  LOG_OUT,
  SendForgotPasswordAction,
  SEND_FORGOT_PASSWORD,
  ResetPasswordAction,
  RESET_PASSWORD,
  EDIT_USER_PROFILE,
  USER_STORE,
  SET_LOADING_USER,
  LOAD_USER_PROFILE,
  CHANGE_PASSWORD,
  CHANGE_PHOTO,
  EditUserProfileAction,
  UserStoreAction,
  SetLoadingUserAction,
  SetChangePasswordLoadingAction,
  UserLoadAction,
  SET_CHANGE_PASSWORD_LOADING,
  ChangePasswordAction,
  ChangePhotoAction,
} from '@/redux/authorization';
import { IUserChangePassword, IUserProfile } from '@/interfaces';

export const authorized = (user: IAuthUser): AuthorizationAction => {
  return {
    type: AUTHORIZE,
    payload: user,
  };
};

export const logOut = (): AuthorizationAction => {
  return {
    type: LOG_OUT,
  };
};

export const sendForgotPassword = (email: string): SendForgotPasswordAction => {
  return {
    type: SEND_FORGOT_PASSWORD,
    payload: email,
  };
};

export const resetPassword = (data: IResetPassword): ResetPasswordAction => {
  return {
    type: RESET_PASSWORD,
    payload: data,
  };
};

export function editUserProfile(user: IUserProfile): EditUserProfileAction {
  return {
    type: EDIT_USER_PROFILE,
    payload: user,
  };
}

export function userStore(user: IAuthUser): UserStoreAction {
  return {
    type: USER_STORE,
    payload: user,
  };
}

export function loadUserProfile(): UserLoadAction {
  return {
    type: LOAD_USER_PROFILE,
  };
}

export function setLoadingUser(isLoading: boolean): SetLoadingUserAction {
  return {
    type: SET_LOADING_USER,
    payload: isLoading,
  };
}

export function changePhoto(values: File): ChangePhotoAction {
  return {
    type: CHANGE_PHOTO,
    payload: values,
  };
}

export function setChangePasswordLoading(
  isLoadingChangePassword: boolean,
): SetChangePasswordLoadingAction {
  return {
    type: SET_CHANGE_PASSWORD_LOADING,
    payload: isLoadingChangePassword,
  };
}

export function changeUserPassword(values: IUserChangePassword): ChangePasswordAction {
  return {
    type: CHANGE_PASSWORD,
    payload: values,
  };
}
