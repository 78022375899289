import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '28px',
      lineHeight: '38px',
      marginBottom: '42px',
    },
    content: {
      paddingBottom: '20px',
    },
    secondTitle: {
      marginTop: '44px',
      fontSize: '18px',
      lineHeight: '25px',
      marginBottom: '18px',
      fontWeight: 500,
    },
    card: {
      boxShadow: '3px 0px 15px rgba(78, 81, 97, 0.14)',
      padding: '21px 22px',
      width: '100%',
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'baseline',
    },
    headerStyle: {
      marginBottom: 0,
      width: 'fit-content',
    },
    headerButton: {
      textTransform: 'none',
      fontSize: '13px',
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
    infoBlock: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logOut: {
      marginTop: '25px',
      textTransform: 'none',
      background: theme.palette.error.main,
      width: '172px',
      height: '39px',
      color: theme.palette.common.white,
    },
  }),
);

export default useStyles;
