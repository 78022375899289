import {
  STORE_NOTIFICATIONS,
  INotificationsState,
  NotificationAction,
  STORE_COUNT_NEW_NOTIFICATIONS,
  STORE_NEW_NOTIFICATIONS,
} from '@/redux/notifications';

const initialState: INotificationsState = {
  isLoading: false,
  newNotificationsCount: {
    count: 0,
  },
  notifications: [],
  newNotifications: [],
  shortNotifications: [],
};

function notificationsReducer(
  state: INotificationsState = initialState,
  action: NotificationAction,
): INotificationsState {
  switch (action.type) {
    case STORE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case STORE_NEW_NOTIFICATIONS:
      return {
        ...state,
        newNotifications: action.payload,
        shortNotifications: action.payload.slice(
          Math.max(action.payload.length - state.newNotificationsCount.count, 1),
        ),
        newNotificationsCount: {
          count: 0,
        },
      };
    case STORE_COUNT_NEW_NOTIFICATIONS:
      return {
        ...state,
        newNotificationsCount: action.payload,
      };
    default:
      return state;
  }
}

export default notificationsReducer;
