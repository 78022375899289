import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    value: {
      fontWeight: 500,
      fontSize: 32,
      lineHeight: '40px',
      letterSpacing: '0.16px',
    },
    sup: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '25px',
      letterSpacing: '0.114286px',
      color: '#AFA8DE',
      marginRight: 2,
    },
  }),
);

export default useStyles;
