import { enGBLocale } from '@/constants';
import { Box } from '@material-ui/core';
import { LocalizationProvider, MobileDateRangePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import useStyles from './styles';
import { RangeDatePickerProps } from './types';

export default function RangeDatePicker({
  date,
  setDate,
  ...rest
}: RangeDatePickerProps): JSX.Element {
  const classes = useStyles();
  return (
    <LocalizationProvider locale={enGBLocale} dateAdapter={AdapterDateFns}>
      <MobileDateRangePicker<Date | null>
        {...rest}
        value={date}
        className={classes.paperContent}
        DialogProps={{ className: classes.dialog }}
        onChange={setDate}
        inputFormat="dd.MM.yyyy"
        mask="__.__.____"
        renderInput={(startProps, endProps) => (
          <>
            <TextField {...startProps} className={classes.dateTextField} label="Start" />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} className={classes.dateTextField} />
          </>
        )}
      />
    </LocalizationProvider>
  );
}
