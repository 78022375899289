export const duration = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];

export interface IDuration {
  message: string;
  priority: number;
  value: number;
}

export const durationMassage: Record<string, Pick<IDuration, 'message' | 'priority'>> = {
  years: {
    message: 'year ago',
    priority: 6,
  },
  months: {
    message: 'months ago',
    priority: 5,
  },
  weeks: {
    message: 'weeks ago',
    priority: 4,
  },
  days: {
    message: 'days ago',
    priority: 3,
  },
  hours: {
    message: 'hours ago',
    priority: 2,
  },
  minutes: {
    message: 'minutes ago',
    priority: 1,
  },
  seconds: {
    message: 'seconds ago',
    priority: 0,
  },
};
