import { BackArrow, EditIcon } from '@/components/Icons';
import { DateFormatUtil, formatMinutesToHoursPeriod } from '@/utils';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { differenceInBusinessDays, differenceInCalendarDays } from 'date-fns';
import { ChangeEvent, useCallback, useState } from 'react';
import useStyles from '../styles';
import { IUserResultAccordionProps } from '../types';

const UserResultAccordion = ({
  report,
  setReport,
  user,
  isUser,
  dateFilteringEnabled,
}: IUserResultAccordionProps) => {
  const [expanded, setExpanded] = useState<string | null>(null);
  const classes = useStyles();

  const handleChange = useCallback(
    (panel: string) => (event: ChangeEvent<unknown>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : null);
    },
    [],
  );

  const currentDate = new Date();
  const reportDate = new Date(report.workedDay);
  const businessDiff = differenceInBusinessDays(reportDate, currentDate);
  const calendarDiff = differenceInCalendarDays(reportDate, currentDate);
  const isEditable =
    calendarDiff <= 0 && businessDiff > -3 && reportDate.getMonth() === currentDate.getMonth();

  return (
    <div>
      <Accordion
        className={classes.accordionStyle}
        square
        expanded={expanded === report.id}
        onChange={handleChange(report.id)}
      >
        <AccordionSummary expandIcon={<BackArrow className={classes.arrow} />} id={user?.id}>
          <div className={classes.userBlock}>
            <div className={classes.titleField}>
              <Typography className={classes.name} component="span">
                {DateFormatUtil.formatDate(report.workedDay)}
              </Typography>
              {isUser && (isEditable || !dateFilteringEnabled) && (
                <IconButton className={classes.editIcon} color="inherit">
                  <EditIcon onClick={() => setReport?.(report)} />
                </IconButton>
              )}
            </div>
            <div className={classes.reportBlock}>
              <Typography className={clsx(classes.task, classes.development)}>
                {report.taskType}
              </Typography>
              <Typography className={classes.reportInfo}>
                {formatMinutesToHoursPeriod(report.workedMinutes)} h
              </Typography>
              <Typography className={classes.reportInfo}>{report.salary}</Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.userInfoRow}>
            <Typography className={classes.infoText}>{report.description}</Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider className={classes.divider} />
    </div>
  );
};

export default UserResultAccordion;
