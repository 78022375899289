import { FormControlLabel, Checkbox } from '@material-ui/core';
import { ICheckBoxProps } from '../types';

export const CheckboxButton = ({
  label,
  checked,
  onChange,
  ...props
}: ICheckBoxProps): JSX.Element => {
  return (
    <FormControlLabel
      control={<Checkbox color="primary" onChange={onChange} checked={checked} {...props} />}
      label={label}
    />
  );
};

export default CheckboxButton;
