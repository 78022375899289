export function formatMinutesToHoursPeriod(minutes: number) {
  const hrs = Math.floor(minutes / 60);
  let mins = (minutes % 60).toString();

  if (mins.length === 1) {
    mins += '0';
  }

  return `${hrs}:${mins}`;
}
