import { VacationType, VacationPaymentType } from '@/types/vacation';
import { useCallback } from 'react';
import { ICheckboxGroupProps } from './types';

const useCheckboxGroup = ({ onChange, name }: Omit<ICheckboxGroupProps, 'options'>) => {
  const handleChange = useCallback(
    (val) => () => {
      onChange(name, val);
      onChange(
        'paymentType',
        val === VacationType.DayOff ? VacationPaymentType.SelfPaid : VacationPaymentType.Paid,
      );
    },
    [onChange, name],
  );

  return {
    handleChange,
  };
};

export default useCheckboxGroup;
