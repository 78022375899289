import { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { blurSelector, loadOverallMinutes, loadUserOverallMinutes, usersSelector } from '@/redux';
import { Periods } from '@/types/periods';
import api from '@/services/api';
import { IDisplayHours, IFormatFromPeriod, IHours, IOverallMinutes } from '@/interfaces';
import { DateFormatUtil } from '@/utils';
import dateUtils from '@/utils/dates';
import { userRoles } from '@/constants';

const getTime = (data: string | number) => {
  const time = typeof data === 'string' ? parseInt(data, 10) : data;

  if (!time || time <= 0) {
    return 0;
  }

  const hours = dateUtils.formattedTime(Math.floor(time / 60));
  const minutes = dateUtils.formattedTime(time % 60);
  return `${hours}:${minutes}`;
};

const useOverallCard = (
  period: Periods,
  overallMinutes: IOverallMinutes | null,
  userRole: string | undefined,
  year: number,
  month: number,
  isDesktop?: boolean,
) => {
  const dispatch = useDispatch();

  const router = useRouteMatch<{ id: string }>();
  const users = useSelector(usersSelector);

  const currentUser = useMemo(
    () => users.find((user) => user.id === router.params.id) ?? users[0],
    [router.params.id, users],
  );

  const overallHours = useMemo(() => {
    const formatFromPeriod: IFormatFromPeriod[] = [
      {
        period: Periods.Month,
        format: (date: string) => {
          return DateFormatUtil.formatToMonth(date);
        },
      },
      {
        period: Periods.Year,
        format: (date: string) => {
          return DateFormatUtil.formatToYear(date);
        },
      },
      {
        period: Periods.Week,
        format: (date: string) => {
          return isDesktop
            ? DateFormatUtil.formatToWeek(date)
            : DateFormatUtil.formatToShortWeek(date);
        },
      },
    ];

    return overallMinutes
      ? overallMinutes.filterDate.map((el) => {
          return {
            date: formatFromPeriod.find((format) => format.period === period)?.format(el.date),
            workedHours: (parseInt(el.workedMinutes, 10) / 60).toString(),
            isVacation: el.isVacation,
          };
        })
      : [];
  }, [overallMinutes, period, isDesktop]);

  const allMonth = useMemo(() => {
    return overallHours.reduce((acc: IDisplayHours, el: IHours) => {
      if (!el.date) {
        return acc;
      }
      const value = {
        date: el.date,
        workedHours: el.workedHours,
      };
      if (acc[el.date]) {
        acc[el.date].push(value);
        return acc;
      }

      return {
        ...acc,
        [el.date]: [value],
      };
    }, {});
  }, [overallHours]);

  const sumOfMonth = useMemo(() => {
    return Object.entries(allMonth).map(([, hours]) =>
      hours.reduce((acc, item) => {
        return {
          date: item.date,
          workedHours: (parseFloat(acc.workedHours) + parseFloat(item.workedHours)).toString(),
        };
      }),
    );
  }, [allMonth]);

  const data = useMemo(
    () =>
      api.getDateByPeriod(period, !isDesktop, period === 'Year' ? sumOfMonth : overallHours, month),
    [period, isDesktop, sumOfMonth, overallHours, month],
  );

  const totalHours = useMemo(() => {
    const minutes =
      overallMinutes?.filterDate.reduce<number>((sum, prev) => {
        return sum + parseInt(prev.workedMinutes, 10);
      }, 0) ?? 0;

    return getTime(minutes);
  }, [overallMinutes?.filterDate]);

  useEffect(() => {
    if (userRole === userRoles.admin && !currentUser?.id) {
      return;
    }
    const overall = {
      year: year.toString(),
      id: userRole === userRoles.admin ? currentUser?.id : undefined,
      type: period.toLowerCase(),
      month,
    };
    dispatch(
      userRole === userRoles.admin ? loadOverallMinutes(overall) : loadUserOverallMinutes(overall),
    );
  }, [currentUser?.id, dispatch, userRole, period, year, month]);

  const isDashboardVisible = useSelector(blurSelector);

  return {
    data,
    totalHours,
    isDesktop,
    isVisible: !isDashboardVisible,
  };
};

export default useOverallCard;
