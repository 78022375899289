import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function BirthDayIcon({ viewBox = '0, 0, 204, 179', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon classes={classes} viewBox={viewBox} {...rest}>
      <g clipPath="url(#clip0_492:6105)">
        <g opacity="0.5">
          <path opacity="0.5" d="M30.996 31.884H30.1865V36.5296H30.996V31.884Z" fill="#61D8A9" />
          <path
            opacity="0.5"
            d="M32.8848 34.6167V33.7969H28.2977V34.6167H32.8848Z"
            fill="#61D8A9"
          />
        </g>
        <g opacity="0.5">
          <path opacity="0.5" d="M59.7704 132.99H58.9609V137.635H59.7704V132.99Z" fill="#61D8A9" />
          <path
            opacity="0.5"
            d="M61.6567 135.722V134.902H57.0697V135.722H61.6567Z"
            fill="#61D8A9"
          />
        </g>
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M19.4931 79.0271H18.6836V83.6727H19.4931V79.0271Z"
            fill="#61D8A9"
          />
          <path
            opacity="0.5"
            d="M21.3818 81.7598V80.9399H16.7948V81.7598H21.3818Z"
            fill="#61D8A9"
          />
        </g>
        <g opacity="0.5">
          <path opacity="0.5" d="M161.214 0H160.404V4.64561H161.214V0Z" fill="#61D8A9" />
          <path
            opacity="0.5"
            d="M163.103 2.73266V1.91284L158.515 1.91284V2.73266L163.103 2.73266Z"
            fill="#61D8A9"
          />
        </g>
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M24.6249 11.5537H23.8154V16.1993H24.6249V11.5537Z"
            fill="#61D8A9"
          />
          <path
            opacity="0.5"
            d="M26.5112 14.2864V13.4666H21.9241V14.2864H26.5112Z"
            fill="#61D8A9"
          />
        </g>
        <path
          opacity="0.5"
          d="M40.1337 116.411C39.9108 116.312 39.7314 116.134 39.6296 115.911C39.5279 115.687 39.5109 115.433 39.5819 115.197C39.5934 115.161 39.6007 115.124 39.6038 115.086C39.6201 114.975 39.598 114.861 39.5412 114.764C39.4844 114.666 39.3964 114.592 39.2918 114.553C39.1872 114.513 39.0725 114.512 38.9669 114.548C38.8613 114.584 38.7711 114.656 38.7116 114.752C38.6893 114.782 38.6706 114.815 38.6557 114.85C38.5584 115.076 38.3825 115.258 38.1616 115.361C37.9406 115.464 37.69 115.481 37.4573 115.409C37.4218 115.397 37.3851 115.39 37.3479 115.387C37.2376 115.37 37.125 115.393 37.0291 115.45C36.9331 115.508 36.8596 115.597 36.8208 115.703C36.7821 115.809 36.7804 115.925 36.8162 116.032C36.852 116.139 36.923 116.23 37.0173 116.29C37.0475 116.313 37.0801 116.332 37.1145 116.347C37.3375 116.446 37.5169 116.624 37.6186 116.847C37.7204 117.071 37.7374 117.325 37.6664 117.561C37.6549 117.597 37.6476 117.634 37.6445 117.671C37.6282 117.783 37.6502 117.897 37.707 117.994C37.7638 118.092 37.8519 118.166 37.9565 118.205C38.061 118.245 38.1758 118.246 38.2814 118.21C38.387 118.174 38.4771 118.102 38.5366 118.006C38.5589 117.976 38.5777 117.943 38.5925 117.908C38.6899 117.682 38.8657 117.5 39.0867 117.397C39.3076 117.294 39.5583 117.277 39.791 117.349C39.8265 117.361 39.8632 117.368 39.9003 117.371C40.0107 117.388 40.1232 117.365 40.2192 117.308C40.3152 117.25 40.3887 117.161 40.4274 117.055C40.4662 116.949 40.4678 116.833 40.432 116.726C40.3963 116.619 40.3253 116.528 40.2309 116.468C40.2008 116.445 40.1682 116.426 40.1337 116.411Z"
          fill="#93B7FA"
        />
        <path
          opacity="0.5"
          d="M36.123 160.565C35.9 160.467 35.7206 160.289 35.6189 160.065C35.5172 159.841 35.5002 159.587 35.5712 159.352C35.5826 159.316 35.59 159.278 35.593 159.241C35.6094 159.129 35.5873 159.015 35.5305 158.918C35.4737 158.821 35.3856 158.746 35.281 158.707C35.1765 158.668 35.0618 158.666 34.9562 158.702C34.8505 158.739 34.7604 158.81 34.7009 158.906C34.6786 158.936 34.6598 158.97 34.645 159.004C34.5482 159.23 34.3729 159.412 34.1524 159.515C33.9319 159.619 33.6816 159.637 33.449 159.566C33.4135 159.554 33.3768 159.547 33.3396 159.544C33.2293 159.527 33.1167 159.549 33.0208 159.607C32.9248 159.664 32.8513 159.754 32.8125 159.86C32.7738 159.965 32.7721 160.082 32.8079 160.189C32.8437 160.296 32.9147 160.387 33.009 160.447C33.0392 160.47 33.0718 160.489 33.1062 160.504C33.3292 160.602 33.5086 160.78 33.6103 161.004C33.7121 161.228 33.7291 161.482 33.6581 161.717C33.6466 161.753 33.6393 161.791 33.6362 161.828C33.6198 161.94 33.6419 162.054 33.6987 162.151C33.7555 162.248 33.8436 162.323 33.9482 162.362C34.0527 162.401 34.1675 162.403 34.2731 162.367C34.3787 162.33 34.4688 162.259 34.5283 162.163C34.5506 162.133 34.5694 162.099 34.5842 162.065C34.6816 161.839 34.8574 161.657 35.0784 161.554C35.2993 161.451 35.55 161.434 35.7827 161.506C35.8182 161.517 35.8549 161.525 35.892 161.528C36.0024 161.544 36.1149 161.522 36.2109 161.465C36.3069 161.407 36.3804 161.318 36.4191 161.212C36.4579 161.106 36.4595 160.99 36.4237 160.883C36.388 160.776 36.317 160.685 36.2226 160.624C36.1919 160.601 36.1584 160.581 36.123 160.565Z"
          fill="#93B7FA"
        />
        <path
          opacity="0.5"
          d="M183.678 62.8277C183.455 62.7292 183.275 62.5511 183.174 62.3273C183.072 62.1035 183.055 61.8497 183.126 61.614C183.137 61.5781 183.145 61.5409 183.148 61.5032C183.164 61.3915 183.142 61.2775 183.085 61.1803C183.028 61.0831 182.94 61.0086 182.836 60.9694C182.731 60.9302 182.616 60.9285 182.511 60.9647C182.405 61.001 182.315 61.0729 182.256 61.1684C182.233 61.1989 182.215 61.232 182.2 61.2669C182.103 61.4926 181.928 61.6744 181.707 61.7779C181.487 61.8814 181.236 61.8993 181.004 61.8282C180.968 61.8166 180.931 61.8092 180.894 61.806C180.784 61.7895 180.671 61.8119 180.575 61.8694C180.479 61.9269 180.406 62.0161 180.367 62.122C180.328 62.2279 180.327 62.3441 180.363 62.4511C180.398 62.558 180.469 62.6493 180.564 62.7096C180.594 62.7322 180.626 62.7512 180.661 62.7662C180.884 62.8648 181.063 63.0429 181.165 63.2666C181.267 63.4904 181.284 63.7443 181.213 63.9799C181.201 64.0159 181.194 64.053 181.191 64.0907C181.175 64.2024 181.197 64.3164 181.253 64.4136C181.31 64.5108 181.398 64.5853 181.503 64.6245C181.607 64.6638 181.722 64.6654 181.828 64.6292C181.933 64.5929 182.024 64.521 182.083 64.4255C182.105 64.395 182.124 64.3619 182.139 64.327C182.236 64.1012 182.412 63.9196 182.633 63.8165C182.854 63.7135 183.105 63.6963 183.337 63.7682C183.373 63.7798 183.41 63.7872 183.447 63.7903C183.557 63.8069 183.67 63.7845 183.766 63.727C183.862 63.6695 183.935 63.5803 183.974 63.4744C184.013 63.3685 184.014 63.2523 183.978 63.1453C183.943 63.0383 183.872 62.9471 183.777 62.8868C183.747 62.8632 183.713 62.8433 183.678 62.8277Z"
          fill="#93B7FA"
        />
        <path
          opacity="0.5"
          d="M177 109.442C176.777 109.343 176.598 109.165 176.496 108.941C176.394 108.717 176.377 108.463 176.448 108.228C176.46 108.192 176.467 108.155 176.47 108.117C176.486 108.005 176.464 107.891 176.407 107.794C176.351 107.697 176.263 107.622 176.158 107.583C176.053 107.544 175.939 107.542 175.833 107.579C175.727 107.615 175.637 107.687 175.578 107.782C175.556 107.813 175.537 107.846 175.522 107.881C175.425 108.106 175.249 108.288 175.028 108.391C174.807 108.494 174.556 108.511 174.324 108.44C174.288 108.428 174.251 108.42 174.214 108.417C174.104 108.401 173.991 108.423 173.895 108.481C173.799 108.538 173.726 108.627 173.687 108.733C173.648 108.839 173.647 108.955 173.682 109.062C173.718 109.169 173.789 109.261 173.884 109.321C173.914 109.343 173.946 109.362 173.981 109.377C174.204 109.476 174.383 109.654 174.485 109.878C174.587 110.102 174.604 110.356 174.533 110.591C174.521 110.627 174.514 110.664 174.511 110.702C174.494 110.814 174.516 110.928 174.573 111.025C174.63 111.122 174.718 111.197 174.823 111.236C174.927 111.275 175.042 111.277 175.148 111.24C175.253 111.204 175.343 111.132 175.403 111.037C175.425 111.006 175.444 110.973 175.459 110.938C175.556 110.713 175.732 110.531 175.953 110.428C176.174 110.325 176.424 110.308 176.657 110.379C176.693 110.391 176.729 110.399 176.767 110.402C176.877 110.418 176.989 110.396 177.085 110.338C177.181 110.281 177.255 110.192 177.294 110.086C177.332 109.98 177.334 109.864 177.298 109.757C177.262 109.65 177.191 109.558 177.097 109.498C177.067 109.476 177.034 109.457 177 109.442Z"
          fill="#93B7FA"
        />
        <path
          opacity="0.5"
          d="M181.976 16.5465C181.753 16.4479 181.574 16.2698 181.472 16.046C181.37 15.8222 181.353 15.5684 181.424 15.3328C181.436 15.2968 181.443 15.2596 181.446 15.222C181.462 15.1102 181.44 14.9962 181.384 14.899C181.327 14.8019 181.239 14.7274 181.134 14.6882C181.03 14.6489 180.915 14.6473 180.809 14.6835C180.704 14.7197 180.613 14.7916 180.554 14.8872C180.532 14.9177 180.513 14.9507 180.498 14.9856C180.401 15.2114 180.225 15.3931 180.004 15.4961C179.783 15.5992 179.532 15.6164 179.3 15.5445C179.264 15.5329 179.227 15.5254 179.19 15.5223C179.08 15.5058 178.967 15.5281 178.871 15.5857C178.775 15.6432 178.702 15.7324 178.663 15.8383C178.624 15.9442 178.623 16.0604 178.659 16.1674C178.694 16.2743 178.765 16.3656 178.86 16.4258C178.89 16.4485 178.922 16.4675 178.957 16.4825C179.18 16.581 179.359 16.7591 179.461 16.9829C179.563 17.2067 179.58 17.4605 179.509 17.6962C179.497 17.7321 179.49 17.7693 179.487 17.807C179.47 17.9187 179.493 18.0327 179.549 18.1299C179.606 18.2271 179.694 18.3015 179.799 18.3408C179.903 18.38 180.018 18.3817 180.124 18.3454C180.229 18.3092 180.319 18.2373 180.379 18.1418C180.401 18.1113 180.42 18.0782 180.435 18.0433C180.532 17.8175 180.708 17.6358 180.929 17.5328C181.15 17.4298 181.401 17.4126 181.633 17.4845C181.669 17.4961 181.705 17.5035 181.743 17.5066C181.853 17.5232 181.966 17.5008 182.062 17.4433C182.157 17.3857 182.231 17.2965 182.27 17.1906C182.308 17.0847 182.31 16.9686 182.274 16.8616C182.239 16.7546 182.168 16.6634 182.073 16.6031C182.043 16.5805 182.01 16.5615 181.976 16.5465Z"
          fill="#93B7FA"
        />
        <path
          opacity="0.5"
          d="M197.587 137.684C197.364 137.586 197.185 137.408 197.083 137.184C196.982 136.96 196.965 136.706 197.036 136.471C197.047 136.435 197.054 136.398 197.057 136.36C197.074 136.248 197.052 136.134 196.995 136.037C196.938 135.94 196.85 135.865 196.745 135.826C196.641 135.787 196.526 135.785 196.421 135.821C196.315 135.858 196.225 135.93 196.165 136.025C196.143 136.056 196.124 136.089 196.109 136.124C196.012 136.349 195.836 136.531 195.615 136.634C195.394 136.737 195.144 136.754 194.911 136.682C194.875 136.671 194.839 136.663 194.802 136.66C194.691 136.644 194.579 136.666 194.483 136.724C194.387 136.781 194.313 136.87 194.274 136.976C194.236 137.082 194.234 137.198 194.27 137.305C194.306 137.412 194.377 137.504 194.471 137.564C194.501 137.586 194.534 137.605 194.568 137.62C194.791 137.719 194.971 137.897 195.072 138.121C195.174 138.345 195.191 138.598 195.12 138.834C195.109 138.87 195.101 138.907 195.098 138.945C195.082 139.057 195.104 139.171 195.161 139.268C195.217 139.365 195.306 139.439 195.41 139.479C195.515 139.518 195.629 139.52 195.735 139.483C195.841 139.447 195.931 139.375 195.99 139.28C196.013 139.249 196.031 139.216 196.046 139.181C196.143 138.955 196.319 138.774 196.54 138.671C196.761 138.568 197.012 138.55 197.245 138.622C197.28 138.634 197.317 138.641 197.354 138.645C197.464 138.661 197.577 138.639 197.673 138.581C197.769 138.524 197.842 138.434 197.881 138.329C197.92 138.223 197.921 138.106 197.886 138C197.85 137.893 197.779 137.801 197.685 137.741C197.654 137.718 197.622 137.699 197.587 137.684Z"
          fill="#93B7FA"
        />
        <path
          opacity="0.5"
          d="M1.61897 65.1025C2.5131 65.1025 3.23794 64.3684 3.23794 63.4629C3.23794 62.5573 2.5131 61.8232 1.61897 61.8232C0.724838 61.8232 0 62.5573 0 63.4629C0 64.3684 0.724838 65.1025 1.61897 65.1025Z"
          fill="#D55F4D"
        />
        <path
          opacity="0.5"
          d="M182.275 37.6057C183.169 37.6057 183.894 36.8716 183.894 35.966C183.894 35.0605 183.169 34.3264 182.275 34.3264C181.381 34.3264 180.656 35.0605 180.656 35.966C180.656 36.8716 181.381 37.6057 182.275 37.6057Z"
          fill="#D55F4D"
        />
        <path
          opacity="0.5"
          d="M108.641 99.3279C109.648 99.3279 110.464 98.5012 110.464 97.4814C110.464 96.4617 109.648 95.635 108.641 95.635C107.634 95.635 106.818 96.4617 106.818 97.4814C106.818 98.5012 107.634 99.3279 108.641 99.3279Z"
          fill="#D55F4D"
        />
        <path
          opacity="0.5"
          d="M108.641 99.3279C109.648 99.3279 110.464 98.5012 110.464 97.4814C110.464 96.4617 109.648 95.635 108.641 95.635C107.634 95.635 106.818 96.4617 106.818 97.4814C106.818 98.5012 107.634 99.3279 108.641 99.3279Z"
          fill="#D55F4D"
        />
        <path
          opacity="0.5"
          d="M202.384 86.9052C203.278 86.9052 204.003 86.1711 204.003 85.2656C204.003 84.3601 203.278 83.626 202.384 83.626C201.489 83.626 200.765 84.3601 200.765 85.2656C200.765 86.1711 201.489 86.9052 202.384 86.9052Z"
          fill="#D55F4D"
        />
        <path
          opacity="0.5"
          d="M71.5097 13.4839C72.5166 13.4839 73.3328 12.6572 73.3328 11.6374C73.3328 10.6177 72.5166 9.79102 71.5097 9.79102C70.5028 9.79102 69.6865 10.6177 69.6865 11.6374C69.6865 12.6572 70.5028 13.4839 71.5097 13.4839Z"
          fill="#61D8A9"
        />
        <path
          opacity="0.5"
          d="M175.386 154.625C176.28 154.625 177.005 153.891 177.005 152.985C177.005 152.08 176.28 151.345 175.386 151.345C174.492 151.345 173.767 152.08 173.767 152.985C173.767 153.891 174.492 154.625 175.386 154.625Z"
          fill="#D55F4D"
        />
        <path
          d="M74.5551 93.9314C73.5973 97.0802 75.0413 100.803 77.8441 102.459C79.0206 103.154 80.3746 103.503 81.5147 104.257C83.9918 105.891 84.969 109.02 85.7736 111.901C86.6974 115.212 87.6235 118.713 86.7776 122.044C86.2379 124.168 85.0103 126.047 84.3127 128.125C83.7291 129.865 83.5259 131.713 83.7171 133.541C83.8192 134.511 84.0574 135.51 84.7138 136.234C85.6618 137.268 87.1884 137.423 88.5594 137.672C91.458 138.227 94.1853 139.47 96.5178 141.299C98.8503 143.128 100.721 145.491 101.978 148.194"
          stroke="url(#paint0_linear_492:6105)"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M74.8495 93.9314C73.8917 97.0802 75.3357 100.803 78.1385 102.459C79.3151 103.154 80.6691 103.503 81.8091 104.257C84.2862 105.891 85.2634 109.02 86.0681 111.901C86.9918 115.212 87.918 118.713 87.072 122.044C86.5324 124.168 85.3048 126.047 84.6071 128.125C84.0236 129.865 83.8204 131.713 84.0115 133.541C84.1136 134.511 84.3518 135.51 85.0082 136.234C85.9562 137.268 87.4828 137.423 88.8538 137.672C91.7524 138.227 94.4797 139.47 96.8122 141.299C99.1447 143.128 101.016 145.491 102.272 148.194"
          stroke="#575757"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M111.104 70.3955C108.741 73.7314 106.288 77.3184 106.086 81.4199C105.778 87.7199 110.754 92.8727 112.976 98.7665C113.939 101.363 114.374 104.129 114.257 106.9C114.14 109.671 113.472 112.389 112.293 114.892C111.208 117.162 109.662 119.417 109.862 121.931C109.976 123.464 110.734 124.86 111.345 126.271C112.842 129.806 113.495 133.649 113.252 137.488C113.008 141.328 111.874 145.054 109.942 148.366"
          stroke="url(#paint1_linear_492:6105)"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M111.395 70.3955C109.035 73.7437 106.582 77.3208 106.376 81.4199C106.067 87.7199 111.043 92.8727 113.265 98.7665C114.228 101.363 114.663 104.129 114.546 106.9C114.429 109.671 113.761 112.389 112.582 114.892C111.497 117.162 109.951 119.417 110.151 121.931C110.265 123.464 111.023 124.86 111.634 126.271C113.131 129.806 113.785 133.649 113.541 137.488C113.297 141.328 112.163 145.054 110.231 148.366"
          stroke="#575757"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M144.256 100.401L145.399 109.833C145.549 111.086 145.678 112.452 145.015 113.526C144.385 114.538 143.204 115.02 142.285 115.769C139.268 118.23 139.543 122.935 140.583 126.712C141.624 130.488 143.121 134.545 141.675 138.179C141.077 139.679 140.022 140.942 139.244 142.35C137.909 144.756 137.38 147.536 137.737 150.272"
          stroke="url(#paint2_linear_492:6105)"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M144.54 100.401L145.683 109.833C145.834 111.086 145.962 112.452 145.299 113.526C144.669 114.538 143.488 115.02 142.569 115.769C139.552 118.23 139.827 122.935 140.867 126.712C141.908 130.488 143.405 134.545 141.959 138.179C141.361 139.679 140.306 140.942 139.528 142.35C138.193 144.756 137.665 147.536 138.021 150.272"
          stroke="#575757"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M173.881 61.6363C173.879 57.224 172.846 52.8747 170.867 48.9439C168.888 45.0131 166.019 41.6124 162.493 39.0196C158.968 36.4268 154.887 34.7156 150.584 34.0257C146.281 33.3358 141.878 33.6869 137.734 35.0502C137.962 33.4793 138.08 31.8939 138.086 30.3061C138.097 23.0596 135.329 16.092 130.365 10.8729C125.401 5.65388 118.629 2.59142 111.479 2.33148C104.328 2.07154 97.357 4.63444 92.0362 9.47961C86.7155 14.3248 83.4608 21.0734 82.9587 28.3021C78.8141 26.9359 74.409 26.5826 70.1037 27.2713C65.7984 27.9599 61.7152 29.6709 58.188 32.2643C54.6609 34.8577 51.79 38.2598 49.8101 42.1924C47.8303 46.125 46.7977 50.4764 46.7969 54.8907C46.7969 68.9235 57.0066 88.8649 70.2914 92.3978L72.3601 93.5943L71.237 97.4914H77.8879L76.444 93.5893L78.5029 92.3978C88.7759 89.6675 97.1989 77.134 100.498 65.1125C102.304 66.3299 104.292 67.2444 106.385 67.8206L108.454 69.0171L107.348 72.9044H113.999L112.555 69.0023L114.614 67.8108C116.14 67.395 117.609 66.7906 118.989 66.0111C120.766 79.4974 130.22 95.9626 142.182 99.1409L144.251 100.337L143.128 104.235H149.779L148.33 100.332L150.389 99.1409C163.684 95.6081 173.881 75.6593 173.881 61.6363Z"
            fill="url(#paint3_linear_492:6105)"
          />
        </g>
        <path
          d="M78.9453 96.0487H72.5083L73.6362 92.1392H77.4965L78.9453 96.0487Z"
          fill="#D55F4D"
        />
        <path
          d="M102.272 54.8265C102.272 69.7653 90.3148 91.6492 75.5666 91.6492C60.8184 91.6492 48.8608 69.7629 48.8608 54.8265C48.8608 47.653 51.6746 40.7733 56.6831 35.7009C61.6916 30.6285 68.4847 27.7788 75.5678 27.7788C82.6509 27.7788 89.4439 30.6285 94.4524 35.7009C99.461 40.7733 102.275 47.653 102.275 54.8265H102.272Z"
          fill="#D55F4D"
        />
        <path
          d="M77.4965 92.3016H73.6362L71.3828 90.9993H79.7499L77.4965 92.3016Z"
          fill="#575757"
        />
        <path
          opacity="0.1"
          d="M65.9135 33.8069C65.9135 33.8069 54.6512 39.9986 53.042 52.3819L65.9135 33.8069Z"
          fill="white"
        />
        <path d="M113.87 72.2716H107.433L108.561 68.3621H112.421L113.87 72.2716Z" fill="#93B7FA" />
        <path
          d="M137.197 31.0494C137.197 45.9882 125.239 67.8721 110.491 67.8721C95.7427 67.8721 83.7852 45.9858 83.7852 31.0494C83.7852 23.8759 86.5989 16.9962 91.6074 11.9238C96.616 6.85137 103.409 4.00171 110.492 4.00171C117.575 4.00171 124.368 6.85137 129.377 11.9238C134.385 16.9962 137.199 23.8759 137.199 31.0494H137.197Z"
          fill="#6C63FF"
        />
        <path
          d="M112.421 68.5245H108.561L106.308 67.2197H114.675L112.421 68.5245Z"
          fill="#575757"
        />
        <path
          opacity="0.1"
          d="M100.838 10.0298C100.838 10.0298 89.5756 16.2215 87.9663 28.6048L100.838 10.0298Z"
          fill="white"
        />
        <path d="M148.5 102.59H142.063L143.191 98.678H147.052L148.5 102.59Z" fill="#3AD29F" />
        <path
          d="M171.827 61.3653C171.827 76.3041 159.87 98.188 145.122 98.188C130.374 98.188 118.414 76.3041 118.414 61.3653C118.414 54.1918 121.227 47.3121 126.236 42.2397C131.244 37.1673 138.037 34.3176 145.121 34.3176C152.204 34.3176 158.997 37.1673 164.005 42.2397C169.014 47.3121 171.827 54.1918 171.827 61.3653Z"
          fill="#3AD29F"
        />
        <path
          d="M147.052 98.8404H143.191L140.938 97.5381H149.305L147.052 98.8404Z"
          fill="#575757"
        />
        <path
          opacity="0.1"
          d="M135.469 40.3457C135.469 40.3457 124.206 46.5374 122.597 58.9208L135.469 40.3457Z"
          fill="white"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M125.534 107.198C123.985 105.625 121.727 105 119.174 105.436C116.787 105.844 114.398 107.159 112.448 109.129C111.883 109.704 111.409 110.363 111.041 111.083C110.682 110.339 110.211 109.655 109.645 109.057C107.73 107.051 105.362 105.699 102.972 105.256C100.43 104.764 98.1618 105.362 96.5841 106.908C95.0065 108.454 94.3599 110.731 94.7537 113.321C95.1232 115.744 96.3824 118.184 98.3003 120.185C102.331 124.397 110.375 122.753 110.715 122.679L110.815 122.657L111.123 122.728C111.464 122.807 119.478 124.592 123.579 120.451C125.524 118.481 126.829 116.066 127.242 113.649C127.692 111.061 127.082 108.774 125.534 107.198ZM109.363 120.247C107.419 120.512 102.574 120.872 100.17 118.358C98.6236 116.741 97.6124 114.811 97.3231 112.917C97.0606 111.194 97.4398 109.717 98.3976 108.786C98.7714 108.423 99.2183 108.146 99.7078 107.974C100.613 107.678 101.579 107.629 102.508 107.831C104.365 108.183 106.242 109.266 107.788 110.884C110.192 113.38 109.687 118.284 109.363 120.244V120.247ZM124.705 113.203C124.384 115.089 123.341 117.004 121.766 118.592C119.318 121.054 114.473 120.618 112.54 120.316C112.251 118.346 111.828 113.449 114.276 110.978C115.851 109.39 117.745 108.338 119.607 108.023C120.54 107.837 121.505 107.903 122.405 108.215C122.892 108.396 123.335 108.682 123.703 109.052C124.641 110.005 124.996 111.477 124.705 113.201V113.203Z"
            fill="url(#paint4_linear_492:6105)"
          />
        </g>
        <path
          d="M111.505 122.142L110.739 122.305C110.413 122.374 102.717 123.947 98.8594 119.917C97.0265 118.001 95.8208 115.668 95.4562 113.348C95.0769 110.869 95.6992 108.691 97.2064 107.211C98.7135 105.731 100.884 105.17 103.318 105.63C105.595 106.061 107.861 107.354 109.694 109.272C113.549 113.302 111.755 121.044 111.677 121.372L111.505 122.142ZM100.194 108.235C99.7253 108.4 99.2974 108.665 98.9396 109.013C98.0256 109.912 97.661 111.315 97.9113 112.967C98.1885 114.776 99.156 116.625 100.636 118.174C102.938 120.579 107.574 120.234 109.434 119.981C109.742 118.107 110.229 113.425 107.927 111.02C106.446 109.473 104.652 108.435 102.873 108.1C101.984 107.906 101.06 107.952 100.194 108.235Z"
          fill="#6C63FF"
        />
        <path
          d="M110.367 122.177L110.197 121.404C110.124 121.074 108.461 113.302 112.385 109.34C114.252 107.457 116.537 106.204 118.822 105.813C121.253 105.394 123.424 105.992 124.899 107.499C126.375 109.006 126.965 111.192 126.545 113.654C126.151 115.965 124.906 118.277 123.04 120.161C119.116 124.122 111.447 122.413 111.128 122.339L110.367 122.177ZM121.914 108.457C121.054 108.158 120.132 108.095 119.24 108.272C117.456 108.577 115.645 109.584 114.135 111.103C111.792 113.469 112.19 118.157 112.475 120.037C114.33 120.323 118.96 120.749 121.301 118.385C122.809 116.864 123.805 115.032 124.114 113.215C124.394 111.568 124.053 110.158 123.154 109.244C122.801 108.895 122.378 108.626 121.914 108.457Z"
          fill="#6C63FF"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M147.903 120.106H73.272V135.23H77.1492V179H144.025V135.23H147.903V120.106Z"
            fill="url(#paint5_linear_492:6105)"
          />
        </g>
        <path d="M143.179 124.678H77.9946V178.257H143.179V124.678Z" fill="#575757" />
        <path d="M116.726 134.245H104.445V178.254H116.726V134.245Z" fill="#6C63FF" />
        <path opacity="0.1" d="M143.179 121.808H77.9946V135.203H143.179V121.808Z" fill="black" />
        <path d="M146.959 120.85H74.2173V134.245H146.959V120.85Z" fill="#575757" />
        <path d="M116.726 120.85H104.445V134.245H116.726V120.85Z" fill="#6C63FF" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_492:6105"
          x1="74.0154"
          y1="121.069"
          x2="102.197"
          y2="121.069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_492:6105"
          x1="5157.07"
          y1="41451.1"
          x2="5450.33"
          y2="41451.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_492:6105"
          x1="6020.66"
          y1="29801.4"
          x2="6293.24"
          y2="29801.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_492:6105"
          x1="80658.9"
          y1="51966.8"
          x2="80658.9"
          y2="9788.78"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_492:6105"
          x1="20957.2"
          y1="10719.7"
          x2="20957.2"
          y2="9390.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_492:6105"
          x1="34024.3"
          y1="42940.5"
          x2="34024.3"
          y2="28852"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0_492:6105">
          <rect width="204" height="179" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
