import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.palette.success.main,
      borderRadius: 6,
      border: 0,
      color: 'white',
      height: 39,
      padding: '0 42px',
      boxShadow: 'none',
      marginTop: theme.spacing(1),

      '&:hover': {
        background: theme.palette.success.main,
      },
    },
    penIcon: {
      width: '20px',
      height: '20px',
      marginLeft: '16px',
      cursor: 'pointer',
    },
    buttonLabel: { textTransform: 'capitalize' },
    avatar: {
      maxWidth: '124px',
      maxHeight: '124px',
      width: '100%',
      height: '124px',
      margin: 'auto',
      marginTop: '36px',
    },
    userName: {
      textAlign: 'center',
      paddingTop: '8px',
      color: theme.palette.text.secondary,
    },
    divider: {
      color: theme.palette.grey[400],
      maxWidth: '404px',
      width: '100%',
      marginTop: '5px',
      marginBottom: '28px',
    },
    dateDivider: {
      color: theme.palette.grey[100],
      maxWidth: '404px',
      width: '100%',
      marginTop: '5px',
      marginBottom: '28px',
    },
    data: {
      color: theme.palette.grey[900],
      marginBottom: '14px',
    },
    date: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    rate: {
      color: theme.palette.grey[900],
      marginBottom: '14px',
      marginLeft: '5px',
    },
    dateRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
    },
    rateBlock: {
      display: 'flex',
      marginRight: '50px',
    },
    lastRateBlock: {
      display: 'flex',
      marginRight: '14px',
    },
    dateBlock: {
      display: 'flex',
      marginLeft: '15px',
    },
    detachBtn: {
      backgroundColor: theme.palette.error.main,
    },
    addRateBtn: {
      width: '27px',
      height: '27px',
      minWidth: '27px',
      minHeight: '27px',
      marginLeft: '10px',
      marginBottom: '5px',
      margin: 0,
      padding: 0,
      '&:focus, &:active, &:hover': {
        background: 'none',
      },
    },
    addRateIcon: {
      width: '27px',
      height: '27px',
    },
    baseAddRateBlock: {
      height: '100%',
      maxHeight: '0',
      transition: 'max-height 0.4s ease-in-out',
      display: 'flex',
      alignItems: 'flex-end',
      overflow: 'hidden',
      opacity: 0,
      '&$showAddRateBlock': {
        transition: 'max-height 1s ease-in-out',
        opacity: '1',
        maxHeight: '100vmax',
        width: '100%',
      },
    },
    showAddRateBlock: {},
  }),
);

export default useStyles;
