import {
  FormGroup,
  Grid,
  Button,
  Typography,
  TextField,
  InputLabel,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import { CheckboxGroup, CustomCheckBox } from '@/components/FormItems';
import { vacationTypeOptions } from '@/types/vacation';
import clsx from 'clsx';
import pluralize from 'pluralize';
import MobileRangeDatePicker from '@/components/MobileRangeDatePicker';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import useVacationForm from '../VacationForm/useVacationForm';
import { IVacationFormProps } from '../VacationForm/types';
import useStyles from './styles';

const VacationForm = ({
  onSubmitHandler,
  date,
  setDate,
  closeModal,
  user,
  ...props
}: IVacationFormProps): JSX.Element => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const {
    total,
    vacationLeft,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    setFieldValue,
    isDisabled,
  } = useVacationForm(onSubmitHandler, date, user);

  return (
    <div {...props}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={isDesktop ? 2 : 0}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <div className={classes.typeBlock}>
                <div className={classes.titleBlock}>
                  <Typography className={classes.mobileTitle}>Request a holiday</Typography>
                  <div className={classes.tape}>
                    <Typography className={classes.leftInfo}>
                      Left: {vacationLeft} {pluralize('day', vacationLeft)}
                    </Typography>
                  </div>
                </div>
                <InputLabel
                  htmlFor="type"
                  className={clsx(classes.subTitle)}
                  error={!!errors?.type}
                >
                  Type
                </InputLabel>
                <FormGroup>
                  <CheckboxGroup
                    name="type"
                    options={vacationTypeOptions}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    values={values.type}
                    icon={<RadioButtonUncheckedOutlinedIcon />}
                    checkedIcon={<RadioButtonCheckedOutlinedIcon />}
                  />
                </FormGroup>
                <FormGroup>
                  <CustomCheckBox
                    name="isPayment"
                    checked={values.isPayment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="SelfPaid"
                    value={values.isPayment}
                  />
                </FormGroup>
              </div>
            </Grid>
            <Grid item xs={12}>
              <MobileRangeDatePicker date={date} setDate={setDate} />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.totalInfo}>
                Total: {total} {pluralize('day', total)}
              </Typography>
            </Grid>
            {!date.includes(null) &&
            !date.find((dateInst) => dateInst && Number.isNaN(dateInst.getTime())) ? (
              <Grid container item xs={12} className={classes.descriptionBlock}>
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel}>Comment</InputLabel>
                  <TextField
                    className={classes.descriptionInput}
                    multiline
                    name="comment"
                    id="comment"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.comment ?? ''}
                    maxRows={4}
                  />
                  <div className={classes.buttonGroup}>
                    <Button
                      variant="contained"
                      onClick={closeModal}
                      classes={{
                        root: classes.button,
                        label: classes.buttonLabel,
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      classes={{
                        root: clsx(classes.button, classes.submitButton),
                        label: classes.buttonLabel,
                      }}
                      disabled={isDisabled}
                    >
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default VacationForm;
