import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  infoBlock: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 20px',
  },
  avatar: {
    marginTop: '50px',
    marginBottom: '50px',
    width: '100px',
    height: '100px',
  },
  infoText: {
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '21px',
  },
  opacityText: {
    opacity: 0.5,
  },
  divider: {
    width: '100%',
    color: theme.palette.divider,
    maxWidth: '335px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  toggle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: '30px',
    width: '100%',
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
