import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonIcon: {
      marginRight: theme.spacing(2),
      fontSize: '20px',
    },
    btnWrap: {
      display: 'flex',
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(2),
      position: 'relative',
    },
    btn: {
      padding: 0,
      textTransform: 'none',
      [theme.breakpoints.down('md')]: {
        '&:not(:first-of-type)': {
          display: 'none',
        },
      },
    },
    arrow: {
      width: '20px',
      height: '18px',
      marginRight: '9px',
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
    search: {
      fontSize: '14px',
      color: '#AFAFAF',
      lineHeight: '20px',
      maxWidth: '306px',
      width: '100%',
      '& .MuiOutlinedInput-input': {
        padding: '8px 12px',
        paddingLeft: '4px',
      },
    },
    searchIcon: {
      color: '#AFAFAF',
      fontSize: '18px',
    },
    user: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1.25),
    },
    avatar: {
      width: '30px',
      height: '30px',
      fontSize: '14px',
      marginRight: theme.spacing(1.75),
    },
    indicator: {
      position: 'absolute',
      width: '64px',
      height: '3px',
      backgroundColor: theme.palette.primary.main,
      bottom: 0,
      left: 0,
      transition: theme.transitions.create(['left'], {
        duration: theme.transitions.duration.standard,
      }),
    },
    card: {
      position: 'sticky',
      overflow: 'auto',
      top: '100px',
      padding: '25px 21px',
      maxHeight: 'calc(100vh - 140px)',
    },
    selectedUser: {
      color: '#775DC6',
    },
  }),
);

export default useStyles;
