import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearResultReports,
  loadReportsByDate,
  loadUserProjects,
  reportsSelector,
  userProjectsSelector,
  userSelector,
} from '@/redux';
import { getCurrentMonthRangeDatePickerState } from '@/constants';
import { useMediaQuery, Theme } from '@material-ui/core';
import { DateRange } from '@mui/lab';
import { dateRangeValidationSchema } from '@/validations';
import { startOfMonth, subMonths } from 'date-fns';
import { projectsService } from '@/services';
import { IProjectReportInfo } from '@/interfaces';

const useReports = () => {
  const dispatch = useDispatch();

  const reports = useSelector(reportsSelector);
  const projects = useSelector(userProjectsSelector);
  const user = useSelector(userSelector);

  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [defaultSelectedProjects, setDefaultSelectedProjects] = useState<
    IProjectReportInfo[] | null
  >(null);
  const [searchName, setSearchName] = useState('');
  const [date, setDate] = useState<DateRange<Date | null>>(getCurrentMonthRangeDatePickerState());
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [showCalendar, setShowCalendar] = useState(true);

  useEffect(() => {
    const startOfPrevMonth = subMonths(startOfMonth(new Date()), 1);
    let isUnmounted = false;

    projectsService
      .loadUserProjects({
        activityFrom: startOfPrevMonth.toISOString(),
        activityTo: new Date().toISOString(),
      })
      .then((data) => {
        if (isUnmounted) {
          return;
        }
        setDefaultSelectedProjects(data);
      })
      .catch(() => {});
    return () => {
      isUnmounted = true;
      setDefaultSelectedProjects(null);
    };
  }, []);

  const fetchResults = useCallback(async () => {
    const dateRange = { from: date[0], to: date[1] };
    const isValid = await dateRangeValidationSchema.isValid(dateRange);
    if (!isValid) {
      return;
    }
    dispatch(clearResultReports());
    dispatch(
      loadReportsByDate({
        date: dateRange,
        limit: 100,
        projectIds: selectedProjects,
      }),
    );
  }, [dispatch, selectedProjects, date]);

  const handleDisplayChange = useCallback(() => {
    setShowCalendar((value) => !value);
  }, []);

  const fetchProjects = useCallback(() => {
    dispatch(loadUserProjects());
  }, [dispatch]);

  return {
    reports,
    handleDisplayChange,
    showCalendar,
    projects,
    defaultSelectedProjects,
    selectedProjects,
    setSelectedProjects,
    searchName,
    setSearchName,
    date,
    setDate,
    isDesktop,
    fetchResults,
    fetchProjects,
    user,
  };
};

export default useReports;
