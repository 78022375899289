import React from 'react';
import Sidebar from '@/components/Sidebar';
import Navbar from '@/components/Navbar';
import MobileMenu from '@/components/MobileMenu';
import { LayoutProps } from '../types';
import useStyles from './styles';

const PrivateLayout: React.FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.layout}>
      <div className={classes.decs}>
        <Navbar />
      </div>
      <div className={classes.root}>
        <div className={classes.decs}>
          <Sidebar />
        </div>
        <main className={classes.contentWrapper}>
          <div className={classes.decs}>
            <div className={classes.toolbar} />
          </div>
          <div className={classes.content}>{children}</div>
        </main>
      </div>
      <div className={classes.mob}>
        <MobileMenu />
      </div>
    </div>
  );
};
export default PrivateLayout;
