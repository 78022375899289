import auth from '@DevimaSolutions/o-auth';
import { AxiosResponse } from 'axios';
import { IUserReport, IReportStore, IReport } from '@/redux/reports/types';
import { LoadResultReportsActionProps } from '@/interfaces';
import { userReportsExportFiltersToQuery, userReportsFiltersToQuery } from '@/utils';
import { ISetReport } from '@/redux';

const loadReports = async (): Promise<IReportStore> => {
  const response = await auth()
    .axios.get(`/user/reports`)
    .then((data: AxiosResponse<IReportStore>) => data);
  return response.data ?? {};
};

const deleteReport = async (id: string): Promise<string> => {
  const response = await auth()
    .axios.delete(`/user/reports/${id}`)
    .then((data: AxiosResponse<string>) => data);
  return response.data ?? {};
};

const loadReportsByDate = async (payload: LoadResultReportsActionProps): Promise<IReportStore> => {
  const response = await auth()
    .axios.get(`/user/reports`, userReportsFiltersToQuery(payload))
    .then((data: AxiosResponse<IReportStore>) => data);

  return response.data ?? [];
};

const createReport = async ({
  project,
  taskType,
  workedDay,
  workMode,
  workedMinutes,
  description,
}: IUserReport): Promise<ISetReport> => {
  return auth()
    .axios.post(`/user/reports`, {
      project,
      taskType,
      workedDay,
      workMode,
      workedMinutes,
      description,
    })
    .then((data: AxiosResponse<Record<string, IReport[]>>) => {
      const [result] = Object.entries(data.data).map(([key, value]) => ({
        reportDay: key,
        reports: value,
      }));

      return result;
    });
};

const editReport = async ({
  project,
  taskType,
  workedDay,
  workedMinutes,
  workMode,
  description,
  id,
}: IUserReport): Promise<IUserReport> => {
  const response = await auth()
    .axios.put(`/user/reports/${id}`, {
      project,
      taskType,
      workedDay,
      workMode,
      workedMinutes,
      description,
    })
    .then((data: AxiosResponse<IUserReport>) => data);

  return response.data ?? {};
};

const loadReportsExport = async (
  payload: Omit<LoadResultReportsActionProps, 'limit'>,
): Promise<BlobPart> => {
  const response = await auth()
    .axios.get(`/admin/reports/export`, userReportsExportFiltersToQuery(payload))
    .then((data: AxiosResponse<BlobPart>) => data);
  return response.data ?? [];
};

export default {
  loadReports,
  createReport,
  editReport,
  loadReportsByDate,
  deleteReport,
  loadReportsExport,
};
