import { useCallback, useState, useMemo, createRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadNewNotifications,
  loadNotifications,
  newNotificationsSelector,
  notificationsSelector,
  shortNotificationsSelector,
} from '@/redux';
import { useHistory } from 'react-router-dom';

const useNotificationPopper = (notificationsCount: number) => {
  const dispatch = useDispatch();

  const [isShowAll, setIsShowAll] = useState(false);
  const scrollRef = createRef<HTMLLIElement>();

  const notifications = useSelector(notificationsSelector);
  const newNotificationsArray = useSelector(newNotificationsSelector);
  const newNotifications = useMemo(() => {
    return notificationsCount !== 0 ? newNotificationsArray : [];
  }, [newNotificationsArray, notificationsCount]);
  const [open, setOpen] = useState(false);

  const handleNotificationsShow = () => {
    setIsShowAll(!isShowAll);
  };

  const history = useHistory();
  const shortNewNotificationArray = useSelector(shortNotificationsSelector);
  const onClickHandler = useCallback(
    (path: string) => {
      setOpen(false);
      history.push(path);
    },
    [setOpen, history],
  );

  const openPopperHandler = useCallback(() => {
    setOpen(!open);
    dispatch(loadNotifications());
    dispatch(loadNewNotifications(false));
  }, [open, dispatch]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView();
  }, [scrollRef]);

  return {
    notifications,
    openPopperHandler,
    onClickHandler,
    open,
    setOpen,
    newNotifications,
    handleNotificationsShow,
    isShowAll,
    shortNewNotificationArray,
    scrollRef,
  };
};

export default useNotificationPopper;
