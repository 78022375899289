import { InputLabel, FormGroup, FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import clsx from 'clsx';
import { useCallback } from 'react';
import { debounce } from 'throttle-debounce';
import useStyles from '../styles';
import { IMultiSelectProps } from './types';

export default function MultiSelect<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  label,
  error,
  searchName,
  setSearchName,
  onChange,
  options,
  className,
  name,
  placeholder,
  ...props
}: IMultiSelectProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  const classes = useStyles();

  const handleChange = useCallback(
    (e, val) => {
      onChange(e, name, val);
    },
    [name, onChange],
  );

  return (
    <FormGroup className={classes.fullWidth}>
      <FormControl
        className={clsx(classes.wrapper, classes.input)}
        variant="outlined"
        error={!!error}
      >
        {label && (
          <InputLabel margin="dense" className={classes.label} error={!!error}>
            {label}
          </InputLabel>
        )}
        <Autocomplete
          multiple
          options={options}
          disableClearable
          className={clsx(classes.input, className)}
          PaperComponent={(paperProps) => <div {...paperProps} className={classes.listBox} />}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              name={name ?? ''}
              value={searchName}
              onChange={debounce(500, (e) => setSearchName(e.target.value))}
              {...params}
              className={classes.input}
            />
          )}
          {...props}
        />
      </FormControl>
    </FormGroup>
  );
}
