import { object, array, number } from 'yup';

const attachUsersSchema = object().shape({
  members: array()
    .min(1, 'The field Members is required')
    .required('The field Members is required'),
  rates: object()
    .shape({
      rate: number().notRequired(),
    })
    .nullable(),
});

export default attachUsersSchema;
