import Header from '@/components/Header';
import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import Button from '@/components/Buttons/Button';
import clsx from 'clsx';
import { notificationTypeIcon } from '@/constants/notification.constants';
import { durationFormat } from '@/utils';
import useNotification from './useNotification';
import useStyles from './styles';

const Notification: React.FC = () => {
  const classes = useStyles();

  const {
    onClickHandler,
    handleNotificationsShow,
    notifications,
    isShowAll,
    shortNewNotificationArray,
  } = useNotification();

  return (
    <Grid container component="main" className={classes.root}>
      <div className={classes.container}>
        <Header className={classes.headerStyle} title="Notifications" />
        <Button className={classes.headerButton} onClick={handleNotificationsShow}>
          {isShowAll ? 'Hide' : 'Read all'}
        </Button>
      </div>
      <div className={classes.notificationBlock}>
        {isShowAll
          ? notifications.map((notification, idx) => (
              <div className={classes.notificationBlock} key={idx}>
                <Button
                  disableRipple
                  onClick={() =>
                    onClickHandler(notification.type === 'Warning' ? '/reports' : '/time-off')
                  }
                  className={clsx(classes.type)}
                >
                  <div className={classes.messageBlock}>
                    {notificationTypeIcon.find((el) => el.type === notification.type)?.content}
                    <Typography
                      className={clsx(
                        classes.message,
                        classes[
                          notificationTypeIcon.find((el) => el.type === notification.type)?.class ??
                            'listItem'
                        ],
                      )}
                    >
                      {notification.message}
                    </Typography>
                  </div>
                  <Typography>{durationFormat(notification)}</Typography>
                </Button>
                <Divider variant="middle" className={classes.divider} />
              </div>
            ))
          : shortNewNotificationArray.map((notification, idx) => {
              const notificationTypeIconClass = notificationTypeIcon.find(
                (el) => el.type === notification.type,
              )?.class;
              return (
                <Button
                  key={idx}
                  disableRipple
                  onClick={() =>
                    onClickHandler(notification.type === 'Warning' ? '/reports' : '/time-off')
                  }
                  className={classes.type}
                >
                  <div className={classes.messageBlock}>
                    {notificationTypeIcon.find((el) => el.type === notification.type)?.content}
                    <Typography
                      className={clsx(
                        classes.message,
                        classes[notificationTypeIconClass ?? 'listItem'],
                      )}
                    >
                      {notification.message}
                    </Typography>
                  </div>
                  <Typography>{durationFormat(notification)}</Typography>
                </Button>
              );
            })}
      </div>
    </Grid>
  );
};

export default Notification;
