import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
      padding: theme.spacing(1),
      background: theme.palette.task.development,
      borderRadius: 0,
      [theme.breakpoints.up('md')]: {
        borderRadius: 12,
      },
    },
    header: {
      padding: theme.spacing(0.5, 3, 1.5),
    },
    content: {
      background: theme.palette.background.paper,
      borderRadius: 12,
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    flexSpaceBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '23px',
      letterSpacing: '0.16px',
      color: '#19181F',
    },
    subTitle: {
      color: '#575757',
      fontWeight: 500,
      marginRight: theme.spacing(1),
    },
    overtimeBox: {
      marginTop: 28,
    },
    overtimeTitle: {
      fontWeight: 500,
      fontsize: 18,
      lineHeight: '23px',
      color: theme.palette.text.secondary,
    },
    overtimeValue: {
      fontWeight: 500,
      fontsize: 18,
      lineHeight: '23px',
    },
    blured: {
      filter: `blur(10px)`,
    },
  }),
);

export default useStyles;
