import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendar: {
      boxShadow: 'none',
    },
    content: {
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%',
      flexDirection: 'column',
      '& .MuiCard-root': {
        width: '100%',
      },
    },
    button: {
      padding: '12px 10px',
      textTransform: 'capitalize',
      lineHeight: '19px',
      width: '100%',
      maxWidth: '155px',
      marginBottom: '-19px',
      marginLeft: '21px',
      maxHeight: '39px',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
  }),
);

export default useStyles;
