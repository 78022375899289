import { Typography, Grid, Button, CircularProgress, MenuItem } from '@material-ui/core';
import { FormikHelpers, useFormik } from 'formik';
import { CustomInput, PasswordInput, DatePicker, PhoneInputField } from '@/components/FormItems';
import { addUserSchema, editUserSchema } from '@/validations';
import FullScreenDialog from '@/components/FullScreenDialog';
import { defaultUserValues, positionOptions, userRoles } from '@/constants';
import { IShortUserInfo, IUser } from '@/interfaces';
import { clearUsers, userAdd, userEdit, usersLoadingSelector } from '@/redux';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { roleOptions } from '@/constants/options.constansts';
import useStyles from './styles';
import { IUserDialogProps } from './types';

const UserDialog = ({
  openDialog,
  handleEditModalClose,
  editUser,
  fetchUsers,
  ...props
}: IUserDialogProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const usersLoading = useSelector(usersLoadingSelector);

  const statusOptions = ['Active', 'Inactive'];
  const timeTypeOptions = ['Full-time', 'Part-time'];

  const onSubmitHandler = (
    values: IShortUserInfo | IUser,
    { resetForm }: FormikHelpers<IShortUserInfo | IUser>,
  ) => {
    const user = {
      ...values,
      ...(values.hireDate && { hireDate: format(new Date(values.hireDate), 'yyyy-MM-dd') }),
    } as IUser;
    const payload = {
      user,
      onSuccess: () => {
        handleEditModalClose();
        dispatch(clearUsers());
        fetchUsers();
        resetForm();
      },
    };
    dispatch(editUser ? userEdit(payload) : userAdd(payload));
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isValid,
    setFieldValue,
  } = useFormik<IShortUserInfo | IUser>({
    validationSchema: editUser ? editUserSchema : addUserSchema,
    onSubmit: onSubmitHandler,
    initialValues: editUser || defaultUserValues,
    enableReinitialize: true,
  });

  const handleDateChange = (date: Date | null) => {
    setFieldValue('hireDate', date);
  };

  return (
    <FullScreenDialog open={openDialog} handleDialogClose={handleEditModalClose}>
      <div {...props}>
        <Typography component="h2" variant="h1" className={classes.title}>
          {editUser ? 'Edit user’s details' : 'Add user'}
        </Typography>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomInput
                label="First name"
                id="firstName"
                name="firstName"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.firstName && !!errors?.firstName}
                helperText={touched.firstName && errors?.firstName}
                value={values?.firstName ?? ''}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInput
                label="Last name"
                id="lastName"
                name="lastName"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.lastName && !!errors?.lastName}
                helperText={touched.lastName && errors?.lastName}
                value={values?.lastName ?? ''}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                label="Email"
                type="email"
                id="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && !!errors?.email}
                helperText={touched.email && errors?.email}
                value={values?.email ?? ''}
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneInputField
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number*"
                onChange={setFieldValue}
                className={classes.phoneInput}
                onBlur={handleBlur}
                value={values?.phoneNumber || ''}
                error={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : undefined}
              />
            </Grid>
            {!editUser && (
              <Grid item xs={12}>
                <PasswordInput
                  label="Password"
                  id="password"
                  name="password"
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.password ?? ''}
                  error={touched.password && !!errors?.password}
                  helperText={touched.password && errors?.password}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <CustomInput
                select
                label="Role"
                id="role"
                name="role"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.role ?? ''}
                error={touched.role && !!errors?.role}
                helperText={touched.role && errors?.role}
              >
                {roleOptions.map((option) => (
                  <MenuItem
                    value={option.value}
                    key={option.value}
                    // Do not allow to switch from user back to trainee
                    disabled={values?.role === userRoles.user && option.value === userRoles.trainee}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </CustomInput>
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                select
                id="status"
                label="Status"
                name="status"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.status ?? ''}
                error={touched.status && !!errors?.status}
                helperText={touched.status && errors?.status}
              >
                {statusOptions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomInput>
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                select
                id="timeType"
                label="Time type"
                name="timeType"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.timeType ?? ''}
                error={touched.timeType && !!errors?.timeType}
                helperText={touched.timeType && errors?.timeType}
              >
                {timeTypeOptions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomInput>
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                select
                id="position"
                label="Position"
                name="position"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.position ?? ''}
                error={touched.position && !!errors?.position}
                helperText={touched.position && errors?.position}
              >
                {positionOptions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomInput>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="hireDate"
                label="Hire Date"
                name="hireDate"
                onChange={handleDateChange}
                onBlur={handleBlur}
                value={values.hireDate ?? ''}
                error={touched.hireDate && !!errors?.hireDate}
                helperText={touched.hireDate && errors?.hireDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <CustomInput
                label="Monthly Rate"
                id="rate"
                name="rate"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.rate || ''}
                error={touched.rate && !!errors?.rate}
                helperText={touched.rate && errors?.rate}
                type="number"
              />
            </Grid>
            <Grid item xs={5}>
              <CustomInput
                label="Overtime percent"
                id="overTimePercent"
                name="overTimePercent"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.overTimePercent}
                error={touched.overTimePercent && !!errors?.overTimePercent}
                helperText={touched.overTimePercent && errors?.overTimePercent}
                type="number"
                inputProps={{ min: 0, max: 100, step: 1 }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                type="submit"
                disabled={!isValid || usersLoading}
                classes={{
                  root: classes.button,
                  label: classes.buttonLabel,
                }}
              >
                {usersLoading ? (
                  <CircularProgress color="primary" size={20} />
                ) : (
                  <>{editUser ? 'Edit' : 'Add'}</>
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </FullScreenDialog>
  );
};

export default UserDialog;
