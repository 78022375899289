import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, Grid, Paper, Link, InputAdornment, Typography } from '@material-ui/core';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import { CustomInput, PasswordInput } from '@/components/FormItems';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import Button from '@/components/Buttons/Button';
import { useFormik } from 'formik';
import auth from '@DevimaSolutions/o-auth';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { toast } from 'react-toastify';
import { loginSchema } from '@/validations';
import useStyles from './styles';

const Login: React.FC = () => {
  const classes = useStyles();

  const onSubmitHandler = useCallback((val: { username: string; password: string }) => {
    auth().signIn(val.username, val.password);
  }, []);

  const { handleSubmit, handleChange, handleBlur, setFieldError, values, errors, touched } =
    useFormik({
      validationSchema: loginSchema,
      onSubmit: onSubmitHandler,
      initialValues: {
        username: '',
        password: '',
      },
    });

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const unsubscribe = auth().onAuthFailed((res) => {
      const errorMessage =
        // eslint-disable-next-line
        (res as any)?.data?.message ?? 'Sorry, something went wrong. Please try again.';

      if (res.status >= 400 && res.status < 500) {
        toast.error(errorMessage);
      }
    });
    return unsubscribe;
  }, [setFieldError]);

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={false} md={6} className={classes.bg}>
        <div className={classes.image} />
      </Grid>
      <Grid
        className={classes.loginWrapper}
        item
        xs={12}
        sm={12}
        md={6}
        component={Paper}
        elevation={6}
        square
      >
        <div className={classes.paper}>
          <div className={classes.wrapper}>
            <Typography className={classes.formTitle} component="h1" variant="h1">
              Login
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <Grid item xs={12}>
                <CustomInput
                  required
                  fullWidth
                  labelClass={classes.label}
                  id="username"
                  label="Email"
                  placeholder="andriykrasava@gmail.com"
                  name="username"
                  autoComplete="email"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.username && !!errors.username ? errors.username : null}
                  error={touched.username && !!errors.username}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlined className={classes.icon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid className={classes.input} item xs={12}>
                <PasswordInput
                  required
                  fullWidth
                  labelClass={classes.label}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  label="Password"
                  name="password"
                  placeholder="••••••••••••"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon className={classes.icon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle-password-visibility"
                          onClick={() => setShowPassword((val) => !val)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={touched.password && !!errors.password}
                  helperText={touched.password && !!errors.password ? errors.password : null}
                />
              </Grid>

              <Grid container justifyContent="flex-end">
                <Link href="/forgot-password" variant="inherit" className={classes.link}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Login
                </Button>
              </Grid>
            </form>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
