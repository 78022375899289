import React, { useCallback, useState } from 'react';
import { Switch } from '@material-ui/core';
import auth from '@DevimaSolutions/o-auth';
import Button from '@/components/Buttons/Button';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { saveTheme, settingsSelector } from '@/redux';
import useStyles from './styles';

const Profile: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { theme } = useSelector(settingsSelector);
  const [light, setLight] = useState(theme === 'light');

  const toggleTheme = useCallback(() => {
    dispatch(saveTheme(theme === 'light' ? 'dark' : 'light'));
    setLight(!light);
  }, [dispatch, light, theme]);
  const logOutHandler = useCallback(() => {
    auth().signOut();
  }, []);
  return (
    <div className={classes.content}>
      <div className={classes.toggle}>
        <div className={clsx(theme === 'light' && classes.opacityText)}>
          <Brightness4Icon />
        </div>
        <Switch checked={light} onChange={toggleTheme} name="checkedA" />
        <div className={clsx(theme !== 'light' && classes.opacityText)}>
          <Brightness7Icon />
        </div>
      </div>
      <Button className={classes.logOut} onClick={logOutHandler}>
        Log Out
      </Button>
    </div>
  );
};

export default Profile;
