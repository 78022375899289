import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Box,
  Typography,
  useMediaQuery,
  ClickAwayListener,
} from '@material-ui/core';
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  format,
  lastDayOfMonth,
  lastDayOfWeek,
  startOfWeek,
} from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import dateUtils from '@/utils/dates';
import { calendarConstants, userRoles } from '@/constants';
import clsx from 'clsx';
import { IEvent, IReport, userSelector } from '@/redux';
import { CalendarContentType } from '@/types/calendar';
import { useSelector } from 'react-redux';
import Cell from './components/Cell';
import Header from './components/Header';
import useStyles from './styles';
import { CalendarTypes, ICalendarProps } from './types';
import LightTooltip from '../Tooltips/LightTooltip';

const toNoon = (date: Date) => {
  date.setHours(12);
  return date;
};

const getMonthDatesGrid = (date: Date) => {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const firstDayInGrid = startOfWeek(firstDayOfMonth, { weekStartsOn: 1 });
  const weeks = eachWeekOfInterval(
    {
      start: firstDayInGrid,
      end: lastDayOfMonth(date),
    },
    { weekStartsOn: 1 },
  );

  return weeks.map((firstDayOfWeek) => {
    return eachDayOfInterval({
      start: firstDayOfWeek,
      end: lastDayOfWeek(firstDayOfWeek, { weekStartsOn: 1 }),
    }).map(toNoon);
  });
};

const getWeekDatesGrid = (date: Date) => {
  return [
    eachDayOfInterval({
      start: startOfWeek(date, { weekStartsOn: 1 }),
      end: lastDayOfWeek(date, { weekStartsOn: 1 }),
    }).map(toNoon),
  ];
};

function Calendar<T extends CalendarContentType>({
  className,
  onCellClick,
  CellContentComponent,
  TooltipComponent,
  editEventHandler,
  removeEventHandler,
  records,
  contentType,
  isOpenReportModal,
  openReportModal,
  setEventDate,
  ...props
}: ICalendarProps<T>): JSX.Element {
  const classes = useStyles();
  const [type, setType] = useState(CalendarTypes.month);
  const [date, setDate] = useState(new Date());
  const [isOpenTooltip, setIsOpenTooltip] = useState<Date | boolean>(false);
  const user = useSelector(userSelector);

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const grid = useMemo(
    () => (type === CalendarTypes.month ? getMonthDatesGrid(date) : getWeekDatesGrid(date)),
    [type, date],
  );

  const formatType = contentType === CalendarContentType.Reports ? 'dd MM yyyy' : 'yyyy-MM-dd';

  const clickHandler = useCallback(
    (dateRecord: Date) => {
      onCellClick?.(dateRecord);
      setIsOpenTooltip(dateRecord);
    },
    [onCellClick],
  );

  const editHandler = useCallback(
    (editableEvent: IEvent) => {
      editEventHandler?.(editableEvent);
      setIsOpenTooltip(false);
    },
    [editEventHandler],
  );

  const removeHandler = useCallback(
    (editableEventId: string) => {
      removeEventHandler?.(editableEventId);
      setIsOpenTooltip(false);
    },
    [removeEventHandler],
  );

  const handleTooltipClose = useCallback(() => {
    setIsOpenTooltip(false);
  }, []);

  const cellClass = useCallback(
    (recordDate: Date) => {
      if (contentType === CalendarContentType.Reports) {
        return '';
      }
      const data = records[format(recordDate, formatType)] as IEvent[];

      const dataSet = new Set(
        data?.map((record) => (user?.role === userRoles.admin ? record.status : null)),
      );
      const classEntries = [
        [classes.pendingCell, dataSet.has('Pending')],
        [
          classes.approvedCell,
          contentType === CalendarContentType.UserTimeOffs && dataSet.has('Approved'),
        ],
        [
          classes.rejectedCell,
          contentType === CalendarContentType.UserTimeOffs && dataSet.has('Rejected'),
        ],
      ] as const;

      const entry = classEntries.find(([, isValid]) => isValid);

      return entry?.[0] ?? '';
    },
    [
      contentType,
      records,
      formatType,
      classes.pendingCell,
      classes.approvedCell,
      classes.rejectedCell,
      user?.role,
    ],
  );
  const renderCell = useCallback(
    (recordDate: Date, isTooltip?: boolean) => {
      const hasVacation = records[format(recordDate, formatType)]?.some(
        (elem) =>
          contentType === CalendarContentType.Reports && (elem as IReport).taskType === 'Vacation',
      );
      return (
        <Cell
          key={recordDate.toString()}
          date={recordDate}
          contentType={contentType}
          disabled={date.getMonth() !== recordDate.getMonth()}
          onClick={() => (hasVacation ? setIsOpenTooltip(recordDate) : clickHandler(recordDate))}
          openReportModal={openReportModal}
          className={cellClass(recordDate)}
          isDesktop={matches}
          hasRecords={records[format(recordDate, formatType)]?.length > 0}
          hasVacation={hasVacation}
        >
          {isTooltip ? (
            <>
              {matches && (
                <LightTooltip
                  placement="right"
                  arrow
                  open={recordDate === isOpenTooltip}
                  title={
                    TooltipComponent ? (
                      <TooltipComponent
                        removeEventHandler={removeHandler}
                        editEventHandler={editHandler}
                        data={records[format(recordDate, formatType)]}
                      />
                    ) : (
                      <>{records[format(recordDate, formatType)]}</>
                    )
                  }
                >
                  <Box>
                    <CellContentComponent
                      data={records[format(recordDate, formatType)]}
                      contentType={contentType}
                      classes={classes}
                      {...(contentType === CalendarContentType.Reports && {
                        recordsLimit:
                          type === CalendarTypes.month
                            ? calendarConstants.monthRecordsLimit
                            : calendarConstants.weekRecordsLimit,
                      })}
                    />
                  </Box>
                </LightTooltip>
              )}
            </>
          ) : (
            <>
              {matches && (
                <CellContentComponent
                  data={records[format(recordDate, formatType)]}
                  contentType={contentType}
                  classes={classes}
                  {...(contentType === CalendarContentType.Reports && {
                    recordsLimit:
                      type === CalendarTypes.month
                        ? calendarConstants.monthRecordsLimit
                        : calendarConstants.weekRecordsLimit,
                  })}
                />
              )}
            </>
          )}
        </Cell>
      );
    },
    [
      records,
      formatType,
      contentType,
      date,
      openReportModal,
      cellClass,
      matches,
      isOpenTooltip,
      TooltipComponent,
      removeHandler,
      editHandler,
      CellContentComponent,
      classes,
      type,
      clickHandler,
    ],
  );

  return (
    <Grid container spacing={matches ? 2 : 0} {...props}>
      <Grid item xs={12}>
        <Header
          contentType={contentType}
          date={date}
          type={type}
          onTypeChange={setType}
          onDateChange={setDate}
          setEventDate={setEventDate}
          matches={matches}
        />
        {contentType !== CalendarContentType.Reports && (
          <div className={classes.statuses}>
            <div className={classes.status}>
              {contentType === CalendarContentType.UserTimeOffs && (
                <>
                  <div className={clsx(classes.indicator, classes.approved)} />
                  <Typography className={classes.statusText}>Approved</Typography>
                  <div className={clsx(classes.indicator, classes.pending)} />
                  <Typography className={classes.statusText}>Pending</Typography>
                </>
              )}
            </div>
            <div className={classes.status}>
              {contentType === CalendarContentType.TimeOffs && (
                <>
                  <div className={clsx(classes.indicator, classes.pending)} />
                  <Typography className={classes.statusText}>Pending</Typography>
                </>
              )}
            </div>
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <TableContainer className={className} component={Paper}>
            <Table aria-label="calendar dates table">
              <TableHead>
                <TableRow>
                  {dateUtils.getWeekDays(!matches).map((day) => (
                    <TableCell key={day} align="center" className={classes.gridHeader}>
                      {day}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {grid.map((row) => (
                  <TableRow key={row[0].toString()}>
                    {row.map((recordDate) =>
                      typeof TooltipComponent === 'undefined' ||
                      records[format(recordDate, formatType)] === undefined
                        ? renderCell(recordDate)
                        : renderCell(recordDate, true),
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ClickAwayListener>
      </Grid>
    </Grid>
  );
}

export default Calendar;
