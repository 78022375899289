import FullScreenDialog from '@/components/FullScreenDialog';
import { MobileVacationForm } from '@/components/Forms';
import { IVacationDialogProps } from './types';

const VacationDialog = ({
  openDialog,
  onSubmitHandler,
  date,
  setDate,
  closeModal,
  user,
  handleDialogClose,
  ...props
}: IVacationDialogProps): JSX.Element => {
  return (
    <FullScreenDialog open={openDialog} handleDialogClose={handleDialogClose}>
      <div {...props}>
        <MobileVacationForm
          onSubmitHandler={onSubmitHandler}
          date={date}
          setDate={setDate}
          closeModal={closeModal}
          user={user}
        />
      </div>
    </FullScreenDialog>
  );
};

export default VacationDialog;
