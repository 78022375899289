import { createSelector } from 'reselect';
import { IApplicationState } from '../types';
import { ITimeOffsState } from './types';

export const timeOffsStateSelector = (state: IApplicationState): ITimeOffsState => state.timeOffs;

export const eventsSelector = createSelector(timeOffsStateSelector, (state) => state.events);
export const nextEventsSelector = createSelector(
  timeOffsStateSelector,
  (state) => state.nextEvents,
);
export const vacationsSelector = createSelector(timeOffsStateSelector, (state) => state.vacations);
export const allEventsSelector = createSelector(timeOffsStateSelector, (state) => state.allEvents);
export const vacationsDetailsSelector = createSelector(
  timeOffsStateSelector,
  (state) => state.vacationsDetails,
);

export const eventsLoadingSelector = createSelector(
  timeOffsStateSelector,
  (state) => state.isLoading,
);

export const pendingVacationsSelector = createSelector(
  timeOffsStateSelector,
  (state) => state.pendingVacations,
);
