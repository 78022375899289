import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    overflow: 'visible',
    marginTop: '62px',
    borderRadius: '6px',
    border: '1px solid #fff',
    cursor: 'pointer',
  },
  cardLink: {
    textDecoration: 'none',
  },
  contentCard: {
    padding: '0 20px 20px',
  },
  avatar: {
    width: '124px',
    height: '124px',
    margin: '0 auto -62px',
    top: '-62px',
  },
  name: {
    textAlign: 'center',
    margin: theme.spacing(3, 0),
    paddingBottom: '6px',
    borderBottom: `1px solid ${theme.palette.basic.pink}`,
  },
  info: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    marginTop: '18px',
  },
  infoTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: 20,
  },
  blurred: {
    filter: `blur(10px)`,
  },
}));

export default useStyles;
