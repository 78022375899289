import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      boxShadow: '3px 0px 15px rgba(78, 81, 97, 0.14)',
      width: '100%',
    },
    datePicker: {
      minHeight: '76px',
      margin: '0px 0px 24px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    datePickerInput: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    button: {
      textTransform: 'none',
    },
  }),
);

export default useStyles;
