import { useState } from 'react';
import {
  TextField,
  InputLabel,
  FormGroup,
  FormControl,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import clsx from 'clsx';
import useStyles from '../styles';
import { ICustomInputProps } from '../types';

export default function PasswordInput({
  label,
  id,
  error,
  labelClass,
  ...rest
}: ICustomInputProps): JSX.Element {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormGroup>
      <FormControl variant="outlined" className={classes.wrapper} error={!!error}>
        {label && (
          <InputLabel
            margin="dense"
            htmlFor={id}
            className={clsx(classes.label, labelClass)}
            error={!!error}
          >
            {label}
          </InputLabel>
        )}
        <TextField
          variant="outlined"
          id={id}
          type={showPassword ? 'text' : 'password'}
          className={classes.input}
          error={error}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle-password-visibility"
                  onClick={() => setShowPassword((value) => !value)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          {...rest}
        />
      </FormControl>
    </FormGroup>
  );
}
