export const LOAD_PROJECTS = 'PROJECTS/LOAD_PROJECTS ';
export const LOAD_USER_PROJECTS = 'PROJECTS/LOAD_USER_PROJECTS ';
export const LOAD_PROJECTS_BY_USER = 'PROJECTS/LOAD_PROJECTS_BY_USER';
export const CLEAR_PROJECTS = 'PROJECTS/CLEAR_PROJECTS ';
export const STORE_PROJECT = 'PROJECTS/STORE_PROJECT ';
export const SET_LOADING_PROJECTS = 'PROJECTS/SET_LOADING_PROJECTS ';
export const STORE_PROJECTS = 'PROJECTS/STORE_PROJECTS ';
export const STORE_USER_PROJECTS = 'PROJECTS/STORE_USER_PROJECTS ';
export const STORE_PROJECTS_BY_USER = 'PROJECTS/STORE_PROJECTS_BY_USER ';
export const CLEAR_PROJECTS_BY_USER = 'PROJECTS/CLEAR_PROJECTS_BY_USER';
export const LOAD_PROJECT = 'PROJECTS/LOAD_PROJECT';
export const CREATE_PROJECT = 'PROJECTS/CREATE_PROJECT';
export const EDIT_PROJECT = 'PROJECTS/EDIT_PROJECT';
export const ATTACH_USER = 'PROJECTS/ATTACH_USER';
export const DETACH_USER = 'PROJECTS/DETACH_USER';
export const LOAD_USERS_RATES = 'PROJECTS/LOAD_USERS_RATES';
export const STORE_USERS_RATES = 'PROJECTS/STORE_USERS_RATES';
export const EDIT_USER_RATE = 'PROJECTS/EDIT_USER_RATE';
export const DELETE_USER_RATE = 'PROJECTS/DELETE_USER_RATE';
