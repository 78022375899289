import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import usersService from '@/services/users.service';
import { toast } from 'react-toastify';
import logError from '@/utils/errors';
import {
  AddUserAction,
  ADD_USER,
  EditUserAction,
  EDIT_USER,
  IUsersWithPagination,
  LoadUserAction,
  LoadUsersAction,
  LOAD_USER,
  LOAD_USERS,
  usersPaginationSelector,
  ATTACH_PROJECT,
  AttachProjectAction,
  CHANGE_USER_PHOTO,
  ChangeUserPhotoAction,
} from '@/redux/users';
import { IPagination, IUser } from '@/interfaces';
import { storeUser, storeUsers, setLoadingUsers, updateUserPhoto } from './actions';

function* loadUsers({ payload }: LoadUsersAction): SagaIterator {
  try {
    yield put(setLoadingUsers(true));

    const usersPagination: IPagination = yield select(usersPaginationSelector);

    const users: IUsersWithPagination = yield call(
      usersService.loadUsers,
      payload && {
        ...payload,
        page: typeof usersPagination.currentPage === 'number' ? usersPagination.currentPage + 1 : 0,
      },
    );
    yield put(storeUsers(users));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingUsers(false));
  }
}

function* loadUser({ payload }: LoadUserAction): SagaIterator {
  try {
    yield put(setLoadingUsers(true));

    const user: IUser = yield call(usersService.loadUser, payload);

    yield put(storeUser(user));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingUsers(false));
  }
}

function* editUser(action: EditUserAction): SagaIterator {
  try {
    yield put(setLoadingUsers(true));
    const editedUser: IUser = yield call(usersService.editUser, action.payload.user);
    yield put(storeUser(editedUser));
    action.payload.onSuccess?.();
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingUsers(false));
  }
}

function* addUser(action: AddUserAction): SagaIterator {
  try {
    yield put(setLoadingUsers(true));
    const addedUser: IUser = yield call(usersService.addUser, action.payload.user);
    yield put(storeUser(addedUser));
    action.payload.onSuccess?.();
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingUsers(false));
  }
}

function* attachProject(action: AttachProjectAction): SagaIterator {
  try {
    yield put(setLoadingUsers(true));
    yield call(usersService.attachProjects, action.payload);
    toast.success('Project(s) was successfully attached');
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingUsers(false));
  }
}

function* changePhoto(action: ChangeUserPhotoAction): SagaIterator {
  try {
    yield put(setLoadingUsers(true));
    const response = yield call(usersService.changePhoto, action.payload);
    yield put(updateUserPhoto({ photo: response.data, id: action.payload.id }));
    toast.success('Successful photo change');
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingUsers(false));
  }
}

function* usersRootSaga() {
  yield all([
    takeLatest(LOAD_USERS, loadUsers),
    takeLatest(LOAD_USER, loadUser),
    takeLatest(EDIT_USER, editUser),
    takeLatest(ADD_USER, addUser),
    takeLatest(ATTACH_PROJECT, attachProject),
    takeLatest(CHANGE_USER_PHOTO, changePhoto),
  ]);
}

export default usersRootSaga;
