import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    closeButton: {
      justifyContent: 'flex-end',
    },
    formWrapper: {
      padding: '0px 16px',
    },
  }),
);

export default useStyles;
