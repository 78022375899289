import {
  LOAD_NOTIFICATIONS,
  LoadNotificationsAction,
  SET_LOADING_NOTIFICATIONS,
  SetLoadingNotificationsAction,
  STORE_NOTIFICATIONS,
  StoreNotificationsAction,
  StoreCountNewNotificationsAction,
  STORE_COUNT_NEW_NOTIFICATIONS,
  NEW_NOTIFICATIONS_COUNT,
  NewNotificationsCountAction,
  INotification,
  LoadNewNotificationsAction,
  LOAD_NEW_NOTIFICATIONS,
  StoreNewNotificationsAction,
  STORE_NEW_NOTIFICATIONS,
} from '@/redux/notifications';

export function loadNotifications(): LoadNotificationsAction {
  return {
    type: LOAD_NOTIFICATIONS,
  };
}

export function loadNewNotifications(isNew: boolean): LoadNewNotificationsAction {
  return {
    type: LOAD_NEW_NOTIFICATIONS,
    payload: isNew,
  };
}

export function newNotificationsCount(): NewNotificationsCountAction {
  return {
    type: NEW_NOTIFICATIONS_COUNT,
  };
}

export function setLoadingNotifications(isLoading: boolean): SetLoadingNotificationsAction {
  return {
    type: SET_LOADING_NOTIFICATIONS,
    payload: isLoading,
  };
}

export function storeNotifications(payload: INotification[]): StoreNotificationsAction {
  return {
    type: STORE_NOTIFICATIONS,
    payload,
  };
}

export function storeNewNotifications(payload: INotification[]): StoreNewNotificationsAction {
  return {
    type: STORE_NEW_NOTIFICATIONS,
    payload,
  };
}

export function storeCountNewNotifications(payload: {
  count: number;
}): StoreCountNewNotificationsAction {
  return {
    type: STORE_COUNT_NEW_NOTIFICATIONS,
    payload,
  };
}
