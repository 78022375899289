import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useTheme } from '@material-ui/core/styles';
import { ILogoProps } from './types';

const Logo: React.FC<ILogoProps> = ({ showCaption, ...props }) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props} viewBox="0 0 1197 284">
      {showCaption && (
        <path
          d="M358.95 71.3008H425.34C442 71.3008 456.77 74.6008 469.68 81.2108C482.59 87.8108 492.6 97.0708 499.73 109.001C506.85 120.931 510.42 134.621 510.42 150.081C510.42 165.541 506.86 179.221 499.73 191.151C492.6 203.081 482.59 212.341 469.68 218.941C456.77 225.541 442 228.851 425.34 228.851H358.95V71.3008ZM423.99 209.261C436.74 209.261 447.96 206.791 457.63 201.831C467.31 196.881 474.77 189.941 480.03 181.011C485.28 172.091 487.91 161.771 487.91 150.071C487.91 138.371 485.28 128.051 480.03 119.121C474.78 110.191 467.31 103.251 457.63 98.3008C447.96 93.3508 436.74 90.8708 423.99 90.8708H381.45V209.251H423.99V209.261Z"
          fill={theme.palette.type === 'light' ? '#323232' : '#fff'}
        />
      )}
      {showCaption && (
        <path
          d="M648.26 176.401H551.49C552.84 186.901 557.45 195.341 565.33 201.721C573.21 208.101 583 211.281 594.7 211.281C608.95 211.281 620.43 206.481 629.13 196.881L641.06 210.831C635.66 217.131 628.94 221.941 620.92 225.231C612.89 228.531 603.93 230.181 594.02 230.181C581.42 230.181 570.24 227.591 560.49 222.421C550.74 217.241 543.19 210.011 537.87 200.711C532.54 191.411 529.88 180.901 529.88 169.201C529.88 157.641 532.47 147.221 537.64 137.921C542.82 128.621 549.95 121.381 559.02 116.201C568.1 111.021 578.34 108.441 589.74 108.441C601.14 108.441 611.3 111.031 620.24 116.201C629.16 121.381 636.14 128.621 641.17 137.921C646.19 147.221 648.71 157.871 648.71 169.881C648.71 171.531 648.55 173.701 648.26 176.401ZM563.74 136.111C556.77 142.411 552.68 150.671 551.48 160.871H628C626.8 150.821 622.71 142.601 615.73 136.231C608.75 129.861 600.08 126.671 589.74 126.671C579.39 126.661 570.73 129.811 563.74 136.111Z"
          fill={theme.palette.type === 'light' ? '#323232' : '#fff'}
        />
      )}
      {showCaption && (
        <path
          d="M778.7 109.561L726.49 228.841H704.43L652.22 109.561H674.72L715.68 205.211L757.54 109.561H778.7Z"
          fill={theme.palette.type === 'light' ? '#323232' : '#fff'}
        />
      )}
      {showCaption && (
        <path
          d="M798.46 82.5509C795.68 79.8509 794.29 76.5509 794.29 72.6409C794.29 68.7409 795.67 65.4009 798.46 62.6309C801.23 59.8609 804.72 58.4609 808.92 58.4609C813.12 58.4609 816.61 59.7709 819.39 62.4009C822.16 65.0309 823.56 68.2909 823.56 72.1909C823.56 76.2409 822.17 79.6609 819.39 82.4309C816.62 85.2009 813.12 86.6009 808.92 86.6009C804.72 86.6009 801.23 85.2509 798.46 82.5509ZM798.13 109.561H808.93H819.73V169.201V228.841H808.93H798.13V169.201V109.561Z"
          fill={theme.palette.type === 'light' ? '#323232' : '#fff'}
        />
      )}
      {showCaption && (
        <path
          d="M1036.99 121.48C1045.77 130.18 1050.16 143.08 1050.16 160.19V228.83H1028.56V162.67C1028.56 151.11 1025.89 142.41 1020.57 136.56C1015.24 130.72 1007.63 127.79 997.72 127.79C986.77 127.79 978.06 131.2 971.61 138.03C965.15 144.86 961.93 154.64 961.93 167.4V228.84H940.33V162.68C940.33 151.12 937.67 142.42 932.34 136.57C927.01 130.73 919.4 127.8 909.49 127.8C898.54 127.8 889.83 131.21 883.38 138.04C876.93 144.87 873.7 154.65 873.7 167.41V228.85H852.1V109.56H872.8V127.34C877.16 121.19 882.85 116.5 889.91 113.28C896.96 110.06 904.99 108.44 913.99 108.44C923.29 108.44 931.55 110.32 938.75 114.07C945.95 117.82 951.5 123.29 955.4 130.49C959.9 123.59 966.17 118.2 974.19 114.29C982.21 110.39 991.26 108.44 1001.31 108.44C1016.33 108.43 1028.22 112.79 1036.99 121.48Z"
          fill={theme.palette.type === 'light' ? '#323232' : '#fff'}
        />
      )}
      {showCaption && (
        <path
          d="M1196 109.561V228.841H1175.3V209.941C1170.49 216.541 1164.42 221.571 1157.06 225.021C1149.71 228.481 1141.61 230.201 1132.76 230.201C1121.2 230.201 1110.85 227.651 1101.7 222.551C1092.55 217.451 1085.38 210.281 1080.2 201.061C1075.03 191.831 1072.44 181.211 1072.44 169.211C1072.44 157.201 1075.03 146.631 1080.2 137.481C1085.38 128.331 1092.54 121.201 1101.7 116.101C1110.85 111.001 1121.2 108.451 1132.76 108.451C1141.31 108.451 1149.18 110.071 1156.39 113.291C1163.6 116.511 1169.6 121.281 1174.4 127.581V109.571H1196V109.561ZM1155.04 205.991C1161.19 202.471 1165.99 197.521 1169.44 191.141C1172.9 184.771 1174.62 177.451 1174.62 169.201C1174.62 160.951 1172.9 153.631 1169.44 147.251C1165.99 140.881 1161.19 135.961 1155.04 132.511C1148.89 129.061 1142.06 127.331 1134.56 127.331C1126.91 127.331 1120.05 129.061 1113.97 132.511C1107.89 135.961 1103.09 140.881 1099.57 147.251C1096.04 153.631 1094.28 160.941 1094.28 169.201C1094.28 177.451 1096.04 184.771 1099.57 191.141C1103.09 197.521 1107.89 202.471 1113.97 205.991C1120.05 209.521 1126.91 211.281 1134.56 211.281C1142.06 211.281 1148.88 209.521 1155.04 205.991Z"
          fill={theme.palette.type === 'light' ? '#323232' : '#fff'}
        />
      )}

      <path
        d="M109.39 271.83V271.74L57.5602 246.75C53.7702 244.93 50.4002 242.49 47.5702 239.56C41.0602 233.02 37.2402 228.43 37.2102 218.86L36.7901 130.45C36.7702 125.78 41.5202 122.61 45.8202 124.41L86.8702 141.61C100.2 147.16 108.9 160.18 108.96 174.62L109.15 215.46L109.34 259.39C109.34 259.57 109.34 259.73 109.37 259.91C109.34 259.97 109.34 260 109.37 260.06C109.65 267.49 113.84 273.57 119.67 276.75L109.39 271.83Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M268.02 130.2L277.48 202.51V206.8C277.45 207.42 277.36 208 277.23 208.62C277.17 208.99 277.11 209.39 277.01 209.73C276.98 209.82 276.98 209.88 276.95 209.98C276.92 210.07 276.92 210.13 276.89 210.23C276.8 210.63 276.67 211.06 276.52 211.43C276.52 211.46 276.49 211.49 276.49 211.52C276.21 212.38 275.84 213.22 275.41 214.02C275.22 214.36 275.04 214.67 274.85 214.98C274.39 215.75 273.83 216.49 273.21 217.2C272.84 217.63 272.47 218.03 272.04 218.43C271.48 218.96 270.9 219.45 270.25 219.91C269.63 220.37 268.95 220.8 268.21 221.21C267.35 221.7 266.45 222.14 265.53 222.57L137.63 281.77H137.6C132.36 284.21 126.83 284.15 122.02 282.26C121.99 282.26 121.96 282.26 121.93 282.23L119.68 281.15L119.65 281.12L109.38 276.18V276.09L57.5601 251.1C53.7701 249.28 50.4001 246.84 47.5701 243.91C41.6201 237.93 37.9101 229.97 37.3301 221.33C38.5301 234.07 48.9601 243.23 60.8401 237.8L60.8701 237.77L109.15 215.47L215.33 166.39L257.11 147.1L258.22 146.55C258.31 146.49 258.41 146.43 258.53 146.36C258.56 146.33 258.59 146.33 258.62 146.33C258.96 146.14 259.33 145.9 259.64 145.68C260.04 145.43 260.44 145.16 260.81 144.88C261.18 144.57 261.52 144.29 261.86 143.95C262.01 143.83 262.17 143.67 262.32 143.52C262.41 143.46 262.47 143.4 262.57 143.3C263 142.9 263.37 142.47 263.74 142C264.3 141.32 264.82 140.61 265.28 139.84C265.59 139.32 265.9 138.76 266.14 138.21C266.17 138.18 266.2 138.15 266.2 138.12C266.45 137.63 266.66 137.1 266.85 136.58C266.88 136.49 266.91 136.39 266.94 136.3C267 136.15 267.06 135.96 267.12 135.78C267.24 135.32 267.4 134.86 267.49 134.39C267.55 134.17 267.58 133.93 267.61 133.71C267.7 133.37 267.76 133.03 267.8 132.69C267.86 132.44 267.86 132.2 267.89 131.95C267.95 131.61 267.98 131.27 267.98 130.96C268.02 130.72 268.02 130.47 268.02 130.2Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M277.48 125.83V204.19C277.02 197.43 273.04 190.83 265.85 187.84L215.29 166.65L26.5 87.5705C10.46 80.8505 0 65.1505 0 47.7205V6.88046C0 2.23046 4.74 -0.919539 9.03 0.870461L264 107.69C265.3 108.25 266.53 108.83 267.73 109.48C274.27 112.96 277.48 119.38 277.48 125.83Z"
        fill="url(#paint2_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="88.0182"
          y1="124.619"
          x2="41.9792"
          y2="232.883"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDABFF" />
          <stop offset="1" stopColor="#4A36FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="110.524"
          y1="206.916"
          x2="273.063"
          y2="206.916"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDABFF" />
          <stop offset="1" stopColor="#4A36FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="285.597"
          y1="102.273"
          x2="-27.6181"
          y2="102.273"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.000165344" stopColor="#00D9A2" />
          <stop offset="0.2307" stopColor="#01D9A6" />
          <stop offset="0.4621" stopColor="#05D8B2" />
          <stop offset="0.6941" stopColor="#0CD8C6" />
          <stop offset="0.9251" stopColor="#15D6E2" />
          <stop offset="1" stopColor="#18D6ED" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default Logo;
