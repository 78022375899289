import { Modal, Card, CardContent, Button, Typography, Grid } from '@material-ui/core';
import { useState } from 'react';
import { addDays, format, startOfDay } from 'date-fns';
import pluralize from 'pluralize';
import { DateFormatUtil } from '@/utils';
import useStyles from '../styles';
import useLocalStyles from './styles';
import { IRejectVacationModalProps } from '../types';

const RejectVacationModal = ({
  open,
  closeModalHandler,
  onConfirm,
  vacation,
}: IRejectVacationModalProps): JSX.Element => {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const [isLoading, setLoading] = useState(false);

  const closeModal = () => {
    closeModalHandler(false);
  };

  const submitModal = async () => {
    if (!vacation) {
      return;
    }

    setLoading(true);
    try {
      await onConfirm(vacation);
    } finally {
      setLoading(false);
    }
  };

  if (!vacation) {
    return <></>;
  }

  const confirmationText = (
    <>
      <Typography>
        {DateFormatUtil.formatToDate(vacation.startDate)} -{' '}
        {DateFormatUtil.formatToDate(vacation.endDate)} ({vacation.duration}{' '}
        {pluralize('day', vacation.duration)})
      </Typography>
      <br />
      <Typography>
        You are going to reject <b>{vacation.status.toLowerCase()}</b> vacation request.
      </Typography>
      <br />
      {vacation.status === 'Approved' && vacation.left < vacation.duration && (
        <Typography>
          <Typography color="secondary">This vacation is already in progress!</Typography> Only{' '}
          {vacation.left} {pluralize('day', vacation.duration)} can be rejected:
          <br /> ({format(addDays(startOfDay(Date.now()), 1), 'dd.MM.yyyy')} -{' '}
          {DateFormatUtil.formatToDate(vacation.endDate)}).
        </Typography>
      )}
    </>
  );

  return (
    <Modal
      open={open}
      onClose={closeModal}
      className={classes.modalWrap}
      disableEnforceFocus
      disableAutoFocus
    >
      <Card className={classes.modalCard}>
        <CardContent className={classes.modalCardContent}>
          <Grid container spacing={2}>
            <Typography className={localClasses.title}>Reject vacation</Typography>
            <Typography>{confirmationText}</Typography>

            <Grid item xs={12}>
              <div className={localClasses.actions}>
                <Button variant="outlined" onClick={closeModal}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={submitModal}
                  disabled={isLoading}
                >
                  Confirm Rejection
                </Button>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default RejectVacationModal;
