import { IAttachmentData, IDetachmentData, IProject, IEditAttachmentData } from '@/interfaces';
import {
  StoreProjectsAction,
  STORE_PROJECTS,
  STORE_PROJECT,
  SetLoadingProjectsAction,
  SET_LOADING_PROJECTS,
  LoadProjectsAction,
  LOAD_PROJECTS,
  CreateProjectAction,
  CREATE_PROJECT,
  EDIT_PROJECT,
  EditProjectAction,
  AttachUserAction,
  ATTACH_USER,
  DETACH_USER,
  EditUserRateAction,
  EDIT_USER_RATE,
  DetachUserAction,
  IProjectsWithPagination,
  ClearProjectsAction,
  CLEAR_PROJECTS,
  LoadProjectsActionProps,
  StoreProjectAction,
  LoadUsersRatesActionProps,
  LoadUsersRatesAction,
  LOAD_USERS_RATES,
  StoreUsersRatesAction,
  STORE_USERS_RATES,
  IUserRateData,
  LoadProjectAction,
  LOAD_PROJECT,
  LoadUserProjectsAction,
  LOAD_USER_PROJECTS,
  StoreUserProjectsAction,
  STORE_USER_PROJECTS,
  LoadProjectsByUserAction,
  LOAD_PROJECTS_BY_USER,
  StoreProjectsByUserAction,
  STORE_PROJECTS_BY_USER,
  LoadProjectsByUserActionProps,
  CLEAR_PROJECTS_BY_USER,
  ClearProjectsByUserAction,
  SingleProjectActionProps,
  DELETE_USER_RATE,
  DeleteUserRateAction,
  DeleteUserRateActionProps,
} from '@/redux/projects';

export function loadProjects(payload: LoadProjectsActionProps): LoadProjectsAction {
  return {
    type: LOAD_PROJECTS,
    payload,
  };
}

export function loadProjectsByUser(
  payload: LoadProjectsByUserActionProps,
): LoadProjectsByUserAction {
  return {
    type: LOAD_PROJECTS_BY_USER,
    payload,
  };
}

export function loadUserProjects(): LoadUserProjectsAction {
  return {
    type: LOAD_USER_PROJECTS,
  };
}

export function clearProjects(): ClearProjectsAction {
  return {
    type: CLEAR_PROJECTS,
  };
}

export function storeProjects(payload: IProjectsWithPagination): StoreProjectsAction {
  return {
    type: STORE_PROJECTS,
    payload,
  };
}

export function storeProject(payload: IProject): StoreProjectAction {
  return {
    type: STORE_PROJECT,
    payload,
  };
}

export function storeProjectsByUser(payload: IProject[]): StoreProjectsByUserAction {
  return {
    type: STORE_PROJECTS_BY_USER,
    payload,
  };
}

export function clearProjectsByUser(): ClearProjectsByUserAction {
  return {
    type: CLEAR_PROJECTS_BY_USER,
  };
}

export function storeUserProjects(payload: IProject[]): StoreUserProjectsAction {
  return {
    type: STORE_USER_PROJECTS,
    payload,
  };
}

export function setLoadingProjects(isLoading: boolean): SetLoadingProjectsAction {
  return {
    type: SET_LOADING_PROJECTS,
    payload: isLoading,
  };
}

export function loadProject(payload: string): LoadProjectAction {
  return {
    type: LOAD_PROJECT,
    payload,
  };
}

export function createProject(payload: SingleProjectActionProps): CreateProjectAction {
  return {
    type: CREATE_PROJECT,
    payload,
  };
}

export function editProject(payload: SingleProjectActionProps): EditProjectAction {
  return {
    type: EDIT_PROJECT,
    payload,
  };
}

export function attachUser(attachmentData: IAttachmentData): AttachUserAction {
  return {
    type: ATTACH_USER,
    payload: attachmentData,
  };
}

export function detachUser(detachmentData: IDetachmentData): DetachUserAction {
  return {
    type: DETACH_USER,
    payload: detachmentData,
  };
}

export function loadUsersRates(payload: LoadUsersRatesActionProps): LoadUsersRatesAction {
  return {
    type: LOAD_USERS_RATES,
    payload,
  };
}

export function storeUsersRates(payload: IUserRateData[]): StoreUsersRatesAction {
  return {
    type: STORE_USERS_RATES,
    payload,
  };
}

export function editUserRate(attachmentData: IEditAttachmentData): EditUserRateAction {
  return {
    type: EDIT_USER_RATE,
    payload: attachmentData,
  };
}

export function deleteUserRate(payload: DeleteUserRateActionProps): DeleteUserRateAction {
  return {
    type: DELETE_USER_RATE,
    payload,
  };
}
