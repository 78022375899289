import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: '16px',
  },
  slideBlock: {
    background: theme.palette.primary.main,
    padding: '16px',
    maxHeight: '70px',
    maxWidth: '155px',
    borderRadius: '12px',
    color: theme.palette.white,
    minWidth: '155px',
    marginRight: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  slideTitle: {
    fontWeight: 700,
  },
}));

export default useStyles;
