import { useMemo } from 'react';
import { CircularProgressProps } from './types';

const SIZE = 44;

const useCircularProgress = ({ value, thickness = 2 }: CircularProgressProps) => {
  const radius = useMemo(() => (SIZE - thickness) / 2, [thickness]);
  const circumference = useMemo(() => 2 * Math.PI * ((SIZE - thickness) / 2), [thickness]);
  const offset = useMemo(
    () => (((100 - (value > 100 ? 100 : value)) / 100) * circumference).toFixed(3),
    [circumference, value],
  );

  return { offset, radius, circumference, SIZE };
};

export default useCircularProgress;
