import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { IEvent } from '@/redux';
import useStyles from './styles';
import { ICalendarTooltipProps } from '../Calendar/types';

const UserEventTooltip = ({ data, ...props }: ICalendarTooltipProps<IEvent>): JSX.Element => {
  const localClasses = useStyles();
  const renderData = data ?? [];

  return (
    <Grid className={localClasses.root} {...props}>
      {renderData.map((item, index) => (
        <Grid container key={index}>
          {item.type !== 'Vacation' ? (
            <Grid item xs={12} className={localClasses.notVacation}>
              <div
                className={clsx(
                  localClasses.icon,
                  item.type === 'Birthday' ? localClasses.bDay : localClasses.sun,
                )}
              />
              <Typography className={localClasses.itemText}>{item.eventName}</Typography>
            </Grid>
          ) : (
            <Grid item xs={12} className={localClasses.vacation}>
              <Typography className={localClasses.itemText}>{item.eventName}</Typography>
              <Typography className={localClasses.itemText}>{item.description}</Typography>
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default UserEventTooltip;
