import { clearUsers, loadUsers, LoadUsersActionProps } from '@/redux';
import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useFetchUsers = (
  searchName: string,
  { status, limit, order, orderBy, roles }: LoadUsersActionProps,
): (() => void) => {
  const dispatch = useDispatch();
  const fetchUsers = useCallback(() => {
    const trimmedName = searchName.trim();
    dispatch(
      loadUsers({
        ...(trimmedName && { name: trimmedName }),
        ...(status && { status }),
        ...(roles?.length && { roles }),
        limit,
        order,
        orderBy,
      }),
    );
  }, [dispatch, searchName, status, limit, order, orderBy, roles]);

  useEffect(() => {
    dispatch(clearUsers());
    fetchUsers();
  }, [dispatch, fetchUsers]);

  useEffect(() => {
    return () => {
      dispatch(clearUsers());
    };
  }, [dispatch]);

  return fetchUsers;
};

export default useFetchUsers;
