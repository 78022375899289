import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '105px',
    height: '39px',
    fontSize: '14px',
    padding: '10px',
    textTransform: 'none',
  },
  currentUser: {
    marginRight: '24px',
    width: 50,
    height: 50,
  },
  earningCardItem: {
    margin: '25px 0',
  },
  avatar: {
    margin: '0px 0px 32px 20px',
    display: 'flex',
    alignItems: 'center',
  },
  slider: {
    width: 'inherit',
    marginTop: '18px',
    padding: '10px 0px 28px 20px',
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    '& .slick-slider': {
      width: 'inherit',
    },
    '& .slick-list': {
      maxWidth: '500px',
    },
  },
  total: {
    fontWeight: 'bold',
    fontSize: '15px',
    marginBottom: '10px',
  },
  datePicker: {
    minHeight: '76px',
    margin: '0px 24px 24px 20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  datePickerInput: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  exportBtn: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: 'auto',
  },
  projectInputBlock: {
    display: 'flex',
    margin: '0 0 0 20px',
    minWidth: '282px',
    width: 'auto',
    '& .MuiOutlinedInput-root': {
      paddingTop: '0px',
      paddingBottom: '0px',
      minHeight: '44px',
    },
  },
  projectInput: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      '& .MuiOutlinedInput-input': {
        padding: '11px 8px',
      },
    },
  },
  datePickerBlock: {
    marginTop: '-25px',
    width: '100%',
    margin: '0px 20px',
  },
  boldText: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '22px',
    marginBottom: '20px',
  },
  userInfoBlock: {
    margin: '-25px 20px 40px 20px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  secondaryBoldText: {
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '20px',
    margin: '0 0 7px 0',
  },
}));

export default useStyles;
