import { Avatar, Typography, Tooltip, Button } from '@material-ui/core';
import AvatarGroup from '@mui/lab/AvatarGroup';
import { Link } from 'react-router-dom';
import { BackArrow } from '@/components/Icons';
import clsx from 'clsx';
import { useCallback } from 'react';
import useStyles from './styles';
import { IProjectHeaderProps } from './types';
import DesktopRangeDatePicker from '../DesktopRangeDatePicker';

export default function ProjectHeader({
  activeUsers,
  setActiveUsers,
  currentProject,
  date,
  setDate,
}: IProjectHeaderProps) {
  const classes = useStyles();

  const handleClick = useCallback(
    (id: string) => () => {
      let ids: string[] = [];
      if (!activeUsers.length) {
        ids =
          currentProject?.teamMembers
            .map((member) => member.user.id)
            .filter((member) => member !== id) || [];
      } else if (activeUsers.length === 1 && activeUsers.includes(id)) {
        return;
      } else {
        ids = activeUsers.includes(id)
          ? activeUsers.filter((item) => item !== id)
          : [...activeUsers, id];
      }

      setActiveUsers(ids);
    },
    [activeUsers, currentProject?.teamMembers, setActiveUsers],
  );

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.titleWrap}>
          <Typography className={classes.title} variant="h1" component="h1">
            {currentProject?.projectName ?? ''}
          </Typography>
          <div className={classes.userList}>
            <AvatarGroup max={100}>
              {currentProject &&
                currentProject.teamMembers.map((user) => (
                  <Tooltip
                    key={user.user.id}
                    className={classes.memberTooltip}
                    classes={{ tooltip: classes.memberTooltip }}
                    title={`${user?.user?.firstName} ${user?.user?.lastName}`}
                  >
                    <div
                      className={clsx({
                        [classes.active]:
                          activeUsers.includes(user?.user?.id) || !activeUsers.length,
                      })}
                    >
                      <Avatar
                        alt={user?.user?.firstName ?? ''}
                        src={user?.user?.photo || '/src/assets/svg/logo.svg'}
                        onClick={handleClick(user?.user?.id)}
                      />
                    </div>
                  </Tooltip>
                ))}
            </AvatarGroup>
            <Link className={classes.buttonLink} to="/projects">
              <Button
                variant="contained"
                color="primary"
                className={classes.backButton}
                startIcon={<BackArrow />}
              >
                Back
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div className={classes.datePicker}>
        <DesktopRangeDatePicker date={date} setDate={setDate} />
      </div>
    </div>
  );
}
