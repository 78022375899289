import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import logError from '@/utils/errors';
import {
  LOAD_NOTIFICATIONS,
  NEW_NOTIFICATIONS_COUNT,
  LOAD_NEW_NOTIFICATIONS,
  LoadNewNotificationsAction,
  INotification,
} from '@/redux/notifications';
import { notificationsService } from '@/services';
import {
  setLoadingNotifications,
  storeNotifications,
  storeCountNewNotifications,
  storeNewNotifications,
} from './actions';

function* loadNotifications(): SagaIterator {
  try {
    yield put(setLoadingNotifications(true));

    const notification: INotification[] = yield call(notificationsService.loadNotifications);
    yield put(storeNotifications(notification));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingNotifications(false));
  }
}

function* loadNewNotifications({ payload }: LoadNewNotificationsAction): SagaIterator {
  try {
    yield put(setLoadingNotifications(true));

    const notification: INotification[] = yield call(
      notificationsService.loadNewNotifications,
      payload,
    );
    yield put(storeNewNotifications(notification));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingNotifications(false));
  }
}

function* countNewNotifications(): SagaIterator {
  try {
    yield put(setLoadingNotifications(true));

    const notificationCount: {
      count: number;
    } = yield call(notificationsService.countNewNotifications);
    yield put(storeCountNewNotifications(notificationCount));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingNotifications(false));
  }
}

function* notificationsRootSaga() {
  yield all([
    takeLatest(LOAD_NOTIFICATIONS, loadNotifications),
    takeLatest(NEW_NOTIFICATIONS_COUNT, countNewNotifications),
    takeLatest(LOAD_NEW_NOTIFICATIONS, loadNewNotifications),
  ]);
}

export default notificationsRootSaga;
