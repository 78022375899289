import { createSelector } from 'reselect';
import { IApplicationState } from '../types';
import { IReportsState } from './types';

export const reportsStateSelector = (state: IApplicationState): IReportsState => state.reports;

export const reportsSelector = createSelector(reportsStateSelector, (state) => state.reportsData);
export const reportsLoadingSelector = createSelector(
  reportsStateSelector,
  (state) => state.isLoading,
);
