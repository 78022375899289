import { RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { EventModal, RejectVacationModal } from '@/components/Modals';
import MobileTimeOff from './components/MobileTimeOff';
import DesktopTimeOff from './components/DesktopTimeOff';
import useTimeOff from './useTimeOff';

const TimeOff: React.FC<RouteComponentProps> = () => {
  const {
    vacationsDetails,
    router,
    onSubmitHandler,
    closeModalHandler,
    openEventModal,
    setEventDate,
    isDesktop,
    users,
    searchName,
    setSearchName,
    fetchUsers,
    usersPagination,
    handleEventModal,
    user,
    onHandleVacation,
    editEventHandler,
    removeEventHandler,
    event,
    selectedYear,
    onCancelVacation,
    vacationToReject,
    closeRejectVacationModal,
    onConfirmCancelVacation,
  } = useTimeOff();

  return (
    <Grid container spacing={isDesktop ? 2 : 0}>
      {isDesktop ? (
        <DesktopTimeOff
          editEventHandler={editEventHandler}
          removeEventHandler={removeEventHandler}
          setOpenEventModal={handleEventModal}
          router={router}
          vacationsDetails={vacationsDetails}
          selectedYear={selectedYear}
          onCancelVacation={onCancelVacation}
          setEventDate={setEventDate}
          onHandleVacation={onHandleVacation}
        />
      ) : (
        <MobileTimeOff
          editEventHandler={editEventHandler}
          removeEventHandler={removeEventHandler}
          setOpenEventModal={handleEventModal}
          router={router}
          vacationsDetails={vacationsDetails}
          selectedYear={selectedYear}
          onCancelVacation={onCancelVacation}
          setEventDate={setEventDate}
          users={users}
          searchName={searchName}
          setSearchName={setSearchName}
          fetchUsers={fetchUsers}
          usersPagination={usersPagination}
          isDesktop={isDesktop}
          user={user}
          onHandleVacation={onHandleVacation}
        />
      )}
      <EventModal
        onSubmitHandler={onSubmitHandler}
        open={openEventModal}
        closeModalHandler={closeModalHandler}
        event={event}
      />
      <RejectVacationModal
        open={!!vacationToReject}
        closeModalHandler={closeRejectVacationModal}
        vacation={vacationToReject}
        onConfirm={onConfirmCancelVacation}
      />
    </Grid>
  );
};

export default TimeOff;
