import { string, object, date } from 'yup';

const contractSchema = object().shape({
  contractName: string().required('The field Contract name is required'),
  startAt: date().required('The field From is required'),
  endAt: date()
    .when(
      'startAt',
      (startAt, yup) => startAt && yup.min(startAt, 'End date cannot be before start date'),
    )
    .required('The field To is required'),
});

export default contractSchema;
