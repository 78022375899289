import { useCallback, ChangeEvent, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import { BackArrow } from '@/components/Icons';
import { DateFormatUtil } from '@/utils';
import clsx from 'clsx';
import pluralize from 'pluralize';
import useStyles from './styles';
import { ITimeOffsAccordionProps } from './types';

const TimeOffsAccordion = ({
  vacationDetails,
  toggleDialog,
  openDialog,
  idx,
  onCancelVacation,
  ...props
}: ITimeOffsAccordionProps): JSX.Element => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<number | null>(null);

  const handleAccordionChange = useCallback(
    (panel: number) => (event: ChangeEvent<unknown>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : null);
    },
    [],
  );
  return (
    <div className={classes.accordionBlock} {...props}>
      <Accordion
        className={classes.accordionStyle}
        square
        expanded={expanded === idx}
        onChange={handleAccordionChange(idx)}
      >
        <AccordionSummary
          expandIcon={<BackArrow className={classes.arrow} />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <div className={classes.projectBlock}>
            <div className={classes.header}>
              <Typography className={classes.name} component="span">
                {vacationDetails.type}
              </Typography>

              <div className={classes.vacationActions}>
                {vacationDetails.status === 'Pending' && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onCancelVacation(vacationDetails);
                    }}
                  >
                    Cancel request
                  </Button>
                )}
                <div
                  className={clsx(
                    classes.status,
                    vacationDetails.status === 'Rejected'
                      ? classes.rejected
                      : classes[vacationDetails.status === 'Approved' ? 'approved' : 'pending'],
                  )}
                >
                  {vacationDetails.status}
                </div>
              </div>
            </div>

            <Typography className={classes.date} variant="body2" component="span">
              {DateFormatUtil.formatToDate(vacationDetails.startDate)} -{' '}
              {DateFormatUtil.formatToDate(vacationDetails.endDate)} ({vacationDetails.duration}{' '}
              {pluralize('day', vacationDetails.duration)})
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.infoBlock}>
          <div className={classes.userInfoRow}>
            <Typography className={classes.infoTitle}>Submitted date:</Typography>

            <Typography className={classes.infoText}>
              {DateFormatUtil.formatToDate(vacationDetails?.startDate)}
            </Typography>
          </div>
          <div>
            <Typography className={classes.infoTitle}>Details:</Typography>
            <Typography className={classes.infoText}>{vacationDetails.details}</Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <div className={classes.dividerBlock}>
        <Divider className={classes.divider} />
      </div>
    </div>
  );
};

export default TimeOffsAccordion;
