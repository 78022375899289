import { enGBLocale } from '@/constants';
import { Box } from '@material-ui/core';
import { DesktopDateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import useStyles from './styles';
import { RangeDatePickerProps } from './types';

export default function VacationRangeDatePicker({
  date,
  setDate,
  componentChildren,
}: RangeDatePickerProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <LocalizationProvider locale={enGBLocale} dateAdapter={AdapterDateFns}>
        <DesktopDateRangePicker
          open={
            date.includes(null) ||
            date.some((dateInst) => dateInst && Number.isNaN(dateInst.getTime()))
          }
          startText="Start"
          allowSameDateSelection={false}
          PopperProps={{
            className: classes.popper,
          }}
          PaperProps={{
            className: classes.paperContent,
          }}
          className={classes.paperContent}
          value={date}
          onChange={setDate}
          inputFormat="dd.MM.yyyy"
          mask="__.__.____"
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} className={classes.dateTextField} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} className={classes.dateTextField} />
            </>
          )}
        />
      </LocalizationProvider>
      {componentChildren}
    </div>
  );
}
