import {
  ITimeOffsState,
  TimeOffsAction,
  STORE_EVENTS,
  SET_LOADING_EVENTS,
  STORE_PENDING_VACATIONS,
  STORE_VACATIONS,
  ALL_EVENTS_STORE,
  STORE_VACATIONS_DETAILS,
  STORE_NEXT_EVENTS,
} from '@/redux/timeOffs';

const initialState: ITimeOffsState = {
  isLoading: false,
  events: {},
  allEvents: {},
  vacations: {},
  nextEvents: [],
  vacationsDetails: [],
  pendingVacations: [],
};

export function timeOffsReducer(
  state: ITimeOffsState = initialState,
  action: TimeOffsAction,
): ITimeOffsState {
  switch (action.type) {
    case SET_LOADING_EVENTS:
      return { ...state, isLoading: action.payload };
    case STORE_EVENTS:
      return { ...state, events: action.payload };
    case ALL_EVENTS_STORE:
      return { ...state, allEvents: action.payload };
    case STORE_VACATIONS:
      return { ...state, vacations: action.payload };
    case STORE_PENDING_VACATIONS:
      return { ...state, pendingVacations: action.payload };
    case STORE_VACATIONS_DETAILS:
      return { ...state, vacationsDetails: action.payload };
    case STORE_NEXT_EVENTS:
      return { ...state, nextEvents: action.payload };

    default:
      return state;
  }
}

export default timeOffsReducer;
